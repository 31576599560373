import { SignInTypes } from "../../models/types";

export const SIGN_IN_STATE: SignInTypes = {
  username: "",
  password: "",
};
export const SIGN_IN_ERROR_STATE: {
  username: string;
  password: string;
  auth: string;
} = {
  username: "",
  password: "",
  auth: "",
};

export const ACCOUNT_TYPE_OPTIONS = [
  { value: 1, label: "Institution" },
  { value: 2, label: "Consultant" },
];
