import { Select } from "antd";
import { DropDownOptionTypes } from "../../models/types";

type DROP_DOWN_PROP_TYPES = {
  id: string;
  className?: string;
  selectClassName?: string;
  value: string;
  onChange: (id: string, value: any) => void;
  onBlur?: () => void;
  options: DropDownOptionTypes[];
  disabled?: boolean;
  size?: "large" | "small";
  label?: string;
  required?: boolean;
  message?: string;
  status?: "error" | "warning" | "";
  placeholder?: string;
  showSearch?: boolean;
};

const DropdownWithInsideLabel = (props: DROP_DOWN_PROP_TYPES) => {
  const {
    id,
    className,
    selectClassName,
    value,
    onChange,
    onBlur,
    options,
    disabled = false,
    size,
    label,
    required = false,
    message,
    status = "",
    placeholder = "Select an option",
    showSearch = false,
  } = props;
  return (
    <div
      className={`flex flex-col mx-2 ${
        className || ""
      } select-with-inside-label`}
      style={{ position: "relative" }}
    >
      <label
        className="mb-1 text-xs text-[#686565]"
        style={{
          position: "absolute",
          zIndex: 2,
          left: 12,
          top: 2,
          fontSize: "10px",
        }}
      >
        {label} {required && <span className="text-[#ff4d4f]">*</span>}
      </label>
      <Select
        showSearch={showSearch}
        id={id}
        className={` ${selectClassName || ""}`}
        value={value || undefined}
        onChange={(newValue) => onChange(id, newValue)}
        options={options}
        disabled={disabled}
        status={status}
        onBlur={onBlur}
        placeholder={placeholder}
        size={size}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
      />
      <span className="error-text">{message}</span>
    </div>
  );
};

export default DropdownWithInsideLabel;
