import { baseUrl, getInstance } from ".";
import { ServiceCharge } from "../models/types";

const instance = getInstance(baseUrl);

export const fetchServiceCharge = async () => {
    return instance.get(`/service-charge/`);
};

export const updateServiceCharge = async (payload: ServiceCharge) => {
    return instance.put(`/service-charge`, payload);
};

export const fetchServiceChargeByFacility = async (facilityId: number) => {
    return instance.get(`/service-charge/facility/${facilityId}`);
};

export const updateServiceChargeForFacility = async (facilityId: number, payload: ServiceCharge) => {
    return instance.put(`/service-charge/facility/${facilityId}`, payload);
};
