import { useEffect, useMemo, useState } from "react";
import OrientationTable from "./OrientationTable";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import "./index.css";
import SideDrawer from "../common/SideDrawer";
import ConfirmationModal from "../common/ConfirmationModal";
import { RightPanel } from "./RightPanel";
import { isEmpty } from "lodash";
import type { Value } from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import Icon from "../common/Icon";
import { TableFilterTypes } from "../../models/types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  approveOrientationRequestThunk,
  getOverlappingJobForOrientationSuggestionThunk,
  suggestOrientationDateThunk,
} from "../../redux/thunks/orientationRequests";
import { useLocation, useNavigate } from "react-router";
import { ApproveDatePayload } from "../../services/orientation.services";
import { resetClientProfile } from "../../redux/reducers/clientsReducer";
import ApproveOrientation from "./ApproveOrientation";
import { getShiftStartEndDateTimes } from "../../helpers/dateTime.helper";
import useFacilityTimezone from "../hooks/useFacilityTimezone";

const Orientation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { timeZoneId } = useFacilityTimezone();
  const jobsCategoryFromState = useAppSelector(
    (state) => state.jobCategoryReducer.jobCategories
  );
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id?.toString() || ""
  );
  const shiftsData = useAppSelector((state) => state.settingsReducer.shifts);

  // to open/close the left drawer
  const [openDrawer, setOpenDrawer] = useState(false);
  // To manage the user profile
  const [profileState, setProfileState] = useState<{
    name: string;
    id: number | null;
  }>({
    name: "",
    id: null,
  });
  // To manage the suggested dates
  const [calendarState, setCalendarState] = useState<{
    name: string;
    id: number | null;
  }>({
    name: "",
    id: null,
  });
  // To show confirmation modal before resetting the data
  const [newSelection, setNewSelection] = useState<{
    name: string;
    id: number | null;
  }>({
    name: "",
    id: null,
  });
  const jobCategoryFilterData: TableFilterTypes[] = useMemo(() => {
    return jobsCategoryFromState.map((item) => ({
      id: item.id,
      label: item.title,
    }));
  }, [jobsCategoryFromState]);
  const [loadedComponent, setLoadedComponent] = useState<
    "calendar" | "profile" | "both" | null
  >(null);
  // Suggested Dates
  const [selectedDates, setSelectedDates] = useState<string[] | []>([]);
  // Confirmation modal
  const [confirmationState, setConfirmationState] = useState({
    show: false,
    okText: "Yes",
    cancelText: "No",
    closeAction: false,
  });
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [approvalState, setApprovalState] = useState<{
    id: number;
    date: string;
  }>();
  const isLoading = useSelector(
    (state: RootState) => state.orientationReducer.loading
  );
  // Triggers when suggested dates are selected
  const onDateSelect = (
    dates: string[] | string | undefined,
    calendarValues: Value
  ) => {
    if (dates && Array.isArray(dates)) {
      setSelectedDates(dates);
    }
    setCalendarValues(calendarValues);
  };
  const [hourlyRate, setHourlyRate] = useState(0);
  const [hourlyRateNotPresent, setHourlyRateNotPresent] = useState(false);
  const [calendarValues, setCalendarValues] = useState<Value>([]);
  const handleSuggest = (
    id: number,
    name: string,
    hourlyRate: number,
    hourlyRateNotPresent: boolean
  ) => {
    // Triggers when user clicks on the Suggest button
    if (!isLoading) {
      setHourlyRate(hourlyRate);
      setHourlyRateNotPresent(hourlyRateNotPresent);
      if (
        !isEmpty(selectedDates) &&
        openDrawer &&
        (loadedComponent === "both" || loadedComponent === "calendar")
      ) {
        setConfirmationState({ ...confirmationState, show: true });
        setNewSelection({
          id,
          name,
        });
      } else {
        setLoadedComponent("calendar");
        setOpenDrawer(true);
        setCalendarState({
          id,
          name,
        });
        setCalendarValues([]);
      }
    }
  };
  const handleDrawerClose = (close: boolean = false) => {
    // Triggered on close button is click when profile is laoded
    if (!isLoading) {
      if (
        !isEmpty(selectedDates) &&
        openDrawer &&
        (loadedComponent === "both" || loadedComponent === "calendar") &&
        !close
      ) {
        setConfirmationState({
          ...confirmationState,
          show: true,
          closeAction: true,
        });
      } else {
        setOpenDrawer(false);
        setCalendarState({ id: null, name: "" });
        setProfileState({ id: null, name: "" });
        setSelectedDates([]);
        dispatch(resetClientProfile());
        setShowApproveModal(false);
      }
    }
  };
  const resetData = (id: number | null, name: string) => {
    // Resets the data when user confirms to load another user's details
    if (!isLoading) {
      if (confirmationState.closeAction) {
        setConfirmationState({
          ...confirmationState,
          show: false,
          closeAction: false,
        });
        setOpenDrawer(false);
      } else {
        setLoadedComponent("calendar");
        setCalendarState({
          id,
          name,
        });
        setConfirmationState({ ...confirmationState, show: false });
      }
      setSelectedDates([]);
      setCalendarValues([]);
    }
  };
  const submitSuggest = (
    dates: string[],
    orientationId: number | null,
    shiftId: string
  ) => {
    if (orientationId) {
      const payload = {
        id: orientationId.toString(),
        shiftId,
        suggestedDates: dates,
        hourlyRate: hourlyRate,
        shiftStartDateTime: "",
        shiftEndDateTime: "",
      };
      const shiftDetails = shiftsData.filter(
        (shift) => Number(shift.id) === Number(payload.shiftId)
      )?.[0];
      const { startTime, endTime } = shiftDetails;
      const tz = timeZoneId;
      const { startDateTime, endDateTime } = getShiftStartEndDateTimes(
        payload.suggestedDates[0],
        startTime,
        endTime,
        true,
        tz
      );
      payload.shiftStartDateTime = startDateTime;
      payload.shiftEndDateTime = endDateTime;
      payload.suggestedDates = payload.suggestedDates.map((d) => {
        const convertedDates = getShiftStartEndDateTimes(
          d,
          startTime,
          endTime,
          true,
          tz
        );
        return convertedDates.startDateTime;
      });
      dispatch(
        suggestOrientationDateThunk(payload, facilityId, timeZoneId, () =>
          handleDrawerClose(true)
        )
      );
    }
  };
  const handleShiftSelection = (
    orientationId: number | null,
    shiftId: string
  ) => {
    if (orientationId) {
      dispatch(
        getOverlappingJobForOrientationSuggestionThunk(orientationId, shiftId)
      );
    }
  };
  const handleApprove = (payload: ApproveDatePayload) => {
    const shiftDetails = shiftsData.filter(
      (shift) => Number(shift.id) === Number(payload.shiftId)
    )?.[0];
    const { startTime, endTime } = shiftDetails;
    const tz = timeZoneId;
    const { startDateTime, endDateTime } = getShiftStartEndDateTimes(
      payload.approvedDate,
      startTime,
      endTime,
      true,
      tz
    );
    payload.approvedDate = startDateTime;
    payload.shiftStartDateTime = startDateTime;
    payload.shiftEndDateTime = endDateTime;
    dispatch(
      approveOrientationRequestThunk(
        payload,
        facilityId,
        timeZoneId,
        handleDrawerClose
      )
    );
  };

  useEffect(() => {
    if (location.state) {
      const { action, orientationId, date, clientFirstName, clientLastName } =
        location.state;
      navigate(location.pathname, { replace: true });
      if (action === "suggest") {
        setLoadedComponent("calendar");
        setCalendarState({
          id: orientationId,
          name: `${clientFirstName} ${clientLastName}`,
        });
        setTimeout(() => {
          setOpenDrawer(true);
        }, 500);
        setCalendarValues([]);
      } else if (action === "approve") {
        setApprovalState({
          id: orientationId,
          date,
        });
        setTimeout(() => {
          setShowApproveModal(true);
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);
  const handleApproveFromNotification = (shiftId: string) => {
    if (approvalState) {
      const payload: ApproveDatePayload = {
        id: approvalState.id.toString(),
        shiftId,
        approvedDate: approvalState.date,
        shiftEndDateTime: "",
        shiftStartDateTime: "",
      };
      handleApprove(payload);
    }
  };
  return (
    <div className="h-full bg-white flex">
      <div className="mr-3 w-full">
        <OrientationTable
          onSuggestClick={handleSuggest}
          isLoading={isLoading}
          onApprove={handleApprove}
          jobCategoryFilterData={jobCategoryFilterData}
          isDrawerOpen={openDrawer}
          closeDrawer={handleDrawerClose}
        />
      </div>
      {openDrawer && (
        <SideDrawer
          open={openDrawer}
          onClose={handleDrawerClose}
          title={
            loadedComponent === "calendar" ? (
              calendarState.name
            ) : loadedComponent === "both" ? (
              <div
                role="button"
                className="w-fit"
                onClick={() => setLoadedComponent("calendar")}
              >
                <Icon name="keyboard_backspace" />
              </div>
            ) : (
              ""
            )
          }
          onSubmit={() => {}}
          showFooter={false}
        >
          <RightPanel
            profileState={profileState}
            calendarState={calendarState}
            component={loadedComponent}
            onDateSelection={onDateSelect}
            selectedDates={selectedDates}
            calendarValues={calendarValues}
            isLoading={isLoading}
            hourlyRateNotPresent={hourlyRateNotPresent}
            onSuggest={(shiftId: string) =>
              submitSuggest(selectedDates, calendarState.id, shiftId)
            }
            onShiftSelection={(shiftId: string) =>
              handleShiftSelection(calendarState.id, shiftId)
            }
          />
        </SideDrawer>
      )}
      {confirmationState.show && (
        <ConfirmationModal
          open={confirmationState.show}
          text={t("alertMessages.discardDates")}
          handleYes={() => resetData(newSelection.id, newSelection.name)}
          handleNo={() =>
            setConfirmationState({
              ...confirmationState,
              show: false,
              closeAction: false,
            })
          }
        />
      )}
      {showApproveModal && (
        <ApproveOrientation
          show={showApproveModal}
          hourlyRateNotPresent={hourlyRateNotPresent}
          onHide={() => {
            setShowApproveModal(false);
            setApprovalState(undefined);
          }}
          onApprove={handleApproveFromNotification}
        />
      )}
    </div>
  );
};

export default Orientation;
