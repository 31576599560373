import React, { useState } from "react";

type ToolTipType = {
  title: string;
  children: any;
};

const Tooltip = (props: ToolTipType) => {
  const { title, children } = props;
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = (e: any) => {
    setTooltipVisible(true);
    if (e?.target?.closest(".ant-table-cell-ellipsis")?.style?.overflow) {
      e.target.closest(".ant-table-cell-ellipsis").style.overflow = "initial";
    }
  };

  const handleMouseLeave = (e: any) => {
    setTooltipVisible(false);
    if (e?.target?.closest(".ant-table-cell-ellipsis")?.style?.overflow) {
      e.target.closest(".ant-table-cell-ellipsis").style.overflow = "initial";
    }
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={(e) => handleMouseEnter(e)}
      onMouseLeave={(e) => handleMouseLeave(e)}
    >
      {children}
      {isTooltipVisible && <div className="tooltip">{title}</div>}
    </div>
  );
};

export default Tooltip;
