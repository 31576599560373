import { Button } from "antd";
import { useMemo } from "react";
import Icon from "../common/Icon";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { markAsPaidThunk } from "../../redux/thunks/invoices";
import { useTranslation } from "react-i18next";
import { FacilityInvoiceTypes } from "../../models/types";
import { showToast } from "../../redux/reducers/feedbackReducer";
import { downloadInvoiceDocumentThunk } from "../../redux/thunks/app";

type Props = {
  selectedInvoices: FacilityInvoiceTypes[];
  resetRowSelection: () => void;
};
const ActionButtons = (props: Props) => {
  const { selectedInvoices, resetRowSelection } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id?.toString() || ""
  );
  const markAsPaid = () => {
    const invoices = selectedInvoices.map((item) => item.id);
    dispatch(markAsPaidThunk(invoices, "facility", Number(facilityId)));
    resetRowSelection();
  };

  const downloadInvoice = () => {
    const { invoiceId, invoiceNumber } = selectedInvoices.map((item) => ({
      invoiceId: item.id,
      invoiceNumber: item.invoiceNumber,
    }))?.[0];

    if (invoiceId) {
      dispatch(downloadInvoiceDocumentThunk(invoiceId, invoiceNumber));
    } else {
      dispatch(
        showToast({
          type: "error",
          message: `Invoice is not available, please contact the admin`,
          show: true,
        })
      );
    }
    resetRowSelection();
  };
  const disableMarkAsPaid = useMemo(() => {
    const paidInvoiceSelected = selectedInvoices.some(
      (item) => item.paymentStatus === "paid" || item.paymentStatus === "no due"
    );
    return !selectedInvoices.length || paidInvoiceSelected;
  }, [selectedInvoices]);

  const disableDownload = useMemo(() => {
    return !selectedInvoices.length;
  }, [selectedInvoices]);
  return (
    <div className="flex action-buttons mr-4">
      {true && (
        <Button
          id="mark-as-paid-btn"
          icon={<Icon name="done" fontSize={16} className="mr-1" />}
          className="mx-3 flex justify-center items-center secondary-btn"
          onClick={markAsPaid}
          size="small"
          disabled={disableMarkAsPaid}
        >
          {t("invoice.markAsPaid")}
        </Button>
      )}
      <Button
        type="primary"
        id="download-invoice-btn"
        icon={<Icon name="download" fontSize={16} className="mr-1" />}
        className="flex justify-center items-center"
        onClick={downloadInvoice}
        size="small"
        disabled={disableDownload}
      >
        {t("invoice.downloadPDF")}
      </Button>
    </div>
  );
};

export default ActionButtons;
