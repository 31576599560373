import { baseUrl, getInstance } from ".";
import { Address } from "../helpers/googlePlacesHelpers";
import { CreateClientTypes, CurrentEmployerTypes, EducationTypes, UpdateEmailTypes, UpdatePhoneTypes, UserBankTypes, WorkEligibility, WorkHistoryTypes } from "../models/types";

const instance = getInstance(baseUrl);

export const fetchAllClients = async () => {
  return instance.get(`/client`);
};

export const fetchClientsByFacility = async (facilityId: number) => {
  return instance.get(`/client/facility/${facilityId}`);
};

export const fetchClientById = async (clientId: number) => {
  return instance.get(`/client/${clientId}`);
};

export const fetchClientProfileById = async (clientId: number) => {
  return instance.get(`/client/profile/${clientId}`);
};

export const approveClientDocument = async (documentId: number) => {
  return instance.put(`/client/approve-document/${documentId}`);
};

export const approveClientProfile = async (userId: number) => {
  return instance.put(`/client/approve-profile/${userId}`);
};
export const putClientProfileOnHold = async (userId: number) => {
  return instance.put(`/client/put-profile-on-hold/${userId}`);
};

export const disableClientForFacility = async (userId: number, facilityId: number) => {
  return instance.put(`/client/disable-user/${userId}/facility/${facilityId}`);
};

export const enableClientForFacility = async (userId: number, facilityId: number) => {
  return instance.put(`/client/enable-user/${userId}/facility/${facilityId}`);
};

export const createClient = async (data: CreateClientTypes) => {
  return instance.post("/account/create-client", data);
};

export const updateWorkEligibility = async (data: WorkEligibility) => {
  if (data.id) {
    return instance.put(`/client/work-eligibility`, data);
  } else {
    return instance.post(`/client/work-eligibility`, data);
  }
};

export const updateBankInfo = async (data: UserBankTypes) => {
  if (data.id) {
    return instance.put(`/payment/payout`, data);
  } else {
    return instance.post(`/payment/payout`, data);
  }
};

export const updateWorkHistory = async (data: WorkHistoryTypes) => {
  if (data.id) {
    return instance.put(`/client/work-history`, data);
  } else {
    return instance.post(`/client/work-history`, data);
  }
};

export const updateEducationInfo = async (data: EducationTypes) => {
  if (data.id) {
    return instance.put(`/client/education`, data);
  } else {
    return instance.post(`/client/education`, data);
  }
};

export const uploadLogo = async (payload: any) => {
  return instance.postForm("/file/upload", payload);
};

export const updateCurrentEmployment = async (data: CurrentEmployerTypes) => {
  return instance.put(`/client/add-update-employers`, data);
};

export const updateAddress = async (data: Address) => {
  if (data.id) {
    return instance.put(`/client/address`, data);
  } else {
    return instance.post(`/client/address`, data);
  }
};

export const setDocumentCompletion = async (data: any) => {
  return instance.post(`/client/submit-documents`, data);
};

export const getClientDocuments = async (userId: number) => {
  return instance.get(`/client/documents/${userId}`);
}
export const getCurrentEmployer = async (userId: number) => {
  return instance.get(`/client/current-employers/${userId}`);
};

export const updatePhoneNumber = async (data: UpdatePhoneTypes) => {
  return instance.put(`/account/web/update-phone`, data);
};

export const updateEmail = async (data: UpdateEmailTypes) => {
  return instance.put(`/account/web/update-email`, data);
};

export const  getUpcomingJobsByClientId= async (userId: Number) => {
  return instance.get(`/job/upcoming/${userId}`);
};
export const getActiveJobsByClientId = async (userId: number) => {
  return instance.post(`/job/active-job/${userId}`);
};





