/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import { Layout } from "antd";
import Sidenav from "./header/Sidenav";
import "./layout.css";
import NewHeader from "./header/NewHeader";
const { Content, Sider } = Layout;

function Main(props: { children: React.ReactNode }) {
  const { children } = props;
  const [collapseSidebar, setCollapsedSidebar] = useState(false);
  return (
    <Layout className="bg-white h-[98vh]">
      <NewHeader onCollapse={() => setCollapsedSidebar(!collapseSidebar)} />
      <Content className="pr-3 pt-2">
        <Layout className="bg-white h-full">
          <Sider
            className="shadow-md overflow-auto"
            style={{ background: "#fff" }}
            width={200}
            collapsible
            collapsed={collapseSidebar}
            trigger={null}
            collapsedWidth={61}
          >
            <Sidenav />
          </Sider>
          <Content className="ml-3 h-full overflow-auto">{children}</Content>
        </Layout>
      </Content>
    </Layout>
  );
}

export default Main;
