import React, { useCallback, useMemo } from "react";
import { DayPropTypes } from "../../../models/day.model";
import "./day.css";
import AddButtonDropdown from "../AddButtonDropdown";
import { useTranslation } from "react-i18next";
import Icon from "../../common/Icon";
import dayjs from "dayjs";
import { Skeleton } from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getJobsByDateThunk } from "../../../redux/thunks/shiftSchedule";
import {
  getTimeStringInTimezone,
  getTodayInTimeZone,
} from "../../../helpers/dateTime.helper";
import useFacilityTimezone from "../../hooks/useFacilityTimezone";
import { setJobsForSelectedDay } from "../../../redux/reducers/shiftScheduleReducer";

const Day = (props: DayPropTypes) => {
  const { today, day, showSummary, jobsSummary, showPanel } = props;
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id?.toString() || ""
  );
  const { timeZoneId } = useFacilityTimezone();

  const isPreviousDay = useMemo(() => {
    return (
      getTimeStringInTimezone(day, timeZoneId) <
      getTodayInTimeZone(timeZoneId).add(-1, "day")
    );
  }, [day, timeZoneId]);

  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    const jobsExists = jobsSummary?.total ? true : false;
    if (jobsExists) {
      dispatch(getJobsByDateThunk({ date: day, facilityId }));
    } else {
      dispatch(
        setJobsForSelectedDay({
          selectedDate: day,
          data: [],
        })
      );
    }
    showSummary && showSummary(day, jobsExists);
  }, [jobsSummary?.total, showSummary, day, dispatch, facilityId]);

  const { t } = useTranslation();

  const Label = (props: {
    children: React.ReactNode;
    text: string;
    type: "blue" | "green" | "red" | "purple" | "orange";
  }) => {
    const { children, text, type } = props;
    const color = {
      orange: "bg-[#ffa500]",
      blue: "bg-[#55acee]",
      green: "bg-[#87d068]",
      red: "bg-[#cd201f]",
      purple: "bg-[#413F88]",
    };
    return (
      <div
        id="tag"
        className={`flex items-center m-1 justify-start pl-1 py-[2px] ${color[type]} text-white`}
        style={{ borderRadius: "4px" }}
      >
        {children}
        <div
          className="text-xs ml-1 overflow-hidden h-[16px] justify-self-start"
          id="tag-text"
        >
          {text}
        </div>
      </div>
    );
  };
  const isLoading = useAppSelector((state) => state.appReducer.loading);
  const isFacilityEnabled = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.enabled
  );

  const handleAddClick = useCallback(
    (jobCategory: string) => {
      showPanel && showPanel(jobCategory, day);
    },
    [showPanel, day]
  );
  const handleSummaryClick = useCallback(
    (event: any) => {
      const id = event.target?.id;
      if (
        id &&
        (id === "date-grid" ||
          id === "tags-container" ||
          id === "tag" ||
          id === "tag-text")
      ) {
        handleClick();
      }
    },
    [handleClick]
  );
  if (isLoading) {
    return (
      <div className="p-4">
        <Skeleton active paragraph={{ rows: 2 }} />
      </div>
    );
  }
  return (
    <div
      role="button"
      className="h-full text-center flex flex-col justify-between grid-container"
      id="date-grid"
      onClick={handleSummaryClick}
    >
      <div className="date-container relative">
        <span
          className={today ? "today" : "other-day"}
          onClick={handleSummaryClick}
        >
          {dayjs(day).format("DD")}
        </span>
        {!isPreviousDay && isFacilityEnabled && (
          <AddButtonDropdown handleSelection={handleAddClick}>
            <span
              role="button"
              className="date-add-button absolute top-2 right-2"
              onClick={(e) => e.preventDefault()}
            >
              <Icon name="add" fontSize={14} />
            </span>
          </AddButtonDropdown>
        )}
      </div>
      {jobsSummary && jobsSummary.total > 0 && (
        <div id="tags-container">
          {jobsSummary.total > 0 && (
            <Label
              text={`${jobsSummary.total} ${t("jobSchedule.totalJobs")}`}
              type="purple"
            >
              <Icon name="insert_invitation" fontSize={14} />
            </Label>
          )}
          {jobsSummary.open > 0 && (
            <Label
              text={`${jobsSummary.open} ${
                isPreviousDay
                  ? t("jobSchedule.unFilled")
                  : t("jobSchedule.openJobs")
              }`}
              type="red"
            >
              <Icon name="help_outline" fontSize={14} />
            </Label>
          )}
          {jobsSummary.progress > 0 && (
            <Label
              text={`${jobsSummary.progress} ${t(
                "jobSchedule.inProgressJobs"
              )}`}
              type="orange"
            >
              <Icon name="check_circle" fontSize={14} />
            </Label>
          )}
          {jobsSummary.scheduled > 0 && (
            <Label
              text={`${jobsSummary.scheduled} ${t(
                "jobSchedule.scheduledJobs"
              )}`}
              type="blue"
            >
              <Icon name="help_outline" fontSize={14} />
            </Label>
          )}
          {jobsSummary.completed > 0 && (
            <Label
              text={`${jobsSummary.completed} ${t(
                "jobSchedule.completedJobs"
              )}`}
              type="green"
            >
              <Icon name="help_outline" fontSize={14} />
            </Label>
          )}
        </div>
      )}
    </div>
  );
};

export default Day;
