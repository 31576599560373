import { useMemo, useState } from "react";
import { Upload, UploadProps } from "antd";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../common/ConfirmationModal";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Icon from "../../common/Icon";
import { uploadFileThunk } from "../../../redux/thunks/clients";
import { getRequiredDocumentList } from "../../../helpers/documents.helper";

type DocumentUploadFormProps = {
  showForm: boolean;
  hideForm: () => void;
};

const DocumentUploadForm = (props: DocumentUploadFormProps) => {
  const { showForm, hideForm } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clientProfile = useAppSelector(
    (state) => state.clientsReducer.clientProfile
  );
  const clientDocuments = useAppSelector(state => state.clientsReducer.clientProfile.documents)
  
  const [fileList] = useState<any>({});
  const [title, setTitle] = useState<string>("");
  const isDocUploadInProgress = useAppSelector(
    (state) => state.clientsReducer.isDocUploadInProgress
  );
  const documentTitles: any = useMemo(() => {
    return getRequiredDocumentList(
      clientProfile.jobTemplateId,
      clientProfile?.workEligibility?.legalStatus
    );
  }, []);
  const handleUpload = (info: any) => {
    if (info.file) {
      const form = new FormData();
      form.append("userId", clientProfile?.id?.toString() || "");
      form.append("documentType", title);
      form.append("title", title);
      form.append("files", info.file);
      dispatch(uploadFileThunk(form, clientProfile?.id, handleClose));
    }
  };

  const uploadProps: UploadProps = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    showUploadList: false,
    accept: "application/pdf",
    multiple: false,
    onChange: handleUpload,
  };

  const handleValidation = () => {};

  const handleClose = () => {
    hideForm();
  };
  const doesDocExists = (key: string) => {
    const select = (clientDocuments ?? [])?.filter(doc => doc.title === key)
    return select?.length > 0
  }
  return (
    <ConfirmationModal
      open={showForm}
      title="Upload user documents"
      handleYes={handleValidation}
      handleNo={hideForm}
      hideSecondaryButton={true}
      hidePrimaryButton={true}
      primaryButtonText=""
      secondaryButtonText={t("common.close") || ""}
      text={
        <div className="pt-4">
          {Object.keys(documentTitles).map((doc, idx) => (
            <div key={`${documentTitles[doc]}_${idx}`} className="mb-3">
              <div className="grid grid-cols-9">
                <div className="col-span-5 flex items-center">
                  <div className="flex-wrap flex items-center gap-2">
                    <span>{documentTitles[doc]}</span>
                    {doesDocExists(doc) && (
                      <Icon
                        fontSize={16}
                        name="check_circle"
                        className="text-[#52c41a]"
                      />
                    )}
                  </div>
                </div>
                <div className="col-span-4 flex justify-end gap-4">
                  <div className="flex items-center gap-2">
                    <Upload
                      disabled={isDocUploadInProgress}
                      {...uploadProps}
                      onChange={handleUpload}
                      beforeUpload={() => {
                        setTitle(doc);
                        return false;
                      }}
                      fileList={fileList[doc]}
                    >
                      <div className="icon-wrapper" role="button">
                        <Icon name="upload" fontSize={16} />
                      </div>
                    </Upload>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      }
    />
  );
};

export default DocumentUploadForm;
