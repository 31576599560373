import { DATE_MONTH_YEAR } from "../../../constants/defaults";
import { getDateByFormat } from "../../../helpers/helpers";
import { EducationTypes } from "../../../models/types";
import Icon from "../Icon";

const Education = (props: { education: EducationTypes[] }) => {
  const { education } = props;
  if (!education || !education.length) {
    return null;
  }
  return (
    <div className="flex">
      <Icon name="school" fontSize={18} className="mt-[2px]" />
      <div className="ml-2">
        {education.map((item) => {
          const startDate = getDateByFormat(item.startDate, DATE_MONTH_YEAR);
          const endDate = item.endDate
            ? getDateByFormat(item.endDate, DATE_MONTH_YEAR)
            : "Present";
          return (
            <div key={item.id} className="mb-3">
              <div className="font-semibold">{`${item.degree}, ${item.fieldOfStudy}`}</div>
              <div className="text-sm text-black">{item.school}</div>
              <div className="text-grey text-sm">{`${startDate} to ${endDate}`}</div>
              <div className="text-grey text-sm">{`Grade: ${item.grade}`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Education;
