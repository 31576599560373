import { useState } from "react";
import Icon from "../common/Icon";
import { useTranslation } from "react-i18next";
import { ContactTypes } from "../../models/types";
import InputField from "../common/InputField";
import ConfirmationModal from "../common/ConfirmationModal";

type ContactFormTypes = {
  contactData: ContactTypes[];
  deleteContact: (index: number) => void;
  handleContactChange: (
    index: number,
    id: string,
    value: string | number | null
  ) => void;
  errors: ContactTypes[];
};
const ContactForm = (props: ContactFormTypes) => {
  const { t } = useTranslation();
  const { contactData, deleteContact, handleContactChange, errors } = props;
  // State for confirmation modal
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  // State to store the index of secondary contact being deleted
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const handlePhoneChange = (
    index: number,
    id: string,
    value: string | number | null
  ) => {
    let val = value?.toString() || "";
    // Checking if the value has only numbers and space
    const phonePattern = /^[0-9 ]*$/;

    if (val.length > 12 || !phonePattern.test(val)) {
      return;
    }
    if (id === "mobile" || id === "officePhone") {
      if (
        (val.length === 3 || val.length === 7) &&
        contactData[index][id] &&
        val.length > contactData[index][id].length
      ) {
        val = val + " ";
      }
      // Checking if the value has only numbers and space
      handleContactChange(index, id, val);
    }
  };
  const handleDelete = () => {
    deleteIndex && deleteContact(deleteIndex);
    setShowConfirmation(false);
    setDeleteIndex(null);
  };
  return (
    <div className="px-2">
      {contactData.map((contact, index) => (
        <div key={index} className="border my-5 relative rounded-md">
          <span className="blue-text absolute -top-[11px] left-4 px-1 bg-white">
            {contact.isPrimary
              ? t("facilities.primary")
              : t("facilities.secondary")}
          </span>
          {!contact?.isPrimary && (
            <Icon
              name="delete"
              role="button"
              className="blue-text absolute -top-[11px] right-4 p-[4px] bg-white hover:bg-[#f1f3f4] rounded-[50%]"
              fontSize={16}
              onClick={() => {
                setShowConfirmation(true);
                setDeleteIndex(index);
              }}
            />
          )}
          <div className="pt-4 px-2">
            <InputField
              type="text"
              id="name"
              label={t("facilities.name") || ""}
              value={contact?.name}
              onChange={(id: string, value: string | number | null) =>
                handleContactChange(index, id, value)
              }
              required={true}
              message={errors[index].name}
              status={errors[index].name ? "error" : ""}
            />
            <InputField
              type="text"
              id="designation"
              label={t("facilities.designation") || ""}
              value={contact.designation}
              onChange={(id: string, value: string | number | null) =>
                handleContactChange(index, id, value)
              }
              className="col-span-2"
              required={true}
              message={errors[index].designation}
              status={errors[index].designation ? "error" : ""}
            />
            <InputField
              type="text"
              id="mobile"
              addonBefore="+1"
              label={t("facilities.mobile") || ""}
              value={contact.mobile}
              onChange={(id: string, value: string | number | null) =>
                handlePhoneChange(index, id, value)
              }
              className="col-span-4"
              message={errors[index].mobile}
              status={errors[index].mobile ? "error" : ""}
              required
            />
            <div className="grid grid-cols-6">
              <InputField
                type="text"
                id="officePhone"
                label={t("facilities.office") || ""}
                value={contact.officePhone}
                addonBefore="+1"
                onChange={(id: string, value: string | number | null) =>
                  handlePhoneChange(index, id, value)
                }
                className="col-span-4"
                message={errors[index].officePhone}
                status={errors[index].officePhone ? "error" : ""}
              />
              <InputField
                type="number"
                id="extension"
                label={t("facilities.ext") || ""}
                value={contact.extension}
                onChange={(id: string, value: string | number | null) =>
                  handleContactChange(index, id, value)
                }
                className="col-span-2"
              />
            </div>
            <InputField
              type="text"
              id="email"
              label={t("facilities.email") || ""}
              value={contact.email}
              onChange={(id: string, value: string | number | null) =>
                handleContactChange(index, id, value)
              }
              onBlur={(id: string, value: string | number | null) =>
                handleContactChange(
                  index,
                  id,
                  value?.toString().toLowerCase() || ""
                )
              }
              message={errors[index].email}
              status={errors[index].email ? "error" : ""}
              required
            />
          </div>
        </div>
      ))}
      {showConfirmation && (
        <ConfirmationModal
          open={showConfirmation}
          text={t("alertMessages.deleteContactInfo")}
          handleNo={() => {
            setShowConfirmation(false);
            setDeleteIndex(null);
          }}
          handleYes={handleDelete}
        />
      )}
    </div>
  );
};

export default ContactForm;
