import { Popover } from "antd";
import { useAppSelector } from "../../redux/hooks";
import Icon from "../common/Icon";
import { useMemo } from "react";
import { getRequiredDocumentList } from "../../helpers/documents.helper";

const DocumentProgressDetails = () => {
  const clientProfile = useAppSelector(
    (state) => state.clientsReducer.clientProfile
  );

  const documentTitles: any = useMemo(() => {
    return getRequiredDocumentList(
      clientProfile.jobTemplateId,
      clientProfile?.workEligibility?.legalStatus
    );
  }, [clientProfile?.workEligibility?.legalStatus, clientProfile?.jobTemplateId]);

  const isDocumentUploadCompleted = useMemo(() => {
    return Object.keys(documentTitles).every((docKey) => {
      return (
        clientProfile.documents?.filter((doc) => doc.title === docKey)?.length >
        0
      );
    });
  }, [
    clientProfile.documents,
   documentTitles]);
  const content = (
    <div className="px-4">
      <ul>
        {Object.keys(documentTitles).map((docKey) => {
          const filteredDoc = clientProfile.documents?.filter(
            (doc) => doc.title === docKey
          );
          const isDocAvailable = filteredDoc?.length > 0;
          const docStatus = filteredDoc?.[0]?.documentStatus === "Approved";
          return (
            <li key={docKey}>
              <p className="flex items-center gap-1">
                <span>{documentTitles[docKey]}</span>
                <Icon
                  name={isDocAvailable ? "check_circle" : "cancel"}
                  fontSize={16}
                  className={
                    isDocAvailable ? "text-[#52c41a]" : "text-[#ff4d4f]"
                  }
                />
                {docStatus ? (
                  <>
                    | <span className="text-xs">Approved</span>
                    <Icon
                      name="select_check_box"
                      fontSize={16}
                      className="text-[#52c41a]"
                    />
                  </>
                ) : isDocAvailable ? (
                  <>
                    | <span className="text-xs">Not Approved</span>
                    <Icon
                      name="pending"
                      fontSize={16}
                      className="text-[#ff4d4f]"
                    />
                  </>
                ) : null}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
  return (
    <div>
      <Popover content={content} title=" Document Status" trigger="hover">
        <div role="button" className="flex justify-between items-center">
          <Icon
            name="info"
            fontSize={20}
            className={
              !clientProfile?.profileStatus?.isDocumentCompletion || !isDocumentUploadCompleted
                ? "text-[#ff4d4f]"
                : "text-[#52c41a]"
            }
          />
        </div>
      </Popover>
    </div>
  );
};

export default DocumentProgressDetails;
