import { configureStore, combineReducers } from "@reduxjs/toolkit";
import appReducer, { AppReducerState } from "./reducers/appReducer";
import jobCategoryReducer, {
  JobCategoryReducerState,
} from "./reducers/jobCategoryReducer";
import settingsReducer, {
  SettingsReducerState,
} from "./reducers/settingsReducer";
import orientationReducer, {
  OrientationReducerState,
} from "./reducers/orientationReducer";
import clientsReducer, { ClientsReducerState } from "./reducers/clientsReducer";
import invoiceReducer, { InvoiceReducerState } from "./reducers/invoices";
import facilitiesReducer, {
  FacilitiesReducerState,
} from "./reducers/facilitiesReducer";
import shiftApprovalReducer, {
  ShiftApprovalReducerState,
} from "./reducers/shiftApprovalReducer";
import shiftScheduleReducer, {
  ShiftScheduleReducerState,
} from "./reducers/shiftScheduleReducer";
import notificationsReducer, {
  NotificationsReducerState,
} from "./reducers/notificationsReducer";
import feedbackReducer, {
  FeedbackReducerState,
} from "./reducers/feedbackReducer";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import ServiceChargeReducer, { ServiceChargeReducerState } from "./reducers/serviceChargeReducer";
import UpcomingJobsReducer, { UpcomingJobsReducerState } from "./reducers/upcomingJobsReducer";

const persistConfig = {
  key: "root",
  version: 2,
  storage,
  whitelist: ["appReducer", "facilitiesReducer", "settingsReducer", "jobCategoryReducer"],
};
const rootReducer = combineReducers({
  appReducer,
  shiftScheduleReducer,
  jobCategoryReducer,
  settingsReducer,
  orientationReducer,
  clientsReducer,
  invoiceReducer,
  facilitiesReducer,
  shiftApprovalReducer,
  notificationsReducer,
  feedbackReducer,
  ServiceChargeReducer,
  UpcomingJobsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== "production",
});
export type RootState = {
  appReducer: AppReducerState;
  shiftScheduleReducer: ShiftScheduleReducerState;
  jobCategoryReducer: JobCategoryReducerState;
  settingsReducer: SettingsReducerState;
  orientationReducer: OrientationReducerState;
  clientsReducer: ClientsReducerState;
  invoiceReducer: InvoiceReducerState;
  facilitiesReducer: FacilitiesReducerState;
  shiftApprovalReducer: ShiftApprovalReducerState;
  notificationsReducer: NotificationsReducerState;
  feedbackReducer: FeedbackReducerState;
  ServiceChargeReducer: ServiceChargeReducerState;
  UpcomingJobsReducer: UpcomingJobsReducerState;
};
// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
