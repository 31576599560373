import dayjs, { Dayjs } from "dayjs";
import * as Sentry from '@sentry/react';
import {
  FacilityTypeTypes,
  JobCategoryTypes,
  ProvinceTypes,
  ShiftIntervalTimeTypes,
  ShiftTypes,
  orientationStatusTypes,
} from "../models/types";
import relativeTime from "dayjs/plugin/relativeTime";
import { getTimeStringInTimezone, getTodayInTimeZone } from "./dateTime.helper";
import { showToast } from "../redux/reducers/feedbackReducer";
dayjs.extend(relativeTime);

export const getJobCategoryName = (data: JobCategoryTypes[], jobId: string) => {
  return data.filter((item) => item.id === jobId)?.[0]?.title || "";
};
export const getShiftName = (data: ShiftTypes[], shiftId: string) => {
  return data.filter((item) => item.id === shiftId)?.[0]?.title || "";
};
export const getProvinceName = (data: ProvinceTypes[], provinceId: string) => {
  return data.filter((item) => item.id === provinceId)?.[0]?.title || "";
};
export const getFacilityType = (
  data: FacilityTypeTypes[],
  facilityId: string
) => {
  return data.filter((item) => item.id === facilityId)?.[0]?.title || "";
};

export const getShiftIntervals = (): ShiftIntervalTimeTypes[] => {
  const intervals = [];
  let h: number = 0;
  let hours: number = 0;
  let m: number = -15;
  for (let i = 0; i < 96; i++) {
    m = m + 15;
    if (m === 60) {
      m = 0;
      h = h + 1;
      hours = hours + 1;
    }
    let hourString = h < 10 ? "0" + h : h.toString();
    let minutes = m < 10 ? "0" + m : m;
    let entry = `${hourString}:${minutes}`;
    intervals.push({
      label: entry,
      key: entry,
      value: entry,
      id: `${hours}:${minutes}`,
    });
  }
  return intervals;
};

export const getTotalHours = (
  shiftIntervalData: ShiftIntervalTimeTypes[],
  startTime: string,
  finishTime: string
): string => {
  const startTimeId =
    shiftIntervalData.filter((item) => item.key === startTime)?.[0].id ||
    "0:00";
  const finishTimeId =
    shiftIntervalData.filter((item) => item.key === finishTime)?.[0].id ||
    "0:00";
  const [startH, startM] = startTimeId.split(":");
  const [finishH, finishM] = finishTimeId.split(":");
  let finalM: string | number = Number(finishM) - Number(startM);
  let finalH = Math.abs(Number(finishH) - Number(startH));
  if (Math.sign(finalM) === -1) {
    finalH = finalH - 1;
    finalM = 60 + finalM;
  }
  if (finalM < 10) {
    finalM = "0" + finalM;
  }

  return `${finalH}:${finalM}`;
};

export const handleApiErrors = (err: any, dispatch: any) => {
  let error = "Something went wrong, please try later.";
  if (err?.response?.status !== 401) {
    if (typeof err.response?.data?.errors === "string") {
      error = err.response?.data?.errors;
    }
    dispatch(
      showToast({
        type: "error",
        message: error,
        show: true,
      })
    );
  }
};

export const logToSentry = (error: any, moduleName: string, functionName: string) => {
  Sentry.withScope((scope) => {
    scope.setTag('module', moduleName); 
    scope.setTag('function', functionName);
    Sentry.captureException(error);
  });
};

export const logComponentErrorToSentry = (error: any, errorInfo: any) => {
  Sentry.withScope((scope) => {
    scope.setTag('componentStack', errorInfo?.componentStack); 
    scope.setTag('digest', errorInfo?.digest); 
    Sentry.captureException(error);
  });
};

export const selectedJobCategoryData = (
  id: string,
  jobCategoryData: JobCategoryTypes[]
) => {
  return jobCategoryData?.filter(
    (element) => Number(element.id) === Number(id)
  )?.[0];
};

export const selectedShift = (id: string, shiftsData: ShiftTypes[]) => {
  return shiftsData?.filter((element) => element.id === id)?.[0];
};

export const getOrientationStatusObjs = (data: orientationStatusTypes[]) => {
  const result: any = {};
  data.forEach((o) => {
    result[o.title] = o.id;
  });
  return result;
};

export const getDateByFormat = (date: string | Date, format: string) => {
  return dayjs(date).format(format);
};

export const getTimeDiff = (startTime: string, endTime: string) => {
  if (!startTime || !endTime) {
    return "00:00";
  }

  startTime = startTime.replace(/am|AM/, "#am").replace(/pm|PM/, "#pm");
  endTime = endTime.replace(/am|AM/, "#am").replace(/pm|PM/, "#pm");

  let [startHour, startMinute, startMeridian] = startTime.split(/[.:#]/);
  let [endHour, endMinute, endMeridian] = endTime.split(/[.:#]/);

  if (startMeridian === "am") {
    startHour = startHour === "12" ? "0" : startHour;
  } else {
    startHour =
      startHour === "12" ? "12" : (parseInt(startHour) + 12).toString();
  }

  if (endMeridian === "am") {
    endHour = endHour === "12" ? "0" : endHour;
  } else {
    endHour = endHour === "12" ? "12" : (parseInt(endHour) + 12).toString();
  }

  let finalMinute: string | number = Number(endMinute) - Number(startMinute);
  let finalHour = Number(endHour) - Number(startHour);
  if (finalHour < 0) {
    finalHour = 24 + finalHour;
  }
  if (Math.sign(finalMinute) === -1) {
    finalHour = finalHour - 1;
    finalMinute = 60 + finalMinute;
  }
  if (finalMinute < 10) {
    finalMinute = "0" + finalMinute;
  }

  return `${finalHour}:${finalMinute}`;
};

export const getInitials = (firstName: string, lastName: string) => {
  return `${firstName[0]}${lastName[0]}`;
};

export const getHoursWorkedInDecimals = (
  startTime: string,
  endTime: string
) => {
  const totalTime = getTimeDiff(startTime, endTime);
  const [hour, minute] = totalTime.split(/[.:]/);

  const fraction = parseFloat(minute) / 60;

  return parseFloat(hour) + fraction;
};

export const getFromNow = (date: string) => {
  return dayjs(date).fromNow();
};

export const disableShift = (
  shiftDate: string | undefined,
  startTime: string,
  tz: string
) => {
  if (!shiftDate) {
    return false;
  }
  const todayForFacility = getTodayInTimeZone(tz);
  const isCurrentDate =
    dayjs(shiftDate).format("YYYY-MM-DD") ===
    todayForFacility.format("YYYY-MM-DD");
  if (isCurrentDate) {
    let jobDate: string | Dayjs = `${shiftDate} ${startTime}`;
    jobDate = getTimeStringInTimezone(jobDate, tz);
    return todayForFacility.isAfter(jobDate);
  }
  return false;
};

export const getJobCategory = (jobCategory?: string) => {
  if (!jobCategory) {
    return "";
  }
  jobCategory = jobCategory.toLowerCase();
  if (jobCategory.startsWith("rn")) {
    return "RN";
  } else if (jobCategory.startsWith("lpn")) {
    return "LPN";
  } else if (jobCategory.startsWith("cca")) {
    return "CCA";
  } else if (jobCategory.startsWith("sitter")) {
    return "Sitter Service";
  } else {
    return "";
  }
};


