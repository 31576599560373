import { Button, Modal } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ShiftApprovalRequestType } from "../../models/types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ShiftInterval from "../common/ShiftInterval";
import { SHIFT_APPROVAL_REQUEST_TEMPLATE } from "./constant";
import { getHoursWorkedInDecimals } from "../../helpers/helpers";
import { approveShiftRequestThunk } from "../../redux/thunks/shiftApproval";
import {
  getShiftStartEndDateTimes,
  getTimeDiffFrom24HTime,
  getTimeFromUtcDate,
} from "../../helpers/dateTime.helper";
import dayjs from "dayjs";
import useFacilityTimezone from "../hooks/useFacilityTimezone";
import objectSupport from "dayjs/plugin/objectSupport";

dayjs.extend(objectSupport);
type EditShiftTimeProps = {
  show: boolean;
  onHide: () => void;
  resetRowSelection: () => void;
  shiftDetails: ShiftApprovalRequestType;
  segmentedFilter: "Approved" | "Pending" | "All";
};

const EditShiftTime = (props: EditShiftTimeProps) => {
  const { t } = useTranslation();
  const { timeZoneId } = useFacilityTimezone();
  const { show, onHide, shiftDetails, resetRowSelection, segmentedFilter } =
    props;
  const dispatch = useAppDispatch();
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id
  );
  const { getFacilityTimeZone } = useFacilityTimezone();
  const [editShiftForm, setEditShiftForm] = useState<ShiftApprovalRequestType>({
    ...SHIFT_APPROVAL_REQUEST_TEMPLATE,
  });
  const [startTimeError, setStartTimeError] = useState("");
  const [endTimeError, setEndTimeError] = useState("");
  const [disableApprove, setDisableApprove] = useState(false);

  useEffect(() => {
    setEditShiftForm({
      ...shiftDetails,
      shiftStartTime: getTimeFromUtcDate(
        shiftDetails.shiftStartDateTime,
        timeZoneId
      ),
      shiftEndTime: getTimeFromUtcDate(
        shiftDetails.shiftEndDateTime,
        timeZoneId
      ),
    });
  }, [shiftDetails, timeZoneId]);

  const onTimeChange = (id: string, value: string) => {
    const formDataCopy = { ...editShiftForm, [id]: value };
    const currentTime = dayjs();
    const [day, month, year] = formDataCopy.shiftDate?.split("-");
    const [startH, startM] = formDataCopy.shiftStartTime?.split(":");
    const [endH, endM] = formDataCopy.shiftEndTime?.split(":");

    const start = dayjs({
      year,
      month,
      day,
      hour: startH,
      minute: startM,
    });
    const end = dayjs({
      year,
      month,
      day,
      hour: endH,
      minute: endM,
    });

    if (start.isAfter(currentTime)) {
      setStartTimeError(t("errors.startInFuture") || "");
      setDisableApprove(true);
      return;
    } else if (end.isAfter(currentTime)) {
      setEndTimeError(t("errors.endTimeInFuture") || "");
      setDisableApprove(true);
      return;
    } else if (formDataCopy.shiftStartTime === formDataCopy.shiftEndTime) {
      setEndTimeError(t("errors.startFinishTimeSame") || "");
      setDisableApprove(true);
      return;
    } else {
      setStartTimeError("");
      setEndTimeError("");
      setDisableApprove(false);
    }
    setEditShiftForm({ ...formDataCopy });
  };

  const [name, totalHours, totalHoursInDecimal] = useMemo(() => {
    const name = `${editShiftForm.clientFirstName} ${editShiftForm.clientLastName}`;
    const totalHours = getTimeDiffFrom24HTime(
      editShiftForm.shiftStartTime,
      editShiftForm.shiftEndTime
    );
    const totalHoursInDecimal = getHoursWorkedInDecimals(
      editShiftForm.shiftStartTime,
      editShiftForm.shiftEndTime
    );
    return [name, totalHours, totalHoursInDecimal];
  }, [
    editShiftForm.clientFirstName,
    editShiftForm.clientLastName,
    editShiftForm.shiftEndTime,
    editShiftForm.shiftStartTime,
  ]);

  const handleEdit = () => {
    const tz = getFacilityTimeZone();
    const { startDateTime, endDateTime } = getShiftStartEndDateTimes(
      editShiftForm.shiftDate || dayjs().utc().format(),
      editShiftForm.shiftStartTime,
      editShiftForm.shiftEndTime,
      true,
      tz
    );
    const payload = {
      id: editShiftForm.id,
      jobAllocationId: editShiftForm.jobAllocationId,
      clientId: editShiftForm.clientId,
      shiftStartDateTime: startDateTime,
      shiftEndDateTime: endDateTime,
      totalHours: totalHoursInDecimal.toString(),
      jobStatus: editShiftForm.jobStatus,
      isEdit: true,
    };
    dispatch(
      approveShiftRequestThunk(
        Number(facilityId),
        payload,
        segmentedFilter,
        timeZoneId
      )
    );
    resetRowSelection();
    onHide();
  };
  return (
    <Modal
      open={show}
      title={t("shiftApproval.editShiftTime")}
      onCancel={onHide}
      footer={[
        <Button
          key="no"
          type="default"
          onClick={onHide}
          id="edit-shift-time-cancel-btn"
        >
          {t("common.cancel")}
        </Button>,
        <Button
          key="yes"
          type="primary"
          onClick={handleEdit}
          disabled={disableApprove}
          id="edit-shift-time-approve-btn"
        >
          {t("common.approve")}
        </Button>,
      ]}
    >
      <div className="min-h-[62px] pb-2">
        <ShiftInterval
          startTime={editShiftForm.shiftStartTime}
          finishTime={editShiftForm.shiftEndTime}
          startTimeId="shiftStartTime"
          finishTimeId="shiftEndTime"
          onChange={onTimeChange}
          startTimeError={startTimeError}
          finishTimeError={endTimeError}
        />
      </div>
      <div className="text-[#5F6368] mx-2 text-xs pb-4">{`${name} started shift at ${editShiftForm.shiftStartTime} and finished at ${editShiftForm.shiftEndTime} (total hours - ${totalHours}).`}</div>
    </Modal>
  );
};

export default EditShiftTime;
