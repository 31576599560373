/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAnnouncement } from "../../redux/reducers/appReducer";

const useFacilityTimezone = () => {
  const { timeZoneId } = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    getFacilityTimeZone();
  }, [timeZoneId]);

  const getFacilityTimeZone = () => {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZoneId && timeZoneId !== currentTimezone) {
      const message = `Your timezone differs from the facility's timezone (${timeZoneId}), times shown in the app are in facility's timezone.`;
      dispatch(setAnnouncement(message));
    } else {
      dispatch(setAnnouncement(''));
    }
    return timeZoneId;
  };
  return {
    getFacilityTimeZone,
    timeZoneId,
  };
};

export default useFacilityTimezone;
