import {
  ServiceCharge,
  ContactTypes,
  FacilitiesErrorsTypes,
  FacilitiesTypes,
} from "./../../models/types";

export const FACILITIES_TEMPLATE: FacilitiesTypes = {
  id: "",
  title: "",
  logo: "",
  latitude: 0,
  longitude: 0,
  streetNumber: "",
  streetName: "",
  facilityTypeId: "",
  city: "",
  provinceId: "",
  postalCode: "",
  timeZoneId: "",
  timeZoneName: "",
  noOrientationForEmployees: false,
  showJobsOnlyToEmployees: false,
  hideHourlyRateForEmployees: false,
  enabled: false,
  contacts: [
    {
      id: 0,
      name: "",
      email: "",
      mobile: "",
      officePhone: "",
      extension: "",
      designation: "",
      isPrimary: true,
    },
  ],
  users: [
    {
      userId: "",
      username: "",
      roleName: "",
    },
  ],
};

export const FACILITIES_ERRORS_TEMPLATE: FacilitiesErrorsTypes = {
  title: "",
  // loginName: "",
  streetNumber: "",
  streetName: "",
  facilityTypeId: "",
  city: "",
  provinceId: "",
  postalCode: "",
  searchFacility: "",
  contact: [
    {
      id: 0,
      name: "",
      email: "",
      mobile: "",
      officePhone: "",
      extension: "",
      designation: "",
      isPrimary: true,
    },
    {
      id: 0,
      name: "",
      email: "",
      mobile: "",
      officePhone: "",
      extension: "",
      designation: "",
      isPrimary: true,
    },
  ],
};

export const CONTACT_DETAILS_TEMPLATE: ContactTypes = {
  id: 0,
  name: "",
  email: "",
  mobile: "",
  officePhone: "",
  extension: "",
  designation: "",
  isPrimary: false,
};

export const FACILITY_SERVICE_CHARGE_TYPE_TEMPLATE: ServiceCharge = {
  id: 0,
  title: "",
  rateCut: 0,
  isFlatRateCut: true,
  overrideRateCut: false,
};

export const Facility_Service_Charge_Errors_Template = {
  rateCut: "",
};

export const FACILITY_COMPLETION: { title: string; key: string }[] = [
  {
    title: "Hourly Rate",
    key: "isHourlyRateDefined",
  },
  {
    title: "Service Charge",
    key: "isServiceChargeDefined",
  },
  {
    title: "Shifts Created",
    key: "isShiftsCreated",
  },
  {
    title: "Address Provided",
    key: "isAddressProvided",
  },
  {
    title: "Users added",
    key: "isUsersAdded",
  },
  {
    title: "Contacts Added",
    key: "isContactsAdded",
  },
];

