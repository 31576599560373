import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import "./map.css";
const KEY = process.env.REACT_APP_GOOGLE_API_KEY || "";

const MapContainer = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: KEY,
  });
  const center = { lat: 44, lng: -80 };
  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <GoogleMap
      zoom={10}
      center={center}
      mapContainerStyle={{ width: "100%", height: "100%" }}
      extraMapTypes={[]}
      mapTypeId={google.maps.MapTypeId.ROADMAP}
    >
      <Marker position={center} />
    </GoogleMap>
  );
};

export default MapContainer;
