import { useEffect, useState } from "react";
import { Modal } from "antd";
import axios from "axios";

type Props = {
  show: boolean;
  title: string;
  onClose: () => void;
  docUrl: string;
};

const DocumentViewer = (props: Props) => {
  const { show, title, onClose, docUrl } = props;
  const [pdfData, setPdfData] = useState("");

  useEffect(() => {
    if (docUrl) {
      axios.get(docUrl, { responseType: "blob" }).then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        setPdfData(fileURL);
      });
    }
  }, [docUrl]);

  return (
    <div className="w-full">
      <Modal
        title={title}
        open={show}
        onCancel={onClose}
        footer={null}
        className="document-preview-modal"
        wrapClassName="w-full"
      >
        {pdfData && (
          <iframe
            title="document_preview"
            src={pdfData}
            width="100%"
            height="500px"
            style={{ border: "none" }}
          />
        )}
      </Modal>
    </div>
  );
};

export default DocumentViewer;
