import { Card } from "../common/Card";
import { DocumentTypes } from "../../models/types";
import Document from "./Document";
import Icon from "../common/Icon";
import { useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { getPreSignedUrl } from "../../services/app.services";
import { get, isEmpty } from "lodash";
import { showToast } from "../../redux/reducers/feedbackReducer";
import DocumentViewer from "../common/DocumentViewer";
import DocumentUploadForm from "./edit/Documents";
import DocumentProgressDetails from "./DocumentStatus";
import { logToSentry } from "../../helpers/helpers";
type Props = {
  documents: DocumentTypes[];
  hasPendingDocs: Boolean;
  clientId: Number | null;
  isConsultant: boolean;
};

const Title = ({
  title,
}: {
  title: string;
  hasPendingDocs: Boolean;
}) => {
  return (
    <div className="flex items-center justify-between mb-3 font-semibold gap-2">
      <span className="font-bold">{title}</span>
      <DocumentProgressDetails />
    </div>
  );
};

const Documents = (props: Props) => {
  const { documents, hasPendingDocs, clientId, isConsultant } = props;
  const [docPreviewLoading, setDocPreviewLoading] = useState(false);

  const [docPreviewState, setDocPreviewState] = useState({
    show: false,
    url: "",
    name: "",
    fileId: 0,
  });
  const dispatch = useAppDispatch();
  const [showUploadDocuments, setShowUploadDocuments] = useState(false);

  const handleDocumentPreview = async (fileId: number, title: string) => {
    try {
      if (!docPreviewState.show) {
        setDocPreviewLoading(true);
        const preSignedUrl = await getPreSignedUrl(fileId);
        const url = get(preSignedUrl, ["data", "data"], "");
        if (url) {
          setDocPreviewState({ show: true, url, name: title, fileId: fileId });
        } else {
          throw new Error();
        }
        setDocPreviewLoading(false);
      }
    } catch (error) {
      dispatch(
        showToast({
          show: true,
          type: "error",
          message:
            "Unable to preview the document at this time, please try later.",
        })
      );
      setDocPreviewLoading(false);
      logToSentry(error, "Documents", "handleDocumentPreview");
    }
  };

  const closeDocPreview = () => {
    setDocPreviewState({
      show: false,
      url: "",
      name: "",
      fileId: 0,
    });
  };

  if (!clientId) {
    return null;
  }

  const onEdit = (e: any) => {
    setShowUploadDocuments(true);
  };

  return (
    <>
      <Card
        title={
          <div className="flex justify-between items-center mb-2">
            <Title
              title="Documents"
              hasPendingDocs={!isEmpty(documents) && hasPendingDocs}
            />
            {isConsultant && (
              <span className="bg-[#1677ff] text-white p-1 flex justify-center items-center rounded-[50%] hover:bg-[#1890ff]">
                <Icon
                  role="button"
                  name="edit"
                  fontSize={18}
                  onClick={onEdit}
                />
              </span>
            )}
          </div>
        }
      >
        {documents?.map((document, idx) => (
          <div key={`${document.title}_${idx}`} className="mb-3">
            <Document
              documentData={document}
              clientId={clientId}
              handlePreview={handleDocumentPreview}
              isConsultant={isConsultant}
              previewLoading={
                docPreviewLoading && docPreviewState.fileId === document.fileId
              }
            />
          </div>
        ))}
      </Card>
      <DocumentViewer
        show={docPreviewState.show}
        onClose={closeDocPreview}
        docUrl={docPreviewState.url}
        title={docPreviewState.name}
      />
      {showUploadDocuments && isConsultant && (
        <DocumentUploadForm
          showForm={showUploadDocuments}
          hideForm={() => setShowUploadDocuments(false)}
        />
      )}
    </>
  );
};

export default Documents;
