
export const PAGINATION = {
    defaultPageNumber: 1,
    defaultPageSize: 25,
    pageSizes: ['10', '25', '50'],
}

export const SORT = {
    defaultSortOrderBy: "id",
    defaultSortDirection: "ASC",
    firstName: "first_name",
    jobCategory: "job_category",
    title: "title"
}