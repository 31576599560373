import { AxiosError } from "axios";
import { handleApiErrors, logToSentry } from "../../helpers/helpers";
import { JobCategoryTypes } from "../../models/types";
import {
  fetchJobCategories,
  updateJobCategory,
} from "../../services/jobCategory.services";
import { showToast } from "../reducers/feedbackReducer";
import {
  setJobCategories,
  setJobCategoryLoading,
} from "../reducers/jobCategoryReducer";
import { AppDispatch } from "../store";

export const fetchJobCategoriesThunk =
  (facilityId: string, callback?: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setJobCategoryLoading(true));
    try {
      const response = await fetchJobCategories(facilityId);

      dispatch(setJobCategories(response.data?.data || []));
      callback && callback();
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "JobCategory", "fetchJobCategoriesThunk");
    } finally {
      dispatch(setJobCategoryLoading(false));
    }
  };

export const updateJobCategoryThunk =
  (formData: JobCategoryTypes, callback?: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setJobCategoryLoading(true));
    try {
      await updateJobCategory(formData);
      const feedbackCallback = () => {
        callback && callback();
        dispatch(
          showToast({
            type: "success",
            message: "Job category has been updated successfully!",
            show: true,
          })
        );
        dispatch(setJobCategoryLoading(false));
      };
      formData.facilityId &&
        dispatch(
          fetchJobCategoriesThunk(formData.facilityId, feedbackCallback)
        );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      dispatch(setJobCategoryLoading(false));
      logToSentry(err, "JobCategory", "updateJobCategoryThunk");
    }
  };
