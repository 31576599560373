import { useState } from "react";
import Icon from "../../common/Icon";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../common/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { logoutThunk } from "../../../redux/thunks/app";
import useResetState from "../../hooks/useResetState";
import { ROUTE } from "../../../constants/routes";

const Logout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { resetState } = useResetState();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const userId = useAppSelector((state) => state.appReducer.currentUser.id);
  const handleLogout = () => {
    if (userId) dispatch(logoutThunk(userId, cleanup));
  };

  const cleanup = () => {
    resetState();
    navigate(ROUTE.signIn);
  };
  return (
    <div className="flex items-center">
      <div
        role="button"
        className="flex items-center mr-4 cursor-pointer text-normal"
        onClick={() => setShowConfirmation(true)}
      >
        <Icon name="logout" fontSize={18} className="mr-1" />
        <span>{t("header.logout")}</span>
      </div>
      {showConfirmation && (
        <ConfirmationModal
          open={showConfirmation}
          handleNo={() => setShowConfirmation(false)}
          handleYes={handleLogout}
          text="Do you want to logout?"
        />
      )}
    </div>
  );
};

export default Logout;
