import { ProvinceTypes } from "../models/types";

export type Address = {
  id?: string;
  userId?: string;
  streetName?: string;
  streetNumber?: string;
  city?: string;
  country?: string;
  postalCode?: string;
  provinceId?: string;
  latitude: string | number;
  longitude: string| number;
  timeZoneId?: string;
  timeZoneName?: string;
};

export const cleanObject = (obj: Record<string, unknown>) => {
  for (var key in obj) {
    let value = obj[key];
    if (typeof value === "string") {
      value = value.trim();
    }
    if (!value) {
      delete obj[key];
    }
  }
  return obj;
};

export const getAddressFromPlaceDetail = (
  provinces: ProvinceTypes[],
  detail?: google.maps.places.PlaceResult | null
) => {
  const address: Partial<Address> = {};

  let provinceName: string | undefined;

  if (detail) {
    if (detail.geometry && detail.geometry.location) {
      address.latitude = `${detail.geometry.location.lat()}`;
      address.longitude = `${detail.geometry.location.lng()}`;
    }

    detail.address_components?.forEach((component) => {
      if (component.types.includes("street_number")) {
        address.streetNumber = component.long_name;
      } else if (component.types.includes("route")) {
        address.streetName = component.long_name;
      } else if (component.types.includes("locality")) {
        address.city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        provinceName = component.long_name;
      } else if (component.types.includes("country")) {
        address.country = component.long_name;
      } else if (component.types.includes("postal_code")) {
        address.postalCode = component.long_name;
      }
    });
  }

  if (provinceName) {
    const province = provinces?.find(
      ({ title, shortTitle }) =>
        title === provinceName || shortTitle === provinceName
    );
    if (province && province.id) {
      address.provinceId = province.id;
    }
  }

  return cleanObject(address) as Partial<Address>;
};
