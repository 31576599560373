import { useCallback, useMemo } from "react";
import { Dropdown } from "antd";
import type { MenuProps } from "antd";
import { useAppSelector } from "../../redux/hooks";

type JOB_CATEGORY_FILTER_TYPES = {
  className?: string;
  handleSelection: ((id: string) => void) | undefined;
  children: React.ReactNode;
};

const AddButtonDropdown = (props: JOB_CATEGORY_FILTER_TYPES) => {
  const { handleSelection, children } = props;
  const jobsCategoryFromState = useAppSelector(
    (state) => state.jobCategoryReducer.jobCategories
  );
  const jobCategoryOptions: MenuProps["items"] = useMemo(() => {
    return (jobsCategoryFromState ?? [])
      .map((element) => ({
        key: element.id,
        label: element.title,
      }))
      .sort((a, b) => Number(a.key) - Number(b.key));
  }, [jobsCategoryFromState]);
  const handleClick = useCallback(
    (data: any) => {
      handleSelection && handleSelection(data?.key);
    },
    [handleSelection]
  );
  return (
    <Dropdown
      menu={{
        items: jobCategoryOptions,
        onClick: handleClick,
      }}
    >
      <div>{children}</div>
    </Dropdown>
  );
};

export default AddButtonDropdown;
