
export const JOB_TYPE_TEMPLATE = {
  id: "",
  title: "",
  description: "",
  hourlyRate: "",
  contactName: "",
  contactPhone: "",
  contactEmail: "",
  isDefault: false,
  jobTemplateId: "",
};
export const Errors_Template = {
  title: "",
  hourlyRate: "",
  contactMobile: "",
  contactEmail: "",
  description: "",
  contractName: "",
};
