import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Main from "./components/layout/Main";
import "antd/dist/reset.css";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { getMasterDataThunk } from "./redux/thunks/app";
import Toast from "./components/common/Toast";
import { ROUTE } from "./constants/routes";
import useFacilityTimezone from "./components/hooks/useFacilityTimezone";
import "./config/sentry.config";
import ErrorBoundary from "./ErrorBoundary";

function App() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.appReducer.currentUser);
  const navigate = useNavigate();
  const location = useLocation();
  useFacilityTimezone();
  useEffect(() => {
    const { roleId, username } = currentUser;

    if (!roleId || !username) {
      navigate(ROUTE.signIn);
    } else if (location.pathname === "/") {
      navigate(ROUTE.scheduleJobs);
    }
  }, [currentUser, location.pathname, navigate]);

  useEffect(() => {
    dispatch(getMasterDataThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ErrorBoundary>
      <Main>
        <Toast />
        <Outlet />
      </Main>
    </ErrorBoundary>
  );
}

export default App;
