import { t } from "i18next";
import { Button, Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ConfirmationModal from "../common/ConfirmationModal";
import { useCallback, useState } from "react";
import { FACILITY_SERVICE_CHARGE_TYPE_TEMPLATE, Facility_Service_Charge_Errors_Template } from "./constants";
import { cloneDeep } from "lodash";
import { ServiceCharge } from "../../models/types";
import ServiceChargeForm from "./ServiceChargeForm";
import { updateServiceChargeForFacilityThunk } from "../../redux/thunks/serviceCharge";
import { useAppDispatch } from "../../redux/hooks";
import Icon from "../common/Icon";

type Props = {
    facilityId: string;
}


function ServiceChargeTable(props: Props) {
    const { facilityId } = props;
    const dispatch = useAppDispatch();

    const [showConfirmation, setShowConfirmation] = useState({
        status: false,
        id: 0,
    });

    const ServiceChargeData = useSelector(
        (state: RootState) => state.ServiceChargeReducer.ServiceCharge
    );

    const columns = [
        {
            title: t("serviceCharge.title"),
            width: "50%",
            dataIndex: "title",
            key: "title",
            render: (text: any, record: any) => (
                <>
                    <div style={{ width: "fit-content", float: "left", position: "relative", top: "2px" }}>
                        <Icon name={record.overrideRateCut ? "vpn_lock" : "public"}
                            fontSize={20}
                        />
                    </div>
                    <span>
                        {record.title}
                    </span>

                </>
            )
        },
        {
            title: t("serviceCharge.rate"),
            width: "30%",
            dataIndex: "rateCut",
            key: "rate",
            render: (text: any, record: any) => (
                <div>
                    {record.isFlatRateCut ? "$" : ""}{record.rateCut}{record.isFlatRateCut ? "" : "%"}
                </div>
            )
        },
        {
            title: "",
            width: "20%",
            dataIndex: "",
            key: "button",
            render: (text: any, record: any) => (
                <Button
                    type="link"
                    id="edit-service-charge-btn"
                    size="small"
                    onClick={() => { showServiceChargeDetails(record.id) }
                    }
                >
                    {t("common.edit")}
                </Button>
            )
        }
    ];

    const handleClose = () => {
        setServiceChargeTemplateData({ ...FACILITY_SERVICE_CHARGE_TYPE_TEMPLATE });
        setShowConfirmation({ status: false, id: 0 });
    };

    const [serviceChargeTemplateData, setServiceChargeTemplateData] = useState<ServiceCharge>(
        cloneDeep(FACILITY_SERVICE_CHARGE_TYPE_TEMPLATE)
    );

    const [errors, setErrors] = useState({ ...Facility_Service_Charge_Errors_Template });

    const showServiceChargeDetails = useCallback(
        (id: number) => {
            // Setting currently selected object's data in the state
            let selectedData = cloneDeep(
                ServiceChargeData.filter((data) => data.id === id)?.[0]
            );

            setServiceChargeTemplateData(selectedData);
            setShowConfirmation({ status: true, id: id });
        },
        [ServiceChargeData]
    );

    const handleBlur = useCallback(
        (id: string, value: string | number | null) => {
            //const trimmedValue = value?.toString().trim();
            setServiceChargeTemplateData({ ...serviceChargeTemplateData, [id]: value });
        },
        [serviceChargeTemplateData]
    );

    const handleChange = useCallback(
        (id: string, value: string | number | null) => {
            // Setting the value in state
            setServiceChargeTemplateData({ ...serviceChargeTemplateData, [id]: value });

            // Resetting the errors
            setErrors({ ...errors, [id]: "" });
        },
        [serviceChargeTemplateData, errors]
    );

    const handleSave = useCallback(() => {
        const id = parseInt(facilityId);
        dispatch(updateServiceChargeForFacilityThunk(id, serviceChargeTemplateData, handleClose));
    }, [facilityId, serviceChargeTemplateData, dispatch]);

    const validateFields = useCallback(() => {
        let validData = true;
        const errorObjCopy = cloneDeep(errors);
        // Validating Title
        if (!serviceChargeTemplateData.rateCut) {
            errorObjCopy.rateCut = t("errors.rateRequired");
            validData = false;
        } else {
            errorObjCopy.rateCut = "";
        }

        if (validData) {
            handleSave();
        } else {
            setErrors(errorObjCopy);
        }
    }, [errors, serviceChargeTemplateData, handleSave]);

    return (
        <>
            <div>
                <Table
                    columns={columns}
                    dataSource={ServiceChargeData}
                    pagination={false}
                    sticky
                    rowClassName={() => 'editable-row'}
                    size="small"
                />
            </div>

            {
                showConfirmation && (
                    <ConfirmationModal
                        open={showConfirmation.status}
                        title=""
                        handleYes={validateFields}
                        handleNo={handleClose}
                        primaryButtonText={t("common.save") || ""}
                        secondaryButtonText={t("common.close") || ""}
                        text={
                            <ServiceChargeForm
                                data={serviceChargeTemplateData}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={errors}
                            />
                        }
                    />
                )
            }

        </>
    )
}

export default ServiceChargeTable;

