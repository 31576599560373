import { Button } from "antd";
import React, { useMemo } from "react";
import Icon from "../common/Icon";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useTranslation } from "react-i18next";
import { ClientInvoiceTypes, InvoiceCycle } from "../../models/types";
import { downloadClientInvoiceSummaryThunk, markAsPaidThunk } from "../../redux/thunks/invoices";
import { showToast } from "../../redux/reducers/feedbackReducer";
import { downloadInvoiceDocumentThunk } from "../../redux/thunks/app";

type Props = {
  selectedInvoices: ClientInvoiceTypes[];
  selectedCycle: InvoiceCycle;
  resetRowSelection: () => void;
};
const ActionButtons = (props: Props) => {
  const { selectedInvoices, resetRowSelection, selectedCycle } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id?.toString() || ""
  );

  const downloadInvoiceSummary = () => {
    if (selectedCycle) {
      dispatch(downloadClientInvoiceSummaryThunk(
        {
          startDateTime: selectedCycle.startDateTime,
          endDateTime: selectedCycle.endDateTime
        }, selectedCycle.cycle));
    }
  };

  const markAsPaid = () => {
    const invoices = selectedInvoices.map((item) => item.id);
    dispatch(markAsPaidThunk(invoices, "client", Number(facilityId)));
    resetRowSelection();
  };

  const downloadInvoice = () => {
    const { invoiceId, invoiceNumber } = selectedInvoices.map((item) => ({
      invoiceId: item.id,
      invoiceNumber: item.invoiceNumber,
    }))?.[0];

    if (invoiceId) {
      dispatch(downloadInvoiceDocumentThunk(invoiceId, invoiceNumber));
    } else {
      dispatch(
        showToast({
          type: "error",
          message: `Invoice is not available, please contact the admin`,
          show: true,
        })
      );
    }
    resetRowSelection();
  };
  const disableMarkAsPaid = useMemo(() => {
    const paidInvoiceSelected = selectedInvoices.some(
      (item) => item.paymentStatus === "paid" || item.paymentStatus === "no due"
    );
    return !selectedInvoices.length || paidInvoiceSelected;
  }, [selectedInvoices]);

  const disableDownload = useMemo(() => {
    return !selectedInvoices.length || selectedInvoices.length > 1;
  }, [selectedInvoices]);
  return (
    <div className="flex action-buttons mr-4">
      {selectedCycle && (
        <Button
          icon={<Icon name="download" fontSize={16} className="mr-1" />}
          className="mx-3 flex justify-center items-center secondary-btn"
          onClick={downloadInvoiceSummary}
          size="small"
          id="download-invoice-summary-btn"
          disabled={!selectedCycle}
        >
          {t("invoice.downloadInvoiceSummary")}
        </Button>
      )}
      {true && (
        <Button
          icon={<Icon name="done" fontSize={16} className="mr-1" />}
          className="mx-3 flex justify-center items-center secondary-btn"
          onClick={markAsPaid}
          size="small"
          id="mark-as-paid-btn"
          disabled={disableMarkAsPaid}
        >
          {t("invoice.markAsPaid")}
        </Button>
      )}
      <Button
        type="primary"
        icon={<Icon name="download" fontSize={16} className="mr-1" />}
        className="flex justify-center items-center"
        onClick={downloadInvoice}
        size="small"
        disabled={disableDownload}
        id="download-invoice-btn"
      >
        {t("invoice.downloadPDF")}
      </Button>
    </div>
  );
};

export default ActionButtons;
