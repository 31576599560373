import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Notification } from "../../models/types";
import { cloneDeep } from "lodash";

export interface NotificationsReducerState {
  notifications: Notification[];
}
const initialState: NotificationsReducerState = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload || [];
    },
    markNotificationAsRead: (state, action: PayloadAction<{ id: number }>) => {
      const idx = state.notifications.findIndex(
        (item) => item.id === action.payload.id
      );
      if (idx > -1) {
        const notifications = cloneDeep(state.notifications);
        notifications[idx].notificationStatus = "read";
        state.notifications = notifications;
      }
    },
    resetNotificationsState: (state) => {
      state.notifications = [];
    },
  },
});

export const {
  setNotifications,
  markNotificationAsRead,
  resetNotificationsState,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
