import TimePicker from "./TimePicker";

type ShiftIntervalTypes = {
  startTime: string;
  finishTime: string;
  startTimeError: string;
  finishTimeError: string;
  startTimeId?: string;
  finishTimeId?: string;
  onChange: (id: string, value: string) => void;
};

const ShiftInterval = (props: ShiftIntervalTypes) => {
  const {
    startTimeId = "startTime",
    finishTimeId = "finishTime",
    startTime,
    finishTime,
    onChange,
    startTimeError,
    finishTimeError,
  } = props;
  return (
    <>
      <div className="flex">
        <div className="w-[50%]">
          <TimePicker
            id={startTimeId}
            label="Start Time"
            value={startTime}
            onChange={onChange}
            placeholder="07:00"
            error={startTimeError ? true : false}
            message={startTimeError}
          />
        </div>
        <div className="w-[50%]">
          <TimePicker
            id={finishTimeId}
            label="End Time"
            value={finishTime}
            onChange={onChange}
            placeholder="15:00"
            error={finishTimeError ? true : false}
            message={finishTimeError}
          />
        </div>
      </div>
    </>
  );
};

export default ShiftInterval;
