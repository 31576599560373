import { Button } from "antd";
import { Notification } from "../../../../models/types";
import NotificationText from "../NotificationText";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Icon from "../../../common/Icon";
import NotificationAvatar from "../../../common/UserAvatar";
import { ROUTE } from "../../../../constants/routes";
import { getDateFromUtcDate } from "../../../../helpers/dateTime.helper";
import { getJobCategory } from "../../../../helpers/helpers";
import dayjs from "dayjs"
import { DATE_MMM_YEAR } from "../../../../constants/defaults"

type Props = {
  notification: Notification;
  handleNotificationOpen: (id: number) => void;
};
const OrientationRequested = (props: Props) => {
  const { notification, handleNotificationOpen } = props;
  const {
    id,
    orientationId,
    requestedDates,
    clientFirstName,
    clientLastName,
    jobCategory,
    facility,
    timezoneId,
  } = notification;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleApprove = () => {
    if (orientationId) {
      navigate(ROUTE.orientations, {
        state: {
          id,
          action: "approve",
          orientationId: orientationId,
          date: requestedDates[0], timezoneId
        },
      });
    }
    handleNotificationOpen(id);
  };
  const handleSuggest = () => {
    navigate(ROUTE.orientations, {
      state: {
        id,
        action: "suggest",
        orientationId: orientationId,
        clientFirstName,
        clientLastName,
      },
    });
    handleNotificationOpen(id);
  };

  const Message = () => {
    const date = dayjs(requestedDates[0].split("T")[0]).format(DATE_MMM_YEAR);
    return (
      <div>
        <b>{`${clientFirstName} ${clientLastName} `}</b> has{" "}
        <b>requested orientation</b> for <b>{getJobCategory(jobCategory)}</b> job at{" "}
        <b>{facility}</b> on <b>{date}</b>
      </div>
    );
  };
  return (
    <div className="hover:bg-[#d9d9d9] p-4">
      <div className="flex items-start">
        <div className="mr-4">
          <NotificationAvatar
            src={notification.clientAvatarUrl || notification.facilityLogo}
            firstName={notification.clientFirstName}
            lastName={notification.clientLastName}
          />
        </div>
        <NotificationText notification={notification}>
          <Message />
        </NotificationText>
      </div>
      <div className="flex justify-end">
        <Button
          id="suggest-orientation-btn"
          type="primary"
          icon={<Icon name="insert_invitation" fontSize={16} />}
          className="mr-3 flex justify-center items-center"
          onClick={handleSuggest}
          size="small"
        >
          {t("orientations.suggest")}
        </Button>
        <Button
          id="approve-orientation-btn"
          icon={<Icon name="done" fontSize={16} />}
          className="flex justify-center items-center secondary-btn"
          size="small"
          onClick={handleApprove}
        >
          {t("common.approve")}
        </Button>
      </div>
    </div>
  );
};

export default OrientationRequested;
