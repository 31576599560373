import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { JobSummary, MonthPropTypes } from "../../models/day.model";
import { JobTypes } from "../../models/job.model";

export interface ShiftScheduleReducerState {
  selectedStartDate: string;
  selectedEndDate: string;
  jobSummary: JobSummary[];
  selectedDay: string;
  jobsForSelectedDay: JobTypes[];
  loading: boolean;
  monthData: MonthPropTypes;
}

const initialState: ShiftScheduleReducerState = {
  selectedDay: "",
  jobsForSelectedDay: [],
  selectedStartDate: "",
  selectedEndDate: "",
  jobSummary: [],
  loading: false,
  monthData: {
    weeks: [],
  },
};

export const shiftScheduleSlice = createSlice({
  name: "shiftSchedule",
  initialState,
  reducers: {
    setJobSummary: (
      state,
      action: PayloadAction<{
        startDate: string;
        endDate: string;
        data: JobSummary[];
      }>
    ) => {
      state.selectedStartDate = action.payload.startDate;
      state.selectedEndDate = action.payload.endDate;
      state.jobSummary = action.payload.data;
    },
    setCalendarData: (
      state,
      action: PayloadAction<{
        month: MonthPropTypes;
      }>
    ) => {
      state.monthData = action.payload.month;
    },
    setJobsForSelectedDay: (
      state,
      action: PayloadAction<{
        selectedDate: string;
        data: JobTypes[];
      }>
    ) => {
      state.selectedDay = action.payload.selectedDate;
      state.jobsForSelectedDay = action.payload.data || [];
    },
    setShiftScheduleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetShiftScheduleState: (state) => {
      state.selectedDay = "";
      state.jobsForSelectedDay = [];
      state.selectedStartDate = "";
      state.selectedEndDate = "";
      state.jobSummary = [];
      state.monthData = {
        weeks: [],
      };
    },
  },
});

export const {
  setJobSummary,
  setJobsForSelectedDay,
  setShiftScheduleLoading,
  setCalendarData,
  resetShiftScheduleState,
} = shiftScheduleSlice.actions;

export default shiftScheduleSlice.reducer;
