import { Notification } from "../../../../models/types";
import NotificationText from "../NotificationText";
import NotificationAvatar from "../../../common/UserAvatar";

type Props = {
  notification: Notification;
  handleNotificationOpen: (id: number) => void;
};
const UserAccountDeleted = (props: Props) => {
  const { notification, handleNotificationOpen } = props;
  const { clientFirstName, clientLastName } = notification;

  const Message = () => {
    return (
      <div>
        <b>{`${clientFirstName} ${clientLastName} `}</b> has <b>deleted</b> the
        Carelynk account.
      </div>
    );
  };
  return (
    <div onClick={() => handleNotificationOpen(notification.id)}>
      <div className="p-4 hover:bg-[#d9d9d9] flex items-start">
        <div className="mr-4">
          <NotificationAvatar
            src={notification.clientAvatarUrl || notification.facilityLogo}
            firstName={notification.clientFirstName}
            lastName={notification.clientLastName}
          />
        </div>
        <NotificationText notification={notification}>
          <Message />
        </NotificationText>
      </div>
    </div>
  );
};

export default UserAccountDeleted;
