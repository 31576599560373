import { useMemo } from "react";
import { ContactTypes, JobCategoryTypes } from "../../models/types";
import AutoCompleteInput from "../common/AutoCompleteInput";
import InputField from "../common/InputField";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useUserRole from "../hooks/useUserRole";

type JobTypeTypes = {
  data: JobCategoryTypes;
  onChange: (id: string, value: string | number | null) => void;
  handleNameSelect: (contact: ContactTypes) => void;
  errors: {
    title: string;
    description?: string;
    hourlyRate: string;
    contactName?: string;
    contactMobile: string;
    contactEmail: string;
  };
};
const JobTypeTemplate = (props: JobTypeTypes) => {
  const { t } = useTranslation();
  const { data, onChange, errors, handleNameSelect } = props;

  const currentFacilityContacts = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.contacts
  );
  const { isFacilityAdmin, isStaffAdmin } = useUserRole();

  const contactOptions = useMemo(() => {
    const options = (currentFacilityContacts || []).map((c) => {
      return { value: c.id.toString(), label: `${c.name}; Email: ${c.email}` };
    });
    return options;
  }, [currentFacilityContacts]);
  const handleContactInfo = (id: string, value: string | number | null) => {
    const selectedOption = currentFacilityContacts.filter(
      (c) => c.id.toString() === id
    )?.[0];
    if (selectedOption) {
      handleNameSelect(selectedOption);
    }
  };
  const handleDescription = (value: string) => {
    if (value === "<p><br></p>") {
      value = "";
    }
    onChange("description", value);
  };
  const showHourlyRate = useMemo(() => {
    return !isFacilityAdmin && !isStaffAdmin;
  }, [isFacilityAdmin, isStaffAdmin]);
  return (
    <div>
      <InputField
        type="text"
        id="title"
        label={t("jobList.title") || ""}
        value={data.title}
        disabled={data.isDefault}
        onChange={onChange}
        required={true}
        message={errors.title}
        status={errors.title ? "error" : ""}
      />
      {showHourlyRate && (
        <InputField
          type="number"
          id="hourlyRate"
          label={t("jobList.hourlyRate") || ""}
          required={true}
          addonBefore="CAD"
          value={data.hourlyRate}
          onChange={onChange}
          message={errors.hourlyRate}
          status={errors.hourlyRate ? "error" : ""}
        />
      )}
      <div className="mx-2 mb-3">
        <label className="mb-1 text-xs text-[#686565]">
          Description <span className="text-[#ff4d4f]">*</span>
        </label>
        <ReactQuill
          theme="snow"
          value={data.description}
          onChange={handleDescription}
        />
        <span className="error-text">{errors.description}</span>
      </div>
      <AutoCompleteInput
        id="contactName"
        label={t("jobList.contactName") || ""}
        value={data.contactName}
        onChange={onChange}
        onSelect={handleContactInfo}
        options={contactOptions}
      />
      <InputField
        type="text"
        id="contactEmail"
        label={t("jobList.contactEmail") || ""}
        value={data.contactEmail}
        onChange={onChange}
        message={errors.contactEmail}
        status={errors.contactEmail ? "error" : ""}
      />
      <InputField
        type="text"
        id="contactMobile"
        label={t("jobList.contactPhone") || ""}
        addonBefore="+1"
        value={data.contactMobile}
        onChange={onChange}
        onBlur={() => {}}
        message={errors.contactMobile}
        status={errors.contactMobile ? "error" : ""}
      />
    </div>
  );
};

export default JobTypeTemplate;
