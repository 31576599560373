import { useEffect, useRef, useState } from "react";
import { getAddressFromPlaceDetail } from "../../helpers/googlePlacesHelpers";
import { useAppSelector } from "../../redux/hooks";

let autoComplete;
const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const AddressAutoComplete = ({
  setSelectedLocation,
  label,
  error,
  message,
}) => {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const provinces = useAppSelector((state) => state.appReducer.provinces);

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        componentRestrictions: { country: "CA" },
      }
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const address = getAddressFromPlaceDetail(provinces, addressObject);
    updateQuery(addressObject.name);
    setSelectedLocation(address, addressObject.name);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pl-2 -pr-1 flex flex-col">
      <label className="mb-1 text-xs text-[#686565]">{label}</label>
      <input
        id={error ? "auto-complete-with-error" : "auto-complete"}
        ref={autoCompleteRef}
        className={`${
          error ? "ant-input-status-error" : ""
        } ant-input pac-target-input`}
        onChange={(event) => setQuery(event.target.value)}
        value={query}
      />
      <span className="error-text">{message}</span>
    </div>
  );
};

export default AddressAutoComplete;
