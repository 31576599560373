import { Card } from "../common/Card";
import { Avatar, Tooltip, Divider } from "antd";
import Icon from "../common/Icon";
import { UserAddressTypes } from "../../models/types";
import { useMemo, useState } from "react";
import CopyIcon from "../common/CopyIcon";
import UpdateAddressForm from "./edit/UpdateAddress";
import useUserRole from "../hooks/useUserRole";
import PhoneNumberForm from "./edit/PhoneNumber";
import EmailForm from "./edit/Email";
import ProfileProgress from "./ProfileProgress";
type Props = {
  firstName: string;
  lastName: string;
  avatarUrl: string;
  jobCategory: string;
  legalStatus: string;
  email: string;
  phone: string;
  isVerified: boolean;
  address: UserAddressTypes;
};
type AvatarWithInitialsProps = {
  initials: string;
  size: number;
};
const AvatarWithInitials = (props: AvatarWithInitialsProps) => {
  const { initials, size } = props;
  return (
    <div
      className="bg-[#b1b1b1] rounded-full flex justify-center items-center text-6xl text-white"
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      {initials}
    </div>
  );
};

const UserProfile = (props: Props) => {
  const {
    firstName,
    lastName,
    avatarUrl,
    isVerified,
    jobCategory,
    legalStatus,
    email,
    phone,
    address,
  } = props;
  const { isConsultant } = useUserRole();
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [showEditPhone, setShowEditPhone] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [isAddressEntered, setIsAddressEntered] = useState(false);
  const onEdit = (e: any) => {
    setShowEditAddress(true);
  };
  const handleEditPhone = (e: any) => {
    setShowEditPhone(true);
  };
  const handleEditEmail = (e: any) => {
    setShowEditEmail(true);
  };

  const [addressLineOne, addressLineTwo, addressLineThree] = useMemo(() => {
    if (!address) {
      setIsAddressEntered(false);
      return ["", "", ""];
    }
    setIsAddressEntered(true);
    const lineOne = `${address.streetNumber}, ${address.streetName}`;
    const lineTwo = `${address.city}, ${address.provinceTitle}`;
    const lineThree = `${address.country}, ${address.postalCode}`;
    return [lineOne, lineTwo, lineThree];
  }, [address]);

  return (
    <Card>
      <div className="flex items-center flex-col py-3">
        {/* Avatar + Name + profile verification status */}
        {avatarUrl ? (
          <Avatar size={164} src={avatarUrl} />
        ) : (
          <AvatarWithInitials
            initials={`${firstName?.[0]}${lastName?.[0]}`}
            size={164}
          />
        )}

        <div className="mt-3 text-xl font-semibold flex items-center">
          {`${firstName} ${lastName}`}
          <Tooltip
            title={isVerified ? "Verified Profile" : "Verification Pending"}
            trigger="hover"
          >
            <div className="ml-1 flex items-center">
              <Icon
                name={isVerified ? "verified" : "front_hand "}
                fontSize={18}
                className={
                  isVerified
                    ? "text-green font-semibold"
                    : "error-text font-semibold"
                }
              />
            </div>
          </Tooltip>
        </div>
        <ProfileProgress />
        <div className="flex flex-col justify-start">
          {/* Job Category + + Legals Status + Email + Phone */}
          <div className="text-lg text-grey mt-1"> {jobCategory}</div>
          <div className="flex items-center  gap-2 mt-2">
            <div className="icon-wrapper">
              <Icon
                name="workspace_premium"
                fontSize={16}
                className="font-bold"
              />
            </div>
            <span>{legalStatus}</span>
          </div>
          <div className="flex items-center gap-2 mt-2">
            <div className="icon-wrapper">
              <Icon name="phone" fontSize={16} className="font-bold" />
            </div>
            <span>{phone}</span>
            <CopyIcon text={phone} />
            {isConsultant && (
              <div
                className="flex items-center"
                onClick={handleEditPhone}
                role="button"
              >
                <Icon name="edit" fontSize={20} className="font-bold" />
              </div>
            )}
          </div>
          {email && (
            <div className="flex items-center gap-2 mt-2">
              <div className="icon-wrapper">
                <Icon name="email" fontSize={14} className="font-bold" />
              </div>
              <span>{email}</span>
              <CopyIcon text={email} />
              <div
                className="flex items-center"
                onClick={handleEditEmail}
                role="button"
              >
                <Icon name="edit" fontSize={20} className="font-bold" />
              </div>
            </div>
          )}
          <Divider className="text-grey my-5" />
          {/* Address */}
          <div className="flex">
            <div className="icon-wrapper">
              <Icon name="home_pin" fontSize={16} className="font-bold" />
            </div>
            <div className="flex flex-col">
              <span className="ml-2 text-grey ">{addressLineOne}</span>
              <span className="ml-2 text-grey ">{addressLineTwo}</span>
              <span className="ml-2 text-grey ">{addressLineThree}</span>
            </div>
            {!isAddressEntered && (
              <Tooltip title="Add Address">
                <div
                  className="flex items-center gap-1 text-[#FBB360]"
                  role="button"
                >
                  <Icon
                    name="warning"
                    fontSize={16}
                    className="font-semibold"
                  />
                  <span className="text-xs">Action needed</span>
                </div>
              </Tooltip>
            )}
            <div className="flex flex-col ml-2">
              {isConsultant && (
                <div className="icon-wrapper">
                  <Icon
                    role="button"
                    name="edit"
                    fontSize={16}
                    className="font-bold"
                    onClick={onEdit}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showEditAddress && (
        <UpdateAddressForm
          showForm={showEditAddress}
          hideForm={() => setShowEditAddress(false)}
        />
      )}
      {showEditPhone && (
        <PhoneNumberForm
          phoneNumber={phone}
          showForm={showEditPhone}
          hideForm={() => setShowEditPhone(false)}
        />
      )}
      {showEditEmail && (
        <EmailForm
          email={email}
          showForm={showEditEmail}
          hideForm={() => setShowEditEmail(false)}
        />
      )}
    </Card>
  );
};

export default UserProfile;
