import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import { store } from "./redux/store";
import Calendar from "./components/calendar/Calendar";
import JobList from "./components/job-category";
import Settings from "./components/settings";
import Orientation from "./components/orientation";
import SignIn from "./components/sign-in";
import Clients from "./components/clients";
import ServiceInvoice from "./components/service-invoice";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./lang/en.json";
import "react-i18next";
import ShiftApproval from "./components/shift-approval";
import Facilities from "./components/facilities";
import NotFound from "./components/common/NotFound";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import ClientInvoices from "./components/clientInvoices";
import InvoiceSchedule from "./components/invoice-schedule";
import { ROUTE } from "./constants/routes";
import ServiceChargePage from "./components/serviceCharge";
import ClientProfile from "./components/clientProfile";
import UpcomingJobsPage from "./components/upcoming-jobs";

const locale = navigator.language;
let lang;
if (locale === "en") {
  lang = "en";
}
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
  },
  lng: lang,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export const persistedStore = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: ROUTE.scheduleJobs,
        element: <Calendar />,
      },
      {
        path: ROUTE.jobCategory,
        element: <JobList />,
      },
      {
        path: ROUTE.orientations,
        element: <Orientation />,
      },
      {
        path: ROUTE.clients,
        element: <Clients />,
      },
      {
        path: ROUTE.facilityInvoices,
        element: <ServiceInvoice />,
      },
      {
        path: ROUTE.shiftApproval,
        element: <ShiftApproval />,
      },
      {
        path: ROUTE.facilities,
        element: <Facilities />,
      },
      {
        path: ROUTE.settings,
        element: <Settings />,
      },
      {
        path: ROUTE.clientInvoices,
        element: <ClientInvoices />,
      },
      {
        path: ROUTE.invoiceScheduler,
        element: <InvoiceSchedule />,
      },
      {
        path: ROUTE.serviceCharge,
        element: <ServiceChargePage />,
      },
      {
        path: `${ROUTE.clients}/:id`,
        element: <ClientProfile />,
      },
      {
        path: `${ROUTE.upcomingJobs}`,
        element: <UpcomingJobsPage />,
      },
    ],
  },
  {
    path: ROUTE.signIn,
    element: <SignIn />,
  },

  {
    path: "*",
    element: <NotFound />,
  },
]);

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
