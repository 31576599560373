import React, { useMemo, useState } from "react";
import ConfirmationModal from "../common/ConfirmationModal";
import { useTranslation } from "react-i18next";
import Dropdown from "../common/Dropdown";
import { useAppSelector } from "../../redux/hooks";
import UnreadDot from "../layout/notifications/UnreadDot";

type Props = {
  show: boolean;
  hourlyRateNotPresent: boolean;
  onHide: () => void;
  onApprove: (shiftId: string) => void;
};

const ApproveOrientation = (props: Props) => {
  const { t } = useTranslation();
  const { show, hourlyRateNotPresent, onHide, onApprove } = props;
  const [shiftId, setShiftId] = useState("");
  const [shiftError, setShiftError] = useState("");
  const shiftsData = useAppSelector((state) => state.settingsReducer.shifts);

  const shiftOptions = useMemo(() => {
    return shiftsData.map((item) => ({
      value: item.id.toString(),
      label: item.title,
    }));
  }, [shiftsData]);

  const handleShiftChange = (id: string, value: string) => {
    setShiftId(value);
    setShiftError("");
  };

  const handleValidate = () => {
    if (!shiftId) {
      setShiftError(t("errors.shiftRequired") || "");
    } else {
      onApprove(shiftId);
    }
  };

  return (
    <ConfirmationModal
      open={show}
      text={
        <div>
          <div>{t("alertMessages.approveOrientation")}</div>
          <Dropdown
            id="shift"
            value={shiftId}
            label="Select shift"
            options={shiftOptions}
            onChange={handleShiftChange}
            className="mt-3"
            message={shiftError}
            status={shiftError ? "error" : ""}
          />

          {hourlyRateNotPresent && (
            <div className="error-text mb-3 pl-2 flex items-start">
              <UnreadDot className="mt-1 mr-1" redDot />
              <span>
                Hourly rate is not set for this job category, you can add it from
                job category section.
              </span>
            </div>
          )}
        </div>
      }
      handleYes={handleValidate}
      handleNo={onHide}
      primaryButtonText={t("common.approve") || ""}
      secondaryButtonText={t("common.cancel") || ""}
      isPrimaryButtonDisabled={hourlyRateNotPresent}
    />
  );
};

export default ApproveOrientation;
