import React, { useState } from "react";
import { Card } from "../common/Card";
import { EducationTypes } from "../../models/types";
import Icon from "../common/Icon";
import { Divider } from "antd";
import dayjs from "dayjs";
import { YYYY_MMM } from "../../constants/defaults";
import EducationHistoryForm from "./edit/EducationInfo";
import useUserRole from "../hooks/useUserRole";
import Tooltip from "../common/ToolTip";
import { useAppSelector } from "../../redux/hooks"

type Props = {
  eductionInfo: EducationTypes[];
};

const NEW_EDUCATION_HISTORY: EducationTypes = {
  id: "",
  school: "",
  degree: "",
  fieldOfStudy: "",
  startDate: "",
  endDate: "",
  grade: "",
  isPresent: false,
  isEducationCompletion: false,
};

const EducationInfo = (props: Props) => {
  const { eductionInfo } = props;
  const { isConsultant } = useUserRole();
  const isEducationCompleted = useAppSelector(
    (state) =>
      state.clientsReducer.clientProfile.profileStatus
        ?.isEducationCompletion
  );
  const [showUpdateEducationHistory, setShowEditEducationHistory] = useState(false);
  const [ehData, setEHData] = useState(NEW_EDUCATION_HISTORY);

  const onEdit = (index: number) => {
    setEHData(eductionInfo[index]);
    setShowEditEducationHistory(true);
  }
  const onAdd = (e: any) => {
    setEHData(NEW_EDUCATION_HISTORY);
    setShowEditEducationHistory(true);
  }

  return (
    <Card
      title={
        <div className="flex justify-between items-center mb-2">
          <span className="font-bold">Education</span>
          {isConsultant && !isEducationCompleted && (
            <Tooltip title="Add Education">
              <div
                className="flex items-center gap-1 text-[#FBB360]"
                role="button"
              >
                <Icon name="warning" fontSize={16} className="font-semibold" />
                <span className="text-xs">Action needed</span>
              </div>
            </Tooltip>
          )}
          {isConsultant && (
            <span className="bg-[#1677ff] text-white p-1 flex justify-center items-center rounded-[50%] hover:bg-[#1890ff]">
              <Icon role="button" name="add" fontSize={18} onClick={onAdd} />
            </span>
          )}
        </div>
      }
    >
      {eductionInfo?.map((edu, idx) => (
        <div key={idx}>
          {idx > 0 && <Divider className="my-3" />}
          <div className="flex">
            <div className="icon-wrapper">
              <Icon name="school" fontSize={18} className="font-bold" />
            </div>
            <div className="flex flex-col">
              <span className="ml-2 font-semibold ">
                {edu.degree} - {edu.fieldOfStudy}
              </span>
              <span className="ml-2 text-grey">{edu.school}</span>
              <span className="ml-2 text-grey">
                {dayjs(edu.startDate).format(YYYY_MMM)} to{" "}
                {edu.endDate ? dayjs(edu.endDate).format(YYYY_MMM) : "Present"}
              </span>
            </div>
            {isConsultant && (
              <div className="icon-wrapper">
                <Icon
                  role="button"
                  name="edit"
                  onClick={() => onEdit(idx)}
                  fontSize={18}
                  className="font-bold"
                />
              </div>
            )}
          </div>
        </div>
      ))}
      {showUpdateEducationHistory && (
        <EducationHistoryForm
          educationHistory={ehData}
          showForm={showUpdateEducationHistory}
          hideForm={() => setShowEditEducationHistory(false)}
        />
      )}
    </Card>
  );
};

export default EducationInfo;
