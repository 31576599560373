import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ServiceCharge } from "../../models/types";


export interface ServiceChargeReducerState {
    ServiceCharge: ServiceCharge[],
    loading: boolean;
}

const initialState: ServiceChargeReducerState = {
    ServiceCharge: [],
    loading: false
}

export const ServiceChargeSlice = createSlice({
    name: "ServiceCharge",
    initialState,
    reducers: {
        setServiceCharge: (state, action: PayloadAction<ServiceCharge[]>) => {
            state.ServiceCharge = action.payload || [];
        },
        setServiceChargeLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    }
});

export const {
    setServiceCharge,
    setServiceChargeLoading,
} = ServiceChargeSlice.actions;

export default ServiceChargeSlice.reducer;
