import {
  CCA_DOCUMENTS,
  COMMON_DOC_TITLES,
  CPR_DOCS,
  LICENSE_DOCS,
  TEMP_WORKER_DOCS,
} from "../constants/documentConstants";

export const getRequiredDocumentList = (
  jobTemplateId: number | string,
  legalStatus: string
) => {
  let requiredDocs = { ...COMMON_DOC_TITLES };
  if (Number(jobTemplateId) === 1 || Number(jobTemplateId) === 2) {
    requiredDocs = { ...requiredDocs, ...LICENSE_DOCS,...CPR_DOCS };
  } else if (Number(jobTemplateId) === 3) {
    requiredDocs = { ...requiredDocs, ...CCA_DOCUMENTS, ...CPR_DOCS };
  }
  if (legalStatus !== "Citizen" && legalStatus !== "Permanent Resident") {
    requiredDocs = { ...requiredDocs, ...TEMP_WORKER_DOCS };
  }
  return requiredDocs;
};
