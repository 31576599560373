import React, { useState } from "react";
import { Dropdown, Checkbox, Button } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import Icon from "./Icon";
import { cloneDeep } from "lodash";
import { TableFilterTypes } from "../../models/types";

type FilterTypes = {
  filterItems: TableFilterTypes[];
  onFilter: (selectedFilters: string[] | []) => void;
};
const Filter = (props: FilterTypes) => {
  const { filterItems, onFilter } = props;
  const [selectedFilters, setSelectedFilters] = useState(new Map());
  const [open, setOpen] = useState(false);

  const handleChange = (e: CheckboxChangeEvent) => {
    //   Triggers when a filter is checked or unchecked
    const { id, checked } = e.target;
    const selectedFiltersCopy = cloneDeep(selectedFilters);
    if (checked) {
      selectedFiltersCopy.set(id, true);
    } else {
      selectedFiltersCopy.delete(id);
    }
    setSelectedFilters(selectedFiltersCopy);
  };
  // Generating menu items
  const items = filterItems.map((item) => ({
    label: (
      <Checkbox
        key={item.id}
        id={item.id}
        onChange={handleChange}
        checked={selectedFilters.get(item.id) || false}
      >
        {item.label}
      </Checkbox>
    ),
    key: item.id,
  }));

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };
  const handleReset = () => {
    // Triggers when Reset button is clicked
    setSelectedFilters(new Map());
  };
  const handleOk = () => {
    // Triggers when Ok button is clicked
    let arr: any = [];
    selectedFilters.forEach((value, key, map) => {
      arr.push(key);
    });
    onFilter(arr);
    setOpen(false);
  };

  items.push({
    label: (
      <div className="pt-3 drop-down-controls w-full flex justify-between">
        <Button
          type="link"
          id="reset-btn"
          size="small"
          disabled={!selectedFilters.size}
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button type="primary" size="small" onClick={handleOk} id="ok-btn">
          Ok
        </Button>
      </div>
    ),
    key: "controls",
  });
  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      onOpenChange={handleOpenChange}
      open={open}
    >
      <Icon
        name="filter_list"
        role="button"
        className="hover:bg-[#f1f3f4] p-1 rounded-sm"
      />
    </Dropdown>
  );
};

export default Filter;
