import { Button, Modal } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MarkJobAsCompletedRequestType } from "../../../models/types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ShiftInterval from "../../common/ShiftInterval";
import { COMPLETE_JOB_REQUEST_TEMPLATE } from "./constant";
import { getHoursWorkedInDecimals } from "../../../helpers/helpers";
import {
  getShiftStartEndDateTimes,
  getTimeDiffFrom24HTime,
  getTimeFromUtcDate,
} from "../../../helpers/dateTime.helper";
import dayjs from "dayjs";
import useFacilityTimezone from "../../hooks/useFacilityTimezone";
import { markJobAsCompleteThunk } from "../../../redux/thunks/shiftSchedule";
import { CalenderViewType } from "../../../models/day.model";
import { JobTypes } from "../../../models/job.model";

type MarkJobCompletedFormProps = {
  show: boolean;
  calendarView: CalenderViewType;
  job: JobTypes;
  onHide: () => void;
  handleClose: () => void;
  currentDate: dayjs.Dayjs;
};

const MarkJobCompletedForm = (props: MarkJobCompletedFormProps) => {
  const { t } = useTranslation();
  const { timeZoneId } = useFacilityTimezone();
  const { show, onHide, handleClose, calendarView, job, currentDate } =
    props;
  const dispatch = useAppDispatch();
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id
  );
  const { getFacilityTimeZone } = useFacilityTimezone();
  const [editShiftForm, setEditShiftForm] = useState<MarkJobAsCompletedRequestType>({
    ...COMPLETE_JOB_REQUEST_TEMPLATE,
  });
  const [error, setError] = useState("");

  useEffect(() => {
    setEditShiftForm({
      jobId: job.id,
      userId: job.clientId,
      shiftStartDateTime: getTimeFromUtcDate(
        job.shiftStartDateTime,
        timeZoneId
      ),
      shiftEndDateTime: getTimeFromUtcDate(
        job.shiftEndDateTime,
        timeZoneId
      ),
      shiftStartTime: getTimeFromUtcDate(
        job.shiftStartDateTime,
        timeZoneId
      ),
      shiftEndTime: getTimeFromUtcDate(
        job.shiftEndDateTime,
        timeZoneId
      ),
    });
  }, [job, timeZoneId]);

  const onTimeChange = (id: string, value: string) => {
    const formDataCopy = { ...editShiftForm, [id]: value };
    if (formDataCopy.shiftStartTime === formDataCopy.shiftEndTime) {
      setError(t("errors.startFinishTimeSame") || "");
    } else {
      setError("");
    }
    setEditShiftForm({ ...formDataCopy });
  };

  const [totalHours, totalHoursInDecimal] = useMemo(() => {
    const totalHours = getTimeDiffFrom24HTime(
      editShiftForm.shiftStartTime,
      editShiftForm.shiftEndTime
    );
    const totalHoursInDecimal = getHoursWorkedInDecimals(
      editShiftForm.shiftStartTime,
      editShiftForm.shiftEndTime
    );
    return [totalHours, totalHoursInDecimal];
  }, [
    editShiftForm.shiftEndTime,
    editShiftForm.shiftStartTime,
  ]);

  const handleMarkJobAsCompleted = () => {
    const tz = getFacilityTimeZone();
    const { startDateTime, endDateTime } = getShiftStartEndDateTimes(
      job.shiftStartDateTime || dayjs().utc().format(),
      editShiftForm.shiftStartTime,
      editShiftForm.shiftEndTime,
      true,
      tz
    );
    const payload: MarkJobAsCompletedRequestType = {
      userId: job.clientId,
      jobId: job.id,
      shiftStartDateTime: startDateTime,
      shiftEndDateTime: endDateTime,
      shiftStartTime: editShiftForm.shiftStartTime,
      shiftEndTime: editShiftForm.shiftEndTime,
    };
    dispatch(
      markJobAsCompleteThunk(
        payload,
        calendarView,
        facilityId,
        timeZoneId,
        currentDate,
        handleClose
      )
    );
    onHide();
  };
  return (
    <Modal
      open={show}
      title={t("jobSchedule.markJobAsComplete")}
      onCancel={onHide}
      footer={[
        <Button key="no" type="default" onClick={onHide} id="edit-shift-time-cancel-btn">
          {t("common.cancel")}
        </Button>,
        <Button key="yes" type="primary" onClick={handleMarkJobAsCompleted} id="edit-shift-time-complete-btn">
          {t("common.complete")}
        </Button>,
      ]}
    >
      <div className="min-h-[62px] pb-2">
        <ShiftInterval
          startTime={editShiftForm.shiftStartTime}
          finishTime={editShiftForm.shiftEndTime}
          startTimeId="shiftStartTime"
          finishTimeId="shiftEndTime"
          onChange={onTimeChange}
          startTimeError={error}
          finishTimeError={error}
        />
      </div>
    </Modal>
  );
};

export default MarkJobCompletedForm;
