import React from "react";
import { Card } from "../common/Card";
import { DeviceIdentifier, EducationTypes } from "../../models/types";
import Icon from "../common/Icon";
import { Divider } from "antd";
import dayjs from "dayjs";
import { YYYY_MMM } from "../../constants/defaults";

type Props = {
    deviceDetails: null | DeviceIdentifier[];
};

const DeviceDetails = (props: Props) => {
  const { deviceDetails } = props;
  return (
    <Card title="Device Details">
      {deviceDetails?.map((dd, idx) => (
        <div key={idx}>
          {idx > 0 && <Divider className="my-3" />}
          <div className="flex">
            <div className="icon-wrapper">
              <Icon name="phone_iphone" fontSize={18} className="font-bold" />
            </div>
            <div className="flex flex-col">
              <span className="ml-2 font-semibold ">
                OS: {dd.osType} - {dd.systemVersion} - {dd.deviceId}
              </span>
              <span className="ml-2 text-grey">App Version: {dd.appVersion}</span>
              <span className="ml-2 text-grey">Device Model: {dd.model}</span>
              <span className="ml-2 text-grey">Resolution: {dd.resolution}</span>
            </div>
          </div>
        </div>
      ))}
    </Card>
  );
};

export default DeviceDetails;
