import {
  createShift,
  getShiftsByFacilityId,
  updateShift,
  uploadLogo,
} from "./../../services/settings.services";
import { FacilitiesTypes, ShiftTypes } from "../../models/types";
import { AppDispatch } from "../store";
import {
  setFacilityTimeZone,
  setSettingsLoading,
  setShifts,
  setUploading,
} from "../reducers/settingsReducer";
import { AxiosError } from "axios";
import { showToast } from "../reducers/feedbackReducer";
import { handleApiErrors, logToSentry } from "../../helpers/helpers";
import { fetchFacilityById } from "../../services/facilities.services";
import { JobCategoryTypes } from "../../models/types";
import {
  setCurrentFacility,
  updateFacilityInList,
} from "../reducers/facilitiesReducer";
import { setJobCategories } from "../reducers/jobCategoryReducer";
import { get } from "lodash";
import { getFacilityStatusThunk } from "./facility"

export const createShiftThunk =
  (formData: ShiftTypes, callback: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setSettingsLoading(true));
    try {
      await createShift(formData);
      const feedbackCallback = () => {
        callback && callback();
        dispatch(
          showToast({
            type: "success",
            message: "New shift has been created successfully!",
            show: true,
          })
        );
        dispatch(setSettingsLoading(false));
      };
      dispatch(getFacilityStatusThunk(formData.facilityId));
      dispatch(
        getShiftListByFacilityIdThunk(
          Number(formData.facilityId),
          feedbackCallback
        )
      );
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      dispatch(setSettingsLoading(false));
      logToSentry(err, "Shift", "createShiftThunk");
    }
  };

export const updateShiftThunk =
  (formData: ShiftTypes, callback: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setSettingsLoading(true));
    try {
      await updateShift(formData);
      const feedbackCallback = () => {
        callback && callback();
        dispatch(
          showToast({
            type: "success",
            message: "Shift has been updated successfully!",
            show: true,
          })
        );
        dispatch(setSettingsLoading(false));
      };
      dispatch(getFacilityStatusThunk(formData.facilityId));
      dispatch(
        getShiftListByFacilityIdThunk(
          Number(formData.facilityId),
          feedbackCallback
        )
      );
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      dispatch(setSettingsLoading(false));
      logToSentry(err, "Shift", "updateShiftThunk");
    }
  };

export const getShiftListByFacilityIdThunk =
  (facilityId: number, callback?: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setSettingsLoading(true));
    try {
      const response = await getShiftsByFacilityId(facilityId);
      dispatch(setShifts(response.data.data || []));
      callback && callback();
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Shift", "getShiftListByFacilityIdThunk");
    } finally {
      dispatch(setSettingsLoading(false));
    }
  };

export const getFacilityDetailsThunk =
  (facilityId: number, callback?: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setSettingsLoading(true));
    try {
      const response = await fetchFacilityById(facilityId, true, true);
      const facility = get(
        response.data.data,
        "facility",
        {}
      ) as FacilitiesTypes;
      const jobCategories = get(
        response.data.data,
        "jobCategories",
        []
      ) as JobCategoryTypes[];
      const shifts = get(response.data.data, "shifts", []) as ShiftTypes[];
      dispatch(setCurrentFacility(facility));
      dispatch(setFacilityTimeZone(get(facility, "timeZoneId", "")));
      dispatch(setShifts(shifts));
      dispatch(setJobCategories(jobCategories));
      callback && callback();
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Facilities", "getFacilityDetailsThunk");
    } finally {
      dispatch(setSettingsLoading(false));
    }
  };
export const uploadFacilityLogoThunk =
  (payload: any, facilityId: number) => async (dispatch: AppDispatch) => {
    dispatch(setUploading(true));
    try {
      await uploadLogo(payload);
      const response = await fetchFacilityById(facilityId, true, true);
      dispatch(updateFacilityInList(response.data.data?.facility || {}));
      dispatch(
        showToast({
          type: "success",
          message: "Logo is uploaded",
          show: true,
          direction: "right",
        })
      );
    } catch (err: any) {
      dispatch(
        showToast({
          type: "error",
          message: "Error uploading the logo, please try later",
          show: true,
        })
      );
      logToSentry(err, "Facilities", "uploadFacilityLogoThunk");
    } finally {
      dispatch(setUploading(false));
    }
  };
