import React, { useEffect, useMemo, useState } from "react";
import { ShiftApprovalRequestType } from "../../models/types";
import { useTranslation } from "react-i18next";
import { getHoursWorkedInDecimals } from "../../helpers/helpers";
import { useAppDispatch } from "../../redux/hooks";
import { Segmented, Space, Table, Tooltip } from "antd";
import EditShiftTime from "./EditShiftTime";
import { SHIFT_APPROVAL_REQUEST_TEMPLATE } from "./constant";
import ActionButtons from "./ActionButtons";
import ConfirmationModal from "../common/ConfirmationModal";
import { approveShiftRequestThunk } from "../../redux/thunks/shiftApproval";
import { useLocation, useNavigate } from "react-router";
import SectionName from "../common/SectionName";
import Icon from "../common/Icon";
import {
  getDateTimeFromUTCDateTime,
  getTimeDiffFrom24HTime,
} from "../../helpers/dateTime.helper";
import useFacilityTimezone from "../hooks/useFacilityTimezone";
import { ROUTE } from "../../constants/routes";

type ShiftApprovalTableTypes = {
  data: ShiftApprovalRequestType[];
  filterChange: (
    key: "segmented" | "job-category",
    value: string | string[] | []
  ) => void;
};
const ShiftApprovalTable = (props: ShiftApprovalTableTypes) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, filterChange } = props;
  const { timeZoneId } = useFacilityTimezone();
  const [segmentedFilter, setSegmentedFilter] = useState<
    "Approved" | "Pending" | "All"
  >("Pending");
  // const jobsCategoryFromState = useAppSelector(
  //   (state) => state.jobCategoryReducer.jobCategories
  // );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editShift, setEditShift] = useState<{
    status: boolean;
    shiftDetails: ShiftApprovalRequestType;
  }>({ status: false, shiftDetails: { ...SHIFT_APPROVAL_REQUEST_TEMPLATE } });
  const [selectedShifts, setSelectedShifts] = useState<
    ShiftApprovalRequestType[]
  >([]);
  const [approveReqState, setApproveReqState] = useState({
    shiftStartTime: "",
    shiftEndTime: "",
    jobAllocationId: undefined,
    clientId: undefined,
    id: undefined,
    facilityId: undefined,
  });
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const locationState = location.state;

  // const jobCategoryFilterData: TableFilterTypes[] = useMemo(() => {
  //   return jobsCategoryFromState.map((item) => ({
  //     id: item.id,
  //     label: item.title,
  //   }));
  // }, [jobsCategoryFromState]);
  // const onFilter = useCallback(
  //   (selectedFilters: string[]) => {
  //     filterChange("job-category", selectedFilters);
  //   },
  //   [filterChange]
  // );
  const resetRowSelection = () => {
    setSelectedKeys([]);
    setSelectedShifts([]);
  };
  let columns = useMemo(
    () => [
      {
        title: t("common.name"),
        ellipsis: true,
        width: "20%",
        render: (
          _: any,
          {
            clientFirstName,
            clientLastName,
            clientId,
            isInvoiceGenerated,
            clientDeletedDate,
          }: {
            clientFirstName: string;
            clientLastName: string;
            clientId: number;
            isInvoiceGenerated: boolean;
            clientDeletedDate?: boolean;
          }
        ) => {
          if (clientDeletedDate) {
            return (
              <>
                <Tooltip title="The user account has been deleted.">
                  <div className="float-left">
                    {`${clientFirstName} ${clientLastName}`}
                  </div>
                </Tooltip>

                {isInvoiceGenerated && (
                  <Tooltip title="Cannot edit shift. Invoice already generated.">
                    <div className="float-left">
                      <Icon name="Lock" fontSize={20} />
                    </div>
                  </Tooltip>
                )}
              </>
            );
          }
          return (
            <>
              <div
                className="link-text float-left"
                onClick={() => navigate(`${ROUTE.clients}/${clientId}`)}
              >
                {`${clientFirstName} ${clientLastName}`}
              </div>
              {isInvoiceGenerated && (
                <Tooltip title="Cannot edit shift. Invoice already generated.">
                  <div className="float-left">
                    <Icon name="Lock" fontSize={20} />
                  </div>
                </Tooltip>
              )}
            </>
          );
        },
      },
      {
        title: (
          <div className="flex justify-between items-center">
            <span>{t("common.jobCategory")}</span>
            {/* <Filter filterItems={jobCategoryFilterData} onFilter={onFilter} /> */}
          </div>
        ),
        dataIndex: "title",
        key: "title",
        width: "20%",
      },
      {
        title: t("shiftApproval.shiftStart"),
        dataIndex: "shiftStartTime",
        key: "shiftStartTime",
        width: "25%",
        render: (
          _: any,
          { shiftStartDateTime }: { shiftStartDateTime: string }
        ) => {
          return getDateTimeFromUTCDateTime(shiftStartDateTime, timeZoneId);
        },
      },
      {
        title: t("shiftApproval.shiftEnd"),
        dataIndex: "shiftEndTime",
        key: "shiftEndTime",
        width: "25%",
        render: (
          _: any,
          { shiftEndDateTime }: { shiftEndDateTime: string }
        ) => {
          return getDateTimeFromUTCDateTime(shiftEndDateTime, timeZoneId);
        },
      },
      {
        title: t("shiftApproval.totalHours"),
        width: "15%",
        render: (_: any, rowData: ShiftApprovalRequestType) => {
          const { shiftStartTime, shiftEndTime } = rowData;
          const totalHours = getTimeDiffFrom24HTime(
            shiftStartTime,
            shiftEndTime
          );
          return (
            <div className="flex w-full ">
              <span>{totalHours}</span>
              {/* {segmentedFilter === "All" && !approved && (
                <div className="tags-for-all ml-3">
                  <Tag color="volcano">{t("shiftApproval.pending")}</Tag>
                </div>
              )}
              {segmentedFilter === "All" && approved && (
                <Tag color="green" className="ml-3">
                  {t("shiftApproval.approved")}
                </Tag>
              )} */}
            </div>
          );
        },
      },
    ],
    [navigate, t, timeZoneId]
  );
  const rowSelection = {
    selectedRowKeys: selectedKeys,
    getCheckboxProps: (record: ShiftApprovalRequestType) => ({
      disabled: false, //record.jobStatus === JOB_STATUSES.approved,
    }),
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: ShiftApprovalRequestType[]
    ) => {
      setSelectedKeys(selectedRowKeys);
      setSelectedShifts(selectedRows);
    },
  };
  const handleFilter = (value: string | number) => {
    if (value === "Approved" || value === "Pending" || value === "All") {
      setSegmentedFilter(value);
      resetRowSelection();
      filterChange("segmented", value.toLocaleLowerCase());
    }
  };
  const approveShiftRequest = () => {
    const {
      shiftEndTime,
      shiftStartTime,
      jobAllocationId,
      id,
      clientId,
      facilityId,
    } = approveReqState;
    if (id && shiftStartTime && shiftEndTime && jobAllocationId && clientId) {
      const payload = {
        id,
        jobAllocationId,
        clientId,
        shiftStartDateTime: shiftStartTime,
        shiftEndDateTime: shiftEndTime,
        isEdit: false,
        totalHours: getHoursWorkedInDecimals(
          shiftStartTime,
          shiftEndTime
        ).toString(),
      };
      dispatch(
        approveShiftRequestThunk(
          Number(facilityId),
          payload,
          segmentedFilter,
          timeZoneId
        )
      );
      setShowConfirmation(false);
    }
  };
  useEffect(() => {
    if (locationState) {
      const {
        action,
        id,
        jobAllocationId,
        clientId,
        clientFirstName,
        clientLastName,
        shiftStartTime,
        shiftEndTime,
        facilityId,
      } = location.state;
      if (action === "edit") {
        setEditShift({
          status: true,
          shiftDetails: {
            id,
            title: "",
            shiftDate: "",
            shiftStartDateTime: shiftStartTime,
            shiftEndDateTime: shiftEndTime,
            shiftStartTime,
            shiftEndTime,
            clientFirstName,
            clientLastName,
            clientId,
            jobStatus: "completed",
            isOrientation: true,
            jobAllocationId,
            isInvoiceGenerated: false,
          },
        });
        navigate(location.pathname, { replace: true });
      } else if (action === "approve") {
        setApproveReqState({
          shiftStartTime,
          shiftEndTime,
          jobAllocationId,
          clientId,
          id,
          facilityId,
        });
        setShowConfirmation(true);
        navigate(location.pathname, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  const closeApproveConfirmation = () => {
    setShowConfirmation(false);
    setApproveReqState({
      shiftStartTime: "",
      shiftEndTime: "",
      jobAllocationId: undefined,
      clientId: undefined,
      id: undefined,
      facilityId: undefined,
    });
  };
  return (
    <Space direction="vertical" className="w-full">
      <div className="flex justify-between items-center">
        <SectionName />
        <div className="flex items-center">
          <ActionButtons
            selectedShifts={selectedShifts}
            resetRowSelection={resetRowSelection}
            segmentedFilter={segmentedFilter}
            onEdit={() =>
              setEditShift({
                status: true,
                shiftDetails: { ...selectedShifts[0] },
              })
            }
          />
          <Segmented
            options={[
              t("shiftApproval.pending") || "Pending",
              t("shiftApproval.approved") || "Approved",
              t("shiftApproval.all") || "All",
            ]}
            value={segmentedFilter}
            onChange={handleFilter}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        sticky
        rowClassName="orientation-table-row"
        size="small"
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />

      {editShift?.status && (
        <EditShiftTime
          show={editShift.status}
          shiftDetails={editShift.shiftDetails}
          segmentedFilter={segmentedFilter}
          onHide={() => {
            setEditShift({
              status: false,
              shiftDetails: { ...SHIFT_APPROVAL_REQUEST_TEMPLATE },
            });
          }}
          resetRowSelection={resetRowSelection}
        />
      )}
      {showConfirmation && (
        <ConfirmationModal
          open={showConfirmation}
          text={t("alertMessages.approveShift")}
          handleYes={approveShiftRequest}
          handleNo={closeApproveConfirmation}
        />
      )}
    </Space>
  );
};

export default ShiftApprovalTable;
