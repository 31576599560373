import { isEmpty } from "lodash";
import { DropDownOptionTypes, FacilitiesTypes } from "../../models/types";
import { Card } from "../common/Card";
import { Divider } from "antd";
import { useAppSelector } from "../../redux/hooks";
import useUserRole from "../hooks/useUserRole";
import { useEffect, useMemo, useState } from "react";
import Icon from "../common/Icon";
import EmploymentForm from "./edit/CurrentEmployment";
import Tooltip from "../common/ToolTip";

type Props = {
  currentEmployer: { fullTime: number[]; casual: number[] }
};

type EmploymentTypeProps = {
  title: string;
  facilityIds: number[] | null;
};
const EmploymentType = (props: EmploymentTypeProps) => {
  const { title, facilityIds } = props;
  const { isConsultant } = useUserRole();
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id
  );

  const facilities = useAppSelector(
    (state) => state.facilitiesReducer.facilities
  );

  const filteredList = useMemo(() => {
    if (!facilityIds || isEmpty(facilityIds)) {
      return [];
    }
    if (isConsultant) {
      return facilityIds;
    }
    return facilityIds?.filter(
      (e) => Number(e) === Number(facilityId)
    );
  }, [facilityIds, facilityId, isConsultant]);

  const getFacility = (facilityId: number): FacilitiesTypes | undefined => {
    return facilities.find((f) => Number(f.id) === facilityId)
  };

  return (
    <div>
      <div className="font-semibold">{title}</div>
      <ul className="pl-4">
        {!isEmpty(filteredList) ? (
          filteredList.map((e) => {
            const facility = getFacility(e);
            if (facility) {
              return (
                <li key={facility?.id} className="list-item">
                  {facility?.title}
                </li>
              );
            }
            return <></>
          })
        ) : (
          <div>None</div>
        )}
      </ul>
    </div>
  );
};

const CurrentEmployment = (props: Props) => {
  const { currentEmployer } = props;
  const [jobOptions, setJobOptions] = useState<DropDownOptionTypes[] | undefined>();
  const { isConsultant } = useUserRole();
  const isCurrentEmployementCompleted = useAppSelector(
    (state) => state.clientsReducer.clientProfile.profileStatus?.isCurrentEmploymentCompletion
  );

  const facilities = useAppSelector(
    (state) => state.facilitiesReducer.facilities
  );

  useEffect(() => {
    const options = facilities
      ?.filter(facility => facility.enabled)
      .map(facility => ({
        label: facility.title,
        value: facility.id,
      }));
  
    setJobOptions(options);
  }, [facilities]);

  const onEdit = (e: any) => { setShowUpdateCurrentEmployment(true) }
  const [showUpdateCurrentEmployment, setShowUpdateCurrentEmployment] = useState(false);
  return (
    <Card
      title={
        <div className="flex justify-between items-center mb-2">
          <span className="font-bold">Partnered Facilities</span>
          {isConsultant && !isCurrentEmployementCompleted && (
            <Tooltip title="Add Current Employment">
              <div
                className="flex items-center gap-1 text-[#FBB360]"
                role="button"
              >
                <Icon name="warning" fontSize={16} className="font-semibold" />
                <span className="text-xs">Action needed</span>
              </div>
            </Tooltip>
          )}
          {isConsultant && (
            <span className="bg-[#1677ff] text-white p-1 flex justify-center items-center rounded-[50%] hover:bg-[#1890ff]">
              <Icon role="button" name="edit" fontSize={18} onClick={onEdit} />
            </span>
          )}
        </div>
      }
    >
      <div>
        <EmploymentType
          title="Full-Time/Part-Time"
          facilityIds={currentEmployer?.fullTime}
        />
        <Divider className="my-2" />
        <EmploymentType title="Casual" facilityIds={currentEmployer?.casual} />
      </div>
      {showUpdateCurrentEmployment && (
        <EmploymentForm
          jobOptions={jobOptions}
          casual={currentEmployer?.casual}
          fullTime={currentEmployer?.fullTime}
          showForm={showUpdateCurrentEmployment}
          hideForm={() => setShowUpdateCurrentEmployment(false)}
        />
      )}
    </Card>
  );
};

export default CurrentEmployment;
