import { useMemo } from "react";
import { CalenderViewType } from "../../../models/day.model";
import { Tooltip } from "antd";
import "./calendarHeader.css";
import AddButton from "../../common/AddButton/AddButton";
import Dropdown from "../../common/Dropdown";
import type { Dayjs } from "dayjs";
import AddButtonDropdown from "../AddButtonDropdown";
import { useTranslation } from "react-i18next";
import Icon from "../../common/Icon";
import { YYYY_MMM_DATE } from "../../../constants/defaults";
import { useAppSelector } from "../../../redux/hooks";

type CalendarHeaderTypes = {
  selectedDate: Dayjs;
  onToday: () => void;
  onPrevious: () => void;
  onNext: () => void;
  showPanel: (id: string) => void;
  calendarView: CalenderViewType;
  setCalendarView: (view: CalenderViewType) => void;
};

const CalendarHeader = (props: CalendarHeaderTypes) => {
  const {
    selectedDate,
    onToday,
    onPrevious,
    onNext,
    showPanel,
    calendarView,
    setCalendarView,
  } = props;
  const { t } = useTranslation();
  const isFacilityEnabled = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.enabled
  );
  const CALENDER_VIEW_OPTIONS = useMemo(() => {
    return [
      { value: "bi-weekly-view", label: t("jobSchedule.biWeeklyView") },
      { value: "month-view", label: t("jobSchedule.monthView") },
    ];
  }, [t]);

  return (
    <div className="calendar-header grid grid-cols-4 justify-between items-center">
      <div className="flex items-center">
        <button className="today-button" onClick={onToday} id="today-btn">
          {t("jobSchedule.today")}
        </button>
        <Tooltip placement="top" title={t("jobSchedule.previous")}>
          <Icon
            name="chevron_left"
            role="button"
            className="mx-2 hover:bg-[#f1f3f4] rounded-[50%] p-1"
            onClick={onPrevious}
          />
        </Tooltip>
        <Tooltip placement="top" title={t("jobSchedule.next")}>
          <Icon
            name="chevron_right"
            role="button"
            className="hover:bg-[#f1f3f4] rounded-[50%] p-1"
            onClick={onNext}
          />
        </Tooltip>
        <Dropdown
          size="small"
          id="calender-view"
          value={calendarView}
          onChange={(id, value) => setCalendarView(value)}
          options={CALENDER_VIEW_OPTIONS}
          selectClassName="mt-2"
          className="mt-2"
        />
      </div>
      <div className="flex items-center justify-center col-span-2">
        <div className="text-xl">{selectedDate?.format(YYYY_MMM_DATE)}</div>
      </div>
      {isFacilityEnabled && (<div className="justify-end flex">
        <AddButtonDropdown handleSelection={showPanel}>
          <AddButton
            tooltipText={t("jobSchedule.postJob")}
            onClick={(e) => e.preventDefault()}
          />
        </AddButtonDropdown>
      </div>)}

    </div>
  );
};

export default CalendarHeader;
