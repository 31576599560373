import { Button } from "antd";
import { Notification } from "../../../../models/types";
import NotificationText from "../NotificationText";
import Icon from "../../../common/Icon";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../redux/hooks";
import { downloadPdfThunk } from "../../../../redux/thunks/invoices";
import UserAvatar from "../../../common/UserAvatar";

type Props = {
  notification: Notification;
  handleNotificationOpen: (id: number) => void;
};
const InvoiceGenerated = (props: Props) => {
  const { notification, handleNotificationOpen } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const downloadInvoice = () => {
    const { id, invoiceId, invoiceNumber } = notification;
    if (invoiceId) {
      dispatch(
        downloadPdfThunk({
          fileId: invoiceId,
          invoiceNumber: invoiceNumber || "Invoice",
        })
      );
    }
    handleNotificationOpen(id);
  };
  return (
    <div className="hover:bg-[#d9d9d9] p-4" onClick={downloadInvoice}>
      <div className="flex items-start">
        <div className="mr-4">
          <UserAvatar
            type="icon"
            size={48}
            icon={<Icon name="receipt_long" />}
          />
        </div>
        <NotificationText notification={notification} />
      </div>
      <div className="flex justify-end">
        <Button
          id="download-invoice-btn"
          type="primary"
          icon={<Icon name="download" fontSize={16} />}
          className="flex justify-center items-center"
          size="small"
        >
          {t("invoice.downloadPDF")}
        </Button>
      </div>
    </div>
  );
};

export default InvoiceGenerated;
