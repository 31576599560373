import { Button, Result } from "antd";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="h-screen w-full flex flex-col justify-center items-center pb-32">
      <Result
        status="warning"
        extra={
          <>
            <div className="text-4xl -mt-2">404</div>
            <div className="text-lg my-2 text-[#5F6368]">
              {t("notFound.message")}
            </div>
            <Button
              type="primary"
              className="mt-2"
              id="back-to-home-btn"
              onClick={() => navigate("/")}
            >
              {t("notFound.backToHome")}
            </Button>
          </>
        }
      />
    </div>
  );
};

export default NotFound;
