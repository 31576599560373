import { Avatar, Button, Upload, UploadProps } from "antd";
import Icon from "../common/Icon";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { uploadFacilityLogoThunk } from "../../redux/thunks/settings";

const Logo = ({
  setLogo,
  logo,
  disableUpload,
  facilityId,
}: {
  logo: string;
  setLogo: (file: any) => void;
  disableUpload: boolean;
  facilityId: string;
}) => {
  const dispatch = useAppDispatch();
  const uploading = useAppSelector((state) => state.settingsReducer.uploading);
  const handleUpload = (info: any) => {
    if (info.file) {
      const form = new FormData();
      form.append("facilityId", facilityId);
      form.append("documentType", "Logo");
      form.append("files", info.file);
      dispatch(uploadFacilityLogoThunk(form, Number(facilityId)));
    }
  };
  const uploadProps: UploadProps = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    showUploadList: false,
    multiple: false,
    beforeUpload: (file) => {
      return false;
    },
    onChange: handleUpload,
  };
  return (
    <div className="mb-3">
      <Avatar
        shape="square"
        className="flex items-center justify-center bg-gray-200"
        src={logo}
        size={120}
        icon={<Icon name="business" fontSize={70} />}
      />
      <div className="flex mt-3">
        <Upload
          {...uploadProps}
          onChange={handleUpload}
          disabled={disableUpload}
          accept="image/png, image/jpeg"
        >
          {uploading ? (
            <span className="ml-5">Uploading...</span>
          ) : (
            <Button
              type="primary"
              size="small"
              id="upload-btn"
              className="flex items-center ml-5"
              onClick={handleUpload}
              disabled={disableUpload}
            >
              <Icon name="upload" fontSize={20} />
              <span>Upload </span>
            </Button>
          )}
        </Upload>
      </div>
    </div>
  );
};

export default Logo;
