import { useState, useMemo, useEffect, useCallback } from "react";
import { OrientationRequestTypes, TableFilterTypes } from "../../models/types";
import { Table, Space, Segmented } from "antd";
import {
  getJobCategoryName,
  getOrientationStatusObjs,
} from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import Filter from "../common/Filter";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getOrientationRequestsThunk } from "../../redux/thunks/orientationRequests";
import { isEmpty } from "lodash";
import ActionButtons from "./ActionButtons";
import ApproveOrientation from "./ApproveOrientation";
import { ApproveDatePayload } from "../../services/orientation.services";
import SectionName from "../common/SectionName";
import useFacilityTimezone from "../hooks/useFacilityTimezone";
import {
  getDateFromUtcDate,
  getDateTimeFromUTCDateTime,
} from "../../helpers/dateTime.helper";
import { ROUTE } from "../../constants/routes";
import { useNavigate } from "react-router";

type ORIENTATION_TABLE_TYPES = {
  onSuggestClick: (
    empId: number,
    empName: string,
    hourlyRate: number,
    hourlyRateNotPresent: boolean
  ) => void;
  onApprove: (payload: ApproveDatePayload) => void;
  isLoading: boolean;
  jobCategoryFilterData: TableFilterTypes[];
  isDrawerOpen: boolean;
  closeDrawer: () => void;
};

const OrientationTable = (props: ORIENTATION_TABLE_TYPES) => {
  const { t } = useTranslation();
  const {
    onSuggestClick,
    isLoading,
    onApprove,
    jobCategoryFilterData,
    closeDrawer,
  } = props;
  const [filter, setFilter] = useState<
    "Unscheduled" | "Scheduled" | "Completed" | "All"
  >("Unscheduled");

  const [hourlyRateNotPresent, setHourlyRateNotPresent] = useState(false);
  const [jobCategoryFilter, setJobCategoryFilter] = useState<string[] | []>([]);
  const orientationStatuses = useAppSelector(
    (state) => state.appReducer.orientationStatuses
  );
  const { timeZoneId } = useFacilityTimezone();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const orientationRequests = useAppSelector(
    (state) => state.orientationReducer.orientationRequests
  );
  const jobsCategoryFromState = useAppSelector(
    (state) => state.jobCategoryReducer.jobCategories
  );
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [selectedOrientations, setSelectedOrientations] = useState<
    OrientationRequestTypes[]
  >([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const filteredTableData = useMemo(() => {
    const orientationStatusIds = getOrientationStatusObjs(orientationStatuses);
    if (filter === "Scheduled") {
      return orientationRequests.filter(
        (o) => o.orientationStatusId === orientationStatusIds.approved
      );
    } else if (filter === "Unscheduled") {
      return orientationRequests.filter(
        (o) =>
          o.orientationStatusId === orientationStatusIds.requested ||
          o.orientationStatusId === orientationStatusIds.suggested
      );
    } else if (filter === "Completed") {
      return orientationRequests.filter(
        (o) => o.orientationStatusId === orientationStatusIds.completed
      );
    } else {
      return orientationRequests;
    }
  }, [filter, orientationRequests, orientationStatuses]);
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id?.toString() || ""
  );
  const [approvalState, setApprovalState] = useState<{
    id: number;
    date: string;
  }>();
  const dispatchFetchOrientations = useCallback(
    (status: string, jobCategories: string[] | []) => {
      if (
        status === "unscheduled" ||
        status === "all" ||
        status === "completed" ||
        status === "scheduled"
      ) {
        facilityId &&
          dispatch(getOrientationRequestsThunk(facilityId, timeZoneId));
        closeDrawer();
      }
    },
    [facilityId, dispatch, timeZoneId, closeDrawer]
  );
  const handleJobCategoryFilter = useCallback(
    (selectedFilters: string[]) => {
      setJobCategoryFilter(selectedFilters);
      // dispatchFetchOrientations(filter.toLowerCase(), selectedFilters);
    },
    [setJobCategoryFilter]
  );
  useEffect(() => {
    // Dispatching action to load orientation requests when page renders for the first time
    dispatchFetchOrientations("unscheduled", []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId]);

  const resetRowSelection = () => {
    setSelectedKeys([]);
    setSelectedOrientations([]);
  };
  const handleFilter = (value: string | number) => {
    if (
      value === "Unscheduled" ||
      value === "Scheduled" ||
      value === "Completed" ||
      value === "All"
    )
      setFilter(value);
    resetRowSelection();
    dispatchFetchOrientations(
      value.toString().toLowerCase(),
      jobCategoryFilter
    );
  };
  let columnsData = useMemo(() => {
    if (filter === "All") {
      return [
        {
          title: t("common.name"),
          dataIndex: "employeeName",
          key: "employeeName",
          ellipsis: true,
          width: "20%",
          render: (
            _: any,
            {
              employeeName,
              requestedBy,
            }: { employeeName: string; requestedBy: number }
          ) => {
            return (
              <div
                className="link-text"
                onClick={() => navigate(`${ROUTE.clients}/${requestedBy}`)}
              >
                {employeeName}
              </div>
            );
          },
        },
        {
          title: (
            <div className="flex justify-between items-center">
              <span>{t("common.jobCategory")}</span>
              <Filter
                filterItems={jobCategoryFilterData}
                onFilter={handleJobCategoryFilter}
              />
            </div>
          ),
          dataIndex: "jobCategoryId",
          key: "jobCategoryId",
          ellipsis: true,
          width: "20%",
          render: (jobCategoryId: string) => {
            return getJobCategoryName(jobsCategoryFromState, jobCategoryId);
          },
        },
        {
          title: t("orientations.requestedDate"),
          dataIndex: "requestedDates",
          key: "requestedDates",
          width: "20%",
          render: (
            _: any,
            { requestedDates }: { requestedDates: string[] }
          ) => {
            requestedDates = (requestedDates || []).map(
              (date) => date.split("T")[0]
            );
            return <span>{requestedDates.join()}</span>;
          },
        },
        {
          title: t("orientations.scheduledDate"),
          dataIndex: "approvedDate",
          key: "approvedDate",
          width: "20%",

          ellipsis: true,
          render: (
            _: any,
            {
              approvedDate,
            }: {
              approvedDate: string;
            }
          ) => {
            return approvedDate
              ? getDateTimeFromUTCDateTime(approvedDate, timeZoneId)
              : "";
          },
        },
        {
          title: t("orientations.suggestedDate"),
          dataIndex: "suggestedDate",
          key: "suggestedDate",
          width: "20%",
          ellipsis: true,
          render: (
            _: any,
            {
              suggestedDates,
            }: {
              requestedDates: string[];
              suggestedDates: string[];
              requestedBy: number;
              employeeName: string;
              id: number;
            }
          ) => {
            suggestedDates = (suggestedDates || []).map((date) =>
              getDateTimeFromUTCDateTime(date, timeZoneId)
            );
            return (
              <div className="flex w-full ">
                {!isEmpty(suggestedDates) && (
                  <span className="mr-2">{suggestedDates.join(", ")}</span>
                )}
              </div>
            );
          },
        },
      ];
    } else if (filter === "Scheduled") {
      return [
        {
          title: t("common.name"),
          dataIndex: "employeeName",
          key: "employeeName",
          ellipsis: true,
          width: "33%",
          render: (
            _: any,
            {
              employeeName,
              requestedBy,
            }: { employeeName: string; requestedBy: number }
          ) => {
            return (
              <div
                className="link-text"
                onClick={() => navigate(`${ROUTE.clients}/${requestedBy}`)}
              >
                {employeeName}
              </div>
            );
          },
        },
        {
          title: (
            <div className="flex justify-between items-center">
              <span>{t("common.jobCategory")}</span>
              <Filter
                filterItems={jobCategoryFilterData}
                onFilter={handleJobCategoryFilter}
              />
            </div>
          ),
          dataIndex: "jobCategoryId",
          key: "jobCategoryId",
          width: "33%",
          ellipsis: true,
          render: (jobCategoryId: string) => {
            return getJobCategoryName(jobsCategoryFromState, jobCategoryId);
          },
        },
        {
          title: t("orientations.scheduledDate"),
          dataIndex: "approvedDate",
          key: "approvedDate",
          width: "33%",
          ellipsis: true,
          render: (
            _: any,
            {
              approvedDate,
            }: {
              approvedDate: string;
            }
          ) => {
            return approvedDate
              ? getDateTimeFromUTCDateTime(approvedDate, timeZoneId)
              : "";
          },
        },
      ];
    } else if (filter === "Unscheduled") {
      return [
        {
          title: t("common.name"),
          dataIndex: "employeeName",
          key: "employeeName",
          ellipsis: true,
          width: "25%",
          render: (
            _: any,
            {
              employeeName,
              requestedBy,
            }: { employeeName: string; requestedBy: number }
          ) => {
            return (
              <div
                className="link-text"
                onClick={() => navigate(`${ROUTE.clients}/${requestedBy}`)}
              >
                {employeeName}
              </div>
            );
          },
        },
        {
          title: (
            <div className="flex justify-between items-center">
              <span>{t("common.jobCategory")}</span>
              <Filter
                filterItems={jobCategoryFilterData}
                onFilter={handleJobCategoryFilter}
              />
            </div>
          ),
          dataIndex: "jobCategoryId",
          key: "jobCategoryId",
          width: "25%",
          ellipsis: true,
          render: (jobCategoryId: string) => {
            return getJobCategoryName(jobsCategoryFromState, jobCategoryId);
          },
        },
        {
          title: t("orientations.requestedDate"),
          dataIndex: "requestedDates",
          key: "requestedDates",
          width: "25%",
          render: (
            _: any,
            { requestedDates }: { requestedDates: string[] }
          ) => {
            requestedDates = (requestedDates || []).map(
              (date) => date.split("T")[0]
            );
            return <span>{requestedDates.join()}</span>;
          },
        },
        {
          title: t("orientations.suggestedDate"),
          dataIndex: "suggestedDates",
          key: "suggestedDates",
          width: "25%",
          ellipsis: true,
          render: (
            _: any,
            {
              suggestedDates,
            }: {
              suggestedDates: string[];
            }
          ) => {
            suggestedDates = (suggestedDates || []).map((date) =>
              getDateFromUtcDate(date, timeZoneId)
            );
            return (
              <div className="flex w-full ">
                {!isEmpty(suggestedDates) && (
                  <span className="mr-2">{suggestedDates.join(", ")}</span>
                )}
              </div>
            );
          },
        },
      ];
    } else if (filter === "Completed") {
      return [
        {
          title: t("common.name"),
          dataIndex: "employeeName",
          key: "employeeName",
          ellipsis: true,
          width: "20%",
          render: (
            _: any,
            {
              employeeName,
              requestedBy,
            }: { employeeName: string; requestedBy: number }
          ) => {
            return (
              <div
                className="link-text"
                onClick={() => navigate(`${ROUTE.clients}/${requestedBy}`)}
              >
                {employeeName}
              </div>
            );
          },
        },
        {
          title: (
            <div className="flex justify-between items-center">
              <span>{t("common.jobCategory")}</span>
              <Filter
                filterItems={jobCategoryFilterData}
                onFilter={handleJobCategoryFilter}
              />
            </div>
          ),
          dataIndex: "jobCategoryId",
          key: "jobCategoryId",
          ellipsis: true,
          width: "20%",
          render: (jobCategoryId: string) => {
            return getJobCategoryName(jobsCategoryFromState, jobCategoryId);
          },
        },
        {
          title: t("orientations.requestedDate"),
          dataIndex: "requestedDates",
          key: "requestedDates",
          width: "20%",
          render: (
            _: any,
            { requestedDates }: { requestedDates: string[] }
          ) => {
            requestedDates = (requestedDates || []).map(
              (date) => date.split("T")[0]
            );
            return <span>{requestedDates.join()}</span>;
          },
        },
        {
          title: t("orientations.scheduledDate"),
          dataIndex: "approvedDate",
          key: "approvedDate",
          width: "20%",

          ellipsis: true,
          render: (
            _: any,
            {
              approvedDate,
            }: {
              approvedDate: string;
            }
          ) => {
            return approvedDate
              ? getDateTimeFromUTCDateTime(approvedDate, timeZoneId)
              : "";
          },
        },
        {
          title: t("orientations.suggestedDate"),
          dataIndex: "suggestedDate",
          key: "suggestedDate",
          width: "20%",
          ellipsis: true,
          render: (
            _: any,
            {
              suggestedDates,
            }: {
              requestedDates: string[];
              suggestedDates: string[];
              requestedBy: number;
              employeeName: string;
              id: number;
            }
          ) => {
            suggestedDates = (suggestedDates || []).map((date) =>
              getDateTimeFromUTCDateTime(date, timeZoneId)
            );
            return (
              <div className="flex w-full ">
                {!isEmpty(suggestedDates) && (
                  <span className="mr-2">{suggestedDates.join(", ")}</span>
                )}
              </div>
            );
          },
        },
      ];
    }
  }, [
    filter,
    t,
    jobCategoryFilterData,
    handleJobCategoryFilter,
    navigate,
    jobsCategoryFromState,
    timeZoneId,
  ]);
  const rowSelection = {
    selectedRowKeys: selectedKeys,
    getCheckboxProps: (record: OrientationRequestTypes) => ({
      disabled: record.orientationStatusId === 2 ? true : false,
    }),
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: OrientationRequestTypes[]
    ) => {
      setSelectedKeys(selectedRowKeys);
      setSelectedOrientations(selectedRows);
    },
  };
  const handleApprove = (shiftId: string) => {
    if (approvalState) {
      const payload: ApproveDatePayload = {
        id: approvalState.id.toString(),
        shiftId,
        approvedDate: approvalState.date,
        shiftEndDateTime: "",
        shiftStartDateTime: "",
      };
      onApprove(payload);
    }
    setShowConfirmation(false);
    setApprovalState(undefined);
    resetRowSelection();
  };
  const handleSuggestClick = () => {
    const { id, employeeName, hourlyRate } = selectedOrientations[0];
    const isHourlyRateNotPresent = Number(hourlyRate?.replace("$", "")) <= 0;
    onSuggestClick(
      id,
      employeeName,
      Number(hourlyRate?.replace("$", "")),
      isHourlyRateNotPresent
    );
  };
  const handleApproveClick = () => {
    const { requestedDates, id, hourlyRate } = selectedOrientations[0];
    setHourlyRateNotPresent(Number(hourlyRate?.replace("$", "")) <= 0);
    setApprovalState({ id, date: requestedDates[0] });
    setShowConfirmation(true);
  };
  const disableSuggestButton = useMemo(() => {
    return (
      filter === "Scheduled" ||
      selectedOrientations.length >= 2 ||
      !selectedOrientations.length ||
      selectedOrientations[0].orientationStatusId === 2
    );
  }, [selectedOrientations, filter]);
  const disableApproveButton = useMemo(() => {
    const approvedEntrySelected = selectedOrientations.some(
      (item) => !isEmpty(item.approvedDate)
    );
    return (
      filter === "Scheduled" ||
      selectedOrientations.length >= 2 ||
      !selectedKeys.length ||
      approvedEntrySelected
    );
  }, [selectedKeys.length, selectedOrientations, filter]);
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div className="flex justify-between items-center">
        <SectionName />
        <div className="flex items-center">
          <ActionButtons
            isLoading={isLoading}
            onSuggest={handleSuggestClick}
            onApprove={handleApproveClick}
            disableApprove={disableApproveButton}
            disableSuggest={disableSuggestButton}
          />
          <Segmented
            options={[
              t("orientations.unscheduled") || "Unscheduled",
              t("orientations.scheduled") || "Scheduled",
              t("orientations.completed") || "Completed",
              t("common.all") || "All",
            ]}
            value={filter}
            onChange={handleFilter}
          />
        </div>
      </div>
      <div className="h-full overflow-auto">
        <Table
          columns={columnsData}
          dataSource={filteredTableData}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          pagination={false}
          sticky
          rowClassName="orientation-table-row"
          scroll={{ x: "auto", y: "auto" }}
          size="small"
          tableLayout="fixed"
        />
      </div>

      {showConfirmation && (
        <ApproveOrientation
          show={showConfirmation}
          hourlyRateNotPresent={hourlyRateNotPresent}
          onHide={() => {
            setShowConfirmation(false);
            setApprovalState(undefined);
          }}
          onApprove={handleApprove}
        />
      )}
    </Space>
  );
};

export default OrientationTable;
