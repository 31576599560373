export const JOB_STATUSES = {
  completed: "completed",
  scheduled: "scheduled",
  open: "open",
  progress: "progress",
  approved: "approved",
};

export const ROLES = {
  consultant: "Consultant",
  facilityAdmin: "Facility Admin",
  staffAdmin: "Staff Admin",
  client: "Client",
};

export const ORIENTATION_STATUSES = {
  requested: "requested",
  approved: "approved",
  suggested: "suggested",
  completed: "completed",
};

export const PAYMENT_STATUSES = {
  paid: "paid",
  due: "due",
};

export const JOB_STATUS_IDS = {
  completed: 4,
  approved: 5,
};

export const NOTIFICATION_STATUSES = {
  received: "Received",
  read: "Read",
};
