import { useState } from "react";
import InputField from "../../common/InputField";
import ConfirmationModal from "../../common/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateEmailThunk } from "../../../redux/thunks/clients";

type EmailFormProps = {
    email: string;
    showForm: boolean;
    hideForm: () => void;
};

const EmailForm = (props: EmailFormProps) => {
    const { showForm, hideForm, email } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const clientProfile = useAppSelector((state) => state.clientsReducer.clientProfile);

    const [formState, setFormState] = useState<any>({ email: email });
    const [errors, setErrors] = useState<any>({ email: "" });

    const handleChange = (id: string, value: any) => {
        setFormState({ ...formState, [id]: value });
        if (errors[id]) {
            setErrors({ ...errors, [id]: "" });
        }
    };

    const handleValidation = () => {
        let valid = true;

        const errorsCopy: any = { ...errors };
        if (!formState?.email.trim()) {
            valid = false;
            errorsCopy.email = t("errors.emailRequired") || "";
        } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
            valid = false;
            errorsCopy.email = t("errors.invalidEmail") || "";
        }
        setErrors(errorsCopy);
        if (valid) {
            handleSave();
        }
    };

    const handleSave = () => {
        const data = {
            email: formState?.email,
            userId: clientProfile?.id,
        };

        dispatch(updateEmailThunk({ payload: data, callback: handleClose }));
    };

    const handleClose = () => {
        setFormState({ email: "" });
        setErrors({ email: "" });
        hideForm();
    };

    return (
        <ConfirmationModal
            open={showForm}
            title={t("clientProfile.updateEmail") || ""}
            handleYes={handleValidation}
            handleNo={hideForm}
            primaryButtonText={t("common.save") || ""}
            secondaryButtonText={t("common.close") || ""}
            text={
                <div className="pt-4">
                    <InputField
                        id="email"
                        type="text"
                        label={t("clientProfile.email") || ""}
                        value={formState?.email}
                        onChange={handleChange}
                        message={errors.email}
                        status={errors.email ? "error" : ""}
                    />
                </div>
            }
        />
    );
};

export default EmailForm;
