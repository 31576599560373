import { Button } from "antd";
import Icon from "../common/Icon";
import { t } from "i18next";

type Props = {
    showInvoiceDateTimePicker: (invoiceType: string) => void;
}

function ScheduleButtons(props: Props) {
    return (
        <div className="flex action-buttons mr-4">
            <Button
                icon={<Icon name="group" fontSize={16} className="mr-1" />}
                type="primary"
                id="generate-client-invoice-btn"
                className="mx-3 flex justify-center items-center"
                size="small"
                onClick={() => props.showInvoiceDateTimePicker("client")}
            >
                {t("invoiceSchedule.clientInvoice")}
            </Button>

            <Button
                id="generate-facility-invoice-btn"
                icon={<Icon name="business" fontSize={16} className="mr-1" />}
                type="default"
                className="flex justify-center items-center secondary-btn"
                size="small"
                onClick={() => props.showInvoiceDateTimePicker("facility")}
            >
                {t("invoiceSchedule.facilityInvoice")}
            </Button>
        </div>
    );
}

export default ScheduleButtons;