import {
  ContactTypes,
  DropDownOptionTypes,
  PostJobErrorsTypes,
  PostJobTypes,
} from "../../../models/types";
import Datepicker from "../../common/Datepicker";
import Dropdown from "../../common/Dropdown";
import InputField from "../../common/InputField";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DATE_FORMAT_PRIMARY } from "../../../constants/defaults";
import AutoCompleteInput from "../../common/AutoCompleteInput";
import { useAppSelector } from "../../../redux/hooks";
import { useMemo } from "react";
import UnreadDot from "../../layout/notifications/UnreadDot";
import { JOB_STATUSES } from "../../../constants/statuses";

type PostJobTypesS = {
  data: PostJobTypes;
  errors: PostJobErrorsTypes;
  handleChange: (id: string, value: string | number | null) => void;
  selectedContact: (contact: ContactTypes) => void;
  jobCategoryOptions: DropDownOptionTypes[];
  shiftOptions: DropDownOptionTypes[];
  editScreen?: boolean;
  hourlyRateNotPresent?: boolean;
};

const PostJob = (props: PostJobTypesS) => {
  const {
    data,
    errors,
    handleChange,
    selectedContact,
    jobCategoryOptions,
    shiftOptions,
    editScreen = false,
    hourlyRateNotPresent,
  } = props;
  const { t } = useTranslation();

  const currentFacilityContacts = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.contacts
  );

  const contactOptions = useMemo(() => {
    const options = (currentFacilityContacts || []).map((c) => {
      return { value: c.id.toString(), label: `${c.name}; Email: ${c.email}` };
    });
    return options;
  }, [currentFacilityContacts]);

  const handleContactInfo = (id: string, value: string | number | null) => {
    const selectedOption = currentFacilityContacts.filter(
      (c) => c.id.toString() === id
    )?.[0];
    if (selectedOption) {
      selectedContact(selectedOption);
    }
  };
  const isScheduledJob = useMemo(() => {
    return data.jobStatus === JOB_STATUSES.scheduled;
  }, [data.jobStatus]);
  return (
    <>
      <div className="overflow-auto">
        {hourlyRateNotPresent && (
          <div className="error-text mb-3 pl-2 flex items-start">
            <UnreadDot className="mt-1 mr-1" redDot />
            <span>
              Hourly rate is not set for this job category, you can add it from
              job category section.
            </span>
          </div>
        )}
        {!shiftOptions.length && (
          <div className="flex items-start error-text mb-3 pl-2">
            <UnreadDot className="mt-1 mr-1" redDot />
            <span>
              Shifts are not created for this facility, please create a shift
              first from settings section.
            </span>
          </div>
        )}
        <Dropdown
          id="jobCategoryId"
          value={data.jobCategoryId}
          options={jobCategoryOptions}
          onChange={handleChange}
          label={t("jobSchedule.jobCategory") || ""}
          required={true}
          disabled={isScheduledJob || editScreen}
          message={errors.jobCategoryId}
          status={errors.jobCategoryId ? "error" : ""}
          placeholder="Select a Job Category"
        />
        <Dropdown
          id="shiftId"
          value={data.shiftId}
          options={shiftOptions}
          onChange={handleChange}
          label={t("jobSchedule.shift") || ""}
          required={true}
          disabled={isScheduledJob || editScreen}
          message={errors.shiftId}
          status={errors.shiftId ? "error" : ""}
          placeholder="Select a Shift"
        />
        <div className="flex">
          <InputField
            type="number"
            id="positions"
            label={t("jobSchedule.numberOfPositions") || ""}
            required={true}
            value={data.positions}
            onChange={handleChange}
            controls={true}
            message={errors.positions}
            status={errors.positions ? "error" : ""}
            disabled={editScreen}
            className="w-full"
            minValue={1}
          />
        </div>
        <div className="flex">
          <Datepicker
            id="shiftDate"
            value={data.shiftDate ? dayjs(data.shiftDate) : undefined}
            onChange={handleChange}
            label={t("common.date") || ""}
            required={true}
            disabled={isScheduledJob || editScreen}
            message={errors.shiftDate}
            placeholder={DATE_FORMAT_PRIMARY}
            className="w-full"
            status={errors.shiftDate ? "error" : ""}
          />
        </div>
        <AutoCompleteInput
          id="contactName"
          label={t("jobList.contactName") || ""}
          value={data.contactName}
          onChange={handleChange}
          onSelect={handleContactInfo}
          options={contactOptions}
        />
        <InputField
          type="text"
          id="contactEmail"
          label={t("jobList.contactEmail") || ""}
          value={data.contactEmail}
          onChange={handleChange}
          message={errors.contactEmail}
          status={errors.contactEmail ? "error" : ""}
        />
        <InputField
          type="text"
          id="contactMobile"
          label={t("jobList.contactPhone") || ""}
          addonBefore="+1"
          value={data.contactMobile}
          onChange={handleChange}
          onBlur={() => {}}
          message={errors.contactMobile}
          status={errors.contactMobile ? "error" : ""}
        />
      </div>
    </>
  );
};

export default PostJob;
