import Avatar from "antd/es/avatar/avatar";
import React, { useMemo } from "react";
import { getProvinceName } from "../../helpers/helpers";
import { FacilitiesTypes } from "../../models/types";
import Icon from "../common/Icon";
import { useAppSelector } from "../../redux/hooks";
import { useTranslation } from "react-i18next";
import { Card } from "antd";
import MapContainer from "./MapContainer";
import { isEmpty } from "lodash";
import FacilityStatusToggleButton from "./FacilityToggleButton";
import FacilityProgress from "../facilities/FacilityProgress";
import useUserRole from "../hooks/useUserRole";

type FacilityDetailsPropsType = {
  facilityDetails: FacilitiesTypes;
  onEdit: () => void;
};

const FacilityDetails = (props: FacilityDetailsPropsType) => {
  const { facilityDetails, onEdit } = props;
  const { isConsultant } = useUserRole();
  const { t } = useTranslation();
  const provinceData = useAppSelector((state) => state.appReducer.provinces);
  const {
    title,
    logo,
    streetNumber,
    streetName,
    city,
    postalCode,
    provinceId,
    contacts,
  } = facilityDetails;
  const provinceName = useMemo(
    () => getProvinceName(provinceData, provinceId),
    [provinceData, provinceId]
  );
  if (isEmpty(facilityDetails)) {
    return null;
  }

  return (
    <>
      <Card
        className="flex flex-col shadow-md"
        title={
          <div className="flex justify-between items-center">
            <span className="text-lg mx-auto flex items-center">{title} {isConsultant && <FacilityProgress></FacilityProgress>}</span>
            <span className="p-1 flex justify-center items-center">
              {isConsultant && <FacilityStatusToggleButton facilityId={facilityDetails.id} />}
            </span>
            <span className="bg-[#1677ff] text-white p-1 flex justify-center items-center rounded-[50%] hover:bg-[#1890ff]">
              <Icon role="button" name="edit" fontSize={18} onClick={onEdit} />
            </span>
          </div>
        }
      >
        <div className="flex justify-center my-4">
          <Avatar
            shape="square"
            className="flex items-center justify-center bg-gray-200"
            src={logo}
            size={120}
            icon={<Icon name="business" fontSize={70} />}
          />
        </div>
        <div className="flex justify-between mt-4 text-[#5F6368]">
          <div>
            <div className="flex items-center px-3">
              <Icon
                name="location_on"
                className="mr-2 blue-text"
                fontSize={20}
              />
              <span>{`${streetNumber}, ${streetName}, ${city}, ${provinceName}, ${postalCode}`}</span>
            </div>
            <div className="px-3">
              {contacts?.map((item, index) => (
                <div
                  className="border my-5 relative rounded-md p-3"
                  key={index}
                >
                  <span className="blue-text absolute -top-[11px] left-4 px-1 bg-white">
                    {item.isPrimary
                      ? t("facilities.primary")
                      : t("facilities.secondary")}
                  </span>
                  <div className="text-[18px] relative w-fit pb-3 my-2 ">
                    {item.name}
                    <div className="text-xs w-full">{item.designation}</div>
                  </div>
                  {item.officePhone && item.officePhone.length > 3 && (
                    <div className="flex items-center my-2 text-sm">
                      <Icon
                        name="call"
                        className="mr-2 blue-text"
                        fontSize={16}
                      />
                      <span>
                        {item.officePhone}
                        <span className="ml-2 text-xs">
                          Ext: {item.extension}
                        </span>
                      </span>
                    </div>
                  )}
                  {item.mobile && item.mobile.length > 3 && (
                    <div className="flex items-center my-2 text-sm">
                      <Icon
                        name="smartphone"
                        className="mr-2 blue-text"
                        fontSize={16}
                      />
                      <span> {item.mobile}</span>
                    </div>
                  )}
                  {item.email && (
                    <div className="flex items-center my-2 text-sm">
                      <Icon
                        name="mail"
                        className="mr-2 blue-text"
                        fontSize={16}
                      />
                      <a
                        className="blue-text hover:underline"
                        href={`mailto:${item.email}`}
                      >
                        {item.email}
                      </a>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div
            className="mr-4 mb-4 border border-white"
            style={{ width: "450px", height: "250px" }}
          >
            <MapContainer />
          </div>
        </div>
      </Card>
    </>
  );
};

export default FacilityDetails;
