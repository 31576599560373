import { InputNumber, Input } from "antd";

type InputFieldTypes = {
  type: "text" | "number" | "area" | "email" | "password";
  required?: boolean;
  label?: string;
  value: number | string | undefined;
  size?: "large" | "small";
  onChange: (id: string, value: string | number | null) => void;
  onBlur?: (id: string, value: string | number | null) => void;
  disabled?: boolean;
  controls?: boolean;
  id: string;
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
  status?: "error" | "warning" | "";
  message?: string;
  className?: string;
  placeholder?: string;
  prefix?: React.ReactNode | string;
  minValue?: number;
  name?: string;
};

const InputField = (props: InputFieldTypes) => {
  const {
    id,
    type,
    required = false,
    label,
    value,
    size,
    onChange,
    onBlur,
    disabled = false,
    addonBefore,
    addonAfter,
    status,
    message,
    controls = false,
    className,
    placeholder,
    prefix,
    minValue,
    name,
  } = props;
  const { TextArea } = Input;

  if (type === "number") {
    return (
      <div className={`flex flex-col mx-2 h[80px] ${className || ""}`}>
        <label className="mb-1 text-xs text-[#686565]">
          {label} {required && <span className="text-[#ff4d4f]">*</span>}
        </label>
        <InputNumber
          type="number"
          id={id}
          controls={controls}
          value={value}
          onChange={(newValue) => onChange(id, newValue)}
          onBlur={(event) => onBlur && onBlur(id, event?.target.value)}
          disabled={disabled}
          status={status}
          size={size}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          placeholder={placeholder}
          prefix={prefix}
          min={minValue}
          name={name}
        />
        <span className="error-text">{message}</span>
      </div>
    );
  } else if (type === "area") {
    return (
      <div className="flex flex-col mx-2">
        <label className="mb-1 text-xs text-[#777676]">
          {label} {required && <span className="text-[#ff4d4f]">*</span>}
        </label>
        <TextArea
          id={id}
          value={value}
          onChange={(event) => onChange(id, event.target.value)}
          onBlur={(event) => onBlur && onBlur(id, event?.target.value)}
          disabled={disabled}
          status={status}
          autoSize={{ minRows: 4, maxRows: 15 }}
          allowClear
          placeholder={placeholder}
          name={name}
        />
        <span className="error-text">{message}</span>
      </div>
    );
  } else if (type === "password") {
    return (
      <div className={`flex flex-col mx-2 h[80px] ${className || ""}`}>
        <label className="mb-1 text-xs text-[#777676]">
          {label} {required && <span className="text-[#ff4d4f]">*</span>}
        </label>
        <Input.Password
          type={type}
          id={id}
          value={value}
          onChange={(event) => onChange(id, event?.target.value)}
          onBlur={(event) => onBlur && onBlur(id, event?.target.value)}
          disabled={disabled}
          status={status}
          size={size}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          placeholder={placeholder}
          prefix={prefix}
          name={name}
          />
        <span className="error-text">{message}</span>
      </div>
    );
  } else {
    return (
      <div className={`flex flex-col mx-2 h[80px] ${className || ""}`}>
        <label className="mb-1 text-xs text-[#777676]">
          {label} {required && <span className="text-[#ff4d4f]">*</span>}
        </label>
        <Input
          type={type}
          id={id}
          value={value}
          onChange={(event) => onChange(id, event?.target.value)}
          onBlur={(event) => onBlur && onBlur(id, event?.target.value)}
          disabled={disabled}
          status={status}
          size={size}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          placeholder={placeholder}
          prefix={prefix}
          name={name}
        />
        <span className="error-text">{message}</span>
      </div>
    );
  }
};

export default InputField;
