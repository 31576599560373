export const createOrientationFormValidationConfig = {
  facilityId: {
    required: "Please select a facility.",
  },
  orientationDate: {
    required: "Please select an orientation date.",
  },
  shiftId: {
    required: "Please select a shift.",
  },
};

export const createClientProfileValidationConfig = {
  firstName: {
    required: "Please enter first name.",
  },
  lastName: {
    required: "Please enter last name.",
  },
  phoneNumber: {
    required: "Please enter phone number.",
  },
  email: {
    required: "Please enter email.",
  },
  gender: {
    required: "Please select gender.",
  },
};
