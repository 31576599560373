import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import SideDrawer from "../common/SideDrawer";
import FacilitiesForm from "../facilities/FacilitiesForm";
import FacilityDetails from "./FacilityDetails";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../common/ConfirmationModal";
import { cloneDeep, isEqual } from "lodash";
import {
  CONTACT_DETAILS_TEMPLATE,
  FACILITIES_ERRORS_TEMPLATE,
  FACILITIES_TEMPLATE,
} from "../facilities/constants";
import {
  FacilitiesErrorsTypes,
  FacilitiesTypes,
  ShiftErrorTypes,
  ShiftTypes,
} from "../../models/types";
import { getFacilityStatusThunk, updateFacilityThunk } from "../../redux/thunks/facility";
import ShiftsTable from "./ShiftTable";
import { SHIFT_ERROR_TEMPLATE, SHIFT_TEMPLATE } from "./constant";
import ShiftForm from "./ShiftForm";
import {
  createShiftThunk,
  getShiftListByFacilityIdThunk,
  updateShiftThunk,
} from "../../redux/thunks/settings";
import { getTotalHours } from "../../helpers/helpers";
import UserManagementTable from "./UserManagementTable";
import useUserRole from "../hooks/useUserRole";
import SectionName from "../common/SectionName";
import { validateForm } from "../../helpers/validationHelpers";
import {
  contactFormValidationConfig,
  facilityFormValidationConfig,
  shiftFormValidationConfig,
} from "./validationConfig";
import { convertLocalTimeToUtcTime } from "../../helpers/dateTime.helper";
import useFacilityTimezone from "../hooks/useFacilityTimezone";
import { fetchServiceChargesByFacilityThunk } from "../../redux/thunks/serviceCharge";

const Settings = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showResetPassword } = useUserRole();
  const { getFacilityTimeZone } = useFacilityTimezone();
  const facilityDetails = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility
  );
  useEffect(() => {
    if (facilityDetails.id) {
      dispatch(getShiftListByFacilityIdThunk(Number(facilityDetails.id)));
      dispatch(fetchServiceChargesByFacilityThunk(Number(facilityDetails.id)));
      dispatch(getFacilityStatusThunk(facilityDetails.id));
      
    }
  }, [dispatch, facilityDetails.id]);

  const isLoading = useAppSelector((state) => state.settingsReducer.loading);
  const isFacilitiesLoading = useAppSelector(
    (state) => state.facilitiesReducer.loading
  );
  const shiftList = useAppSelector((state) => state.settingsReducer.shifts);
  const shiftIntervalData = useAppSelector(
    (state) => state.appReducer.shiftIntervalData
  );
  // Drawer state
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentFacility, setCurrentFacility] = useState<FacilitiesTypes>(
    cloneDeep(FACILITIES_TEMPLATE)
  );
  const [initialData, setInitialData] = useState({});
  // Confirmation modal state
  const [showConfirmation, setShowConfirmation] = useState({
    status: false,
    id: "",
  });
  // Right panel content, to distinguish between facility form and shift form
  const [rightPanelContent, setRightPanelContent] = useState<
    "facility-form" | "shift-form" | ""
  >("");

  // Shift form data state
  const [shiftData, setShiftData] = useState<ShiftTypes>(
    cloneDeep(SHIFT_TEMPLATE)
  );
  const [initialShiftData, setInitialShiftData] = useState<ShiftTypes>(
    cloneDeep(SHIFT_TEMPLATE)
  );

  const [shiftErrors, setShiftErrors] = useState<ShiftErrorTypes>(
    cloneDeep(SHIFT_ERROR_TEMPLATE)
  );
  // Form Error state
  const [errors, setErrors] = useState<FacilitiesErrorsTypes>(
    cloneDeep(FACILITIES_ERRORS_TEMPLATE)
  );
  const [logo, setLogo] = useState<any>("");

  // When Edit button is clicked
  const updateFacilities = useCallback(() => {
    const selectedFacility = cloneDeep(facilityDetails);
    selectedFacility.contacts = selectedFacility.contacts.map((item) => ({
      ...item,
      mobile: item.mobile.startsWith("+1 ")
        ? item.mobile.slice(3)
        : item.mobile,
      officePhone: item.officePhone.startsWith("+1 ")
        ? item.officePhone.slice(3)
        : item.officePhone,
    }));
    setOpenDrawer(true);
    setCurrentFacility(cloneDeep(selectedFacility));
    setInitialData(cloneDeep(selectedFacility));
    setRightPanelContent("facility-form");
    // If there are already multiple contacts, adding the error objects for the same
    if (selectedFacility.contacts.length > 1) {
      const errorsObj = cloneDeep(FACILITIES_ERRORS_TEMPLATE);
      selectedFacility.contacts.forEach((contact, index) => {
        if (!contact.isPrimary) {
          errorsObj.contact.push({ ...CONTACT_DETAILS_TEMPLATE });
        }
      });
      setErrors(errorsObj);
    }
  }, [facilityDetails]);

  // When a drawer is closed
  const handleClose = useCallback(() => {
    if (rightPanelContent === "facility-form") {
      setCurrentFacility(cloneDeep(FACILITIES_TEMPLATE));
      setErrors(cloneDeep(FACILITIES_ERRORS_TEMPLATE));
    } else {
      setShiftData(cloneDeep(SHIFT_TEMPLATE));
      setShiftErrors(cloneDeep(SHIFT_ERROR_TEMPLATE));
    }
    setOpenDrawer(false);
    setShowConfirmation({ status: false, id: "" });
  }, [rightPanelContent]);

  const handleSave = useCallback(() => {
    if (rightPanelContent === "facility-form") {
      currentFacility.contacts.forEach((user) => {
        user.mobile = user.mobile.startsWith("+1 ")
          ? user.mobile
          : "+1 " + user.mobile;
        user.officePhone = user.officePhone.startsWith("+1 ")
          ? user.officePhone
          : "+1 " + user.officePhone;
      });
      dispatch(
        updateFacilityThunk({
          formData: currentFacility,
          updateCurrent: true,
          callback: handleClose,
        })
      );
    } else {
      const shiftDataCopy = { ...shiftData };
      shiftDataCopy.facilityId = facilityDetails.id;
      shiftDataCopy.hours = getTotalHours(
        shiftIntervalData,
        shiftDataCopy.startTime,
        shiftDataCopy.endTime
      );
      const tz = getFacilityTimeZone();
      shiftDataCopy.startTime = convertLocalTimeToUtcTime(
        shiftDataCopy.startTime,
        tz
      );
      shiftDataCopy.endTime = convertLocalTimeToUtcTime(
        shiftDataCopy.endTime,
        tz
      );
      shiftDataCopy.id
        ? dispatch(updateShiftThunk(shiftDataCopy, handleClose))
        : dispatch(createShiftThunk(shiftDataCopy, handleClose));
    }
  }, [
    rightPanelContent,
    currentFacility,
    dispatch,
    handleClose,
    shiftData,
    facilityDetails.id,
    shiftIntervalData,
    getFacilityTimeZone,
  ]);

  const handleConfirmation = (
    id: "close" | "create-shift" | "facility" | string,
    resetClicked: boolean = false
  ) => {
    // Showing confirmation only when the user clicks a row other than the currently selected on
    const dataChanged =
      rightPanelContent === "facility-form"
        ? !isEqual(currentFacility, initialData)
        : !isEqual(shiftData, initialShiftData);
    if (openDrawer && !resetClicked && (dataChanged || logo)) {
      // If drawer is open, show the modal and save id of recently clicked row
      setShowConfirmation({
        status: true,
        id: id || "",
      });
    } else {
      // If drawer is not open, set the drawer. If Add button is clicked id would be "new"
      setErrors(cloneDeep(FACILITIES_ERRORS_TEMPLATE));
      if (id === "close") {
        handleClose();
      } else if (id === "facility") {
        updateFacilities();
      } else if (id === "create-shift") {
        setOpenDrawer(true);
        setRightPanelContent("shift-form");
        const shiftDataCopy = cloneDeep(SHIFT_TEMPLATE);
        setShiftData(shiftDataCopy);
        setInitialShiftData(shiftDataCopy);
        setShiftErrors(cloneDeep(SHIFT_ERROR_TEMPLATE));
      } else {
        const selectedShift = shiftList.filter((shift) => shift.id === id);
        const shiftDataCopy = cloneDeep(selectedShift[0]);
        setOpenDrawer(true);
        setRightPanelContent("shift-form");
        setShiftData(shiftDataCopy);
        setInitialShiftData(shiftDataCopy);
        setShiftErrors(cloneDeep(SHIFT_ERROR_TEMPLATE));
      }
      setShowConfirmation({ id: "", status: false });
    }
  };

  const validateFacilityForm = useCallback(() => {
    let validData = true;
    let errorObjCopy = cloneDeep(errors);
    const { valid, formData, errorObject } = validateForm(
      currentFacility,
      facilityFormValidationConfig,
      errors
    );
    // Primary and Secondary contact info validations
    currentFacility.contacts.forEach((contact, index) => {
      const {
        valid: isContactValid,
        formData: contactForm,
        errorObject: contactErrors,
      } = validateForm(
        contact,
        contactFormValidationConfig,
        errorObjCopy.contact[index]
      );
      formData.contacts[index] = contactForm;
      errorObject.contact[index] = contactErrors;
      validData = validData && valid && isContactValid;
    });
    setCurrentFacility(formData);
    if (validData) {
      handleSave();
    } else {
      setErrors(errorObject);
    }
  }, [errors, currentFacility, handleSave]);
  // Handle user input in the form
  const handleChange = useCallback(
    (id: string, value: string | number | null | boolean) => {
      setCurrentFacility({ ...currentFacility, [id]: value });
      setErrors({ ...errors, [id]: "" });
    },
    [currentFacility, errors]
  );

  const handleMultipleChange = useCallback(
    (changes: Partial<FacilitiesTypes>) => {
      setCurrentFacility((currentData) => ({
        ...currentData,
        ...changes,
      }));

      setErrors((currentData) => {
        const currentError = { ...currentData };
        Object.keys(changes).forEach((key) => {
          currentError[key as keyof FacilitiesErrorsTypes] = "";
        });
        return currentError;
      });
    },
    []
  );
  // Adding multiple contacts
  const addContacts = useCallback(() => {
    // To add secondary contacts in the form
    const dataCopy = cloneDeep(currentFacility);
    const errorsCopy = cloneDeep(errors);
    dataCopy.contacts.push({ ...CONTACT_DETAILS_TEMPLATE });
    errorsCopy.contact.push({ ...CONTACT_DETAILS_TEMPLATE });
    setCurrentFacility(dataCopy);
    setErrors(errorsCopy);
  }, [currentFacility, errors]);

  // Deleting secondary contact
  const deleteContact = useCallback(
    (index: number) => {
      const dataCopy = cloneDeep(currentFacility);
      const errorsCopy = cloneDeep(errors);
      dataCopy.contacts.splice(index, 1);
      errorsCopy.contact.splice(index, 1);
      setCurrentFacility(dataCopy);
      setErrors(errorsCopy);
    },
    [currentFacility, errors]
  );
  // Handle user input in the contact form
  const handleContactChange = useCallback(
    (index: number, id: string, value: string | number | null) => {
      if (
        id === "email" ||
        id === "name" ||
        id === "mobile" ||
        id === "officePhone" ||
        id === "extension" ||
        id === "designation"
      ) {
        const val = value?.toString();
        const facilityDataCopy = cloneDeep(currentFacility);
        facilityDataCopy.contacts[index][id] = val || "";
        const errorsCopy = cloneDeep(errors);
        errorsCopy.contact[index][id] = "";
        setCurrentFacility(facilityDataCopy);
        setErrors(errorsCopy);
      }
    },
    [currentFacility, errors]
  );
  const closeConfirmation = () => {
    setShowConfirmation({ id: "", status: false });
  };
  // Shift Form related methods
  const validateShiftForm = () => {
    let { valid, errorObject } = validateForm(
      shiftData,
      shiftFormValidationConfig,
      shiftErrors
    );
    if (
      shiftData.startTime &&
      shiftData.endTime &&
      shiftData.startTime === shiftData.endTime
    ) {
      errorObject.endTime = t("errors.startFinishTimeSame");
    }
    const validStartTime = ["00", "15", "30", "45"].includes(
      shiftData.startTime.split(":")[1]
    );
    if (!validStartTime) {
      errorObject.startTime = "Please select the time from dropdown";
      valid = false;
    }
    if (shiftErrors.title) {
      valid = false;
      errorObject.title = shiftErrors.title;
    }
    const validEndTime = ["00", "15", "30", "45"].includes(
      shiftData.endTime.split(":")[1]
    );
    if (!validEndTime) {
      errorObject.endTime = "Please select the time from dropdown";
      valid = false;
    }
    if (valid) {
      handleSave();
    } else {
      setShiftErrors(errorObject);
    }
  };
  const validateShiftName = () => {
    const errorObjCopy = cloneDeep(shiftErrors);
    const existingShiftNames = shiftList.map((shift) => shift.title);
    if (existingShiftNames.includes(shiftData.title.trim())) {
      errorObjCopy.title = t("errors.shiftNameExists");
      setShiftErrors(errorObjCopy);
    }
  };
  const handleShiftDataChange = useCallback(
    (id: string, value: string | number | null) => {
      setShiftData({ ...shiftData, [id]: value });
      setShiftErrors({ ...shiftErrors, [id]: "" });
    },
    [shiftData, shiftErrors]
  );
  return (
    <div>
      <SectionName className="mb-2" />
      <div className="h-full bg-white flex justify-between overflow-auto">
        <div className={`overflow-auto w-[65%] pr-5`}>
          <div className="mr-3 w-full">
            <FacilityDetails
              facilityDetails={facilityDetails}
              onEdit={() => handleConfirmation("facility")}
            />
            {facilityDetails.id && (
              <ShiftsTable
                onNameClick={(id: string) => handleConfirmation(id)}
                handleCreate={() => handleConfirmation("create-shift")}
              />
            )}
            {facilityDetails.id && showResetPassword && (
              <UserManagementTable facilityDetails={facilityDetails} />
            )}
          </div>
        </div>

        {openDrawer && (
          <SideDrawer
            open={openDrawer}
            onClose={() => handleConfirmation("close")}
            title={
              rightPanelContent === "shift-form"
                ? shiftData.id
                  ? "Edit Shift"
                  : "Create Shift"
                : ""
            }
            primaryButton={t("common.save") || ""}
            onSubmit={
              rightPanelContent === "facility-form"
                ? validateFacilityForm
                : validateShiftForm
            }
            disablePrimaryBtn={isLoading || isFacilitiesLoading}
          >
            {rightPanelContent === "facility-form" ? (
              <FacilitiesForm
                data={currentFacility}
                onChange={handleChange}
                onMultipleChange={handleMultipleChange}
                handleContactChange={handleContactChange}
                addContacts={addContacts}
                deleteContact={deleteContact}
                errors={errors}
                logo={facilityDetails.logo}
                setLogo={setLogo}
              />
            ) : (
              <ShiftForm
                shiftData={shiftData}
                errors={shiftErrors}
                onChange={handleShiftDataChange}
                validateShiftName={validateShiftName}
              />
            )}
          </SideDrawer>
        )}
        {showConfirmation && (
          <ConfirmationModal
            text={t("alertMessages.discardConfirmation")}
            open={showConfirmation.status}
            handleYes={() => handleConfirmation(showConfirmation.id, true)}
            handleNo={closeConfirmation}
          />
        )}
      </div>
    </div>
  );
};

export default Settings;
