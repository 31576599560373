import { t } from "i18next";
import { Table, Tag } from "antd";
import SectionName from "../common/SectionName";
import { JobTypes } from "../../models/job.model";
import useUserRole from "../hooks/useUserRole";
import useFacilityTimezone from "../hooks/useFacilityTimezone";
import { useAppSelector } from "../../redux/hooks";
import { get } from "lodash";
import { getDateTimeFromUTCDateTime } from "../../helpers/dateTime.helper";

type Props = {
    data: JobTypes[],
}


function UpcomingJobsTable(props: Props) {
    const { data } = props;
    const { isConsultant } = useUserRole();
    const { timeZoneId } = useFacilityTimezone();
    const facilityId = useAppSelector(
        (state) => state.facilitiesReducer.currentFacility.id
      );
      const facilityList = useAppSelector(
        (state) => state.facilitiesReducer.facilities
      );
    const facilityTimeZoneId = isConsultant
    ? get(
        facilityList.filter((f) => Number(f.id) === Number(facilityId)),
        ["0", "timeZoneId"],
        ""
      )
    : timeZoneId;

    const columns = [
        {
            title: t("upcomingJobs.title"),
            width: "25%",
            dataIndex: "title",
            key: "title",
            render: (text: any, record: any) => {
                return (
                    <div>
                        {record.title}
                    </div>
                );
            }
        },
        {
            title: t("upcomingJobs.shiftDate"),
            width: "25%",
            dataIndex: "shiftDate",
            key: "shiftDate",
            render: (text: any, record: any) => (
                <div>
                    {getDateTimeFromUTCDateTime(record.shiftDate, facilityTimeZoneId)}
                </div>
            )
        },
        {
            title: t("upcomingJobs.jobStatus"),
            dataIndex: "jobStatus",
            key: "jobStatus",
            width: "15%",
            render: (
              _: any,
              { jobStatus }: { jobStatus: string}
            ) => {
              return (
                <div className="flex w-full ">
                  <Tag
                    color={
                        jobStatus === "scheduled"
                        ? "green"
                        : jobStatus === "open"
                        ? "volcano"
                        : "default"
                    }
                    className="flex justify-between items-center px-4"
                  >
                    {jobStatus === "scheduled"
                      ? t("upcomingJobs.scheduled")
                      : t("upcomingJobs.unfilled")}
                  </Tag>
                </div>
              );
            },
          },
        {
            title: t("upcomingJobs.clientName"),
            width: "25%",
            dataIndex: "clientName",
            key: "clientName",
            render: (text: any, record: any) => (
                <div>
                    {record.clientName}
                </div>
            )
        },
        {
            title: t("upcomingJobs.clientPhoneNumber"),
            width: "25%",
            dataIndex: "clientPhoneNumber",
            key: "clientPhoneNumber",
            render: (text: any, record: any) => (
                <div>
                    {record.clientPhoneNumber}
                </div>
            )
        },
        {
            title: t("upcomingJobs.facilityName"),
            width: "25%",
            dataIndex: "facilityName",
            key: "facilityName",
            render: (text: any, record: any) => {
                return (
                    <div>
                        {record.facilityName}
                    </div>
                );
            }
        },
    ];



    return (
        <div>
            <SectionName className="mb-2" />
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                sticky
                rowClassName={() => 'editable-row'}
                size="small"
            />
        </div>
    )
}

export default UpcomingJobsTable;