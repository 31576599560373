export const SIDE_PANEL_NAMES = {
  calendar: "calendar",
  jobCategories: "jobCategories",
  orientations: "orientations",
  shiftApproval: "shiftApproval",
  clients: "clients",
  invoices: "invoices",
  clientInvoices: "clientInvoices",
  facilities: "facilities",
  serviceCharges: "serviceCharges",
  invoiceScheduler: "invoiceScheduler",
  settings: "settings",
};

export const SIDE_PANEL_TYPES = {
  createJobForClient: "CREATE_JOB_FOR_CLIENT",
  disableClient: "DISABLE_CLIENT",
};
