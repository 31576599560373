/* eslint-disable no-useless-escape */
import { useMemo, useState } from "react";
import ConfirmationModal from "../common/ConfirmationModal";
import InputField from "../common/InputField";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { verifyUsername } from "../../services/settings.services";
import Dropdown from "../common/Dropdown";
import { createFacilityUserThunk } from "../../redux/thunks/facility";

const VALID_PWD_LENGTH = Number(process.env.REACT_APP_VALID_PASSWORD_LENGTH);

type Props = {
  showForm: boolean;
  hideForm: () => void;
  facilityId: string;
  facilityName: string;
};

type FormState = {
  username: string;
  password: string;
  confirmPassword: string;
  role: string;
};
type ErrorState = {
  username: string;
  password: string;
  confirmPassword: string;
  role: string;
};
const AddUserForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector((state) => state.appReducer.roles);
  const { showForm, hideForm, facilityId, facilityName } = props;
  const { t } = useTranslation();
  const isLoading = useAppSelector(
    (state) => state.facilitiesReducer.updateInprogress
  );
  const [formState, setFormState] = useState<FormState>({
    username: "",
    password: "",
    confirmPassword: "",
    role: "",
  });
  const [errorState, setErrorState] = useState<ErrorState>({
    username: "",
    password: "",
    confirmPassword: "",
    role: "",
  });
  const roleOptions = useMemo(() => {
    return roles
      .filter((role) => Number(role.id) === 3 || Number(role.id) === 4)
      .map((role) => ({
        value: role.title,
        label: role.title,
      }));
  }, [roles]);
  const handleChange = (id: string, value: string | number | null) => {
    if (
      id === "username" ||
      id === "password" ||
      id === "confirmPassword" ||
      id === "role"
    ) {
      value = value?.toString() || "";
      setFormState({ ...formState, [id]: value });
      if (errorState[id]) {
        setErrorState({ ...errorState, [id]: "" });
      }
    }
  };
  const handleBlur = (id: string, value: string | number | null) => {
    if (id === "username" || id === "password" || id === "confirmPassword") {
      value = value?.toString().trim().toLowerCase() || "";
      setFormState({ ...formState, [id]: value });
      if (id === "username") {
        const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!emailPattern.test(formState.username)) {
          setErrorState({
            ...errorState,
            username: t("errors.invalidEmail"),
          });
        } else {
          validateUsername(value);
        }
      }
    }
  };
  const validateUsername = async (value: string) => {
    const usernameExists = await verifyUsername(value);
    if (usernameExists) {
      setErrorState({
        ...errorState,
        username: t("errors.usernameIsTaken"),
      });
    }
  };
  const handleValidation = () => {
    let valid = true;
    const errorsCopy = { ...errorState };
    const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (errorState.username) {
      valid = false;
    } else if (!formState.username) {
      valid = false;
      errorsCopy.username = t("errors.usernameRequired");
    } else if (!emailPattern.test(formState.username)) {
      valid = false;
      errorsCopy.username = t("errors.invalidEmail");
    }
    if (!formState.role) {
      valid = false;
      errorsCopy.role = t("errors.roleRequired");
    }
    if (!formState.password) {
      valid = false;
      errorsCopy.password = t("errors.passwordRequired");
    } else if (formState.password.length < VALID_PWD_LENGTH) {
      valid = false;
      errorsCopy.password = t("errors.invalidPasswordLength");
    } else if (!formState.confirmPassword) {
      valid = false;
      errorsCopy.confirmPassword = t("errors.passwordRequired");
    } else if (formState.password !== formState.confirmPassword) {
      valid = false;
      errorsCopy.confirmPassword = t("errors.passwordsDontMatch");
    }

    if (valid) {
      handleCreate();
    } else {
      setErrorState(errorsCopy);
    }
  };
  const handleCreate = () => {
    const { username, password, role } = formState;
    const payload = {
      username: username.toLowerCase(),
      password,
      facilityId,
      userType: role,
    };
    dispatch(createFacilityUserThunk({ payload, callback: handleClose }));
  };
  const handleClose = () => {
    setFormState({
      username: "",
      password: "",
      confirmPassword: "",
      role: "",
    });
    setErrorState({
      username: "",
      password: "",
      confirmPassword: "",
      role: "",
    });
    hideForm();
  };
  return (
    <ConfirmationModal
      open={showForm}
      title={`${t("facilities.createUser")} ${facilityName}`}
      handleYes={handleValidation}
      handleNo={handleClose}
      primaryButtonText={t("common.create") || ""}
      secondaryButtonText={t("common.close") || ""}
      isLoading={isLoading}
      text={
        <div className="pt-4">
          <InputField
            id="username"
            type="text"
            label={t("facilities.username") || ""}
            value={formState.username}
            placeholder={t("facilities.usernamePlaceholder") || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            message={errorState.username}
            status={errorState.username ? "error" : ""}
          />
          <Dropdown
            id="role"
            options={roleOptions}
            value={formState.role}
            onChange={handleChange}
            label={t("facilities.role") || ""}
            placeholder={t("facilities.rolePlaceholder") || ""}
            message={errorState.role}
            status={errorState.role ? "error" : ""}
          />
          <InputField
            id="password"
            type="password"
            label={t("facilities.password") || ""}
            value={formState.password}
            placeholder={t("facilities.password") || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            message={errorState.password}
            status={errorState.password ? "error" : ""}
          />
          <InputField
            id="confirmPassword"
            type="password"
            className="mt-1"
            label={t("facilities.confirmPassword") || ""}
            value={formState.confirmPassword}
            placeholder={t("facilities.confirmPassword") || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            message={errorState.confirmPassword}
            status={errorState.confirmPassword ? "error" : ""}
          />
        </div>
      }
    />
  );
};

export default AddUserForm;
