import {
  CurrentUserTypes,
  JobStatusTypes,
  JobTemplateTypes,
  orientationStatusTypes,
  paymentStatusTypes,
} from "./../../models/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  FacilityTypeTypes,
  ProvinceTypes,
  ShiftIntervalTimeTypes,
  Facility,
  UserRolesTypes,
} from "../../models/types";

export interface AppReducerState {
  roles: UserRolesTypes[];
  facilityTypes: FacilityTypeTypes[];
  jobStatuses: JobStatusTypes[];
  orientationStatuses: orientationStatusTypes[];
  paymentStatuses: paymentStatusTypes[];
  provinces: ProvinceTypes[];
  loading: boolean;
  facilityList: Facility[];
  currentUser: CurrentUserTypes;
  shiftIntervalData: ShiftIntervalTimeTypes[];
  jobTemplates: JobTemplateTypes[];
  announcement: string;
  sidePanel: {
    status: boolean;
    name: string;
    type: string;
  };
  documentDownloading: {
    status: boolean;
    fileId: number | null;
    fileName: string;
  };
}

const initialState: AppReducerState = {
  roles: [],
  facilityTypes: [],
  jobStatuses: [],
  jobTemplates: [],
  orientationStatuses: [],
  paymentStatuses: [],
  provinces: [],
  loading: false,
  facilityList: [],
  currentUser: {
    id: 0,
    roleId: null,
    facilityId: null,
    username: "",
  },
  shiftIntervalData: [],
  announcement: "",
  sidePanel: {
    name: "",
    type: "",
    status: false,
  },
  documentDownloading: {
    status: false,
    fileId: null,
    fileName: "",
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<CurrentUserTypes>) => {
      state.currentUser = action.payload || {
        roleId: null,
        facilityId: null,
        username: "",
      };
    },
    setMasterData: (
      state,
      action: PayloadAction<{
        roles: UserRolesTypes[];
        facilityTypes: FacilityTypeTypes[];
        jobStatuses: JobStatusTypes[];
        orientationStatuses: orientationStatusTypes[];
        paymentStatuses: paymentStatusTypes[];
        provinces: ProvinceTypes[];
        jobTemplates: JobTemplateTypes[];
      }>
    ) => {
      state.roles = action.payload?.roles || [];
      state.facilityTypes = action.payload?.facilityTypes || [];
      state.jobStatuses = action.payload?.jobStatuses || [];
      state.orientationStatuses = action.payload?.orientationStatuses || [];
      state.paymentStatuses = action.payload?.paymentStatuses || [];
      state.provinces = action.payload?.provinces || [];
      state.jobTemplates = action.payload?.jobTemplates || [];
    },

    setFacilityTypes: (state, action: PayloadAction<FacilityTypeTypes[]>) => {
      state.facilityTypes = action.payload;
    },
    setProvinces: (state, action: PayloadAction<ProvinceTypes[]>) => {
      state.provinces = action.payload || [];
    },
    setFacilityList: (state, action: PayloadAction<Facility[]>) => {
      state.facilityList = action.payload || [];
    },
    setShiftIntervalData: (
      state,
      action: PayloadAction<ShiftIntervalTimeTypes[]>
    ) => {
      state.shiftIntervalData = action.payload;
    },
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetAppState: (state) => {
      state.currentUser = {
        id: 0,
        roleId: null,
        facilityId: null,
        username: "",
      };
      state.facilityList = [];
      state.sidePanel = { name: "", status: false, type: "" };
    },
    setSidePanel: (
      state,
      action: PayloadAction<{ name: string; status: boolean; type: string }>
    ) => {
      const { name, status, type } = action.payload;
      state.sidePanel = { name, status, type };
    },
    closeSidePanel: (state) => {
      state.sidePanel = { name: "", status: false, type: "" };
    },
    setAnnouncement: (state, action: PayloadAction<string>) => {
      state.announcement = action.payload;
    },
    setDocumentLoading: (
      state,
      action: PayloadAction<{
        status: boolean;
        fileId: number | null;
        fileName: string;
      }>
    ) => {
      state.documentDownloading = action.payload;
    },
  },
});

export const {
  setCurrentUser,
  setFacilityTypes,
  setMasterData,
  setProvinces,
  setFacilityList,
  setShiftIntervalData,
  resetAppState,
  setAppLoading,
  setAnnouncement,
  setSidePanel,
  closeSidePanel,
  setDocumentLoading,
} = appSlice.actions;

export default appSlice.reducer;
