import { isValidEmail, isValidPhone } from "../../helpers/validationHelpers";

export const shiftFormValidationConfig = {
  title: {
    required: "Shift title is required.",
  },
  startTime: {
    required: "Start time is required.",
  },
  endTime: {
    required: "End time is required.",
  },
};

export const facilityFormValidationConfig = {
  title: {
    required: "Facility name is required.",
  },
  facilityTypeId: {
    required: "Facility type is required.",
  },
  streetNumber: {
    required: "Street number is required.",
  },
  streetName: {
    required: "Street name is required.",
  },
  city: {
    required: "City is required.",
  },
  provinceId: {
    required: "Province is required.",
  },
  postalCode: {
    required: "Postal code is required.",
  },
};

export const contactFormValidationConfig = {
  name: {
    required: "Name is required.",
  },
  designation: {
    required: "Designation is required.",
  },
  email: {
    required: "Email is required.",
    regex: {
      callback: isValidEmail,
      message: "Please provide a valid email.",
    },
  },
  mobile: {
    required: "Mobile is required.",
    regex: {
      callback: isValidPhone,
      message: "Please provide a valid phone number.",
    },
  },
  officePhone: {
    required: null,
    regex: {
      callback: isValidPhone,
      message: "Please provide a valid phone number.",
    },
  },
};
