import { FacilitiesTypes, FacilityUserPayloadTypes } from "../models/types";
import { baseUrl, getInstance } from ".";
import { cloneDeep } from "lodash";
import { SORT } from "../constants/pagination";

const instance = getInstance(baseUrl);

export type UpdateUserPayload = {
  userId: string;
  username: string;
  userType: string;
  requestedBy: number;
};
export type DeleteUserPayload = {
  userId: string;
  requestedBy: number;
};
export type ResetUserPasswordPayload = {
  userId: string;
  password: string;
};

export const fetchFacilities = async (
  pageSize?: number,
  pageNumber?: number,
  orderBy?: string,
  orderDirection?: string,
  facilityTypeFilter?: string[],
  provinceFilter?: string[],
  search?: string
) => {
  return instance.get(
    `/facility?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy && orderBy !== "" ? orderBy : SORT.title
    }&orderDirection=${orderDirection && orderDirection !== ""
      ? orderDirection
      : SORT.defaultSortDirection
    }&facilityTypeFilter=${facilityTypeFilter && facilityTypeFilter?.length > 0
      ? facilityTypeFilter
      : ""
    }&provinceFilter=${provinceFilter && provinceFilter?.length > 0 ? provinceFilter : ""
    }&search=${search ?? ""}`
  );
};
export const fetchFacilityById = async (
  facilityId: number,
  includeJc: boolean,
  includeShifts: boolean
) => {
  return instance.get(
    `/facility/${facilityId}?job-categories=${includeJc}&shifts=${includeShifts}`
  );
};

export const fetchFacilityStatus = async (
  facilityId: string
) => {
  return instance.get(
    `/facility/${facilityId}/status`
  );
};

export const enableFacility = async (
  facilityId: string
) => {
  return instance.post(
    `/facility/${facilityId}/enable`
  );
};

export const disableFacility = async (
  facilityId: string
) => {
  return instance.post(
    `/facility/${facilityId}/disable`
  );
};

export const createFacility = async (data: FacilitiesTypes) => {
  const updatedData: any = cloneDeep(data);
  updatedData.facilityTypeId = updatedData.facilityTypeId.toString();
  updatedData.provinceId = updatedData.provinceId.toString();
  updatedData.country = "";
  delete updatedData.id;
  delete updatedData.logoUrl;
  delete updatedData.users;
  return instance.post("/facility", updatedData);
};

export const updateFacility = async (data: FacilitiesTypes) => {
  return instance.put("/facility", data);
};

export const createFacilityUser = async (payload: FacilityUserPayloadTypes) => {
  return instance.post("/account/web/create", payload);
};

export const resetUserPassword = async (payload: ResetUserPasswordPayload) => {
  return instance.post("/account/reset-password", payload);
};
export const updateUser = async (payload: UpdateUserPayload) => {
  return instance.patch("/account/web/update-user", payload);
};
export const deleteUser = async (payload: DeleteUserPayload) => {
  return instance.patch("/account/web/delete-user", payload);
};
