import { AutoComplete } from "antd";
import { filter, get } from "lodash";
import { useMemo } from "react";
import { DownOutlined } from "@ant-design/icons";
type InputFieldTypes = {
  required?: boolean;
  label?: string;
  value: number | string | undefined;
  size?: "large" | "small";
  onChange: (id: string, value: string | number | null) => void;
  onBlur?: (id: string, value: string | number | null) => void;
  onSelect?: (value: any, option: any) => void;
  disabled?: boolean;
  displayLabel?: boolean;
  id: string;
  status?: "error" | "warning" | "";
  message?: string;
  className?: string;
  placeholder?: string;
  options: { label: string; value: string, labelStr?: string; disabled?: boolean }[];
};

const AutoCompleteInput = (props: InputFieldTypes) => {
  const {
    id,
    required = false,
    label,
    value,
    size,
    onChange,
    onSelect,
    onBlur,
    disabled = false,
    status,
    message,
    className,
    placeholder,
    options,
    displayLabel,
  } = props;

  const handleBlur = (e: any) => {
    const { id, value } = e.target;
    onBlur && onBlur(id, value);
  };
  const valueToDisplay = useMemo(() => {
    if (displayLabel) {
      const selectedOptions = filter(options, (o) => o.value === value);
      return get(selectedOptions, [0, "label"], value);
    } else {
      return value;
    }
  }, [displayLabel, options, value]);
  return (
    <div className={`relative flex flex-col mx-2 h[80px] ${className || ""}`}>
      <label className="mb-1 text-xs text-[#777676]">
        {label} {required && <span className="text-[#ff4d4f]">*</span>}
      </label>
      <AutoComplete
        id={id}
        value={valueToDisplay}
        onChange={(value) => onChange(id, value)}
        onBlur={handleBlur}
        disabled={disabled}
        status={status}
        size={size}
        allowClear={true}
        placeholder={placeholder}
        options={options.map((option) => ({
          ...option,
          label: (
            <div
              style={{ color: option.disabled ? "gray" : "black" }}
              onClick={(e) => option.disabled && e.preventDefault()}
            >
              {option.label.toUpperCase()}
            </div>
          ),
        }))}
        onSelect={onSelect}
        filterOption={(inputValue, option) => {
          if (option!.labelStr && option!.labelStr?.toUpperCase().includes(inputValue.toUpperCase())) {
            return true;
          } else { return false }
        }
        }
      />
      <div className="absolute bottom-7 right-2.5">
        <DownOutlined
          style={{ color: "rgba(0, 0, 0, 0.25)", fontSize: "12px" }}
        />
      </div>
      <span className="error-text">{message}</span>
    </div>
  );
};

export default AutoCompleteInput;
