import React from "react";

const Icon = (props: {
  name: string;
  fontSize?: number;
  role?: string;
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
}) => {
  const { name, fontSize, role, className, onClick, disabled } = props;
  const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    !disabled && onClick && onClick(e);
  };
  
  return (
    <span
      role={role}
      className={`material-symbols-outlined ${className || ""} ${
        disabled ? "disabled-icon" : ""
      }`}
      style={{ fontSize: `${fontSize}px` }}
      onClick={handleClick}
    >
      {name}
    </span>
  );
};

export default Icon;
