import { AxiosError } from "axios";
import { handleApiErrors, logToSentry } from "../../helpers/helpers";
import { Notification } from "../../models/types";
import {
  fetchNotifications,
  markNotification,
} from "../../services/notifications.service";
import {
  markNotificationAsRead,
  setNotifications,
} from "../reducers/notificationsReducer";
import { AppDispatch } from "../store";

export const getNotificationsThunk =
  (userId: number) => async (dispatch: AppDispatch) => {
    try {
      const response = await fetchNotifications(userId);
      let data = response.data.data || [];
      data = data.map((d: any) => {
        if (d.payload) {
          const type = typeof d.payload;
          const payload = type === "string" ? JSON.parse(d.payload) : d.payload;
          const formattedObj = { ...d, ...payload };
          delete formattedObj.payload;
          return formattedObj;
        } else return d;
      });
      dispatch(setNotifications(data as Notification[]));
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Notifications", "getNotificationsThunk");
    }
  };

export const markNotificationAsReadThunk =
  (notificationId: number) => async (dispatch: AppDispatch) => {
    try {
      await markNotification(notificationId);
      dispatch(markNotificationAsRead({ id: notificationId }));
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Notifications", "markNotificationAsReadThunk");
    }
  };
