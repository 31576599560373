/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { NavLink, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import Icon from "../../common/Icon";
import { useMemo } from "react";
import useUserRole from "../../hooks/useUserRole";
import { ROUTE } from "../../../constants/routes";

const STAFF_ADMIN_MENU_KEYS: string[] = ["1", "2", "3", "4", "5", "11"];
const FACILITY_ADMIN_MENU_KEYS: string[] = ["1", "2", "3", "4", "5", "6", "11"];
function Sidenav() {
  const location = useLocation();
  const { t } = useTranslation();
  const currentPath = location.pathname;
  const { isConsultant, isFacilityAdmin } = useUserRole();
  const MENU_ITEMS = useMemo(() => {
    const options = [
      {
        key: "1",
        icon: <Icon name="edit_calendar" className="menu-icon ml-1" />,
        label: t("sidebar.jobSchedule"),
        url: ROUTE.scheduleJobs,
      },
      {
        key: "2",
        icon: <Icon name="work_outline" className="menu-icon ml-1" />,
        label: t("sidebar.jobCategory"),
        url: ROUTE.jobCategory,
      },
      {
        key: "3",
        icon: <Icon name="checklist_rtl" className="menu-icon ml-1" />,
        label: t("sidebar.orientations"),
        url: ROUTE.orientations,
      },
      {
        key: "4",
        icon: <Icon name="assignment_turned_in" className="menu-icon ml-1" />,
        label: t("sidebar.shiftApproval"),
        url: ROUTE.shiftApproval,
      },
      {
        key: "5",
        icon: <Icon name="groups" className="menu-icon ml-1" />,
        label: t("sidebar.clients"),
        url: ROUTE.clients,
      },
      {
        key: "6",
        icon: <Icon name="receipt_long" className="menu-icon ml-1" />,
        label: t("sidebar.invoice"),
        url: ROUTE.facilityInvoices,
      },
      {
        key: "7",
        icon: <Icon name="receipt" className="menu-icon ml-1" />,
        label: t("sidebar.clientInvoices"),
        url: ROUTE.clientInvoices,
      },
      {
        key: "8",
        icon: <Icon name="business" className="menu-icon ml-1" />,
        label: t("sidebar.facilities"),
        url: ROUTE.facilities,
      },
      {
        key: "9",
        icon: <Icon name="scatter_plot" className="menu-icon ml-1" />,
        label: t("sidebar.serviceCharge"),
        url: ROUTE.serviceCharge,
      },
      {
        key: "10",
        icon: <Icon name="schedule" className="menu-icon ml-1" />,
        label: t("sidebar.invoiceScheduler"),
        url: ROUTE.invoiceScheduler,
      },
      {
        key: "11",
        icon: <Icon name="settings" className="menu-icon ml-1" />,
        label: t("sidebar.settings"),
        url: ROUTE.settings,
      },
      {
        key: "12",
        icon: <Icon name="upcoming" className="menu-icon ml-1" />,
        label: t("sidebar.upcomingJobs"),
        url: ROUTE.upcomingJobs,
      },
    ];
    if (isConsultant) {
      return options;
    } else if (isFacilityAdmin) {
      return options.filter((op) => FACILITY_ADMIN_MENU_KEYS.includes(op.key));
    } else {
      return options.filter((op) => STAFF_ADMIN_MENU_KEYS.includes(op.key));
    }
  }, [isConsultant, isFacilityAdmin, t]);
  const selectedKey = useMemo(() => {
    return (
      MENU_ITEMS.filter((item) => item.url === currentPath)?.[0]?.key || "0"
    );
  }, [currentPath, MENU_ITEMS]);
  return (
    <Menu
      mode="inline"
      className="h-full bg-[#fff]"
      selectedKeys={[selectedKey]}
    >
      {MENU_ITEMS.map((menuItem) => (
        <Menu.Item
          key={menuItem.key}
          style={{
            margin: "4px 0",
            paddingLeft: "16px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <NavLink
            to={menuItem.url}
            className={
              currentPath === menuItem.url
                ? "flex !items-center active-nav-link"
                : "flex !items-center"
            }
          >
            {menuItem.icon}
            {<span className="ml-4">{menuItem.label}</span>}
          </NavLink>
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default Sidenav;
