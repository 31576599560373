import React, { useState } from "react";
import Icon from "./Icon";

type Props = {
  text: string;
};

const CopyIcon = (props: Props) => {
  const { text } = props;
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    if (!copied) {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };
  return (
    <div className="flex items-center" onClick={handleClick} role="button">
      {!copied ? (
        <Icon name="content_copy" fontSize={20} className="font-bold" />
      ) : (
        <Icon
          name="done_all"
          fontSize={20}
          className="font-bold text-[#5F73D8]"
        />
      )}
    </div>
  );
};

export default CopyIcon;
