/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { isEmpty } from "lodash";
import useUserRole from "../../hooks/useUserRole";
import { getFacilityDetailsThunk } from "../../../redux/thunks/settings";
import DropdownWithInsideLabel from "../../common/DropdownWithInsideLabel";
import { closeSidePanel } from "../../../redux/reducers/appReducer";

const FacilitySelection = () => {
  const dispatch = useAppDispatch();
  const facility = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility
  );
  const facilities = useAppSelector(
    (state) => state.facilitiesReducer.facilities
  );
  useEffect(() => {
    if (!isEmpty(facilities) && isEmpty(facility)) {
      const id = facilities[0].id;
      dispatch(getFacilityDetailsThunk(Number(id)));
    }
  }, [facilities, dispatch]);
  const { isConsultant } = useUserRole();
  const facilityList = useMemo(() => {
    return facilities.map((item) => ({
      value: item.id,
      label: item.title,
    }));
  }, [facilities]);
  const handleChangeFacility = (id: string, value: string) => {
    const selectedFacility = facilities.filter((item) => item.id === value)[0];
    dispatch(
      getFacilityDetailsThunk(Number(selectedFacility.id), () => {
        dispatch(closeSidePanel());
      })
    );
  };
  if (isEmpty(facility)) {
    return null;
  }
  return isConsultant ? (
    <div className="flex flex-col gap-4">
      <DropdownWithInsideLabel
        id="facilitySelectionDropdown"
        value={facility.title || ""}
        options={facilityList}
        onChange={handleChangeFacility}
        className="w-[300px] h-[40px]"
        label="Facility"
        showSearch
      />
    </div>
  ) : (
    <div className="facility-info-container-non-consultant relative">
      <div
        style={{
          position: "absolute",
          zIndex: 2,
          left: 12,
          top: 2,
          fontSize: "10px",
        }}
      >
        Facility
      </div>
      <div className="facility-name-text">{facility.title}</div>
    </div>
  );
};

export default FacilitySelection;
