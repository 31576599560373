import { AxiosError, AxiosResponse } from "axios";
import { SignInTypes } from "../../models/types";
import {
  logout,
  login,
  fetchMasterData,
  getPreSignedUrl,
  getInvoicePreSignedUrl,
} from "../../services/app.services";

import {
  setAppLoading,
  setCurrentUser,
  setDocumentLoading,
  setMasterData,
} from "../reducers/appReducer";
import { showToast } from "../reducers/feedbackReducer";
import { AppDispatch } from "../store";
import { handleApiErrors, logToSentry } from "../../helpers/helpers";
import { downloadFile, downloadInvoicePdf } from "../../services";

export const loginThunk =
  (payload: SignInTypes, callback: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setAppLoading(true));
    try {
      const response = (await login(payload)) as AxiosResponse;
      dispatch(setCurrentUser({ ...response.data.data }));
      callback();
      dispatch(getMasterDataThunk());
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "App", "loginThunk");
    } finally {
      dispatch(setAppLoading(false));
    }
  };
export const getMasterDataThunk = () => async (dispatch: AppDispatch) => {
  dispatch(setAppLoading(true));
  try {
    const response = (await fetchMasterData()) as AxiosResponse;
    const {
      roles,
      facilityTypes,
      jobStatuses,
      orientationStatuses,
      provinces,
      paymentStatuses,
      jobTemplates,
    } = response.data.data;
    dispatch(
      setMasterData({
        roles,
        facilityTypes,
        jobStatuses,
        orientationStatuses,
        provinces,
        paymentStatuses,
        jobTemplates,
      })
    );
  } catch (err: any) {
    handleApiErrors(err as AxiosError, dispatch);
    logToSentry(err, "App", "getMasterDataThunk");
  } finally {
    dispatch(setAppLoading(false));
  }
};

export const logoutThunk =
  (userId: number, callback: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setAppLoading(true));
    try {
      await logout(userId);
      callback();
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "App", "logoutThunk");
    } finally {
      dispatch(setAppLoading(false));
    }
  };

export const downloadUserDocumentThunk =
  (fileId: number, fileTitle: string) => async (dispatch: AppDispatch) => {
    dispatch(setAppLoading(true));
    dispatch(setDocumentLoading({ status: true, fileId, fileName: fileTitle }));
    try {
      const response = await getPreSignedUrl(fileId);
      await downloadFile(response.data.data, fileTitle);
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "App", "downloadUserDocumentThunk");
    } finally {
      dispatch(setAppLoading(false));
      dispatch(
        setDocumentLoading({ status: false, fileId: null, fileName: "" })
      );
    }
  };
export const downloadInvoiceDocumentThunk =
  (invoiceId: number, invoiceNumber: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(setAppLoading(true));
    try {
      dispatch(
        showToast({
          type: "info",
          message: `${invoiceNumber} is being downloaded`,
          show: true,
          direction: "left",
        })
      );
      const response = await getInvoicePreSignedUrl(invoiceId);
      await downloadInvoicePdf(response.data.data, invoiceNumber);
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "App", "downloadInvoiceDocumentThunk");
    } finally {
      dispatch(setAppLoading(false));
    }
  };

export const getPresignedUrlThunk =
  (fileId: number) => async (dispatch: AppDispatch) => {
    dispatch(setAppLoading(true));
    try {
      const response = await getPreSignedUrl(fileId);
      return response.data.data;
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "App", "getPresignedUrlThunk");
    } finally {
      dispatch(setAppLoading(false));
    }
  };
