import { useMemo, useState } from "react";
import { FacilityInvoiceTypes } from "../../models/types";
import { Table, Space, Tag, Segmented } from "antd";
import { useTranslation } from "react-i18next";
import ActionButtons from "./ActionButtons";
import SectionName from "../common/SectionName";
import useFacilityTimezone from "../hooks/useFacilityTimezone";
import { getDateFromUtcDate, getDateFromUtcDateTime } from "../../helpers/dateTime.helper";
import dayjs from "dayjs";

type INVOICE_TABLE_TYPES = {
  data: FacilityInvoiceTypes[];
  handleFilters: (key: "segmented", value: string) => void;
};
const InvoiceTable = (props: INVOICE_TABLE_TYPES) => {
  const { t } = useTranslation();
  const { data, handleFilters } = props;

  const [filter, setFilter] = useState<"Due" | "Paid" | "No Due" | "All">(
    "Due"
  );
  const [selectedInvoices, setSelectedInvoices] = useState<
    FacilityInvoiceTypes[]
  >([]);
  const { timeZoneId } = useFacilityTimezone();

  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const resetRowSelection = () => {
    setSelectedKeys([]);
    setSelectedInvoices([]);
  };
  const handleFilter = (value: string | number) => {
    if (value === "Due" || value === "Paid" || value === "All")
      setFilter(value);
    resetRowSelection();
    handleFilters("segmented", value.toString().toLocaleLowerCase());
  };
  const tableData = useMemo(() => {
    if (filter === "All") {
      return [...data].sort((a, b) =>
        a.paymentStatus >= b.paymentStatus ? 1 : -1
      );
    } else if (filter === "Due") {
      return data.filter((i) => i.paymentStatus === "due");
    } else if (filter === "No Due") {
      return data.filter((i) => i.paymentStatus === "no due");
    } else {
      return data.filter((i) => i.paymentStatus === "paid");
    }
  }, [data, filter]);

  let columns = [
    {
      title: t("clientInvoice.invoice"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: "20%",
    },
    {
      title: t("invoice.startDate"),
      width: "15%",
      render: (_: any, { startDate }: { startDate: string }) => {
        return dayjs(startDate).format("DD/MM/YYYY");
      },
    },
    {
      title: t("invoice.endDate"),
      width: "15%",
      render: (_: any, { endDate }: { endDate: string }) => {
        return dayjs(endDate).format("DD/MM/YYYY");
      },
    },
    {
      title: t("invoice.generatedDate"),
      width: "15%",
      render: (_: any, { createdOn }: { createdOn: string }) => {
        return dayjs(createdOn).format("DD/MM/YYYY");
      },
    },
    {
      title: t("invoice.clients"),
      dataIndex: "numberOfClients",
      key: "numberOfClients",
      width: "10%",
    },
    {
      title: t("invoice.hours"),
      dataIndex: "totalHours",
      key: "totalHours",
      width: "10%",
    },
    {
      title: t("invoice.amount"),
      dataIndex: "amount",
      key: "amount",
      width: "15%",
      render: (_: any, { amount }: { amount: string }) => {
        return amount ? `${amount}` : "$0";
      },
    },
    {
      title: t("invoice.status"),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: "15%",
      render: (
        _: any,
        { paymentStatus }: { paymentStatus: "paid" | "due" | "no due" }
      ) => {
        return (
          <div className="flex w-full ">
            <Tag
              color={
                paymentStatus === "paid"
                  ? "green"
                  : paymentStatus === "due"
                  ? "volcano"
                  : "default"
              }
              className="flex justify-between items-center px-4"
            >
              {paymentStatus === "paid"
                ? t("invoice.paid")
                : paymentStatus === "due"
                ? t("invoice.due")
                : t("invoice.noDue")}
            </Tag>
          </div>
        );
      },
    },
  ];
  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: FacilityInvoiceTypes[]
    ) => {
      setSelectedKeys(selectedRowKeys);
      setSelectedInvoices(selectedRows);
    },
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div className="flex justify-between items-center">
        <SectionName />
        <div className="flex items-center">
          <ActionButtons
            selectedInvoices={selectedInvoices}
            resetRowSelection={resetRowSelection}
          />
          <Segmented
            options={[
              t("invoice.due") || "Due",
              t("invoice.paid") || "Paid",
              t("common.all") || "All",
            ]}
            value={filter}
            onChange={handleFilter}
            className=""
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        sticky
        rowClassName="orientation-table-row"
        size="small"
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        scroll={{ y: window.innerHeight - 200 }}
      />
    </Space>
  );
};

export default InvoiceTable;
