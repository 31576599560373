/* eslint-disable no-useless-escape */
import { useEffect, useMemo, useState } from "react";
import ConfirmationModal from "../common/ConfirmationModal";
import InputField from "../common/InputField";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { verifyUsername } from "../../services/settings.services";
import Dropdown from "../common/Dropdown";
import { updateFacilityUserThunk } from "../../redux/thunks/facility";
import useUserRole from "../hooks/useUserRole";
import { logoutThunk } from "../../redux/thunks/app";
import { useNavigate } from "react-router";
import useResetState from "../hooks/useResetState";
import { ROUTE } from "../../constants/routes";

type Props = {
  showForm: boolean;
  hideForm: () => void;
  facilityId: string;
  userId: string;
  requestedBy: number;
  userName: string;
  roleName: string;
};

type FormState = {
  username: string;
  role: string;
};
type ErrorState = {
  username: string;
  role: string;
};
const UpdateUserForm = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { resetState } = useResetState();
  const { userId: loggedInUserId } = useUserRole();
  const roles = useAppSelector((state) => state.appReducer.roles);
  const {
    showForm,
    hideForm,
    facilityId,
    userId,
    userName,
    roleName,
    requestedBy,
  } = props;
  const { t } = useTranslation();
  const [formState, setFormState] = useState<FormState>({
    username: "",
    role: "",
  });
  const [errorState, setErrorState] = useState<ErrorState>({
    username: "",
    role: "",
  });
  useEffect(() => {
    if (formState.role !== roleName || formState.username !== userName) {
      setFormState({
        role: roleName,
        username: userName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, roleName]);

  const roleOptions = useMemo(() => {
    return roles
      .filter((role) => Number(role.id) === 3 || Number(role.id) === 4)
      .map((role) => ({
        value: role.title,
        label: role.title,
      }));
  }, [roles]);
  const handleChange = (id: string, value: string | number | null) => {
    if (id === "username" || id === "role") {
      value = value?.toString() || "";
      setFormState({ ...formState, [id]: value });
      if (errorState[id]) {
        setErrorState({ ...errorState, [id]: "" });
      }
    }
  };
  const handleBlur = (id: string, value: string | number | null) => {
    if (id === "username" && value !== userName) {
      value = value?.toString().trim().toLowerCase() || "";
      setFormState({ ...formState, [id]: value });
      if (id === "username") {
        const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!emailPattern.test(formState.username)) {
          setErrorState({
            ...errorState,
            username: t("errors.invalidEmail"),
          });
        } else {
          validateUsername(value);
        }
      }
    }
  };
  const validateUsername = async (value: string) => {
    const usernameExists = await verifyUsername(value);
    if (usernameExists) {
      setErrorState({
        ...errorState,
        username: t("errors.usernameIsTaken"),
      });
    }
  };
  const handleValidation = () => {
    let valid = true;
    const errorsCopy = { ...errorState };
    const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (errorState.username) {
      valid = false;
    } else if (!formState.username) {
      valid = false;
      errorsCopy.username = t("errors.usernameRequired");
    } else if (!emailPattern.test(formState.username)) {
      valid = false;
      errorsCopy.username = t("errors.invalidEmail");
    }
    if (!formState.role) {
      valid = false;
      errorsCopy.role = t("errors.roleRequired");
    }

    if (valid) {
      handleUpdate();
    } else {
      setErrorState(errorsCopy);
    }
  };
  const handleUpdate = () => {
    const { username, role } = formState;
    const payload = {
      username: username.toLocaleLowerCase(),
      userType: role,
      userId,
      requestedBy,
    };
    if (role !== roleName || userName !== username) {
      dispatch(
        updateFacilityUserThunk({
          payload,
          facilityId: Number(facilityId),
          callback: () => {
            if (Number(loggedInUserId) === Number(userId)) {
              dispatch(
                logoutThunk(loggedInUserId, () => {
                  handleClose();
                  resetState();
                  navigate(ROUTE.signIn);
                })
              );
            } else {
              handleClose();
            }
          },
        })
      );
    } else {
      handleClose();
    }
  };
  const handleClose = () => {
    setFormState({
      username: "",
      role: "",
    });
    setErrorState({
      username: "",
      role: "",
    });
    hideForm();
  };
  return (
    <ConfirmationModal
      open={showForm}
      title={"Update User Details"}
      handleYes={handleValidation}
      handleNo={handleClose}
      primaryButtonText={t("common.update") || ""}
      secondaryButtonText={t("common.close") || ""}
      text={
        <div className="pt-4">
          <InputField
            id="username"
            type="text"
            label={t("facilities.username") || ""}
            value={formState.username}
            placeholder={t("facilities.usernamePlaceholder") || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            message={errorState.username}
            status={errorState.username ? "error" : ""}
          />
          <Dropdown
            id="role"
            options={roleOptions}
            value={formState.role}
            onChange={handleChange}
            label={t("facilities.role") || ""}
            placeholder={t("facilities.rolePlaceholder") || ""}
            message={errorState.role}
            status={errorState.role ? "error" : ""}
          />
          {Number(loggedInUserId) === Number(userId) && (
            <div className="error-text">
              If you're updating your username/role you will be logged out of
              the application, and need to login again.
            </div>
          )}
        </div>
      }
    />
  );
};

export default UpdateUserForm;
