import { useMemo } from "react";
import { useAppSelector } from "../../redux/hooks";
import AutoCompleteInput from "../common/AutoCompleteInput";
import { useTranslation } from "react-i18next";
import Datepicker from "../common/Datepicker";
import dayjs from "dayjs";
import { DATE_FORMAT_PRIMARY } from "../../constants/defaults";
import Dropdown from "../common/Dropdown";
import InputField from "../common/InputField";
import { get } from "lodash";
import { ContactTypes } from "../../models/types";
import { Checkbox } from "antd";

type Props = {
  data: {
    facilityId: string;
    orientationDate: string;
    shiftId: string;
    contactName: string;
    contactMobile: string;
    contactEmail: string;
    postToPoolWhenWithdrawn: boolean;
  };
  errors: { facilityId: string; orientationDate: string; shiftId: string };
  shiftsOptions: any[];
  onChange: (id: string, value: string | number | null | boolean) => void;
  selectedContact: (contact: ContactTypes) => void;
  isConsultant: boolean;
  clientName: string;
};
export const CreateOrientationForm = (props: Props) => {
  const {
    onChange,
    data,
    errors,
    shiftsOptions,
    isConsultant,
    selectedContact,
  } = props;
  const { t } = useTranslation();
  const facilities = useAppSelector(
    (state) => state.facilitiesReducer.facilities
  );
  const currentFacility = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility
  );
  const facilityList = useMemo(() => {
    return facilities.map((item) => ({
      value: item.id,
      label: `${item.title}`,
      labelStr: `${item.title}`,
      disabled: !item.enabled,
    }));
  }, [facilities]);
  const contactOptions = useMemo(() => {
    const selectedFacility = isConsultant
      ? facilities.filter((f) => Number(f.id) === Number(data.facilityId))
      : [currentFacility];
    const contacts = get(selectedFacility, [0, "contacts"], []);
    const options = contacts.map((c: any) => {
      return {
        value: c.id.toString(),
        label: `${c.name}; Email: ${c.email}`,
      };
    });
    return options;
  }, [isConsultant, facilities, currentFacility, data.facilityId]);

  const handleContactInfo = (id: string, value: string | number | null) => {
    const selectedFacility = isConsultant
      ? facilities.filter((f) => Number(f.id) === Number(data.facilityId))
      : [currentFacility];
    const contacts = get(selectedFacility, [0, "contacts"], []);
    const selectedOption = contacts.filter(
      (c: any) => c.id.toString() === id
    )?.[0];
    if (selectedOption) {
      selectedContact(selectedOption);
    }
  };
  return (
    <div>
      {isConsultant && (
        <AutoCompleteInput
          id="facilityId"
          label={t("createOrientationForm.facility") || ""}
          placeholder={t("createOrientationForm.facilityPlaceHolder") || ""}
          value={data.facilityId}
          onChange={onChange}
          required={true}
          options={facilityList}
          message={errors.facilityId}
          status={errors.facilityId ? "error" : ""}
          displayLabel={true}
        />
      )}
      <div className="flex">
        <Datepicker
          id="orientationDate"
          value={data.orientationDate ? dayjs(data.orientationDate) : undefined}
          onChange={onChange}
          label={t("createOrientationForm.orientationDate") || ""}
          required={true}
          message={errors.orientationDate}
          placeholder={DATE_FORMAT_PRIMARY}
          className="w-full"
          status={errors.orientationDate ? "error" : ""}
        />
      </div>
      <Dropdown
        id="shiftId"
        label={t("createOrientationForm.shift") || ""}
        placeholder={t("createOrientationForm.shiftPlaceHolder") || ""}
        required={true}
        disabled={!data.facilityId || !data.orientationDate}
        value={data.shiftId}
        onChange={onChange}
        options={shiftsOptions}
        message={errors.shiftId}
        status={errors.shiftId ? "error" : ""}
        className="mt-2"
      />
      <AutoCompleteInput
        id="contactName"
        label={t("jobList.contactName") || ""}
        value={data.contactName}
        onChange={onChange}
        onSelect={handleContactInfo}
        options={contactOptions}
        disabled={!data.facilityId}
      />
      <InputField
        type="text"
        id="contactEmail"
        label={t("jobList.contactEmail") || ""}
        value={data.contactEmail}
        onChange={onChange}
      />
      <InputField
        type="text"
        id="contactMobile"
        label={t("jobList.contactPhone") || ""}
        addonBefore="+1"
        value={data.contactMobile}
        onChange={onChange}
        onBlur={() => {}}
      />
      <div className="pl-2">
        <Checkbox
          checked={data.postToPoolWhenWithdrawn}
          className="mb-3"
          onChange={(e) =>
            onChange("postToPoolWhenWithdrawn", e.target.checked)
          }
        >
          Activate Shift Sharing to Ensure Coverage
        </Checkbox>
        <p className="pl-3 pr-2 small-text -mt-2 light-text">
          Please select this option if you wish to make the shift available in
          the job pool for other staff members to accept in case of a
          cancellation. If not selected, the shift will be removed entirely.
        </p>
      </div>
    </div>
  );
};
