import { ServiceCharge } from "../../models/types";
import InputField from "../common/InputField";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

const { Option } = Select;

type Props = {
    data: ServiceCharge;
    onChange: (id: string, value: string | number | null) => void;
    onBlur: (id: string, value: string | number | null) => void;
    errors: {
        rateCut: string;
    };
};

const ServiceChargeForm = (props: Props) => {
    const { t } = useTranslation();
    const { data, onChange, onBlur, errors } = props;

    const typeChange = (value: any) => {
        onBlur('isFlatRateCut', value);
    }

    const selectAfter = (
        <Select
            onChange={typeChange}
            defaultValue={"true"}
            value={data.isFlatRateCut.toString()}
            style={{
                width: 60,
            }}
        >
            <Option value={"true"}>$</Option>
            <Option value={"false"}>%</Option>
        </Select>
    );

    return (
        <div>
            <InputField
                type="text"
                id="title"
                label={t("jobList.title") || ""}
                value={data.title}
                disabled={true}
                onChange={onChange}
            />
            <InputField
                type="number"
                id="rateCut"
                label={t("jobList.description") || ""}
                value={data.rateCut}
                onChange={onChange}
                onBlur={onBlur}
                required={true}
                message={errors.rateCut}
                status={errors.rateCut ? "error" : ""}
                addonAfter={selectAfter}
            />
        </div>
    );
};

export default ServiceChargeForm;
