import { Notification } from "../../../../models/types";
import NotificationText from "../NotificationText";
import NotificationAvatar from "../../../common/UserAvatar";
import Icon from "../../../common/Icon";
import { Button } from "antd";
import { t } from "i18next";
import { useState } from "react";
import VerifyDocumentModal from "../../../common/VerifyDocumentModal";
import { DOCUMENT_TITLES } from "../../../common/ClientProfile/Documents";

type Props = {
  notification: Notification;
  icon?: string;
  handleNotificationOpen: (id: number) => void;
};

const UserDocumentNotification = (props: Props) => {
  const { notification, handleNotificationOpen } = props;
  const [open, setOpen] = useState(false);

  const handleDocumentApproveModalOpen = () => {
    handleNotificationOpen(notification.id);
    setOpen(true);
  };

  const handleNo = () => {
    setOpen(false);
  };
  const { clientFirstName, clientLastName, fileName } = notification;

  const Message = () => {
    return (
      <div>
        <b>{`${clientFirstName} ${clientLastName} `}</b> has <b>uploaded</b> a
        new document {fileName ? "for" : ""}{" "}
        <b> {(fileName && DOCUMENT_TITLES[fileName]) || fileName}. </b> You can{" "}
        <b> verify </b> it.
      </div>
    );
  };
  return (
    <>
      <div className="hover:bg-[#d9d9d9] p-4">
        <div className="flex items-start">
          <div className="mr-4">
            <NotificationAvatar
              src={notification.clientAvatarUrl || notification.facilityLogo}
              firstName={notification.clientFirstName}
              lastName={notification.clientLastName}
            />
          </div>
          <NotificationText notification={notification}>
            <Message />
          </NotificationText>
        </div>
        <div className="flex justify-end">
          <Button
            type="primary"
            id="verify-document-btn"
            icon={<Icon name="assignment_turned_in" fontSize={16} />}
            className="mr-3 flex justify-center items-center"
            size="small"
            onClick={() => handleDocumentApproveModalOpen()}
          >
            {t("userDocument.verify")}
          </Button>
        </div>
      </div>

      {open && (
        <VerifyDocumentModal
          open={open}
          clientId={notification.clientId ?? 0}
          documentId={notification.fileId ?? 0}
          fileName={notification.fileName ?? ""}
          handleCancel={handleNo}
        />
      )}
    </>
  );
};

export default UserDocumentNotification;
