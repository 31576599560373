import { Avatar, Dropdown, MenuProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Logout from "./Logout";
import { useAppSelector } from "../../../redux/hooks";
import { useMemo } from "react";
import { get } from "lodash";

const UserProfile = () => {
  const { currentUser, roles } = useAppSelector((state) => state.appReducer);
  const userRole = useMemo(() => {
    const filteredRole = roles.filter(
      (r) => Number(r.id) === Number(currentUser.roleId)
    );
    return get(filteredRole, ["0", "title"], "");
  }, [currentUser.roleId, roles]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex items-center py-1 gap-4 px-0 border-separate">
          <Avatar
            size={64}
            className="flex items-center justify-center"
            icon={<UserOutlined />}
          />
          <div className="flex flex-col py-2">
            <div className="username-text">{currentUser.username}</div>
            <div className="role-text">{userRole}</div>
          </div>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div className="pb-1">
          <Logout />
        </div>
      ),
    },
  ];
  return (
    <div className="profile-avatar">
      <Dropdown
        menu={{ items }}
        overlayStyle={{ minWidth: "200px", paddingBottom: "16px" }}
      >
        <Avatar
          size={40}
          className="flex items-center justify-center"
          icon={<UserOutlined />}
        />
      </Dropdown>
    </div>
  );
};

export default UserProfile;
