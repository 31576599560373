import { Notification } from "../../../models/types";
import GenericNotification from "./types/GenericNotification";
import InvoiceGenerated from "./types/InvoiceGenerated";
import JobScheduled from "./types/JobScheduled";
import JobCompleted from "./types/JobCompleted";
import JobInProgress from "./types/JobInProgress";
import JobWithdrawn from "./types/JobWithdrawn";
import OrientationAccepted from "./types/OrientationAccepted";
import OrientationApproved from "./types/OrientationApproved";
import OrientationCancelled from "./types/OrientationCancelled";
import OrientationRequested from "./types/OrientationRequested";
import ProfileCompleted from "./types/ProfileCompleted";
import UserDocumentNotification from "./types/UserDocumentNotification";
import UserAccountDeleted from "./types/UserAccountDeleted";

type Props = {
  notification: Notification;
  handleNotificationOpen: (id: number) => void;
};
const NotificationComponent = (props: Props) => {
  const { notification } = props;

  const notificationCategories = {
    InvoiceGenerated: <InvoiceGenerated {...props} />,
    JobScheduled: <JobScheduled {...props} />,
    JobCompleted: <JobCompleted {...props} />,
    JobWithdrawn: <JobWithdrawn {...props} />,
    JobCancelled: <JobWithdrawn {...props} />,
    JobInProgress: <JobInProgress {...props} />,
    OrientationRequested: <OrientationRequested {...props} />,
    OrientationAccepted: <OrientationAccepted {...props} />,
    OrientationApproved: <OrientationApproved {...props} />,
    OrientationCancelled: <OrientationCancelled {...props} />,
    ProfileCompleted: <ProfileCompleted {...props} />,
    PayoutCompleted: <ProfileCompleted {...props} />,
    DocumentCompleted: <ProfileCompleted {...props} />,
    WorkHistoryCompleted: <ProfileCompleted {...props} />,
    EducationCompleted: <ProfileCompleted {...props} />,
    WorkEligibilityCompleted: <ProfileCompleted {...props} />,
    AddressCompleted: <ProfileCompleted {...props} />,
    PersonalInfoCompleted: <ProfileCompleted {...props} />,
    DocumentUploaded: <UserDocumentNotification {...props} />,
    UserAccountDeleted: <UserAccountDeleted {...props} />,
  };
  return (
    notificationCategories[notification.entityType] ?? (
      <GenericNotification {...props} />
    )
  );
};

export default NotificationComponent;
