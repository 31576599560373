import { baseUrl, getInstance } from ".";
import { JobCategoryTypes } from "../models/types";

const instance = getInstance(baseUrl);

export const fetchJobCategories = async (facilityId: string) => {
  return instance.get(`/job-category/facility/${facilityId}`);
};

export const updateJobCategory = async (payload: JobCategoryTypes) => {
  return instance.put("/job-category", payload);
};
