import { Button, Space, Table, Modal, DatePicker } from "antd";
import ScheduleButtons from "./ScheduleButtons";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  generateClientInvoicesThunk,
  generateFacilityInvoicesThunk,
  getFailedJobsThunk,
  isClientInvoiceReadyToGenerateThunk,
  isFacilityInvoiceReadyToGenerateThunk,
} from "../../redux/thunks/invoices";
import { useEffect, useState } from "react";
import { InvoiceCheckResponse, ScheduledJob } from "../../models/types";
import Icon from "../common/Icon";
import SectionName from "../common/SectionName";
import useFacilityTimezone from "../hooks/useFacilityTimezone";
import { getBrowserTimeZone, getDateFromUtcDate } from "../../helpers/dateTime.helper";
import dayjs, { Dayjs } from "dayjs";

type Props = {
  data: ScheduledJob[];
  retryFacilityInvoice: (id: number) => void;
  retryClientInvoice: (id: number) => void;
};

function InvoiceScheduleTable(props: Props) {
  const dispatch = useAppDispatch();
  const { timeZoneId } = useFacilityTimezone();
  const [invoiceType, setInvoiceType] = useState<string>("");
  const [showInvoiceDateTimePickerModal, setShowInvoiceDateTimePickerModal] = useState<boolean>(false);
  const [generateTillDate, setGenerateTillDate] = useState<any>();
  const [payFromDate, setPayFromDate] = useState<any>();
  const [payToDate, setPayToDate] = useState<any>();

  const clientInvoiceCheckResponse = useAppSelector<InvoiceCheckResponse | undefined>(
    (state) => state.invoiceReducer.clientInvoiceCheckResponse
  );

  const facilityInvoiceCheckResponse = useAppSelector<InvoiceCheckResponse | undefined>(
    (state) => state.invoiceReducer.facilityInvoiceCheckResponse
  );

  const [timezone, setTimezone] = useState<string>(getBrowserTimeZone());

  useEffect(() => {
    // Dispatching action to load the requests when page renders for the first time
    dispatch(isClientInvoiceReadyToGenerateThunk());
    dispatch(isFacilityInvoiceReadyToGenerateThunk());
    setTimezone(getBrowserTimeZone());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableData = props.data;
  let columns = [
    {
      title: t("failedJobs.jobType"),
      dataIndex: "jobType",
      key: "jobType",
      width: "15%",
      render: (
        _: any,
        { jobType }: { jobType: "client-invoice" | "facility-invoice" }
      ) => {
        return (
          <div>
            {jobType === "client-invoice"
              ? t("failedJobs.clientInvoice")
              : t("failedJobs.facilityInvoice")}
          </div>
        );
      },
    },
    {
      title: t("failedJobs.startDate"),
      width: "15%",
      render: (_: any, { startDate }: { startDate: string }) => {
        return getDateFromUtcDate(startDate, timeZoneId);
      },
    },
    {
      title: t("failedJobs.endDate"),
      width: "15%",
      render: (_: any, { endDate }: { endDate: string }) => {
        return getDateFromUtcDate(endDate, timeZoneId);
      },
    },
    {
      title: t("failedJobs.errorMessage"),
      width: "25%",
      dataIndex: "errorMessage",
      key: "errorMessage",
    },
    {
      title: t("failedJobs.retryCount"),
      width: "10%",
      render: (_: any, { retryCount }: { retryCount: number | null }) => {
        return retryCount ? retryCount : 0;
      },
    },
    {
      title: "",
      width: "10%",
      render: (
        _: any,
        {
          jobType,
          id,
        }: { jobType: "client-invoice" | "facility-invoice"; id: number }
      ) => {
        return (
          <div>
            {jobType === "client-invoice" && (
              <Button
                icon={<Icon name="refresh" fontSize={16} className="mr-1" />}
                type="primary"
                id="retry-client-invoice-btn"
                className="mx-3 flex justify-center items-center"
                size="small"
                onClick={() => props.retryClientInvoice(id)}
              >
                {t("failedJobs.retry")}
              </Button>
            )}
            {jobType === "facility-invoice" && (
              <Button
                id="retry-facility-invoice-btn"
                icon={<Icon name="refresh" fontSize={16} className="mr-1" />}
                type="primary"
                className="mx-3 flex justify-center items-center"
                size="small"
                onClick={() => props.retryFacilityInvoice(id)}
              >
                {t("failedJobs.retry")}
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  function generateClientInvoice(tillDate: Dayjs, fromDate: Dayjs, toDate: Dayjs): void {
    const payload = {
      invoiceEndDateTime: dayjs.tz(tillDate, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      payFromDate: dayjs.tz(fromDate, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      payToDate: dayjs.tz(toDate, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
    };

    dispatch(generateClientInvoicesThunk(payload)).then(() => {
      dispatch(isClientInvoiceReadyToGenerateThunk());
      dispatch(getFailedJobsThunk());
    });
  }

  function generateFacilityInvoice(tillDate: Dayjs, fromDate: Dayjs, toDate: Dayjs): void {
    const payload = {
      invoiceEndDateTime: dayjs.tz(tillDate, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      payFromDate: dayjs.tz(fromDate, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      payToDate: dayjs.tz(toDate, timezone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
    };
    dispatch(generateFacilityInvoicesThunk(payload)).then(() => {
      dispatch(isFacilityInvoiceReadyToGenerateThunk());
      dispatch(getFailedJobsThunk());
    });
  }

  function showInvoiceDateTimePicker(type: string): void {
    setShowInvoiceDateTimePickerModal(true);
    setInvoiceType(type);
  }

  const handleOk = () => {
    setShowInvoiceDateTimePickerModal(false);
    if (invoiceType === "client") {
      generateClientInvoice(generateTillDate, payFromDate, payToDate);
    } else {
      generateFacilityInvoice(generateTillDate, payFromDate, payToDate);
    }

  };

  const handleCancel = () => {
    setShowInvoiceDateTimePickerModal(false);
  };

  const onGenerateTillDateChange = (date: Dayjs | null) => {
    setGenerateTillDate(date);
  };

  const payFromDateChange = (date: Dayjs | null) => {
    setPayFromDate(date);
  };

  const payToDateChange = (date: Dayjs | null) => {
    setPayToDate(date);
  };

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <div className="flex justify-between items-center">
          <SectionName />
          <div>
            <div>
              <span className="role-text">Last Client Invoice: </span>
              <span>{clientInvoiceCheckResponse?.lastInvoicedDate && dayjs(clientInvoiceCheckResponse?.lastInvoicedDate).tz(timezone).format('YYYY-MM-DD HH:mm:ss')} </span>
            </div>
            <div>
              <span className="role-text">Next Client Invoice: </span>
              <span>{clientInvoiceCheckResponse?.nextInvoiceDate && dayjs(clientInvoiceCheckResponse?.nextInvoiceDate).tz(timezone).format('YYYY-MM-DD HH:mm:ss')}</span>
            </div>
          </div>
          <div>
            <div>
              <span className="role-text">Last Facility Invoice: </span>
              <span>{facilityInvoiceCheckResponse?.lastInvoicedDate && dayjs(facilityInvoiceCheckResponse?.lastInvoicedDate).tz(timezone).format('YYYY-MM-DD HH:mm:ss')} </span>
            </div>
            <div>
              <span className="role-text">Next Facility Invoice: </span>
              <span>{facilityInvoiceCheckResponse?.nextInvoiceDate && dayjs(facilityInvoiceCheckResponse?.nextInvoiceDate).tz(timezone).format('YYYY-MM-DD HH:mm:ss')}</span>
            </div>
          </div>
          <ScheduleButtons
            showInvoiceDateTimePicker={showInvoiceDateTimePicker}
          />
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          sticky
          rowClassName="orientation-table-row"
          size="small"
        />
      </Space>
      {showInvoiceDateTimePickerModal && (
        <Modal
          title="Select Date and Time"
          open={showInvoiceDateTimePickerModal}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Close
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              Generate
            </Button>,
          ]}
        >
          <div>
            <div>
              <span className="role-text">Generate Till: </span>
              <span>
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  onChange={onGenerateTillDateChange}
                  value={generateTillDate}
                />
              </span>
            </div>
            <div>
              <div>
                <span className="role-text">Pay from date: </span>
                <span>
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    onChange={payFromDateChange}
                    value={payFromDate}
                  />
                </span>
              </div>
              <div>
                <span className="role-text">Pay to date: </span>
                <span>
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    onChange={payToDateChange}
                    value={payToDate}
                  />
                </span>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default InvoiceScheduleTable;
