import { Table } from "antd";
import { JobCategoryTypes } from "../../models/types";
import { useTranslation } from "react-i18next";
import SectionName from "../common/SectionName";
import { useMemo } from "react";
import useUserRole from "../hooks/useUserRole";

type JobListTableTypes = {
  jobList: JobCategoryTypes[];
  onClick: (id: string) => void;
};
const JobListTable = (props: JobListTableTypes) => {
  const { t } = useTranslation();
  const { jobList, onClick } = props;
  const { isFacilityAdmin, isStaffAdmin } = useUserRole();
  const data = useMemo(() => {
    return [...jobList].sort((a, b) => (a.id > b.id ? 1 : -1));
  }, [jobList]);
  const columns = useMemo(() => {
    const items = [
      {
        title: t("jobList.title"),
        dataIndex: "title",
        key: "title",
        width: "20%",
        render: (_: any, { title, id }: { title: string; id: string }) => {
          return (
            <div
              className="cursor-pointer link-text"
              onClick={() => onClick(id)}
            >
              {title}
            </div>
          );
        },
      },
      {
        title: t("jobList.hourlyRate"),
        dataIndex: "hourlyRate",
        key: "hourlyRate",
        width: "10%",
        ellipsis: true,
      },
      {
        title: t("jobList.contactName"),
        dataIndex: "contactName",
        key: "contactName",
        width: "15%",
        ellipsis: true,
      },
      {
        title: t("jobList.contactPhone"),
        dataIndex: "contactMobile",
        key: "contactMobile",
        width: "15%",
        ellipsis: true,
      },
      {
        title: t("jobList.contactEmail"),
        dataIndex: "contactEmail",
        key: "contactEmail",
        width: "15%",
        ellipsis: true,
      },
    ];
    if (isFacilityAdmin || isStaffAdmin) {
      items.splice(1, 1);
    }
    return items;
  }, [isFacilityAdmin, isStaffAdmin, onClick, t]);

  return (
    <div>
      <SectionName className="mb-2" />
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        sticky
        size="small"
      />
    </div>
  );
};

export default JobListTable;
