import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown, Checkbox, Button } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import Icon from "./Icon";
import { cloneDeep, isEmpty } from "lodash";
import { TableFilterTypes } from "../../models/types";

type FilterTypes = {
  defaultValues?: Map<string, string>;
  filterItems: TableFilterTypes[];
  onFilter: (selectedFilters: string[] | []) => void;
};
const CheckboxFilter = (props: FilterTypes) => {
  const { filterItems, onFilter } = props;
  const [selectedFilters, setSelectedFilters] = useState(new Map());
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isEmpty(filterItems)) {
      const defaultState = new Map();
      filterItems.map((f) => defaultState.set(f.id, true));
      setSelectedFilters(defaultState);
    }
  }, [filterItems]);

  const handleChange = useCallback(
    (e: CheckboxChangeEvent) => {
      //   Triggers when a filter is checked or unchecked
      const { id, checked } = e.target;
      const selectedFiltersCopy = cloneDeep(selectedFilters);
      if (checked) {
        selectedFiltersCopy.set(id, true);
      } else {
        selectedFiltersCopy.delete(id);
      }
      setSelectedFilters(selectedFiltersCopy);
    },
    [selectedFilters]
  );

  const handleOpenChange = (flag: boolean) => {
    flag && setOpen(flag);
  };
  const handleReset = useCallback(() => {
    const defaultState = new Map();
    filterItems.map((f) => defaultState.set(f.id, true));
    setSelectedFilters(defaultState);
    onFilter(filterItems.map((f) => f.id));
    setOpen(false);
  }, [filterItems, onFilter]);

  const handleOk = useCallback(() => {
    // Triggers when Ok button is clicked
    let arr: any = [];
    selectedFilters.forEach((value, key, map) => {
      arr.push(key);
    });
    onFilter(arr);
    setOpen(false);
  }, [onFilter, selectedFilters]);

  const items = useMemo(() => {
    const menuItems = filterItems.map((fi) => ({
      label: (
        <Checkbox
          key={fi.id}
          id={fi.id}
          onChange={handleChange}
          checked={selectedFilters.get(fi.id) || false}
        >
          {fi.label}
        </Checkbox>
      ),
      key: fi.id,
    }));
    menuItems.push({
      label: (
        <div className="pt-3 drop-down-controls w-full flex justify-between">
          <Button
            type="link"
            size="small"
            id="reset-btn"
            disabled={
              !selectedFilters.size ||
              selectedFilters.size === filterItems.length
            }
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button type="primary" size="small" onClick={handleOk} id="ok-button">
            Ok
          </Button>
        </div>
      ),
      key: "controls",
    });
    return menuItems;
  }, [filterItems, handleChange, handleOk, handleReset, selectedFilters]);

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      onOpenChange={handleOpenChange}
      open={open}
    >
      <Icon
        name="filter_list"
        role="button"
        className="hover:bg-[#f1f3f4] p-1 rounded-sm"
      />
    </Dropdown>
  );
};

export default CheckboxFilter;
