import { ClientProfileTypes, TableFilterTypes } from "../../models/types";
import { Table, Tag, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import SectionName from "../common/SectionName";
import { useNavigate } from "react-router";
import { ROUTE } from "../../constants/routes";
import AddButton from "../common/AddButton/AddButton";
import SearchBar from "../common/SearchBar";
import useUserRole from "../hooks/useUserRole";
import {  useMemo, useState } from "react";

type ORIENTATION_TABLE_TYPES = {
  data: ClientProfileTypes[];
  totalRecords: number;
  jobCategoryFilterData: TableFilterTypes[];
  handleConfirmation: (type: string, resetClicked?: boolean) => void;
};
const ClientsTable = (props: ORIENTATION_TABLE_TYPES) => {
  const { t } = useTranslation();
  const { data, handleConfirmation } = props;
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { isConsultant } = useUserRole();

  const columns = [
    {
      title: t("common.name"),
      width: "25%",
      render: (
        _: any,
        {
          firstName,
          lastName,
          id,
          phoneNumber,
          deletedOn,
        }: {
          firstName: string;
          lastName: string;
          id: number | null;
          phoneNumber: string;
          deletedOn?: boolean;
        }
      ) => {
        if (deletedOn) {
          return (
            <Tooltip title="The user account has been deleted.">
              <div>
                {firstName && lastName
                  ? `${firstName} ${lastName}`
                  : phoneNumber}
              </div>
            </Tooltip>
          );
        }
        return (
          <div
            className="link-text"
            onClick={() => {
              navigate(`${ROUTE.clients}/${id}`);
            }}
          >
            {firstName && lastName ? `${firstName} ${lastName}` : phoneNumber}
          </div>
        );
      },
      key: "first_name",
      showSorterTooltip: false,
      sorter: {
        compare: (
          a: { firstName: string; lastName: string; phoneNumber: string },
          b: { firstName: string; lastName: string; phoneNumber: string }
        ) => {
          const dataA =
            a.firstName && a.lastName
              ? `${a.firstName} ${a.lastName}`
              : a.phoneNumber;
          const dataB =
            b.firstName && b.lastName
              ? `${b.firstName} ${b.lastName}`
              : b.phoneNumber;
          return dataA.localeCompare(dataB);
        },
      },
    },
    {
      title: t("common.licenseNumber"),
      dataIndex: "licenseNumber",
      key: "licenseNumber",
      width: "25%",
    },
    {
      title: t("common.jobCategory"),
      dataIndex: "jobCategory",
      key: "jobCategory",
      width: "25%",
      showSorterTooltip: false,
      sorter: {
        compare: (a: { jobCategory: any }, b: { jobCategory: any }) => {
          const dataA = a.jobCategory ? a.jobCategory : "";
          const dataB = b.jobCategory ? b.jobCategory : "";
          return dataA.localeCompare(dataB);
        },
      },
    },
    {
      title: t("clientProfile.status"),
      dataIndex: "isProfileVerified",
      key: "isProfileVerified",
      render: (
        _: any,
        {
          isProfileVerified,
        }: {
          isProfileVerified: boolean;
        }
      ) => {
        return (
          <Tag color={isProfileVerified ? "green" : "volcano"}>
            {isProfileVerified ? "Verified" : "Not Verified"}
          </Tag>
        );
      },
      width: "25%",
      showSorterTooltip: false,
      sorter: {
        compare: (
          a: { isProfileVerified: boolean },
          b: { isProfileVerified: boolean }
        ) => Number(a.isProfileVerified) - Number(b.isProfileVerified),
      },
    },
  ];

  const filteredClients = useMemo(() => {
    console.log(searchQuery)
    if (searchQuery) {
      return data.filter((row) => {
        const dataToCheck =
          row.firstName && row.lastName
            ? `${row.firstName} ${row.lastName}`
            : row.phoneNumber;
        return dataToCheck.toLowerCase().includes(searchQuery.trim().toLowerCase());
      });
    }
    return data;
  }, [data, searchQuery]);

  return (
    <>
      <div className="flex items-center justify-between w-full mb-2">
        <SectionName />
        <div className="flex flex-1 justify-end items-center gap-3">
          <SearchBar
            onSearch={setSearchQuery}
            placeHolder="Search Clients by Name"
          />
          {isConsultant && (
            <AddButton
              onClick={() => handleConfirmation("new")}
              tooltipText={t("createClientProfile.createNewClient")}
            />
          )}
        </div>
      </div>
      <div className="mr-3 w-full h-full">
        <Table
          columns={columns}
          dataSource={filteredClients}
          pagination={false}
          sticky
          size="small"
        />
      </div>
    </>
  );
};

export default ClientsTable;
