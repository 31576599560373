import { OrientationRequestTypes } from "./../../models/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface OrientationReducerState {
  orientationRequests: OrientationRequestTypes[];
  loading: boolean;
  overlappingJobDates: string[];
}
const initialState: OrientationReducerState = {
  orientationRequests: [],
  loading: false,
  overlappingJobDates: [],
};

export const orientationSlice = createSlice({
  name: "orientation",
  initialState,
  reducers: {
    setOrientationRequest: (
      state,
      action: PayloadAction<OrientationRequestTypes[]>
    ) => {
      state.orientationRequests = (action.payload || []).map((item, index) => ({
        key: index,
        ...item,
      }));
    },
    setOrientationLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetOrientationState: (state) => {
      state.orientationRequests = [];
    },
    setOverlappingJobsForOrientation: (state, action: PayloadAction<any>) => {
      state.overlappingJobDates = action.payload;
    }
  },
});

export const {
  setOrientationRequest,
  setOrientationLoading,
  resetOrientationState,
  setOverlappingJobsForOrientation
} = orientationSlice.actions;

export default orientationSlice.reducer;
