import { ShiftApprovalRequestType } from "./../../models/types";
export const SHIFT_APPROVAL_REQUEST_TEMPLATE: ShiftApprovalRequestType = {
  id: 0,
  clientId: 0,
  clientFirstName: "",
  clientLastName: "",
  title: "",
  shiftDate: "",
  shiftStartTime: "",
  shiftEndDateTime: "",
  shiftStartDateTime: "",
  shiftEndTime: "",
  jobStatus: "approved",
  isOrientation: false,
  jobAllocationId: 0,
  isInvoiceGenerated: false,
  clientDeletedDate: false,
};
