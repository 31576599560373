import { isEmpty } from "lodash";
import { JobTypes } from "../../models/job.model";
import {
  createJobs,
  deleteJob,
  GetJobsByDate,
  getJobsByDate,
  getJobSummaryByDates,
  markJobAsComplete,
  unAssignJob,
  updateJob,
} from "../../services/shiftSchedule.services";
import {
  setCalendarData,
  setJobsForSelectedDay,
  setShiftScheduleLoading,
} from "../reducers/shiftScheduleReducer";
import { AppDispatch } from "../store";
import {
  CalenderViewType,
  DayPropTypes,
  JobSummary,
  WeekPropTypes,
} from "../../models/day.model";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import range from "lodash.range";
import { MarkJobAsCompletedRequestType, PostJobTypes, WithdrawJobRequest } from "../../models/types";
import { handleApiErrors, logToSentry } from "../../helpers/helpers";
import { AxiosError } from "axios";
import { showToast } from "../reducers/feedbackReducer";
import { DATE_FORMAT_PRIMARY } from "../../constants/defaults";
import { getTodayInTimeZone } from "../../helpers/dateTime.helper";

const JOB_STATUSES = ["open", "progress", "scheduled", "completed", "approved"];

export const createJobsThunk =
  (
    payload: PostJobTypes,
    calendarView: CalenderViewType,
    facilityId: string,
    tz: string,
    currentDate: Dayjs,
    callback: () => void
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setShiftScheduleLoading(true));
      await createJobs(payload);
      const feedback = () => {
        callback();
        dispatch(
          showToast({
            type: "success",
            message: "Jobs created successfully!",
            show: true,
          })
        );
        dispatch(setShiftScheduleLoading(false));
      };
      dispatch(
        getJobSummaryThunk(currentDate, calendarView, facilityId, tz, feedback)
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      dispatch(setShiftScheduleLoading(false));
      logToSentry(err, "ShiftSchedule", "createJobsThunk");
    }
  };

export const getJobsByDateThunk =
  (payload: GetJobsByDate) => async (dispatch: AppDispatch) => {
    dispatch(setShiftScheduleLoading(true));
    try {
      const response = await getJobsByDate(payload);
      dispatch(
        setJobsForSelectedDay({
          selectedDate: payload.date,
          data:
            response.data.data?.filter((j: JobTypes) =>
              JOB_STATUSES.includes(j.jobStatus)
            ) || ([] as JobTypes[]),
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "ShiftSchedule", "getJobsByDateThunk");
    } finally {
      dispatch(setShiftScheduleLoading(false));
    }
  };

export const getJobSummaryThunk =
  (
    currentDate: Dayjs,
    calendarView: CalenderViewType,
    facilityId: string,
    tz: string,
    callback?: () => void
  ) =>
  async (dispatch: AppDispatch) => {
    const type = calendarView === "month-view" ? "month" : "week";
    const daysToAdd = calendarView === "month-view" ? 7 : 14;
    const firstOfMonth = dayjs(currentDate).startOf(type).day();
    const lastOfMonth = dayjs(currentDate).endOf(type).day();
    const firstDayOfGrid = dayjs(currentDate)
      .startOf(type)
      .subtract(firstOfMonth, "days");
    const lastDayOfGrid = dayjs(currentDate)
      .endOf(type)
      .subtract(lastOfMonth, "days")
      .add(daysToAdd, "days");
    const startCalendar = firstDayOfGrid.date();
    const calendarDates = range(
      startCalendar,
      startCalendar + lastDayOfGrid.diff(firstDayOfGrid, "days")
    ).map((date, index: number): DayPropTypes => {
      const newDate = dayjs(firstDayOfGrid).date(date);
      const formattedDate = newDate.format(DATE_FORMAT_PRIMARY);
      const todayForFacility =
        getTodayInTimeZone(tz).format(DATE_FORMAT_PRIMARY);
      return {
        id: index,
        today: todayForFacility === newDate.format(DATE_FORMAT_PRIMARY),
        selected: dayjs(newDate).isSame(dayjs(), "day"),
        day: formattedDate,
        jobsSummary: {
          total: 0,
          open: 0,
          scheduled: 0,
          completed: 0,
          progress: 0,
          date: formattedDate,
        },
      };
    });
    try {
      let requestDates = calendarDates.map((day) => day.day);
      const payload = {
        facilityId,
        dates: requestDates,
      };
      const response = await getJobSummaryByDates(payload);
      calendarDates.forEach((c) => {
        const jobSummaryForDay = response.data?.data?.find(
          (res: JobSummary) => res.date === c.day
        );
        if (!isEmpty(jobSummaryForDay)) {
          c.jobsSummary = jobSummaryForDay;
        }
      });
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "ShiftSchedule", "getJobSummaryThunk");
    } finally {
      const weeks: WeekPropTypes[] = [];
      let index = 0;
      while (calendarDates.length > 0) {
        weeks.push({
          days: calendarDates.splice(0, 7),
          id: index,
        });
        index++;
      }
      dispatch(
        setCalendarData({
          month: { weeks },
        })
      );
      callback && callback();
      dispatch(setShiftScheduleLoading(false));
    }
  };
export const updateJobThunk =
  (
    payload: PostJobTypes,
    calendarView: CalenderViewType,
    facilityId: string,
    tz: string,
    currentDate: Dayjs,
    callback: () => void
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(setShiftScheduleLoading(true));
    try {
      await updateJob(payload);
      const feedback = () => {
        callback();
        dispatch(
          showToast({
            type: "success",
            message: "Job updated successfully!",
            show: true,
          })
        );
        dispatch(setShiftScheduleLoading(false));
      };
      dispatch(
        getJobSummaryThunk(currentDate, calendarView, facilityId, tz, feedback)
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      dispatch(setShiftScheduleLoading(false));
      logToSentry(err, "ShiftSchedule", "updateJobThunk");
    }
  };
export const deleteJobThunk =
  (
    jobId: string,
    reason: string,
    calendarView: CalenderViewType,
    facilityId: string,
    tz: string,
    currentDate: Dayjs,
    callback: () => void,
    userId: string,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      await deleteJob(jobId, reason, userId);
      const feedback = () => {
        callback();
        dispatch(
          showToast({
            type: "success",
            message: "Job deleted successfully!",
            show: true,
          })
        );
      };
      dispatch(
        getJobSummaryThunk(currentDate, calendarView, facilityId, tz, feedback)
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      dispatch(setShiftScheduleLoading(false));
      logToSentry(err, "ShiftSchedule", "deleteJobThunk");
    } finally {
      dispatch(setShiftScheduleLoading(true));
    }
  };

  export const markJobAsCompleteThunk =
  (
    payload: MarkJobAsCompletedRequestType,
    calendarView: CalenderViewType,
    facilityId: string,
    tz: string,
    currentDate: Dayjs,
    callback: () => void
  ) =>
    async (dispatch: AppDispatch) => {
      try {
        await markJobAsComplete(payload);
        const feedback = () => {
          callback();
          dispatch(
            showToast({
              type: "success",
              message: "Job is marked as completed successfully!",
              show: true,
            })
          );
        };
        dispatch(
          getJobSummaryThunk(currentDate, calendarView, facilityId, tz, feedback)
        );
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
      }
    };

    export const unAssignJobThunk =
  (
    payload: WithdrawJobRequest,
    calendarView: CalenderViewType,
    facilityId: string,
    tz: string,
    currentDate: Dayjs,
    callback: () => void
  ) =>
    async (dispatch: AppDispatch) => {
      try {
        await unAssignJob(payload);
        const feedback = () => {
          callback();
          dispatch(
            showToast({
              type: "success",
              message: "Job unassigned successfully!",
              show: true,
            })
          );
        };
        dispatch(
          getJobSummaryThunk(currentDate, calendarView, facilityId, tz, feedback)
        );
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
      }
    };
