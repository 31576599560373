import { CreateClientTypes, DropDownOptionTypes } from "../../models/types";
import InputField from "../common/InputField";
import RadioButton from "../common/RadioButton";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";

type CreateClientProfileTypes = {
    data: CreateClientTypes;
    onChange: (id: string, value: string | number | null) => void;
    errors: {
        firstName: string;
        lastName?: string;
        gender: string;
        phoneNumber?: string;
        email: string;
    };
};
const CreateClientProfile = (props: CreateClientProfileTypes) => {
    const { t } = useTranslation();
    const { data, onChange, errors } = props;

    const genderTypes: DropDownOptionTypes[] = [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }];
    return (
      <div>
        <InputField
          type="text"
          id="firstName"
          label={t("createClientProfile.firstName") || ""}
          value={data.firstName}
          onChange={onChange}
          message={errors.firstName}
          status={errors.firstName ? "error" : ""}
          required
        />
        <InputField
          type="text"
          id="lastName"
          label={t("createClientProfile.lastName") || ""}
          value={data.lastName}
          onChange={onChange}
          onBlur={() => {}}
          message={errors.lastName}
          status={errors.lastName ? "error" : ""}
          required
        />
        <div className="mx-2 mb-3">
          <label className="mb-1 text-xs text-[#686565]">
            Gender <span className="text-[#ff4d4f]">*</span>
          </label>
          <RadioButton
            id="gender"
            value={data.gender}
            options={genderTypes}
            onChange={onChange}
          />
          <span className="error-text">{errors.gender}</span>
        </div>

        <InputField
          type="text"
          id="phoneNumber"
          label={t("createClientProfile.phoneNumber") || ""}
          addonBefore="+1"
          value={data.phoneNumber}
          onChange={onChange}
          onBlur={() => {}}
          message={errors.phoneNumber}
          status={errors.phoneNumber ? "error" : ""}
          required
        />
        <InputField
          type="text"
          id="email"
          label={t("createClientProfile.email") || ""}
          value={data.email}
          onChange={onChange}
          onBlur={() => {}}
          message={errors.email}
          status={errors.email ? "error" : ""}
          required
        />
      </div>
    );
};

export default CreateClientProfile;
