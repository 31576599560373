import { Button } from "antd";
import { useMemo, useState } from "react";
import Icon from "../common/Icon";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../common/ConfirmationModal";
import { ShiftApprovalRequestType } from "../../models/types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { approveShiftRequestThunk } from "../../redux/thunks/shiftApproval";
import { JOB_STATUSES } from "../../constants/statuses";
import { getHoursWorkedInDecimals } from "../../helpers/helpers";
import useFacilityTimezone from "../hooks/useFacilityTimezone";

type Props = {
  selectedShifts: ShiftApprovalRequestType[];
  segmentedFilter: "Approved" | "Pending" | "All";
  resetRowSelection: () => void;
  onEdit: () => void;
};

const ActionButtons = (props: Props) => {
  const { t } = useTranslation();
  const { timeZoneId } = useFacilityTimezone();
  const { selectedShifts, segmentedFilter, resetRowSelection, onEdit } = props;
  const dispatch = useAppDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id
  );
  const approveShiftRequest = () => {
    const requests = selectedShifts.map((s) => ({
      id: s.id,
      jobAllocationId: s.jobAllocationId,
      clientId: s.clientId,
      shiftStartDateTime: s.shiftStartDateTime,
      shiftEndDateTime: s.shiftEndDateTime,
      totalHours: getHoursWorkedInDecimals(
        s.shiftStartTime,
        s.shiftEndTime
      ).toString(),
    }));
    const payload = { ...requests[0], isEdit: false };
    dispatch(
      approveShiftRequestThunk(
        Number(facilityId),
        payload,
        segmentedFilter,
        timeZoneId
      )
    );
    setShowConfirmation(false);
    resetRowSelection();
  };

  const disableEdit = useMemo(() => {
    const editShiftSelected = selectedShifts.some(
      (item) => item.isInvoiceGenerated === true
    );
    return (
      !selectedShifts.length || selectedShifts.length > 1 || editShiftSelected
    );
  }, [selectedShifts]);

  const disableApprove = useMemo(() => {
    const approvedShiftSelected = selectedShifts.some(
      (item) => item.jobStatus === JOB_STATUSES.approved
    );
    return (
      !selectedShifts.length ||
      selectedShifts.length > 1 ||
      approvedShiftSelected
    );
  }, [selectedShifts]);

  return (
    <div className="flex action-buttons mr-4">
      <Button
        type="default"
        id="edit-shift-btn"
        icon={<Icon name="edit" fontSize={16} className="mr-1" />}
        className="mx-3 flex justify-center items-center"
        onClick={onEdit}
        size="small"
        disabled={disableEdit}
      >
        {t("shiftApproval.editShift")}
      </Button>
      <Button
        id="approve-shift-btn"
        type="primary"
        icon={<Icon name="done" fontSize={16} className="mr-1" />}
        className="flex justify-center items-center"
        size="small"
        onClick={() => {
          setShowConfirmation(true);
        }}
        disabled={disableApprove}
      >
        {t("common.approve")}
      </Button>
      {showConfirmation && (
        <ConfirmationModal
          open={showConfirmation}
          text={t("alertMessages.approveShift")}
          handleYes={approveShiftRequest}
          handleNo={() => setShowConfirmation(false)}
        />
      )}
    </div>
  );
};

export default ActionButtons;
