import { JOB_STATUSES, JOB_STATUS_IDS } from "./../../constants/statuses";
import {
  ShiftApprovePayload,
  approveShiftRequest,
  fetchShiftApprovalRequests,
} from "./../../services/shiftApproval.service";
import { ShiftApprovalRequestType } from "./../../models/types";
import { AppDispatch } from "../store";
import {
  setShiftApprovalRequestLoading,
  setShiftRequests,
} from "../reducers/shiftApprovalReducer";
import { handleApiErrors, logToSentry } from "../../helpers/helpers";
import { AxiosError } from "axios";
import { showToast } from "../reducers/feedbackReducer";
import {
  getDateFromUtcDate,
  getTimeFromUtcDate,
} from "../../helpers/dateTime.helper";

export const getShiftRequestsThunk =
  (facilityId: number, tz: string, statusId?: number, callback?: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setShiftApprovalRequestLoading(true));
    try {
      const shiftRequest = await fetchShiftApprovalRequests(
        facilityId,
        statusId
      );
      shiftRequest?.data?.data?.map((item: any) => {
        item.shiftStartTime = getTimeFromUtcDate(item.shiftStartDateTime, tz);
        item.shiftEndTime = getTimeFromUtcDate(item.shiftEndDateTime, tz);
        item.shiftDate = getDateFromUtcDate(item.shiftStartDateTime, tz);
        return item;
      });
      dispatch(
        setShiftRequests(shiftRequest.data.data as ShiftApprovalRequestType[])
      );
      callback && callback();
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "ShiftApproval", "getShiftRequestsThunk");
    } finally {
      dispatch(setShiftApprovalRequestLoading(false));
    }
  };

export const approveShiftRequestThunk =
  (
    facilityId: number,
    payload: ShiftApprovePayload,
    segmentedFilter: "Approved" | "Pending" | "All",
    tz: string,
    callback?: () => void
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(setShiftApprovalRequestLoading(true));
    try {
      await approveShiftRequest(payload);
      const successMessage =
        payload.jobStatus === JOB_STATUSES.approved
          ? "Shift edited successfully"
          : "Approved the job successfully";
      const feedback = () => {
        dispatch(
          showToast({
            type: "success",
            message: successMessage,
            show: true,
          })
        );
        callback && callback();
      };
      const statusId =
        segmentedFilter === "Pending"
          ? JOB_STATUS_IDS.completed
          : segmentedFilter === "Approved"
          ? JOB_STATUS_IDS.approved
          : undefined;
      dispatch(getShiftRequestsThunk(facilityId, tz, statusId, feedback));
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "ShiftApproval", "approveShiftRequestThunk");
    } finally {
      dispatch(setShiftApprovalRequestLoading(false));
    }
  };
