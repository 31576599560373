import { useState } from "react";
import { useNavigate } from "react-router";
import { Button, Card } from "antd";
import { SignInErrorTypes, SignInTypes } from "../../models/types";
import InputField from "../common/InputField";
import { SIGN_IN_ERROR_STATE, SIGN_IN_STATE } from "./constants";
import { cloneDeep, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import Icon from "../common/Icon";
import { useAppDispatch } from "../../redux/hooks";
import { loginThunk } from "../../redux/thunks/app";
import Toast from "../common/Toast";
import useResetState from "../hooks/useResetState";
import { ROUTE } from "../../constants/routes";
const VALID_PWD_LENGTH = Number(process.env.REACT_APP_VALID_PASSWORD_LENGTH);

const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { resetState } = useResetState();
  const [state, setState] = useState<SignInTypes>(SIGN_IN_STATE);
  const [errors, setErrors] = useState<SignInErrorTypes>(SIGN_IN_ERROR_STATE);

  const handleChange = (
    id: string,
    value: string | number | null | boolean
  ) => {
    setState({ ...state, [id]: value });
    setErrors({ ...errors, [id]: "" });
  };
  const handleBlur = (id: string, value: string | number | null) => {
    if (value) {
      setState({ ...state, [id]: value.toString().trim() });
    }
  };
  const handleValidate = (e?: any) => {
    e && e.preventDefault();
    let validData = true;
    const errorObjCopy = cloneDeep(errors);
    // Validating Title
    if (isEmpty(state.username)) {
      errorObjCopy.username = t("errors.usernameRequired");
      validData = false;
    } else {
      // eslint-disable-next-line no-useless-escape
      // const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      // if (!emailPattern.test(state.username)) {
      //   errorObjCopy.username = t("errors.invalidEmail");
      //   validData = false;
      // } else {
      errorObjCopy.username = "";
      // }
    }
    if (isEmpty(state.password)) {
      errorObjCopy.password = t("errors.passwordRequired");
      validData = false;
    } else {
      if (state.password.length < VALID_PWD_LENGTH) {
        errorObjCopy.password = t("errors.invalidPasswordLength");
        validData = false;
      } else {
        errorObjCopy.password = "";
      }
    }
    if (validData) {
      resetState();
      handleLogin();
    } else {
      setErrors(errorObjCopy);
    }
  };
  const handleLogin = () => {
    dispatch(
      loginThunk(state, () => {
        navigate(ROUTE.scheduleJobs);
      })
    );
    // Dispatch an action for API call
  };
  return (
    <form
      className="h-[98vh] w-full flex justify-center items-center"
      onSubmit={handleValidate}
    >
      <Card className="w-[450px] shadow-md">
        <div className="h-[450px] pt-12 px-12 mt-6 flex flex-col pb-4">
          {/* Heading and controls */}
          <div>
            <div className="text-center text-xl font-normal mb-8">
              {t("signIn.title")}
            </div>
            <InputField
              type="email"
              id="username"
              value={state.username}
              onChange={handleChange}
              onBlur={handleBlur}
              status={errors.username || errors.auth ? "error" : ""}
              message={errors.username || ""}
              placeholder={t("signIn.username") || ""}
              prefix={
                <Icon name="person" fontSize={16} className="text-zinc-400 " />
              }
            />
            <InputField
              type="password"
              id="password"
              value={state.password}
              onChange={handleChange}
              onBlur={handleBlur}
              status={errors.password || errors.auth ? "error" : ""}
              message={errors.password || errors.auth || ""}
              placeholder={t("signIn.password") || ""}
              prefix={
                <Icon name="lock" fontSize={16} className="text-zinc-400 " />
              }
            />
            <div className="m-2">
              <Button
                type="primary"
                className="w-full"
                onClick={handleValidate}
                loading={false}
                htmlType="submit"
                id="login-btn"
              >
                {t("signIn.signIn")}
              </Button>
            </div>
          </div>
          {/* Help Text */}
          <div className="text-center text-sm mt-6">
            <div className="mr-4 link-text">{t("signIn.askForHelp")}</div>
            <div className="my-2 link-text">{t("signIn.forgotPassword")}</div>
            <div className="link-text">{t("signIn.forgotUsername")}</div>
          </div>
          {/* Footer container */}
          <div className="mt-auto flex justify-center text-xs">
            <span className="mx-2  link-text">{t("signIn.terms")}</span>
            <span className="mx-2  link-text">{t("signIn.privacy")}</span>
            <span className="mx-2  link-text">{t("signIn.contact")}</span>
          </div>
        </div>
      </Card>
      <Toast />
    </form>
  );
};

export default SignIn;
