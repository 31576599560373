import { ProfileStatus } from "./../../models/types";
type ProfileStatusKey = keyof ProfileStatus;
export const PROFILE_SECTIONS: { title: string; key: ProfileStatusKey }[] = [
  {
    title: "Profile Information",
    key: "isPersonalInfoCompletion",
  },
  {
    title: "Address",
    key: "isAddressCompletion",
  },
  {
    title: "Current Employment",
    key: "isCurrentEmploymentCompletion",
  },
  {
    title: "Documents",
    key: "isDocumentCompletion",
  },
  {
    title: "Work Credentials",
    key: "isWorkEligibilityCompletion",
  },
  {
    title: "Bank Details",
    key: "isPayoutCompletion",
  },
  {
    title: "Work History",
    key: "isWorkHistoryCompletion",
  },
  {
    title: "Education",
    key: "isEducationCompletion",
  },
];
