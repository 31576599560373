import { baseUrl, getInstance } from ".";

const instance = getInstance(baseUrl);

export type ShiftApprovePayload = {
  id: number;
  jobAllocationId: number;
  clientId: number;
  shiftStartDateTime: string;
  shiftEndDateTime: string;
  totalHours: string;
  jobStatus?: string;
  isEdit: boolean;
};
export const fetchShiftApprovalRequests = async (
  facilityId: number,
  statusId?: number
) => {
  return instance.get(`/job/facility/${facilityId}/status?id=${statusId}`);
};

export const approveShiftRequest = async (payload: ShiftApprovePayload) => {
  return instance.post("/job/approve", payload);
};
