import { JobTypes } from "../../models/job.model";
import {
  ContactTypes,
  DropDownOptionTypes,
  PostJobErrorsTypes,
  PostJobTypes,
} from "../../models/types";
import ClientProfile from "../common/ClientProfile/ClientProfile";
import PostJob from "./post-job/PostJob";
import Summary from "./summary/Summary";

type RIGHT_PANE_TYPES = {
  type: "summary" | "post-job" | "edit-job" | "user-profile" | string;
  postJobData: PostJobTypes;
  postJobErrors: PostJobErrorsTypes;
  handleChange: (id: string, value: string | number | null) => void;
  selectedContact: (contact: ContactTypes) => void;
  jobCategoryOptions: DropDownOptionTypes[];
  shiftOptions: DropDownOptionTypes[];
  selectedDay: string;
  selectedClientId: number | null;
  handleEdit: (job: JobTypes) => void;
  handleNameClick: (id: number) => void;
  handleDeleteJob: (id: string, reason: string, userId: string) => void;
  handleMarkJobAsCompleted: (job: JobTypes) => void;
  handleUnAssignUser: (job: JobTypes) => void;
  hourlyRateNotPresent?: boolean;
};

const RightPane = (props: RIGHT_PANE_TYPES) => {
  const {
    type,
    postJobData,
    postJobErrors,
    handleChange,
    jobCategoryOptions,
    shiftOptions,
    selectedClientId,
    selectedDay,
    handleEdit,
    handleNameClick,
    handleDeleteJob,
    handleMarkJobAsCompleted,
    handleUnAssignUser,
    selectedContact,
    hourlyRateNotPresent,
  } = props;
  if (type === "post-job" || type === "edit-job") {
    return (
      <PostJob
        data={postJobData}
        errors={postJobErrors}
        handleChange={handleChange}
        jobCategoryOptions={jobCategoryOptions}
        shiftOptions={shiftOptions}
        editScreen={type === "edit-job"}
        selectedContact={selectedContact}
        hourlyRateNotPresent={hourlyRateNotPresent}
      />
    );
  } else if (type === "summary" && selectedDay) {
    return (
      <Summary
        selectedDay={selectedDay}
        handleEdit={handleEdit}
        handleNameClick={handleNameClick}
        handleDeleteJob={handleDeleteJob}
        handleMarkJobAsCompleted={handleMarkJobAsCompleted}
        handleUnAssignUser={handleUnAssignUser}
      />
    );
  } else if (type === "user-profile") {
    return <ClientProfile clientId={selectedClientId} />;
  }
  return <></>;
};

export default RightPane;
