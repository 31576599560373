import { baseUrl, getInstance } from ".";
import { PAGINATION } from "../constants/pagination";
import { ClientInvoiceSummaryRequest } from "../models/types";

const instance = getInstance(baseUrl);

export type GenerateInvoicePayload = {
  invoiceEndDateTime: string;
};

export const fetchFacilityInvoices = async (facilityId: number, pageSize?: number, pageNumber?: number, paymentStatusFilter?: string) => {
  return instance.get(`/payment/facility-invoice/${facilityId}?pageSize=${pageSize ?? PAGINATION.defaultPageSize}&pageNumber=${pageNumber ?? PAGINATION.defaultPageNumber}&paymentFilter=${paymentStatusFilter}`);
};
export const fetchClientInvoices = async (pageSize?: number, pageNumber?: number, paymentStatusFilter?: string) => {
  return instance.get(`/payment/client-invoices?pageSize=${pageSize ?? PAGINATION.defaultPageSize}&pageNumber=${pageNumber ?? PAGINATION.defaultPageNumber}&paymentFilter=${paymentStatusFilter}`);
};
export const markAsPaid = async (invoices: number[]) => {
  const requests = invoices.map((i) =>
    instance.post(`/payment/mark-as-paid/${i}`)
  );
  return Promise.allSettled(requests);
};
export const generateClientInvoices = async (payload: GenerateInvoicePayload) => {
  return instance.post(`/payment/generate/client-invoice`, payload)
};
export const generateFacilityInvoices = async (payload: GenerateInvoicePayload) => {
  return instance.post(`/payment/generate/facility-invoice`, payload)
};
export const checkClientinvoiceReadyToGenerate = async () => {
  return instance.get(`/payment/client/check-eligible-to-generate-invoice`);
};
export const checkFacilityinvoiceReadyToGenerate = async () => {
  return instance.get(`/payment/facility/check-eligible-to-generate-invoice`);
};
export const getFailedJobs = async () => {
  return instance.get(`/payment/invoice/get-failed-jobs`);
};
export const retryClientInvoiceGeneration = async (id: number) => {
  return instance.post(`/payment/retry/client-invoice/${id}`);
};
export const retryFacilityInvoiceGeneration = async (id: number) => {
  return instance.post(`/payment/retry/facility-invoice/${id}`);
};
export const fetchInvoiceCycles = async (type: string) => {
  return instance.get(`/payment/invoice-intervals/${type}`);
};
export const downloadInvoiceSummaryCSV = async (payload: ClientInvoiceSummaryRequest) => {
  return instance.post(`/file/download/client/invoices`, payload);
};

