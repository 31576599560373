import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect, useState } from "react";
import ShiftApprovalTable from "./ShiftApprovalTable";
import { getShiftRequestsThunk } from "../../redux/thunks/shiftApproval";
import { JOB_STATUS_IDS } from "../../constants/statuses";
import useFacilityTimezone from "../hooks/useFacilityTimezone";

const ShiftApproval = () => {
  const dispatch = useAppDispatch();
  // Reading Requests from redux store
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id
  );
  const { timeZoneId } = useFacilityTimezone();
  const shiftRequests = useAppSelector(
    (state) => state.shiftApprovalReducer.shiftRequests
  );

  // State to handle segmented filter
  const [segmentedFilter, setSegmentedFilter] = useState<
    "pending" | "approved" | "all"
  >("pending");

  const [tableData, setTableData] = useState(shiftRequests);

  useEffect(() => {
    if (facilityId) {
      if (segmentedFilter === "pending") {
        dispatch(
          getShiftRequestsThunk(
            Number(facilityId),
            timeZoneId,
            JOB_STATUS_IDS.completed
          )
        );
      } else if (segmentedFilter === "approved") {
        dispatch(
          getShiftRequestsThunk(
            Number(facilityId),
            timeZoneId,
            JOB_STATUS_IDS.approved
          )
        );
      } else {
        dispatch(getShiftRequestsThunk(Number(facilityId), timeZoneId));
      }
    }
  }, [segmentedFilter, facilityId, dispatch, timeZoneId]);

  useEffect(() => {
    setTableData(shiftRequests);
  }, [shiftRequests]);

  const handleFilters = (
    key: "segmented" | "job-category",
    value: string | string[] | []
  ) => {
    // Segmented Filter
    if (key === "segmented") {
      if (value === "approved" || value === "pending" || value === "all") {
        setSegmentedFilter(value);
      }
    } else if (key === "job-category") {
      // Job category filter
      if (Array.isArray(value)) {
        // setJobCategoryFilter(value);
      }
    }
  };

  return (
    <div className="h-full bg-white flex">
      <div className="mr-3 w-full">
        <ShiftApprovalTable data={tableData} filterChange={handleFilters} />
      </div>
    </div>
  );
};

export default ShiftApproval;
