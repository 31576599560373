import { useMemo, useState } from "react";
import MultiDateSelector from "../common/MultiDateSelector";
import ClientProfile from "../common/ClientProfile/ClientProfile";
import type { Value } from "react-multi-date-picker";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import Icon from "../common/Icon";
import { useAppSelector } from "../../redux/hooks";
import Dropdown from "../common/Dropdown";
import UnreadDot from "../layout/notifications/UnreadDot";
import { Button } from "antd";

type RIGHT_PANEL_PROP_TYPES = {
  component: "calendar" | "profile" | "both" | null;
  onDateSelection: (
    selectedDates: string[] | undefined,
    calendarValues: Value
  ) => void;
  calendarState?: { id: number | null; name: string };
  profileState: { id: number | null; name: string };
  selectedDates: string[];
  calendarValues: Value | null;
  isLoading: boolean;
  hourlyRateNotPresent: boolean;
  onSuggest: (shiftId: string) => void;
  onShiftSelection: (shiftId: string) => void;
};

export const RightPanel = (props: RIGHT_PANEL_PROP_TYPES) => {
  const { t } = useTranslation();
  const {
    component,
    onDateSelection,
    profileState,
    selectedDates,
    calendarValues,
    isLoading,
    hourlyRateNotPresent,
    onSuggest,
    onShiftSelection,
  } = props;
  const [error, setError] = useState<string>("");
  const [selectedShift, setSelectedShift] = useState("");
  const [shiftError, setShiftError] = useState("");
  const overlappingDates = useAppSelector(
    (state) => state.orientationReducer.overlappingJobDates
  );
  const shiftsData = useAppSelector((state) => state.settingsReducer.shifts);
  const handleSuggest = () => {
    let valid = true;
    if (isEmpty(selectedDates)) {
      setError(t("errors.datesRequired") || "");
      valid = false;
    }
    if (!selectedShift) {
      setShiftError(t("errors.shiftRequired") || "");
      valid = false;
    }
    if (valid) {
      onSuggest(selectedShift);
    }
  };
  const shiftOptions = useMemo(() => {
    return shiftsData.map((item) => ({
      value: item.id.toString(),
      label: item.title,
    }));
  }, [shiftsData]);
  if (component === "profile" || component === "both") {
    return (
      <div>
        <ClientProfile clientId={profileState.id} />
      </div>
    );
  } else if (component === "calendar") {
    const suggestedDates = selectedDates.join(", ");

    const handleShiftChange = (id: string, value: string) => {
      onShiftSelection(value);
      setSelectedShift(value);
      setShiftError("");
    };
    const handleClear = () => {
      setSelectedShift("");
      onDateSelection([], null);
    };
    return (
      <div className="flex flex-col justify-center">
        <div className="mx-auto h-full flex flex-col">
          <div className="mb-2 flex-wrap w-[256px] text-grey text-sm">
            {`${t("orientations.suggestedDates")}:`} {suggestedDates}
            <span className="error-text">{error}</span>
          </div>
          <Dropdown
            id="shift"
            value={selectedShift}
            label="Select shift"
            options={shiftOptions}
            onChange={handleShiftChange}
            className="mt-3"
            message={shiftError}
            status={shiftError ? "error" : ""}
            disabled={selectedShift.trim() !== ""}
          />
          {selectedShift && (
            <MultiDateSelector
              getSelectedDate={(selectedDates, calendarValues) => {
                onDateSelection(selectedDates, calendarValues);
                setError("");
              }}
              values={calendarValues}
              datesToDisable={overlappingDates}
            />
          )}
          {hourlyRateNotPresent && (
            <div className="error-text mb-3 pl-2 flex items-start">
              <UnreadDot className="mt-1 mr-1" redDot />
              <span>
                Hourly rate is not set for this job category, you can add it
                from job category section.
              </span>
            </div>
          )}
          <div className="flex mt-3 ml-auto gap-3">
            <Button
              id="clear-dates-btn"
              type="default"
              icon={<Icon name="clear" fontSize={16} className="mr-1" />}
              className="flex justify-center items-center"
              onClick={handleClear}
              style={{ marginRight: "5px" }}
              size="small"
            >
              {t("common.clear")}
            </Button>
            <Button
              id="suggest-dates-btn"
              type="primary"
              icon={
                <Icon name="insert_invitation" fontSize={16} className="mr-1" />
              }
              className="flex justify-center items-center"
              onClick={handleSuggest}
              size="small"
              loading={isLoading}
              disabled={
                hourlyRateNotPresent || !selectedShift || !suggestedDates
              }
            >
              {isLoading
                ? `${t("common.saving")}...`
                : `${t("orientations.suggestDates")}`}
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};
