import { Divider, Skeleton, Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Icon from "../Icon";
import ClientProfileTabs from "./ClientProfileTabs";
import {
  approveClientProfileThunk,
  getClientByIdThunk,
} from "../../../redux/thunks/clients";
import NotificationAvatar from "../UserAvatar";
import ConfirmationModal from "../ConfirmationModal";
import { t } from "i18next";

type USER_PROFILE_TYPES = {
  clientId: number | null;
};

const ClientProfile = (props: USER_PROFILE_TYPES) => {
  const dispatch = useAppDispatch();
  const [verificationConfirmation, setVerificationConfirmation] =
    useState(false);
  const clientProfile = useAppSelector(
    (state) => state.clientsReducer.clientProfile
  );
  const clientProfileCompleteionPercentage = useAppSelector(
    (state) => state.clientsReducer.clientProfileCompletionPercentage
  );
  const isLoading = useAppSelector((state) => state.clientsReducer.loading);
  const { clientId } = props;

  const handleApproveProfile = () => {
    setVerificationConfirmation(true);
  };

  const handleYes = () => {
    dispatch(approveClientProfileThunk(clientProfile.id ?? 0));
    setVerificationConfirmation(false);
  };

  const handleNo = () => {
    setVerificationConfirmation(false);
  };

  useEffect(() => {
    if (clientId && Number(clientProfile.id) !== Number(clientId)) {
      dispatch(getClientByIdThunk(clientId));
    }
  }, [dispatch, clientId, clientProfile]);
  const name = useMemo(() => {
    return clientProfile.firstName && clientProfile.lastName
      ? `${clientProfile.firstName} ${clientProfile.lastName}`
      : "";
  }, [clientProfile.firstName, clientProfile.lastName]);
  return isLoading ? (
    <>
      <Skeleton active avatar paragraph={{ rows: 8 }} />
      <Divider />
      <div className="pl-14">
        <Skeleton active paragraph={{ rows: 8 }} />
      </div>
    </>
  ) : (
    <>
      <div className="px-2">
        <div className="flex flex-col justify-center items-center w-full pb-4">
          <div className="avatar" id="userprofile">
            <NotificationAvatar
              firstName={clientProfile.firstName}
              lastName={clientProfile.lastName}
              size={162}
              src={clientProfile.avatarUrl}
            />
          </div>
          <div className="mt-3 text-xl font-semibold flex items-center">
            <span>{name}</span>
            {clientProfile.isProfileVerified && (
              <Tooltip
                title="Verified Profile"
                placement="topLeft"
                zIndex={123}
              >
                <Icon
                  name="verified"
                  role="button"
                  fontSize={20}
                  className="my-1 blue-text font-semibold"
                />
              </Tooltip>
            )}
            {!clientProfile.isProfileVerified &&
              clientProfileCompleteionPercentage < 100 && (
                <Tooltip title="Pending documents to Approve">
                  <Icon
                    name="error"
                    role="button"
                    fontSize={20}
                    className="my-1 error-text font-semibold"
                  />
                </Tooltip>
              )}
            {!clientProfile.isProfileVerified &&
              clientProfileCompleteionPercentage === 100 && (
                <Tooltip title="Click to Approve">
                  <Icon
                    name="verified"
                    role="button"
                    fontSize={20}
                    className="my-1 error-text font-semibold"
                    onClick={() => handleApproveProfile()}
                  />
                </Tooltip>
              )}
          </div>
          <div className="flex justify-between items-center mt-1 text-lg text-grey">
            {clientProfile.jobCategory}
          </div>
          {clientProfile.licenseNumber && (
            <div className="flex justify-between items-center mt-1 text-sm">
              <Icon name="medical_information" fontSize={16} />
              <span className="ml-2">{clientProfile.licenseNumber}</span>
            </div>
          )}
          <div className="flex justify-between items-center mt-1 text-sm">
            <Icon name="phone" fontSize={16} />
            <span className="ml-2  blue-text font-normal">
              {clientProfile.phoneNumber}
            </span>
          </div>
          {clientProfile.email && (
            <div className="flex justify-between items-center mt-1 text-sm">
              <Icon name="mail" fontSize={16} />
              <span className="ml-2 blue-text font-normal">
                {clientProfile.email}
              </span>
            </div>
          )}
        </div>
        <ClientProfileTabs clientProfile={clientProfile} />
      </div>
      {verificationConfirmation && (
        <ConfirmationModal
          text={t("alertMessages.approveProfile")}
          open={verificationConfirmation}
          handleYes={handleYes}
          handleNo={handleNo}
        />
      )}
    </>
  );
};

export default ClientProfile;
