import { useEffect, useState } from "react";
import { Form, Select, Checkbox } from "antd";
import ConfirmationModal from "../../common/ConfirmationModal";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { DropDownOptionTypes } from "../../../models/types";
import { updateCurrentEmploymentThunk } from "../../../redux/thunks/clients";
import { useTranslation } from "react-i18next";

type EmploymentFormProps = {
  showForm: boolean;
  jobOptions?: DropDownOptionTypes[];
  fullTime: number[] | null;
  casual: number[] | null;
  hideForm: () => void;
};

const EmploymentForm = (props: EmploymentFormProps) => {
  const { showForm, hideForm, fullTime, casual, jobOptions } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const client = useAppSelector((state) => state.clientsReducer.clientProfile);
  const isUpdateInProgress = useAppSelector(
    (state) => state.clientsReducer.isUpdateInProgress
  );
  const [form] = Form.useForm();
  const [casualJobs, setCasualJobs] = useState<string[]>([]);
  const [fullTimeJobs, setFullTimeJobs] = useState<string[]>([]);
  const [notWorkingFullTimePartTime, setNotWorkingFullTimePartTime] =
    useState(false);
  const [notWorkingCasual, setNotWorkingCasual] = useState(false);

  useEffect(() => {
    if (fullTime) {
      const facilityIds = fullTime.map((item) => item.toString());
      setFullTimeJobs(facilityIds);
      setNotWorkingFullTimePartTime(facilityIds.length === 0);
      form.setFieldsValue({
        fullTimeEmployment: facilityIds,
        notWorkingFullTimePartTime: facilityIds.length === 0,
      });
    }
    if (casual) {
      const facilityIds = casual.map((item) => item.toString());
      setCasualJobs(facilityIds);
      setNotWorkingCasual(facilityIds.length === 0);
      form.setFieldsValue({
        casualEmployment: facilityIds,
        notWorkingCasual: facilityIds.length === 0,
      });
    }
  }, [fullTime, casual, form, jobOptions]);

  const handleCasualChange = (value: any) => {
    setCasualJobs(value);
  };

  const handleFullTimeChange = (value: any) => {
    setFullTimeJobs(value);
  };

  const handleCheckboxChange = (e: any) => {
    if (e.target.name === "notWorkingFullTimePartTime") {
      setNotWorkingFullTimePartTime(e.target.checked);
      if (e.target.checked) {
        form.setFieldsValue({ fullTimeEmployment: [] });
        setFullTimeJobs([]);
      }
    } else if (e.target.name === "notWorkingCasual") {
      setNotWorkingCasual(e.target.checked);
      if (e.target.checked) {
        form.setFieldsValue({ casualEmployment: [] });
        setCasualJobs([]);
      }
    }
  };

  const onFinish = (values: any) => {
    console.log("Form values:", values);
  };

  const handleValidation = () => {
    const fullTimeJobData = {
      userId: client?.id,
      facilityIds: [...casualJobs],
      capacity: "casual",
    };
    const casualJobData = {
      userId: client?.id,
      facilityIds: [...fullTimeJobs],
      capacity: "fullTime",
    };
    dispatch(
      updateCurrentEmploymentThunk({
        fullTimePayload: { ...fullTimeJobData },
        casualPayload: { ...casualJobData },
        callback: handleClose,
      })
    );
  };

  const handleClose = () => {
    hideForm();
  };

  return (
    <ConfirmationModal
      open={showForm}
      title="Update Employment"
      handleYes={handleValidation}
      handleNo={hideForm}
      primaryButtonText={t("common.save") || ""}
      secondaryButtonText={t("common.close") || ""}
      isPrimaryButtonDisabled={isUpdateInProgress}
      text={
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Is the user working full-time/part-time anywhere?"
            name="fullTimeEmployment"
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Select Full-time/Part-time facility"
              value={fullTimeJobs}
              onChange={handleFullTimeChange}
              options={jobOptions}
              disabled={notWorkingFullTimePartTime}
            />
          </Form.Item>
          <Form.Item name="notWorkingFullTimePartTime" valuePropName="checked">
            <Checkbox
              name="notWorkingFullTimePartTime"
              onChange={handleCheckboxChange}
              checked={notWorkingFullTimePartTime}
            >
              Not Listed
            </Checkbox>
          </Form.Item>
          <Form.Item
            label="Is the user working casual anywhere?"
            name="casualEmployment"
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Select casual facility"
              value={casualJobs}
              onChange={handleCasualChange}
              options={jobOptions}
              disabled={notWorkingCasual}
            />
          </Form.Item>
          <Form.Item name="notWorkingCasual" valuePropName="checked">
            <Checkbox
              name="notWorkingCasual"
              onChange={handleCheckboxChange}
              checked={notWorkingCasual}
            >
              Not Listed
            </Checkbox>
          </Form.Item>
        </Form>
      }
    />
  );
};

export default EmploymentForm;
