import { useEffect, useMemo, useState } from "react";
import { getShiftIntervals } from "../../helpers/helpers";
import { useAppDispatch } from "../../redux/hooks";
import { setShiftIntervalData } from "../../redux/reducers/appReducer";
import AutoCompleteInput from "./AutoCompleteInput";

type TimePickerPropTypes = {
  id: string;
  onChange: (id: string, value: string) => void;
  value: string;
  placeholder?: string;
  error: boolean;
  label: string;
  message?: string;
};

const TimePicker = (props: TimePickerPropTypes) => {
  const dispatch = useAppDispatch();
  const { id, onChange, value, placeholder, label, message } = props;
  const [time, setTime] = useState("");
  useEffect(() => {
    setTime(value);
  }, [value]);
  const items = useMemo(() => {
    const shiftIntervals = getShiftIntervals();
    dispatch(setShiftIntervalData(shiftIntervals));
    return shiftIntervals;
  }, [dispatch]);

  const handleSelect = (value: any, option: any) => {
    onChange(id, value);
  };
  const handleChange = (id: string, value: any) => {
    setTime(value);
    onChange(id, value);
  };
  return (
    <div>
      <AutoCompleteInput
        id={id}
        required
        label={label}
        placeholder={placeholder}
        value={time}
        onChange={handleChange}
        onSelect={handleSelect}
        options={items}
        message={message}
      />
    </div>
  );
};

export default TimePicker;
