import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getFacilitiesThunk } from "../../redux/thunks/facility";
import { getFacilityDetailsThunk } from "../../redux/thunks/settings";

const useUserRole = () => {
  const dispatch = useAppDispatch();
  const {
    currentUser: { roleId, facilityId, id: userId },
  } = useAppSelector((state) => state.appReducer);
  const roles = useAppSelector((state) => state.appReducer.roles);

  const [isConsultant, isFacilityAdmin, isStaffAdmin] = useMemo(() => {
    const consultant =
      roles.filter((item) => item.id === roleId)?.[0]?.title === "Consultant";
    const facilityAdmin =
      roles.filter((item) => item.id === roleId)?.[0]?.title ===
      "Facility Admin";
    const staffAdmin =
      roles.filter((item) => item.id === roleId)?.[0]?.title === "Staff Admin";
    return [consultant, facilityAdmin, staffAdmin];
  }, [roleId, roles]);

  useEffect(() => {
    fetchFacilityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsultant]);

  const fetchFacilityData = useCallback(() => {
    if (isConsultant) {
      dispatch(getFacilitiesThunk());
    } else {
      facilityId && dispatch(getFacilityDetailsThunk(facilityId));
    }
  }, [dispatch, facilityId, isConsultant]);

  const showResetPassword = useMemo(() => {
    return isConsultant || isFacilityAdmin;
  }, [isConsultant, isFacilityAdmin]);

  return {
    isConsultant,
    isFacilityAdmin,
    showResetPassword,
    isStaffAdmin,
    userId,
  };
};

export default useUserRole;
