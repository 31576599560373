import { Notification } from "../../../../models/types";
import NotificationText from "../NotificationText";
import NotificationAvatar from "../../../common/UserAvatar";
import Icon from "../../../common/Icon";

type Props = {
  notification: Notification;
  handleNotificationOpen: (id: number) => void;
};

const ProfileCompleted = (props: Props) => {
  const { notification, handleNotificationOpen } = props;
  const {
    clientFirstName,
    clientLastName,
    modifiedField,
    profileCompletionPercentage,
  } = notification;

  const Message = () => {
    return (
      <div>
        <b>{`${clientFirstName} ${clientLastName} `}</b> has{" "}
        <b>completed {modifiedField}</b> section. Now profile is
        <b> {profileCompletionPercentage}% </b> complete
      </div>
    );
  };
  return (
    <div onClick={() => handleNotificationOpen(notification.id)}>
      <div className="p-4 hover:bg-[#d9d9d9] flex items-start">
        <div className="mr-4">
          <NotificationAvatar
            type="icon"
            size={52}
            src={notification.clientAvatarUrl}
            icon={<Icon name="person_add" />}
          />
        </div>
        <NotificationText notification={notification}>
          <Message />
        </NotificationText>
      </div>
    </div>
  );
};

export default ProfileCompleted;
