import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type ToastType = {
  type: "error" | "success" | "info" | undefined;
  message: string;
  show: boolean;
  direction?: "left" | "right";
};

export interface FeedbackReducerState {
  toast: ToastType;
}
const initialState: FeedbackReducerState = {
  toast: {
    type: undefined,
    message: "",
    show: false,
    direction: "right",
  },
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<ToastType>) => {
      state.toast = action.payload;
    },
    hideToast: (state) => {
      state.toast = {
        type: undefined,
        message: "",
        show: false,
        direction: "left",
      };
    },
  },
});

export const { showToast, hideToast } = feedbackSlice.actions;

export default feedbackSlice.reducer;
