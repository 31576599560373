import { Popover } from "antd";
import { useAppSelector } from "../../redux/hooks";
import Icon from "../common/Icon";
import { PROFILE_SECTIONS } from "./constants";
import { isEmpty } from "lodash"

const ProfileProgressDetails = () => {
  const profileStatus = useAppSelector(
    (state) => state.clientsReducer.clientProfile.profileStatus
  );

  const content = (
    <div className="px-4">
      <ul>
        {PROFILE_SECTIONS.map((section) => {
          const isSectionVerified = profileStatus?.[section.key];
          if(isEmpty(profileStatus)){
            return <></>
          }
          return (
            <li key={section.key}>
              <p className="flex items-center gap-1">
                <span>{section.title}</span>
                <Icon
                  name={isSectionVerified ? "check_circle" : "cancel"}
                  fontSize={16}
                  className={
                    isSectionVerified ? "text-[#52c41a]" : "text-[#ff4d4f]"
                  }
                />
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <div>
      <Popover
        content={content}
        title={`${profileStatus?.completionPercentage}% completed`}
        trigger="hover"
      >
        <div role="button" className="flex justify-between items-center">
          <Icon
            name="info"
            fontSize={20}
            className={
              profileStatus?.completionPercentage < 100
                ? "text-[#ff4d4f]"
                : "text-[#52c41a]"
            }
          />
        </div>
      </Popover>
    </div>
  );
};

export default ProfileProgressDetails;
