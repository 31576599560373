import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { JobTypes } from "../../models/job.model";


export interface UpcomingJobsReducerState {
    UpcomingJobs: JobTypes[],
    loading: boolean;
}

const initialState: UpcomingJobsReducerState = {
    UpcomingJobs: [],
    loading: false
}

export const UpcomingJobsSlice = createSlice({
    name: "ServiceCharge",
    initialState,
    reducers: {
        setUpcomingJobs: (state, action: PayloadAction<JobTypes[]>) => {
            state.UpcomingJobs = action.payload || [];
        },
        setUpcomingJobsLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    }
});

export const {
    setUpcomingJobs,
    setUpcomingJobsLoading,
} = UpcomingJobsSlice.actions;

export default UpcomingJobsSlice.reducer;
