import { Spin } from "antd";
import { useEffect, useState } from "react";

type PaginationScrollProps = {
    children: any;
    prev?: () => Promise<void>;
    next?: () => Promise<void>;
    hasNext?: boolean;
    adjustScroll?: boolean;
}

const PaginationScroll = (props: PaginationScrollProps) => {
    const { prev, next, hasNext, children, adjustScroll } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [currentScrollTop, setCurrentScrollTop] = useState(0);

    useEffect(() => {
        if (adjustScroll) {
            // document.getElementById("scrollable-pagination-component")?.closest("main")?.scrollTo(0, (currentScrollTop));
            document.getElementById("scrollable-pagination-component")?.querySelector(".ant-table-body")?.scrollTo(0, (currentScrollTop));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adjustScroll]);

    const handleScroll = (e: any) => {
        if (!isLoading) {
            const top = window.innerHeight + e.target.scrollTop === document.documentElement.offsetHeight;
            const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) === (e.target.clientHeight);

            if (top && prev) {
                setIsLoading(true);
                setCurrentScrollTop(window.innerHeight + e.target.scrollTop + 25);
                prev().then(() => {
                    setIsLoading(false);
                });
            }
            else if (bottom && hasNext && next) {
                setIsLoading(true);
                setCurrentScrollTop(e.target.scrollTop - e.target.clientHeight - 210);
                next().then(() => {
                    setIsLoading(false);
                });
            }

            e.stopPropagation();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);

        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    });

    return (
        <>
            <div className="h-full" id="scrollable-pagination-component">
                <Spin className="h-full" spinning={isLoading} tip="Loading...">
                    <div className="h-full">{children}</div>
                </Spin>
            </div>
        </>
    )
}

export default PaginationScroll;