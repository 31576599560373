import Button from "../common/Button";
import { useAppDispatch } from "../../redux/hooks";
import { setSidePanel } from "../../redux/reducers/appReducer";
import { SIDE_PANEL_NAMES, SIDE_PANEL_TYPES } from "../../constants/sidePanel";

type Props = {
  disabled: boolean;
};

const ScheduleJob = (props: Props) => {
  const dispatch = useAppDispatch();

  const { disabled } = props;

  const handleClick = () => {
    dispatch(
      setSidePanel({
        name: SIDE_PANEL_NAMES.clients,
        status: true,
        type: SIDE_PANEL_TYPES.createJobForClient,
      })
    );
  };

  return (
    <Button
      id="approve_button"
      title="Schedule Job"
      onClick={handleClick}
      disabled={disabled}
      icon="event"
      variant="filled"
    />
  );
};

export default ScheduleJob;
