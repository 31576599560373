import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  DATE_FORMAT_PRIMARY,
  DATE_MMM_YEAR,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_SS,
  TIME_FORMAT_24_H,
} from "../constants/defaults";

dayjs.extend(utc);
dayjs.extend(timezone);

export const convertUtcTimeToLocalTime = (utcTime: string, tz: string) => {
  // Converts the UTC time zone to local time and returns time in HH:mm format
  let timeSplit = utcTime.split(":");
  let utcString = "";
  const today = dayjs().format(DATE_FORMAT_PRIMARY);
  if (timeSplit.length === 3) {
    utcString = `${today}T${utcTime}.000Z`;
  } else {
    utcString = `${today}T${utcTime}:00.000Z`;
  }
  return dayjs(utcString).tz(tz).format(TIME_FORMAT_24_H);
};

export const getCurrentUtcTime = () => {
  const currentUtcTime = dayjs().utc();
  const formattedUtcTime = currentUtcTime.format('YYYY-MM-DD HH:mm:ss');
  return formattedUtcTime;
};

export const convertLocalTimeToUtcTime = (time: string, tz: string) => {
  const today = dayjs().format(DATE_FORMAT_PRIMARY);
  let timeInTz: string | Dayjs = `${today} ${time}:00`;
  timeInTz = dayjs.tz(timeInTz, tz);
  const utcTime = timeInTz.utc().format("HH:mm");
  return utcTime;
};

export const convertLocalDateToUtcDate = (date: string, tz: string) => {
  // Converts the local time zone to UTC and returns time in HH:mm format
  let timeInTz: string | Dayjs = `${date} 00:00:00`;
  timeInTz = dayjs.tz(timeInTz, tz);
  return timeInTz.utc().format(DATE_FORMAT_PRIMARY);
};

export const getTodayInTimeZone = (tz: string) => {
  let today = dayjs();
  return dayjs.tz(today, tz);
};

export const getTimeStringInTimezone = (date: string, tz: string) => {
  return dayjs.tz(date, tz);
};
export const getUtcEodDateTime = (date: string) => {
  return dayjs(date).add(23, "h").add(59, "m").utc().format();
};

export const getDateFromUtcDate = (utcDateTimeString: string, tz: string) => {
  // Converts UTC date to the local time zone and return date in YYYY-MM-DD in format
  const localDateTime = dayjs(utcDateTimeString).tz(tz);
  return localDateTime.format(DATE_MMM_YEAR);
};

export const getDateFromUtcDateTime = (utcDateTimeString: string, tz: string) => {
  // Converts UTC date to the local time zone and return date in YYYY-MM-DD in format
  const localDateTime = dayjs(utcDateTimeString).tz(tz);
  return localDateTime.format(DATE_TIME_FORMAT_SS);
};

export const getDateTimeFromUTCDateTime = (
  utcDateTimeString: string,
  tz: string
) => {
  // Converts UTC date to the local time zone and return date in YYYY-MM-DD in format
  const localDateTime = dayjs(utcDateTimeString).tz(tz);
  return localDateTime.format(DATE_TIME_FORMAT);
};
export const getTimeFromUtcDate = (utcDateTimeString: string, tz: string) => {
  // Converts UTC date to the local time zone and return time in HH:mm in format
  const localDateTime = dayjs(utcDateTimeString).tz(tz);
  return localDateTime.format(TIME_FORMAT_24_H);
};

export const getBrowserTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
}

export const getTimeDiffFrom24HTime = (startTime: string, endTime: string) => {
  // Gives the time difference between two times
  const today = dayjs().format(DATE_FORMAT_PRIMARY);
  const startDate = dayjs(`${today} ${startTime}`);
  const endDate = dayjs(`${today} ${endTime}`);
  let diffInMin = endDate.diff(startDate, "m");
  if (diffInMin < 0) {
    diffInMin = 24 * 60 + diffInMin;
  }
  let h: string | number = Math.floor(diffInMin / 60);
  if (h < 10) {
    h = "0" + h;
  }
  let m: string | number = diffInMin % 60;
  if (m < 10) {
    m = "0" + m;
  }
  return `${h}:${m}`;
};

export const getShiftStartEndDateTimes = (
  startDate: string,
  startTime: string,
  endTime: string,
  inUtc: boolean,
  tz: string
) => {
  const startDateObj = dayjs.tz(startDate, tz);
  let date = startDateObj.format(DATE_FORMAT_PRIMARY);
  let startDateTime;
  let endDateTime;
  if (inUtc) {
    startDateTime = dayjs.tz(`${date} ${startTime}:00`, tz).utc().format();
  } else {
    startDateTime = dayjs
      .tz(`${date} ${startTime}:00`, tz)
      .format(DATE_TIME_FORMAT);
  }

  const isNextDay = startTime.split(":")[0] > endTime.split(":")[0];
  if (isNextDay) {
    const endDate = startDateObj.add(1, "day").format(DATE_FORMAT_PRIMARY);
    if (inUtc) {
      endDateTime = dayjs.tz(`${endDate} ${endTime}`, tz).utc().format();
    } else {
      endDateTime = dayjs
        .tz(`${endDate} ${endTime}`, tz)
        .format(DATE_TIME_FORMAT);
    }
  } else {
    if (inUtc) {
      endDateTime = dayjs.tz(`${date} ${endTime}`, tz).utc().format();
    } else {
      endDateTime = dayjs.tz(`${date} ${endTime}`, tz).format(DATE_TIME_FORMAT);
    }
  }

  return {
    startDateTime,
    endDateTime,
  };
};
