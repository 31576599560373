import { ReactNode } from "react";

const styles = {
  borderRadius: "12px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  border: "1px solid #DDDDDD",
};
type Props = {
  title?: ReactNode | string;
  children: ReactNode;
  containerClasses?: string;
  styles?: object;
};
export const Card = (props: Props) => {
  const { children, containerClasses, title } = props;
  return (
    <div
      style={{ ...styles, ...props.styles }}
      className={containerClasses ? containerClasses : "px-4 py-3 "}
    >
      {typeof title === "string" ? (
        <div className="font-bold mb-3">{title}</div>
      ) : (
        <div>{title}</div>
      )}
      <div>{children}</div>
    </div>
  );
};
