import JobCard from "./JobCard";
import { isEmpty } from "lodash";
import { JobTypes } from "../../../models/job.model";

type JOBS_STATUS_TYPES = {
  shiftFilter: string;
  jobs?: JobTypes[];
  isPreviousDay: boolean;
  handleEdit: (job: JobTypes) => void;
  handleNameClick: (id: number) => void;
  handleDeleteJob: (id: string, reason: string, userId: string) => void;
  handleMarkJobAsCompleted: (job: JobTypes) => void;
  handleUnAssignUser: (job: JobTypes) => void;
};
const JobsStatus = (props: JOBS_STATUS_TYPES) => {
  const {
    shiftFilter,
    isPreviousDay,
    handleEdit,
    handleNameClick,
    handleDeleteJob,
    handleMarkJobAsCompleted,
    handleUnAssignUser,
    jobs,
  } = props;

  return (
    <div>
      {jobs && !isEmpty(jobs) ? (
        jobs.map((job) => (
          <JobCard
            key={job.id}
            shift={shiftFilter}
            job={job}
            isPreviousDay={isPreviousDay}
            handleEdit={handleEdit}
            handleNameClick={handleNameClick}
            handleDeleteJob={handleDeleteJob}
            handleMarkJobAsCompleted={handleMarkJobAsCompleted}
            handleUnAssignUser={handleUnAssignUser}
          />
        ))
      ) : (
        <div>There are no matching jobs, try changing the filter</div>
      )}
    </div>
  );
};
export default JobsStatus;
