type Props = {
  className?: string;
  redDot?: boolean;
};

const UnreadDot = (props: Props) => {
  const { className = "", redDot } = props;
  return (
    <div className={className}>
      <div
        className={`w-[6px] h-[6px] rounded-full ${
          redDot ? "bg-[#ff4d4f]" : "bg-[#1677ff]"
        }`}
      />
    </div>
  );
};

export default UnreadDot;
