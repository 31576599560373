import { useState, useMemo } from "react";
import { useAppSelector } from "../../../redux/hooks";
import Icon from "../../common/Icon";

const AnnouncementBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const announcement = useAppSelector((state) => state.appReducer.announcement);
  const bannerStyle = useMemo(() => {
    return {
      opacity: showBanner ? 1 : 0,
      height: showBanner ? "36px" : "0",
      padding: showBanner ? "4px" : "0",
      overflow: showBanner ? "visible" : "hidden",
      transition:
        "opacity 0.5s ease-in-out, height 0.5s ease-in-out, padding 0.5s ease-in-out",
    };
  }, [showBanner]);
  return (
    announcement ? <div className="announcement-banner-container" style={bannerStyle}>
      <div className="announcement-banner">{announcement}</div>
      <Icon
        name="close"
        className="text-lg mr-2 font-bold"
        role="button"
        onClick={() => setShowBanner(false)}
      />
    </div> : <></>
  );
};

export default AnnouncementBanner;
