import { AxiosError } from "axios";
import { handleApiErrors, logToSentry } from "../../helpers/helpers";
import {
  setServiceCharge,
  setServiceChargeLoading,
} from "../reducers/serviceChargeReducer";
import { AppDispatch } from "../store";
import { showToast } from "../reducers/feedbackReducer";
import {
  fetchServiceCharge,
  fetchServiceChargeByFacility,
  updateServiceCharge,
  updateServiceChargeForFacility,
} from "../../services/service.charge.service";
import { ServiceCharge } from "../../models/types";

export const fetchServiceChargesThunk = () => async (dispatch: AppDispatch) => {
  dispatch(setServiceChargeLoading(true));
  try {
    const response = await fetchServiceCharge();
    dispatch(setServiceCharge(response.data.data ?? []));
  } catch (err) {
    handleApiErrors(err as AxiosError, dispatch);
    logToSentry(err, "ServiceCharge", "fetchServiceChargesThunk");
  } finally {
    dispatch(setServiceChargeLoading(false));
  }
};

export const updateServiceChargeThunk =
  (formData: ServiceCharge, callback?: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setServiceChargeLoading(true));
    try {
      await updateServiceCharge(formData);
      dispatch(fetchServiceChargesThunk());
      callback && callback();
      dispatch(
        showToast({
          type: "success",
          message: "ServiceCharge has been updated successfully!",
          show: true,
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      dispatch(setServiceChargeLoading(false));
      logToSentry(err, "ServiceCharge", "updateServiceChargeThunk");
    }
  };

export const fetchServiceChargesByFacilityThunk =
  (id: number) => async (dispatch: AppDispatch) => {
    dispatch(setServiceChargeLoading(true));
    try {
      const response = await fetchServiceChargeByFacility(id);
      dispatch(setServiceCharge(response.data.data ?? []));
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "ServiceCharge", "fetchServiceChargesByFacilityThunk");
    } finally {
      dispatch(setServiceChargeLoading(false));
    }
  };

export const updateServiceChargeForFacilityThunk =
  (facilityId: number, formData: ServiceCharge, callback?: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setServiceChargeLoading(true));
    try {
      await updateServiceChargeForFacility(facilityId, formData);
      dispatch(fetchServiceChargesByFacilityThunk(facilityId));
      callback && callback();
      dispatch(
        showToast({
          type: "success",
          message: "ServiceCharge for facility has been updated successfully!",
          show: true,
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "ServiceCharge", "updateServiceChargeForFacilityThunk");
    } finally {
      dispatch(setServiceChargeLoading(false));
    }
  };
