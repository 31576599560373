import { JobTypes } from "../../../models/job.model";
import { JobResponseType, MarkJobAsCompletedRequestType } from "../../../models/types";

export const COMPLETE_JOB_REQUEST_TEMPLATE: MarkJobAsCompletedRequestType = {
    jobId: "",
    userId: "",
    shiftStartDateTime: "",
    shiftEndDateTime: "",
    shiftStartTime: "",
    shiftEndTime: "",
};

export const JOB_RESPONSE_DATA_TEMPLATE: JobTypes = {
    id: "", // Default to an empty string
    uuid: "", // Default to an empty string
    title: "", // Default to an empty string
    description: "", // Default to an empty string or HTML content
    jobCategoryId: "", // Default to an empty string
    shiftStartDateTime: "", // Default to an empty string (ISO 8601 format)
    shiftStartTime: "", // Default to an empty string (ISO 8601 format)
    shiftEndDateTime: "", // Default to an empty string (ISO 8601 format)
    shiftEndTime: "", // Default to an empty string (ISO 8601 format)
    shiftDate: "", // Default to an empty string (ISO 8601 format)
    shiftId: "", // Default to an empty string
    totalHours: "", // Default to an empty string
    isOrientation: false, // Default to false
    hourlyRate: "", // Default to an empty string (e.g., "$35.00")
    facilityId: "", // Default to an empty string
    contactName: "", // Default to an empty string
    contactEmail: "", // Default to an empty string
    contactMobile: "", // Default to an empty string (e.g., "+1 902 865 6364")
    jobStatus: "", // Default to an empty string (e.g., "scheduled")
    shiftTitle: "", // Default to an empty string (e.g., "Night")
    jobCategoryTitle: "", // Default to an empty string
    jobCategoryDescription: "", // Default to an empty string or HTML content
    jobCategoryHourlyRate: "", // Default to an empty string (e.g., "$45.00")
    clientFirstName: "", // Default to an empty string
    clientMiddleName: "", // Default to an empty string
    clientLastName: "", // Default to an empty string
    clientEmail: "", // Default to an empty string
    clientPhoneNumber: "", // Default to an empty string (e.g., "+16479168379")
    clientId: "", // Default to an empty string
    avatarUrl: "" // Default to an empty string or a placeholder image URL
};