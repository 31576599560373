import { Notification } from "../../../../models/types";
import NotificationText from "../NotificationText";
import NotificationAvatar from "../../../common/UserAvatar";
import Icon from "../../../common/Icon";

type Props = {
  notification: Notification;
  handleNotificationOpen: (id: number) => void;
  icon?: string;
};
const ENTITIES = {
  Job: "work",
  Payment: "payments",
  Orientation: "work",
  Profile: "person_add",
};

const GenericNotification = (props: Props) => {
  const {
    notification,
    handleNotificationOpen,
    icon = ENTITIES[notification.entity],
  } = props;
  return (
    <div onClick={() => handleNotificationOpen(notification.id)}>
      <div className="p-4 hover:bg-[#d9d9d9] flex items-start">
        <div className="mr-4">
          <NotificationAvatar
            type="icon"
            size={52}
            src={notification.clientAvatarUrl || notification.facilityLogo}
            icon={<Icon name={icon} />}
          />
        </div>
        <NotificationText notification={notification} />
      </div>
    </div>
  );
};

export default GenericNotification;
