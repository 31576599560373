import { PostJobTypes } from "../../models/types";

export const NEW_JOB_DATA_TEMPLATE: PostJobTypes = {
  jobCategoryId: "",
  positions: 1,
  contactEmail: "",
  contactName: "",
  contactMobile: "",
  shiftEndDateTime: "",
  shiftStartDateTime: "",
  shiftId: "",
  shiftDate: "",
};

export const NEW_JOB_ERRORS_TEMPLATE = {
  jobCategoryId: "",
  positions: "",
  shiftId: "",
  shiftDate: "",
  contactName: "",
  contactMobile: "",
  contactEmail: "",
};
