import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import InvoiceScheduleTable from "./InvoiceScheduleTable";
import { getFailedJobsThunk, retryClientInvoiceGenerationThunk, retryFacilityInvoiceGenerationThunk } from "../../redux/thunks/invoices";

function InvoiceSchedule() {
    const dispatch = useAppDispatch();

    const failedJobs = useAppSelector(
        (state) => state.invoiceReducer.failedJobs
    );


    useEffect(() => {
        // Dispatching action to load the requests when page renders for the first time
        dispatch(getFailedJobsThunk());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retryClientinvoiceGeneration = useCallback((id: number) => {
        dispatch(retryClientInvoiceGenerationThunk(id)).then(() => {
            dispatch(getFailedJobsThunk());
        });
    }, [dispatch]);

    const retryFacilityInvoiceGeneration = useCallback((id: number) => {
        dispatch(retryFacilityInvoiceGenerationThunk(id)).then(() => {
            dispatch(getFailedJobsThunk());
        });
    }, [dispatch]);

    return (
        <div className="h-full bg-white flex">
            <div className="mr-3 w-full">
                <InvoiceScheduleTable
                    data={failedJobs}
                    retryClientInvoice={retryClientinvoiceGeneration}
                    retryFacilityInvoice={retryFacilityInvoiceGeneration}
                />
            </div>
        </div>
    )
}

export default InvoiceSchedule;