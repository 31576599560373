import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import Button from "./Button";

type ConfirmationModalTypes = {
  open: boolean;
  title?: string;
  handleYes: () => void;
  handleNo: () => void;
  text: string | React.ReactNode;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  isPrimaryButtonDanger?: boolean;
  isSecondaryButtonDanger?: boolean;
  isPrimaryButtonDisabled?: boolean;
  isSecondaryButtonDisabled?: boolean;
  hideSecondaryButton?: boolean;
  hidePrimaryButton?: boolean;
  isLoading?: boolean;
};

const ConfirmationModal = (props: ConfirmationModalTypes) => {
  const { t } = useTranslation();
  const {
    open,
    title,
    handleNo,
    handleYes,
    text,
    primaryButtonText = t("common.yes"),
    secondaryButtonText = t("common.no"),
    isPrimaryButtonDisabled,
    isSecondaryButtonDisabled,
    hideSecondaryButton = false,
    hidePrimaryButton = false,
    isLoading,
  } = props;
  return (
    <Modal
      open={open}
      title={title}
      onOk={handleYes}
      onCancel={handleNo}
      maskClosable={false}
      footer={
        <div className="flex gap-3 justify-center mt-6">
          {!hidePrimaryButton && (
            <Button
              key="yes"
              isLoading={isLoading}
              id="yes-btn"
              variant="filled"
              onClick={handleYes}
              disabled={isPrimaryButtonDisabled}
              title={primaryButtonText || ""}
            />
          )}
          {!hideSecondaryButton && (
            <Button
              key="no"
              variant="outline"
              onClick={handleNo}
              id="no-btn"
              disabled={isSecondaryButtonDisabled}
              title={secondaryButtonText || ""}
            />
          )}
        </div>
      }
    >
      {text}
    </Modal>
  );
};

export default ConfirmationModal;
