import { useState } from "react";
import { Checkbox } from "antd";
import InputField from "../../common/InputField";
import ConfirmationModal from "../../common/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateWorkHistoryThunk } from "../../../redux/thunks/clients";
import dayjs from "dayjs";
import { DATE_FORMAT_PRIMARY } from "../../../constants/defaults";
import { WorkHistoryTypes } from "../../../models/types";
import Dropdown from "../../common/Dropdown";
import Datepicker from "../../common/Datepicker";

type WorkHistoryFormProps = {
  workHistory: WorkHistoryTypes;
  showForm: boolean;
  hideForm: () => void;
};

const WorkHistoryForm = (props: WorkHistoryFormProps) => {
  const { showForm, hideForm, workHistory } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clientProfile = useAppSelector(
    (state) => state.clientsReducer.clientProfile
  );

  const [formState, setFormState] = useState<WorkHistoryTypes>(workHistory);
  const isUpdateInProgress = useAppSelector(
    (state) => state.clientsReducer.isUpdateInProgress
  );
  const [errors, setErrors] = useState<any>({
    employer: "",
    sector: "",
    title: "",
    capacity: "",
    startDate: "",
    endDate: "",
  });

  const handleChange = (id: string, value: any) => {
    if (id === "isPresent" && value === true) {
      formState.endDate = null;
    }
    setFormState({ ...formState, [id]: value });

    if (errors[id]) {
      setErrors({ ...errors, [id]: "" });
    }
  };

  const handleValidation = () => {
    let valid = true;
    const errorsCopy: any = { ...errors };

    if (!formState?.employer.trim()) {
      valid = false;
      errorsCopy.employer = t("errors.employerNameRequired");
    }

    if (!formState?.sector.trim()) {
      valid = false;
      errorsCopy.sector = t("errors.sectorRequired");
    }

    if (!formState?.title.trim()) {
      valid = false;
      errorsCopy.title = t("errors.titleRequired");
    }

    if (!formState?.capacity) {
      valid = false;
      errorsCopy.capacity = t("errors.capacityRequired");
    }

    if (!formState?.startDate) {
      valid = false;
      errorsCopy.startDate = t("errors.startDateRequired");
    }

    if (!formState?.isPresent && !formState?.endDate) {
      valid = false;
      errorsCopy.endDate = t("errors.endDateRequired");
    }

    setErrors(errorsCopy);

    if (valid) {
      handleSave();
    }
  };

  const handleSave = () => {
    if (formState?.isPresent) {
      formState.endDate = null;
    }
    const data = {
      ...formState,
      userId: clientProfile?.id,
      isWorkHistoryCompletion: true,
    };
    dispatch(
      updateWorkHistoryThunk({ payload: { ...data }, callback: handleClose })
    );
  };

  const handleClose = () => {
    // Reset form and errors after saving
    setFormState({
      id: "",
      employer: "",
      sector: "",
      title: "",
      capacity: "",
      startDate: "",
      endDate: "",
      isPresent: false,
      isWorkHistoryCompletion: false,
    });

    setErrors({
      employer: "",
      sector: "",
      title: "",
      capacity: "",
      startDate: "",
      endDate: "",
    });

    hideForm(); // Close the form after saving
  };

  return (
    <ConfirmationModal
      open={showForm}
      title={`${t("clientProfile.updateWorkHistory")}`}
      handleYes={handleValidation}
      handleNo={hideForm}
      primaryButtonText={t("common.save") || ""}
      secondaryButtonText={t("common.close") || ""}
      isPrimaryButtonDisabled={isUpdateInProgress}
      text={
        <div className="pt-4">
          <InputField
            id="employer"
            type="text"
            label="Employer Name *"
            value={formState?.employer}
            onChange={handleChange}
            message={errors?.employer}
            status={errors?.employer ? "error" : ""}
          />
          <InputField
            id="sector"
            type="text"
            label="Sector *"
            value={formState?.sector}
            onChange={handleChange}
            message={errors?.sector}
            status={errors?.sector ? "error" : ""}
          />
          <InputField
            id="title"
            type="text"
            label="Title *"
            value={formState?.title}
            onChange={handleChange}
            message={errors?.title}
            status={errors?.title ? "error" : ""}
          />
          <Dropdown
            id="capacity"
            label="Capacity *"
            options={[
              { value: "Full-Time", label: "Full-Time" },
              { value: "Part-Time", label: "Part-Time" },
            ]}
            value={formState?.capacity}
            onChange={handleChange}
            message={errors?.capacity}
            status={errors?.capacity ? "error" : ""}
          />
          <Datepicker
            id="startDate"
            value={
              formState?.startDate ? dayjs(formState?.startDate) : undefined
            }
            onChange={handleChange}
            label={t("clientProfile.startDate") || ""}
            required={true}
            message={errors?.startDate}
            placeholder={DATE_FORMAT_PRIMARY}
            onlyFutureDate={false}
            status={errors?.startDate ? "error" : ""}
          />
          <div className={`flex flex-col mx-2 h[80px] w-full`}>
            <Checkbox
              id="isPresent"
              checked={formState?.isPresent}
              className="mb-3"
              onChange={(e: any) => handleChange("isPresent", e.target.checked)}
            >
              I currently work here
            </Checkbox>
          </div>
          <Datepicker
            id="endDate"
            value={formState?.endDate ? dayjs(formState?.endDate) : undefined}
            onChange={handleChange}
            label={t("clientProfile.endDate") || ""}
            required={true}
            message={errors?.endDate}
            placeholder={DATE_FORMAT_PRIMARY}
            onlyFutureDate={false}
            disabled={formState?.isPresent}
            status={errors?.endDate ? "error" : ""}
          />
        </div>
      }
    />
  );
};

export default WorkHistoryForm;
