import { useState, useMemo, useEffect } from "react";
import { Skeleton } from "antd";
import JobsStatus from "./JobsStatus";
import { cloneDeep, isEmpty } from "lodash";
import { useAppSelector } from "../../../redux/hooks";
import { JobTypes } from "../../../models/job.model";
import {
  getTimeStringInTimezone,
  getTodayInTimeZone,
} from "../../../helpers/dateTime.helper";
import useFacilityTimezone from "../../hooks/useFacilityTimezone";
import Dropdown from "../../common/Dropdown";
import CheckboxFilter from "../../common/CheckboxFilter";

type SUMMARY_TYPES = {
  handleEdit: (job: JobTypes) => void;
  handleNameClick: (id: number) => void;
  handleDeleteJob: (id: string, reason: string, userId: string) => void;
  handleMarkJobAsCompleted: (job: JobTypes) => void;
  handleUnAssignUser: (job: JobTypes) => void;
  selectedDay: string;
};
const DEFAULT_FILTER_STATE = [
  "open",
  "progress",
  "scheduled",
  "completed",
  "approved",
];
const JOB_STATUS_ORDER: { [key: string]: number } = {
  open: 1,
  progress: 2,
  scheduled: 3,
  completed: 4,
  approved: 5,
};

const Summary = (props: SUMMARY_TYPES) => {
  const { handleEdit, handleNameClick, handleDeleteJob, handleMarkJobAsCompleted, selectedDay, handleUnAssignUser } = props;
  const postedJobs = useAppSelector(
    (state) => state.shiftScheduleReducer.jobsForSelectedDay
  );
  const isLoading = useAppSelector(
    (state) => state.shiftScheduleReducer.loading
  );

  const { timeZoneId } = useFacilityTimezone();
  const shiftsDataFromState = useAppSelector(
    (state) => state.settingsReducer.shifts
  );
  const [shiftFilter, setShiftFilter] = useState<string>("all");
  const [jobStatusFilter, setJobStatusFilter] = useState<any[]>(
    cloneDeep(DEFAULT_FILTER_STATE)
  );
  const isPreviousDay = useMemo(() => {
    return (
      getTimeStringInTimezone(selectedDay, timeZoneId) <
      getTodayInTimeZone(timeZoneId).add(-1, "day")
    );
  }, [selectedDay, timeZoneId]);

  const shiftWiseJobs = useMemo(() => {
    let filteredData = cloneDeep(postedJobs);
    if (shiftFilter !== "all") {
      filteredData = filteredData?.filter(
        (job) => Number(job.shiftId) === Number(shiftFilter)
      );
    }
    return filteredData;
  }, [postedJobs, shiftFilter]);

  useEffect(() => {
    setJobStatusFilter(cloneDeep(DEFAULT_FILTER_STATE));
    setShiftFilter("all");
  }, [selectedDay]);

  const filteredJobs = useMemo(() => {
    const filteredData = shiftWiseJobs?.filter((job) =>
      jobStatusFilter.includes(job.jobStatus)
    );
    filteredData?.sort((a, b) => {
      return JOB_STATUS_ORDER[a.jobStatus] < JOB_STATUS_ORDER[b.jobStatus]
        ? -1
        : 1;
    });
    return filteredData;
  }, [shiftWiseJobs, jobStatusFilter]);

  const TABS_DATA = useMemo(() => {
    const shifts = [{ id: "all", title: "All" }];
    shifts.push(...shiftsDataFromState);
    return shifts.map((shift) => ({
      label: shift.title,
      value: shift.id,
    }));
  }, [shiftsDataFromState]);

  const JOB_STATUS_FILTER = useMemo(() => {
    return [
      { id: "open", label: isPreviousDay ? "Unfilled" : "Open" },
      { id: "progress", label: "Progress" },
      { id: "scheduled", label: "Scheduled" },
      { id: "completed", label: "Completed" },
      { id: "approved", label: "Approved" },
    ];
  }, [isPreviousDay]);

  if (isLoading) {
    return <Skeleton active paragraph={{ rows: 4 }} />;
  }

  return (
    <>
      <div className="flex items-center gap-6">
        <div className="w-[90%] flex-grow-1">
          <Dropdown
            label="Shift"
            id="Shift"
            className="w-full"
            options={TABS_DATA}
            onChange={(id, value) => {
              setShiftFilter(value);
            }}
            value={shiftFilter}
          />
        </div>
        <div className="w-[10%]">
          <CheckboxFilter
            filterItems={JOB_STATUS_FILTER}
            onFilter={setJobStatusFilter}
          />
        </div>
      </div>

      {isEmpty(shiftWiseJobs) ? (
        <div>There are no jobs scheduled in this shift yet.</div>
      ) : (
        <JobsStatus
          shiftFilter={shiftFilter}
          jobs={filteredJobs}
          isPreviousDay={isPreviousDay}
          handleEdit={handleEdit}
          handleNameClick={handleNameClick}
          handleDeleteJob={handleDeleteJob}
          handleMarkJobAsCompleted={handleMarkJobAsCompleted}
          handleUnAssignUser={handleUnAssignUser}
        />
      )}
    </>
  );
};

export default Summary;
