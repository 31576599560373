import React, { useEffect, useMemo, useState } from "react";
import Icon from "../../common/Icon";
import { Dropdown, MenuProps } from "antd";
import "./notifications.css";
import Notification from "./Notification";
import UnreadDot from "./UnreadDot";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getNotificationsThunk,
  markNotificationAsReadThunk,
} from "../../../redux/thunks/notifications";
import useUserRole from "../../hooks/useUserRole";
import { getFacilityDetailsThunk } from "../../../redux/thunks/settings";

const Notifications = () => {
  const menuStyle = {
    boxShadow: "none",
  };
  const dispatch = useAppDispatch();
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const userId = useAppSelector((state) => state.appReducer.currentUser.id);
  const { isConsultant } = useUserRole();
  const notifications = useAppSelector(
    (state) => state.notificationsReducer.notifications
  );

  const newNotificationExists = useMemo(() => {
    return notifications.some((n) => n.notificationStatus !== "read");
  }, [notifications]);

  useEffect(() => {
    let intervalId: any;
    if (userId) {
      dispatch(getNotificationsThunk(userId));
      intervalId = setInterval(() => {
        dispatch(getNotificationsThunk(userId));
      }, 60 * 1000);
      setTimerId(intervalId);
    }

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch, userId]);

  const handleNotificationOpen = (id: number) => {
    clearInterval(timerId!);
    const newIntervalId = setInterval(() => {
      dispatch(getNotificationsThunk(userId));
    }, 60 * 1000);
    setTimerId(newIntervalId);
    dispatch(markNotificationAsReadThunk(id));

    //change facility selection for consultant
    if (isConsultant) {
      const notification: any = notifications.find((notification) => {
        return notification.id === id;
      });
      if (notification && notification?.facilityId) {
        dispatch(getFacilityDetailsThunk(Number(notification.facilityId)));
      }
    }
  };
  const items: MenuProps["items"] = notifications.map((item) => ({
    key: item.id,
    label: (
      <Notification
        notification={item}
        handleNotificationOpen={handleNotificationOpen}
      />
    ),
  }));

  const contentStyle = {
    backgroundColor: "#fff",
    borderRadius: "8px",
    zIndex: 10,
    boxShadow:
      "0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2)",
  };
  return (
    <Dropdown
      overlayClassName="notifications-root-container"
      menu={{ items }}
      trigger={["click"]}
      dropdownRender={(menu) => (
        <div style={contentStyle} className="notification-container">
          <div className="title">Notifications</div>
          {React.cloneElement(menu as React.ReactElement, {
            style: menuStyle,
          })}
        </div>
      )}
    >
      <div
        className="mr-2 hover:bg-[#d9d9d9] rounded-full p-2 items-center flex relative"
        role="button"
      >
        <Icon name="notifications" />
        {newNotificationExists && (
          <UnreadDot className="absolute right-2.5 top-2" />
        )}
      </div>
    </Dropdown>
  );
};

export default Notifications;
