import { useAppDispatch } from "../../redux/hooks";
import { resetAppState } from "../../redux/reducers/appReducer";
import { resetClientState } from "../../redux/reducers/clientsReducer";
import { resetFacilitiesState } from "../../redux/reducers/facilitiesReducer";
import { resetInvoicesState } from "../../redux/reducers/invoices";
import { resetJobCategorySate } from "../../redux/reducers/jobCategoryReducer";
import { resetNotificationsState } from "../../redux/reducers/notificationsReducer";
import { resetOrientationState } from "../../redux/reducers/orientationReducer";
import { resetSettingsState } from "../../redux/reducers/settingsReducer";
import { resetShiftApprovalState } from "../../redux/reducers/shiftApprovalReducer";
import { resetShiftScheduleState } from "../../redux/reducers/shiftScheduleReducer";

const useResetState = () => {
  const dispatch = useAppDispatch();
  const resetState = () => {
    dispatch(resetAppState());
    dispatch(resetClientState());
    dispatch(resetFacilitiesState());
    dispatch(resetInvoicesState());
    dispatch(resetJobCategorySate());
    dispatch(resetNotificationsState());
    dispatch(resetOrientationState());
    dispatch(resetSettingsState());
    dispatch(resetShiftApprovalState());
    dispatch(resetShiftScheduleState());
  };
  return { resetState };
};

export default useResetState;
