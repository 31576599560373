import { useEffect, useState } from "react";
import { ClientInvoiceTypes, DropDownOptionTypes, InvoiceCycle } from "../../models/types";
import { Table, Space, Tag, Segmented, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import ActionButtons from "./ActionButtons";
import Dropdown from "../common/Dropdown";
import SectionName from "../common/SectionName";
import useFacilityTimezone from "../hooks/useFacilityTimezone";
import {
  getDateFromUtcDate,
  getDateFromUtcDateTime,
} from "../../helpers/dateTime.helper";
import { ROUTE } from "../../constants/routes";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../redux/hooks";

type INVOICE_TABLE_TYPES = {
  data: ClientInvoiceTypes[];
  handleFilters: (key: "segmented", value: string) => void;
};
const ClientInvoiceTable = (props: INVOICE_TABLE_TYPES) => {
  const { t } = useTranslation();
  const { data, handleFilters } = props;
  const [cycles, setCycles] = useState<DropDownOptionTypes[]>([]);
  const invoiceCycles = useAppSelector((state) => state.invoiceReducer.clientInvoiceCycles);
  const [filter, setFilter] = useState<"Due" | "Paid" | "No Due" | "All">(
    "Due"
  );
  const { timeZoneId } = useFacilityTimezone();
  const navigate = useNavigate();
  const [selectedInvoices, setSelectedInvoices] = useState<
    ClientInvoiceTypes[]
  >([]);
  const [cycle, setCycle] = useState<any>();
  const [selectedCycle, setSelectedCycle] = useState<any>({});
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  const resetRowSelection = () => {
    setSelectedKeys([]);
    setSelectedInvoices([]);
  };
  const handleFilter = (value: string | number) => {
    if (value === "Due" || value === "Paid" || value === "All")
      setFilter(value);
    resetRowSelection();
    handleFilters("segmented", value.toString().toLocaleLowerCase());
  };

  const handleCycleSelection = (value: any) => {
    const selectedItem = invoiceCycles.find((c: InvoiceCycle) => c.cycle === value);
    setCycle(selectedItem);
    setSelectedCycle(value);
  };

  useEffect(() => {
    let cyclesList: DropDownOptionTypes[] = [];
    invoiceCycles && invoiceCycles.map((c: InvoiceCycle) => {
      cyclesList.push({ label: c.cycle, value: c.cycle });
    });
    setCycles(cyclesList);
  }, [invoiceCycles]);

  let columns = [
    {
      title: t("clientInvoice.invoice"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: "20%",
    },
    {
      title: t("clientInvoice.client"),
      width: "20%",
      render: (
        _: any,
        {
          firstName,
          lastName,
          userid: userId,
          userDeletedOn,
        }: {
          firstName: string;
          lastName: string;
          userid: number;
          userDeletedOn: boolean;
        }
      ) => {
        if (userDeletedOn) {
          return (
            <Tooltip title="The user account has been deleted.">
              <div>{`${firstName} ${lastName}`}</div>
            </Tooltip>
          );
        }
        return (
          <div
            className="link-text"
            onClick={() => navigate(`${ROUTE.clients}/${userId}`)}
          >
            {`${firstName} ${lastName}`}
          </div>
        );
      },
    },
    {
      title: t("invoice.startDate"),
      width: "15%",
      render: (_: any, { startDate }: { startDate: string }) => {
        return getDateFromUtcDate(startDate, timeZoneId);
      },
    },
    {
      title: t("invoice.endDate"),
      width: "15%",
      render: (_: any, { endDate }: { endDate: string }) => {
        return getDateFromUtcDate(endDate, timeZoneId);
      },
    },
    {
      title: t("invoice.generatedDate"),
      width: "15%",
      render: (_: any, { createdOn }: { createdOn: string }) => {
        return getDateFromUtcDateTime(createdOn, timeZoneId);
      },
    },
    {
      title: t("invoice.hours"),
      dataIndex: "totalHours",
      width: "15%",
      key: "amount",
      render: (_: any, { totalHours }: { totalHours: string }) => {
        return totalHours ? `${totalHours}` : "$0";
      },
    },
    {
      title: t("invoice.amount"),
      dataIndex: "amount",
      width: "15%",
      key: "amount",
      render: (_: any, { amount }: { amount: string }) => {
        return amount ? `${amount}` : "$0";
      },
    },
    {
      title: t("clientInvoice.status"),
      width: "15%",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (
        _: any,
        { paymentStatus }: { paymentStatus: "paid" | "due" | "no due" }
      ) => {
        return (
          <div className="flex w-full ">
            <Tag
              color={
                paymentStatus === "paid"
                  ? "green"
                  : paymentStatus === "due"
                    ? "volcano"
                    : "default"
              }
              className="flex justify-between items-center px-4"
            >
              {paymentStatus === "paid"
                ? t("clientInvoice.paid")
                : paymentStatus === "due"
                  ? t("clientInvoice.due")
                  : t("clientInvoice.noDue")}
            </Tag>
          </div>
        );
      },
    },
  ];
  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: ClientInvoiceTypes[]
    ) => {
      setSelectedKeys(selectedRowKeys);
      setSelectedInvoices(selectedRows);
    },
  };
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div className="flex justify-between items-center">
        <SectionName />
        <div className="flex items-center">
          <Dropdown
            size="small"
            id="calender-view"
            value={selectedCycle}
            onChange={(id, value) => handleCycleSelection(value)}
            options={cycles}
            selectClassName="mt-2"
            className="mt-2"
          />
          <ActionButtons
            selectedCycle={cycle}
            selectedInvoices={selectedInvoices}
            resetRowSelection={resetRowSelection}
          />
          <Segmented
            options={[
              t("invoice.due") || "Due",
              t("invoice.paid") || "Paid",
              t("common.all") || "All",
            ]}
            value={filter}
            onChange={handleFilter}
            className=""
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        sticky
        rowClassName="orientation-table-row"
        size="small"
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        scroll={{ y: window.innerHeight - 200 }}
      />
    </Space>
  );
};

export default ClientInvoiceTable;
