import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getFacilitiesInvoicesOnScrollThunk, getFacilitiesInvoicesThunk } from "../../redux/thunks/invoices";
import InvoiceTable from "./InvoiceTable";
import { PAGINATION } from "../../constants/pagination";
import PaginationScroll from "../common/PaginationScroll";
import { removeFirstFacilityInvoices, removeLastFacilityInvoices } from "../../redux/reducers/invoices";

const ServiceInvoice = () => {
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<string>('due');
  const [pageNumber, setPageNumber] = useState(PAGINATION.defaultPageNumber);
  const [adjustScroll, setAdjustScroll] = useState(false);

  const invoices = useAppSelector(
    (state) => state.invoiceReducer.facilityInvoices
  );
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id?.toString() || ""
  );
  const hasNextPage = useAppSelector(
    (state) => state.invoiceReducer.hasNextPage
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    // Dispatching action to load the requests when page renders for the first time
    if (facilityId) {
      dispatch(
        getFacilitiesInvoicesThunk(
          Number(facilityId),
          PAGINATION.defaultPageSize,
          PAGINATION.defaultPageNumber,
          selectedPaymentStatus
        )
      );
    }
  }, [dispatch, facilityId, selectedPaymentStatus]);
  const handleFilters = (key: "segmented", value: string) => {
    // Segmented Filter
    if (
      key === "segmented" &&
      (value === "due" || value === "paid" || value === "all")
    ) {
      dispatch(getFacilitiesInvoicesThunk(Number(facilityId), PAGINATION.defaultPageSize, PAGINATION.defaultPageNumber, value));
      setSelectedPaymentStatus(value);
    }
  };

  const prev = async () => {
    if (pageNumber > 4) {
      setAdjustScroll(false);

      await dispatch(
        getFacilitiesInvoicesOnScrollThunk(Number(facilityId), PAGINATION.defaultPageSize, pageNumber - 4, selectedPaymentStatus, true)
      );

      setPageNumber(pageNumber - 1);

      dispatch(removeLastFacilityInvoices());
      setAdjustScroll(true);
    }
  }

  const next = async () => {
    setAdjustScroll(false);

    await dispatch(
      getFacilitiesInvoicesOnScrollThunk(Number(facilityId), PAGINATION.defaultPageSize, pageNumber + 1, selectedPaymentStatus)
    );

    setPageNumber(pageNumber + 1);

    if (pageNumber > 3) {
      dispatch(removeFirstFacilityInvoices());
      setAdjustScroll(true);
    }
  }

  return (
    <div>
      <PaginationScroll next={next} prev={prev} hasNext={hasNextPage} adjustScroll={adjustScroll}>
        <InvoiceTable data={invoices} handleFilters={handleFilters} />
      </PaginationScroll>
    </div>
  );
};

export default ServiceInvoice;
