import { useState } from "react";
import { Card } from "../common/Card";
import { WorkHistoryTypes } from "../../models/types";
import Icon from "../common/Icon";
import { Divider } from "antd";
import dayjs from "dayjs";
import { YYYY_MMM } from "../../constants/defaults";
import WorkHistoryForm from "./edit/WorkHistory";
import useUserRole from "../hooks/useUserRole";
import Tooltip from "../common/ToolTip";
import { useAppSelector } from "../../redux/hooks"

type Props = {
  workHistory: WorkHistoryTypes[];
};

const NEW_WORK_HISTORY: WorkHistoryTypes = {
  id: "",
  employer: "",
  sector: "",
  title: "",
  capacity: "",
  startDate: "",
  endDate: "",
  isPresent: false,
  isWorkHistoryCompletion: false,
};

const WorkHistory = (props: Props) => {
  const { workHistory } = props;
  const [showUpdateWorkHistory, setShowEditWorkHistory] = useState(false);
  const [whData, setWHData] = useState(NEW_WORK_HISTORY);
  const { isConsultant } = useUserRole();
  const isWorkHistoryCompleted = useAppSelector(
    (state) =>
      state.clientsReducer.clientProfile.profileStatus?.isWorkHistoryCompletion
  );
  const onEdit = (index: number) => {
    setWHData(workHistory[index]);
    setShowEditWorkHistory(true);
  }
  const onAdd = (e: any) => {
    setWHData(NEW_WORK_HISTORY);
    setShowEditWorkHistory(true);
  }

  return (
    <Card
      title={
        <div className="flex justify-between items-center mb-2">
          <span className="font-bold">Work History</span>
          {isConsultant && !isWorkHistoryCompleted && (
            <Tooltip title="Add Work History">
              <div
                className="flex items-center gap-1 text-[#FBB360]"
                role="button"
              >
                <Icon name="warning" fontSize={16} className="font-semibold" />
                <span className="text-xs">Action needed</span>
              </div>
            </Tooltip>
          )}
          {isConsultant && (
            <span className="bg-[#1677ff] text-white p-1 flex justify-center items-center rounded-[50%] hover:bg-[#1890ff]">
              <Icon role="button" name="add" fontSize={18} onClick={onAdd} />
            </span>
          )}
        </div>
      }
    >
      {workHistory?.map((work, idx) => (
        <div key={idx}>
          {idx > 0 && <Divider className="my-3" />}
          <div className="flex">
            <div className="icon-wrapper">
              <Icon name="work_history" fontSize={18} className="font-bold" />
            </div>
            <div className="flex flex-col">
              <span className="ml-2 font-semibold ">{work.title}</span>
              <span className="ml-2 text-grey ">
                {work.employer} - {work.sector}
              </span>
              <span className="ml-2 text-grey">
                {dayjs(work.startDate).format(YYYY_MMM)} to{" "}
                {work.endDate
                  ? dayjs(work.endDate).format(YYYY_MMM)
                  : "Present"}
              </span>
            </div>
            {isConsultant && (
              <div className="icon-wrapper">
                <Icon
                  role="button"
                  name="edit"
                  onClick={() => onEdit(idx)}
                  fontSize={18}
                  className="font-bold"
                />
              </div>
            )}
          </div>
        </div>
      ))}
      {showUpdateWorkHistory && (
        <WorkHistoryForm
          workHistory={whData}
          showForm={showUpdateWorkHistory}
          hideForm={() => setShowEditWorkHistory(false)}
        />
      )}
    </Card>
  );
};

export default WorkHistory;
