import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ConfirmationModal from "../../common/ConfirmationModal";
import AddressAutoComplete from "../../common/AddressAutoComplete";
import { getUserTimeZoneFromGoogle } from "../../../services/app.services";
import { Address } from "../../../helpers/googlePlacesHelpers";
import InputField from "../../common/InputField";
import { useTranslation } from "react-i18next";
import { updateAddressThunk } from "../../../redux/thunks/clients";

type UpdateAddressFormProps = {
    showForm: boolean;
    hideForm: () => void;
};

const UpdateAddressForm = (props: UpdateAddressFormProps) => {
    const { showForm, hideForm } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [address, setAddress] = useState<Address>({
        id: "",
        city: "",
        country: "",
        latitude: "",
        longitude: "",
        postalCode: "",
        provinceId: "",
        streetName: "",
        streetNumber: "",
        timeZoneId: "",
        timeZoneName: "",
    });
    const [errors, setErrors] = useState<any>(
        {
            searchAddress: '',
            title: '',
            streetNumber: '',
            streetName: '',
            city: '',
            postalCode: '',
            provinceId: '',
            country: '',
            latitude: '',
            longitude: '',
        }
    );
    const [province, setProvince] = useState<string>("");
    const [disableSave, setDisableSave] = useState<boolean>(true);
    const clientProfile = useAppSelector((state) => state.clientsReducer.clientProfile);
    const provinceOptions = useAppSelector((state) => state.appReducer.provinces);
    const isUpdateInProgress = useAppSelector(
      (state) => state.clientsReducer.isUpdateInProgress
    );
    useEffect(() => {
        if (clientProfile && clientProfile?.address?.[0]) {
            if (clientProfile?.address?.[0].provinceId) {
                setProvince(getProvince(clientProfile?.address?.[0].provinceId));
            }
            setAddress({
                id: clientProfile.address?.[0].id,
                city: clientProfile.address?.[0].city,
                country: clientProfile.address?.[0].country,
                latitude: clientProfile.address?.[0].latitude,
                longitude: clientProfile.address?.[0].longitude,
                postalCode: clientProfile.address?.[0].postalCode,
                provinceId: clientProfile.address?.[0].provinceId,
                streetName: clientProfile.address?.[0].streetName,
                streetNumber: clientProfile.address?.[0].streetNumber,
            });
            setDisableSave(false);
        }
    }, [clientProfile]);
    const handleChange = useCallback(
        (id: string, value: string | number | null | boolean) => {
            if (id === "provinceId") {
                setProvince(getProvince(value));
            }
            setErrors({ ...errors, [id]: "" });
        },
        [errors]
    );

    const getProvince = (id: string | number | null | boolean) => {
        return provinceOptions.find((province) => province.id === id)?.title || "";
    }

    const updateAddressDetails = useCallback(
        async (address: Partial<Address>, name: string) => {
            const provinceName = address.provinceId ? getProvince(address.provinceId) : "";
            setProvince(provinceName);
            const updatedData = { ...address };
            setAddress({
                ...address,
                city: updatedData.city || "",
                country: updatedData.country || "",
                latitude: updatedData.latitude || "",
                longitude: updatedData.longitude || "",
                postalCode: updatedData.postalCode || "",
                provinceId: updatedData.provinceId || "",
                streetName: updatedData.streetName || "",
                streetNumber: updatedData.streetNumber || "",
                timeZoneId: updatedData.timeZoneId || "",
                timeZoneName: updatedData.timeZoneName || "",
            });
            setDisableSave(false);
        },
        []
    );

    const handleValidation = () => {
        let valid = true;
        const errorsCopy: any = { ...errors };

        if (!address?.streetName?.trim()) {
            valid = false;
            errorsCopy.streetName = t("errors.streetNameRequired");
        }

        if (!address?.streetNumber?.trim()) {
            valid = false;
            errorsCopy.streetNumber = t("errors.streetNumberRequired");
        }

        if (!address?.city?.trim()) {
            valid = false;
            errorsCopy.city = t("errors.cityRequired");
        }

        if (!address?.provinceId?.toString().trim()) {
            valid = false;
            errorsCopy.provinceId = t("errors.provinceRequired");
        }

        if (!address?.postalCode?.trim()) {
            valid = false;
            errorsCopy.postalCode = t("errors.postalCodeRequired");
        }

        setErrors(errorsCopy);

        if (valid) {
            handleSave();
        }
    };

    const handleSave = async () => {
        const { timeZoneId, timeZoneName } = await getUserTimeZoneFromGoogle(
            address.latitude,
            address.longitude
        );
        const data = {
            ...address,
            id: clientProfile?.address?.[0].id,
            userId: clientProfile?.id?.toString(),
            unit: null,
            timeZoneId: timeZoneId,
            timeZoneName: timeZoneName,
            preferredRadius: 50,
            isAddressCompletion: true
        };
        dispatch(updateAddressThunk({ payload: { ...data }, callback: handleClose }));
    };

    const handleClose = () => {
        // Reset form and errors after saving
        setAddress({
            city: "",
            country: "",
            latitude: "",
            longitude: "",
            postalCode: "",
            provinceId: "",
            streetName: "",
            streetNumber: "",
            timeZoneId: "",
            timeZoneName: "",
        });

        setErrors({
            searchAddress: '',
            title: '',
            streetNumber: '',
            streetName: '',
            city: '',
            postalCode: '',
            provinceId: '',
            country: '',
            latitude: '',
            longitude: '',
        });

        hideForm(); // Close the form after saving
    };

    return (
        <ConfirmationModal
            open={showForm}
            title="Update Address"
            handleYes={handleValidation}
            handleNo={hideForm}
            primaryButtonText="Save"
            secondaryButtonText="Close"
            isPrimaryButtonDisabled={disableSave || isUpdateInProgress}
            text={
                <>
                    <AddressAutoComplete
                        setSelectedLocation={updateAddressDetails}
                        label="Search Address"
                        message={errors.searchAddress}
                        error={errors.searchAddress}
                    />
                    {!!address.latitude && !!address.longitude && <div>
                        <div className="flex justify-between">
                            <InputField
                                type="text"
                                id="streetNumber"
                                label={t("facilities.streetNumber") || ""}
                                value={address.streetNumber}
                                onChange={handleChange}
                                required={true}
                                className="w-full"
                                message={errors.streetNumber}
                                status={errors.streetNumber ? "error" : ""}
                                disabled={true}
                            />
                            <InputField
                                type="text"
                                id="streetName"
                                label={t("facilities.streetName") || ""}
                                value={address.streetName}
                                onChange={handleChange}
                                required={true}
                                className="w-full"
                                message={errors.streetName}
                                status={errors.streetName ? "error" : ""}
                                disabled={true}
                            />
                        </div>
                        <div className="flex justify-between">
                            <InputField
                                type="text"
                                id="city"
                                label={t("facilities.city") || ""}
                                value={address.city}
                                onChange={handleChange}
                                required={true}
                                className="w-full"
                                message={errors.city}
                                status={errors.city ? "error" : ""}
                                disabled={true}
                            />
                            <InputField
                                type="text"
                                id="postalCode"
                                label={t("facilities.postalCode") || ""}
                                value={address.postalCode}
                                onChange={handleChange}
                                className="w-full"
                                required={true}
                                message={errors.postalCode}
                                status={errors.postalCode ? "error" : ""}
                                disabled={true}
                            />
                        </div>
                        <div className="flex">
                            <InputField
                                type="text"
                                id="provinceId"
                                label={t("facilities.province") || ""}
                                value={province}
                                onChange={handleChange}
                                className="w-full"
                                required={true}
                                message={errors.provinceId}
                                status={errors.provinceId ? "error" : ""}
                                disabled={true}
                            />
                        </div>
                    </div>}
                </>
            }
        />);
};

export default UpdateAddressForm;
