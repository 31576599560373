export const JOB_TEMPLATE_MAPPING = {
  1: "RN",
  2: "LPN",
  3: "CCA",
  4: "Sitter",
};

export const DOCUMENT_TITLES: any = {
  Resume: "Resume",
  CoverLetter: "Cover Letter",
  IdProof: "ID Proof",
  WorkPermit: "Work Permit",
  PCC: "PCC",
  License: "License",
  CcaCertificate: "CCA Certificate",
  NNAS: "NNAS",
};

export const COMMON_DOC_TITLES = {
  Resume: "Resume",
  CoverLetter: "Cover Letter",
  IdProof: "ID Proof",
  PCC: "PCC",
};
export const LICENSE_DOCS = {
  License: "License",
};
export const TEMP_WORKER_DOCS = {
  WorkPermit: "Work Permit",
};
export const CPR_DOCS = {
  CPR: "CPR",
};
export const CCA_DOCUMENTS = {
  CcaCertificate: "CCA Certificate",
  NNAS: "NNAS",
};
