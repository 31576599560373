import { ShiftTypes } from "./../models/types";
import { baseUrl, getInstance } from ".";
import { cloneDeep } from "lodash";

const instance = getInstance(baseUrl);

export type LogoUploadPayload = {
  files: File[];
  facilityId: string;
  documentType: string;
  title?: string;
};
export const getShiftsByFacilityId = async (facilityId: number) => {
  return instance.get(`/shift/facility/${facilityId}`);
};

export const createShift = async (data: ShiftTypes) => {
  const dataCopy: any = cloneDeep(data);
  delete dataCopy.id;
  return instance.post("/shift", dataCopy);
};

export const updateShift = async (data: ShiftTypes) => {
  return instance.put("/shift", data);
};

export const verifyUsername = async (username: string) => {
  const response = await instance.get(`/account/find-user/${username}`);
  return response.data.data;
};

export const uploadLogo = async (payload: any) => {
  return instance.postForm("/file/upload-logo", payload);
};
