import { FacilitiesTypes, TableFilterTypes } from "../../models/types";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import Filter from "../common/Filter";
import { useAppSelector } from "../../redux/hooks";
import { SORT } from "../../constants/pagination";

type FACILITIES_TABLE_TYPES = {
  data: FacilitiesTypes[];
  onClick: (id: string) => void;
  facilityTypeFilterData: TableFilterTypes[];
  provinceFilterData: TableFilterTypes[];
  onFilter: (type: "facilityType" | "province", data: string[]) => void;
  handleSort: ({
    key,
    order,
  }: {
    key: string;
    order: string;
  }) => void;
  sortState: { key: string; order: string };
};

const FacilitiesTable = (props: FACILITIES_TABLE_TYPES) => {
  const { t } = useTranslation();
  const {
    data,
    onClick,
    facilityTypeFilterData,
    provinceFilterData,
    onFilter,
    handleSort,
  } = props;
  const isLoading = useAppSelector((state) => state.facilitiesReducer.loading);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortBy = sorter.columnKey ?? SORT.firstName;
    const sortDirection = sorter.order === "descend" ? "DESC" : SORT.defaultSortDirection;
    handleSort({ key: sortBy, order: sortDirection });
  }

  const columns = [
    {
      title: (
        <div className="flex  items-center justify-between">
          <span>{t("facilities.name")}</span>
        </div>
      ),
      dataIndex: "title",
      key: "title",
      width: "40%",
      sorter: true,
      render: (_: any, { title, id }: { title: string; id: string }) => {
        return (
          <div className="cursor-pointer link-text" onClick={() => onClick(id)}>
            {title}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex justify-between items-center">
          <span>{t("facilities.type")}</span>
          <div className="flex" onClick={(e) => { e.stopPropagation() }}>
            <Filter
              filterItems={facilityTypeFilterData}
              onFilter={(selectedFilters) =>
                onFilter("facilityType", selectedFilters)
              }
            />
          </div>
        </div>
      ),
      dataIndex: "type",
      key: "type",
      width: "30%",
      sorter: true,
    },
    {
      title: (
        <div className="flex justify-between items-center">
          <span>{t("facilities.province")}</span>
          <div className="flex" onClick={(e) => { e.stopPropagation() }}>
            <Filter
              filterItems={provinceFilterData}
              onFilter={(selectedFilters) =>
                onFilter("province", selectedFilters)
              }
            />
          </div>
        </div>
      ),
      dataIndex: "province",
      key: "province",
      width: "30%",
      sorter: true,
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      sticky
      size="small"
      loading={isLoading}
      onChange={handleTableChange}
    />
  );
};

export default FacilitiesTable;
