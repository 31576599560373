import { Avatar } from "antd";
import { useMemo } from "react";
import { getInitials } from "../../helpers/helpers";

type Props = {
  type?: "icon";
  src?: string;
  firstName?: string;
  lastName?: string;
  size?: number;
  icon?: React.ReactNode;
};

const UserAvatar = (props: Props) => {
  const { src, firstName, lastName, size = 48, icon, type } = props;

  const initials = useMemo(() => {
    if (firstName && lastName) {
      return getInitials(firstName, lastName);
    }
    return "";
  }, [firstName, lastName]);

  if (src) {
    return (
      <Avatar
        src={<img src={src} alt="AS" />}
        size={size}
        className="flex items-center justify-center"
      />
    );
  } else if (type === "icon") {
    return (
      <Avatar
        icon={icon}
        size={size}
        className="flex items-center justify-center"
      />
    );
  } else {
    return (
      <Avatar size={size} className="flex items-center justify-center">
        {initials}
      </Avatar>
    );
  }
};

export default UserAvatar;
