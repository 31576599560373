import { isValidEmail, isValidPhone } from "../../helpers/validationHelpers";

export const postJobFormValidationConfig = {
  shiftId: {
    required: "Shift is required.",
  },
  positions: {
    required: "Number of positions is required.",
  },
  shiftDate: {
    required: "Shift Date is required.",
  },
  contactEmail: {
    regex: {
      callback: isValidEmail,
      message: "Please provide a valid email.",
    },
  },
  contactMobile: {
    regex: {
      callback: isValidPhone,
      message: "Please provide a valid phone number.",
    },
  },
};
