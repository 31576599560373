import Button from "../common/Button";

type Props = {
  onDisableClientClick: () => void;
  onEnableClientClick: () => void;
  isClientDisabled: boolean;
  isDisabled: boolean;
};

const DisableClient = (props: Props) => {
  const { onDisableClientClick, onEnableClientClick, isClientDisabled, isDisabled } = props;

  if (isClientDisabled) {
    return (
      <Button
        id="enable_client_btn"
        title="Enable Client"
        disabled={isDisabled}
        onClick={onEnableClientClick}
        icon="done"
        variant="filled"
      />
    );
  } else {
    return (
      <Button
        id="disable_client_btn"
        title="Disable Client"
        onClick={onDisableClientClick}
        icon="block"
        disabled={isDisabled}
        variant="filled"
      />
    );
  }
};

export default DisableClient;
