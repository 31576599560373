export const DATE_FORMAT_PRIMARY = "YYYY-MM-DD";

export const DATE_MONTH_YEAR = "MMM-YYYY";

export const DATE_MMM_YEAR = "DD-MMM-YYYY";

export const YYYY_MMM_DATE = "YYYY-MMM-DD";

export const YYYY_MMM = "YYYY-MMM";

export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";

export const DATE_TIME_FORMAT_SS = "YYYY-MM-DD HH:mm:ss";

export const TIME_FORMAT_24_H = "HH:mm";
