import InputField from "../common/InputField";
import { useTranslation } from "react-i18next";
import { ErrorState, FormState } from "./UserManagement";

type Props = {
  handleChange: (id: string, value: string | number | null) => void;
  handleBlur: (id: string, value: string | number | null) => void;
  formState: FormState;
  errorState: ErrorState;
  resetForSelf: boolean;
};

const ResetPasswordForm = (props: Props) => {
  const { formState, errorState, handleChange, handleBlur, resetForSelf } =
    props;
  const { t } = useTranslation();

  return (
    <div className="pt-4">
      <InputField
        id="password"
        type="password"
        label={t("facilities.password") || ""}
        value={formState.password}
        placeholder={t("facilities.password") || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        message={errorState.password}
        status={errorState.password ? "error" : ""}
      />
      <InputField
        id="confirmPassword"
        type="password"
        className="mt-1"
        label={t("facilities.confirmPassword") || ""}
        value={formState.confirmPassword}
        placeholder={t("facilities.confirmPassword") || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        message={errorState.confirmPassword}
        status={errorState.confirmPassword ? "error" : ""}
      />
      {resetForSelf && (
        <div className="error-text">
          If you're updating your own password, you will be logged out of the
          application, and need to login again.
        </div>
      )}
    </div>
  );
};

export default ResetPasswordForm;
