import { ClientProfileTypes, CreateClientTypes } from "../models/types";

export const EMPLOYEE_PROFILE: ClientProfileTypes = {
  id: null,
  email: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  jobCategory: "",
  jobCategoryId: "",
  jobTemplateId: "",
  licenseNumber: "",
  avatarUrl: "",
  gender: "",
  workHistory: [],
  educationHistory: [],
  documents: [],
  isProfileVerified: false,
  hasPendingDocumentsToApprove: true,
  fullTime: null,
  casual: null,
  address: [
    {
      city: "",
      country: "",
      id: "",
      unit: "",
      latitude: "",
      longitude: "",
      postalCode: "",
      provinceId: "",
      provinceTitle: "",
      streetName: "",
      streetNumber: "",
    },
  ],
  avatarId: "",
  emailVerified: false,
  payout: {
    accountNumber: "",
    accountType: "checking",
    bankName: "",
    institutionNumber: "",
    transitNumber: "",
    accountHolderName: "",
    userId: "",
  },
  roleDescription: "",
  roleId: null,
  roleTitle: "",
  userStatusId: null,
  workEligibility: {
    canadianExperience: 0,
    internationalExperience: 0,
    legalStatus: "",
    nnasRegistered: "no",
    sin: "",
    workPermitExpiryDate: "",
    hasAdvisoryReport: "no",
  },
  currentEmployer: { casual: [], fullTime: [] },
  disabledFacilities: [],
  profileStatus: {
    isPersonalInfoCompletion: false,
    isWorkEligibilityCompletion: false,
    isAddressCompletion: false,
    isEducationCompletion: false,
    isWorkHistoryCompletion: false,
    isDocumentCompletion: false,
    isPayoutCompletion: false,
    isProfileVerified: false,
    isCurrentEmploymentCompletion: false,
    completionPercentage: 0,
  },
  deviceDetails: [],
};

export const CREATE_CLIENT: CreateClientTypes = {
  id: null,
  email: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  gender: "",
};
