import {
  ClientInvoiceTypes,
  FacilityInvoiceTypes,
  InvoiceCheckResponse,
  InvoiceCycle,
  ScheduledJob,
} from "../../models/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PAGINATION } from "../../constants/pagination";

export interface InvoiceReducerState {
  facilityInvoices: FacilityInvoiceTypes[];
  clientInvoices: ClientInvoiceTypes[];
  clientInvoiceCycles: InvoiceCycle[];
  failedJobs: ScheduledJob[];
  loading: boolean;
  failedJobsLoading: boolean;
  clientInvoiceCheckResponse?: InvoiceCheckResponse;
  facilityInvoiceCheckResponse?: InvoiceCheckResponse;
  hasNextPage: boolean;
}
const initialState: InvoiceReducerState = {
  facilityInvoices: [],
  clientInvoiceCycles: [],
  clientInvoices: [],
  failedJobs: [],
  loading: false,
  failedJobsLoading: false,
  clientInvoiceCheckResponse: undefined,
  facilityInvoiceCheckResponse: undefined,
  hasNextPage: false,
};

export const invoiceSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setFacilityInvoices: (
      state,
      action: PayloadAction<FacilityInvoiceTypes[]>
    ) => {
      state.facilityInvoices = (action.payload || []).map((item, key) => ({
        key,
        ...item,
      }));
    },
    setFacilityInvoicesClone: (state, action: PayloadAction<FacilityInvoiceTypes[]>) => {
      if (JSON.stringify(state.facilityInvoices) !== JSON.stringify(action.payload)) {
        state.facilityInvoices = [...state.facilityInvoices, ...action.payload] || [];
      } else {
        state.facilityInvoices = action.payload;
      }
    },
    setFacilityInvoicesClonePrepend: (state, action: PayloadAction<FacilityInvoiceTypes[]>) => {
      if (JSON.stringify(state.facilityInvoices) !== JSON.stringify(action.payload)) {
        state.facilityInvoices = [...action.payload, ...state.facilityInvoices] || [];
      } else {
        state.facilityInvoices = action.payload;
      }
    },
    setClientInvoices: (state, action: PayloadAction<ClientInvoiceTypes[]>) => {
      state.clientInvoices = (action.payload || []).map((item, key) => ({
        key,
        ...item,
      }));
    },
    setClientInvoicesClone: (state, action: PayloadAction<ClientInvoiceTypes[]>) => {
      if (JSON.stringify(state.clientInvoices) !== JSON.stringify(action.payload)) {
        state.clientInvoices = [...state.clientInvoices, ...action.payload] || [];
      } else {
        state.clientInvoices = action.payload;
      }
    },
    setClientInvoicesClonePrepend: (state, action: PayloadAction<ClientInvoiceTypes[]>) => {
      if (JSON.stringify(state.clientInvoices) !== JSON.stringify(action.payload)) {
        state.clientInvoices = [...action.payload, ...state.clientInvoices] || [];
      } else {
        state.clientInvoices = action.payload;
      }
    },
    setFailedJobs: (state, action: PayloadAction<ScheduledJob[]>) => {
      state.failedJobs = (action.payload || []).map((item, key) => ({
        key,
        ...item,
      }));
    },
    resetInvoicesState: (state) => {
      state.clientInvoices = [];
      state.facilityInvoices = [];
    },
    setInvoiceLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFailedJobsLoading: (state, action: PayloadAction<boolean>) => {
      state.failedJobsLoading = action.payload;
    },
    setClientInvoiceGenerationEligibility: (
      state,
      action: PayloadAction<InvoiceCheckResponse>
    ) => {
      state.clientInvoiceCheckResponse = action.payload;
    },
    setFacilityInvoiceGenerationEligibility: (
      state,
      action: PayloadAction<InvoiceCheckResponse>
    ) => {
      state.facilityInvoiceCheckResponse = action.payload;
    },
    setClientInvoiceHasNextPage: (state, action: PayloadAction<boolean>) => {
      state.hasNextPage = action.payload || false;
    },
    removeFirstClientInvoices: (state) => {
      state.clientInvoices = state.clientInvoices.slice(PAGINATION.defaultPageSize);
    },
    removeLastClientInvoices: (state) => {
      state.clientInvoices = state.clientInvoices.slice(0, (PAGINATION.defaultPageSize * -1));
    },
    removeFirstFacilityInvoices: (state) => {
      state.facilityInvoices = state.facilityInvoices.slice(PAGINATION.defaultPageSize);
    },
    removeLastFacilityInvoices: (state) => {
      state.facilityInvoices = state.facilityInvoices.slice(0, (PAGINATION.defaultPageSize * -1));
    },
    setClientInvoiceCycles: (
      state,
      action: PayloadAction<InvoiceCycle[]>
    ) => {
      state.clientInvoiceCycles = action.payload;
    },
  },
});

export const {
  setFacilityInvoices,
  setClientInvoices,
  setFailedJobs,
  resetInvoicesState,
  setInvoiceLoading,
  setFailedJobsLoading,
  setClientInvoiceGenerationEligibility,
  setFacilityInvoiceGenerationEligibility,
  setClientInvoicesClone,
  setClientInvoicesClonePrepend,
  setClientInvoiceHasNextPage,
  removeFirstClientInvoices,
  removeLastClientInvoices,
  setFacilityInvoicesClone,
  setFacilityInvoicesClonePrepend,
  removeFirstFacilityInvoices,
  removeLastFacilityInvoices,
  setClientInvoiceCycles,

} = invoiceSlice.actions;

export default invoiceSlice.reducer;
