import { FacilityStatus, ShiftTypes } from "./../../models/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { convertUtcTimeToLocalTime } from "../../helpers/dateTime.helper";

export interface SettingsReducerState {
  loading: boolean;
  uploading: boolean;
  enabled: boolean;
  timeZoneId: string;
  shifts: ShiftTypes[];
  facilityStatus: FacilityStatus;
}
const initialState: SettingsReducerState = {
  loading: false,
  timeZoneId: "",
  uploading: false,
  enabled: false,
  shifts: [],
  facilityStatus: {} as FacilityStatus,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setShifts: (state, action: PayloadAction<ShiftTypes[]>) => {
      state.shifts =
        action.payload?.map((item) => {
          item.startTime = convertUtcTimeToLocalTime(
            item.startTime,
            state.timeZoneId
          );
          item.endTime = convertUtcTimeToLocalTime(
            item.endTime,
            state.timeZoneId
          );
          return item;
        }) || [];
    },
    setSettingsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUploading: (state, action: PayloadAction<boolean>) => {
      state.uploading = action.payload;
    },
    resetSettingsState: (state) => {
      state.shifts = [];
    },
    setFacilityTimeZone: (state, action: PayloadAction<string>) => {
      state.timeZoneId = action.payload;
    },
    setFacilityStatus: (state, action: PayloadAction<FacilityStatus>) => {
      state.facilityStatus = action.payload;
    },
  },
});

export const {
  setShifts,
  setSettingsLoading,
  resetSettingsState,
  setUploading,
  setFacilityTimeZone,
  setFacilityStatus,
} = settingsSlice.actions;

export default settingsSlice.reducer;
