import type { RadioChangeEvent } from "antd";
import { Radio } from "antd";
import { DropDownOptionTypes } from "../../models/types";

type RADIO_TYPES = {
  id: string;
  value: string | number;
  options: DropDownOptionTypes[];
  onChange: (id: string, value: string | number) => void;
  className?: string;
};

const RadioButton = (props: RADIO_TYPES) => {
  const { id, value, onChange, options } = props;
  const handleChange = (event: RadioChangeEvent) => {
    const { value } = event.target;
    onChange(id, value);
  };
  return (
    <div className="flex flex-col m-2">
      <Radio.Group onChange={handleChange} value={value} id={id}>
        {options.map((option) => (
          <Radio value={option.value} key={option.value}>
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

export default RadioButton;
