import { FacilitiesTypes } from "./../../models/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface FacilitiesReducerState {
  facilities: FacilitiesTypes[];
  currentFacility: FacilitiesTypes;
  loading: boolean;
  updateInprogress: boolean;
  showRightPanel: boolean;
}

const initialState: FacilitiesReducerState = {
  facilities: [],
  currentFacility: {} as FacilitiesTypes,
  loading: false,
  showRightPanel: false,
  updateInprogress: false,
};

export const facilitiesSlice = createSlice({
  name: "facilities",
  initialState,
  reducers: {
    setFacilities: (state, action: PayloadAction<FacilitiesTypes[]>) => {
      state.facilities = action.payload || [];
    },
    setRightPanelStatus: (state, action: PayloadAction<boolean>) => {
      state.showRightPanel = action.payload;
    },
    setCurrentFacility: (state, action: PayloadAction<FacilitiesTypes>) => {
      state.currentFacility = action.payload || {};
    },
    setFacilitiesLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFacilitiesUpdateProgress: (state, action: PayloadAction<boolean>) => {
      state.updateInprogress = action.payload;
    },
    updateFacilityInList: (state, action: PayloadAction<FacilitiesTypes>) => {
      const idx = state.facilities.findIndex(
        (f) => Number(f.id) === Number(action.payload.id)
      );
      if (idx > -1) {
        state.facilities[idx] = action.payload;
      }
      if (Number(state.currentFacility.id) === Number(action.payload.id)) {
        state.currentFacility.logo = action.payload.logo;
      }
    },
    resetFacilitiesState: (state) => {
      state.facilities = [];
      state.currentFacility = {} as FacilitiesTypes;
    },
  },
});

export const {
  setFacilities,
  setCurrentFacility,
  setRightPanelStatus,
  resetFacilitiesState,
  setFacilitiesLoading,
  updateFacilityInList,
  setFacilitiesUpdateProgress,
} = facilitiesSlice.actions;

export default facilitiesSlice.reducer;
