import React, { useMemo, useState } from "react";
import Icon from "./Icon";
type Props = {
  text: string;
  textStyles?: string;
};

const MaskedText = (props: Props) => {
  const { text, textStyles } = props;
  const [show, setShow] = useState(false);

  const handleClick = () => {
    if (!show) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  };

  const maskedText = useMemo(() => {
    const maskedArr = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (let i of text) {
      maskedArr.push("*");
    }
    return maskedArr.join("");
  }, [text]);

  return (
    <div className="flex items-center gap-5">
      {show ? (
        <span className={textStyles}>{text}</span>
      ) : (
        <span>{maskedText}</span>
      )}
      {!show && (
        <div
          className="flex items-center justify-center"
          onClick={handleClick}
          role="button"
        >
          <Icon name="visibility" fontSize={20} />
        </div>
      )}
    </div>
  );
};

export default MaskedText;
