import { Tooltip } from "antd";
import Icon from "../Icon";

type AddButtonType = {
  tooltipPlacement?: "left" | "right" | "bottom" | "top";
  tooltipText: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const AddButton = (props: AddButtonType) => {
  const { tooltipPlacement = "left", tooltipText, onClick } = props;
  return (
    <Tooltip placement={tooltipPlacement} title={tooltipText}>
      <div
        role="button"
        className="add-button shadow-md hover:shadow-xl"
        onClick={onClick}
        id="add-button"
      >
        <Icon name="add" fontSize={32} />
      </div>
    </Tooltip>
  );
};

export default AddButton;
