import { useCallback, useMemo, useState } from "react";
import ApproveReject from "../common/ApproveReject";
import { DocumentTypes } from "../../models/types";
import { Card } from "../common/Card";
import Icon from "../common/Icon";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { downloadUserDocumentThunk } from "../../redux/thunks/app";
import { Spin } from "antd";
import { isEmpty } from "lodash";
import VerifyDocumentModal from "../common/VerifyDocumentModal";

type Props = {
  documentData: DocumentTypes;
  clientId: Number;
  handlePreview: (fieldId: number, title: string) => void;
  previewLoading: boolean;
  isConsultant: boolean;
};
export const DOCUMENT_TITLES: any = {
  CcaCertificate: "CCA Certificate",
  CoverLetter: "Cover Letter",
  Resume: "Resume",
  IdProof: "Id Proof",
  WorkPermit: "Work Permit",
  ProfileImage: "Profile Picture",
  PCC: "PCC",
  License: "License",
};
const Document = (props: Props) => {
  const {
    documentData,
    clientId,
    handlePreview,
    previewLoading,
    isConsultant,
  } = props;
  const dispatch = useAppDispatch();
  const documentDownloading = useAppSelector(
    (state) => state.appReducer.documentDownloading
  );
  const [verificationConfirmation, setVerificationConfirmation] = useState({
    status: false,
    documentId: 0,
    fileName: "",
  });
  const isDocumentDownloadInprogress = useMemo(() => {
    if (isEmpty(documentData) || isEmpty(documentDownloading)) {
      return false;
    }
    return (
      documentDownloading.status &&
      Number(documentData.id) === Number(documentDownloading.fileId) &&
      documentData.title === documentDownloading.fileName
    );
  }, [documentData, documentDownloading]);

  const handleApproveDocument = () => {
    setVerificationConfirmation({
      status: true,
      documentId: documentData.id,
      fileName: DOCUMENT_TITLES[documentData.title] ?? documentData.title,
    });
  };

  const handleNo = useCallback(() => {
    setVerificationConfirmation({
      status: false,
      documentId: 0,
      fileName: "",
    });
  }, []);
  const handleDownload = () => {
    !isDocumentDownloadInprogress &&
      dispatch(downloadUserDocumentThunk(documentData.id, documentData.title));
  };

  return (
    <>
      <Card
        styles={
          documentData.documentStatus === "Approved"
            ? {}
            : { border: "1px solid #FBB360" }
        }
      >
        <div className="grid grid-cols-9">
          <div className="col-span-5 flex items-center">
            <div className="flex-wrap flex">
              {DOCUMENT_TITLES[documentData.title] ?? documentData.title}
            </div>
            {documentData.documentStatus === "Approved" && (
              <Icon name="priority" fontSize={18} className="text-green ml-1" />
            )}
          </div>
          <div className="col-span-4 flex justify-end gap-4">
            {isConsultant && (
              <div className="flex justify-center`">
                {documentData.documentStatus === "Pending" && (
                  <ApproveReject
                    onApprove={handleApproveDocument}
                    onReject={() => {}}
                  />
                )}
              </div>
            )}
            <div className="flex items-center gap-2">
              {!previewLoading ? (
                <div
                  className="icon-wrapper"
                  role="button"
                  onClick={() => {
                    handlePreview(documentData.id, documentData.title);
                  }}
                >
                  <Icon name="visibility" fontSize={16} />
                </div>
              ) : (
                <div>
                  <Spin
                    className="flex justify-center items-center"
                    style={{ width: "24px", height: "24px" }}
                    size="small"
                  />
                </div>
              )}
              {isConsultant && (!isDocumentDownloadInprogress ? (
                <div
                  className="icon-wrapper"
                  role="button"
                  onClick={handleDownload}
                >
                  <Icon name="download" fontSize={16} />
                </div>
              ) : (
                <div>
                  <Spin
                    className="flex justify-center items-center"
                    style={{ width: "24px", height: "24px" }}
                    size="small"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {verificationConfirmation && (
          <VerifyDocumentModal
            open={verificationConfirmation.status}
            clientId={Number(clientId)}
            documentId={verificationConfirmation.documentId}
            fileName={verificationConfirmation.fileName}
            handleCancel={handleNo}
          />
        )}
      </Card>
    </>
  );
};

export default Document;
