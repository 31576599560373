import React, { useState } from "react";
import { UserBankTypes } from "../../models/types";
import { Card } from "../common/Card";
import CopyIcon from "../common/CopyIcon";
import { Divider } from "antd";
import MaskedText from "../common/MaskedText";
import Icon from "../common/Icon";
import BankDetailsForm from "./edit/BankInfo";
import Tooltip from "../common/ToolTip";
import { useAppSelector } from "../../redux/hooks"

type Props = {
  bankInfo: UserBankTypes;
  isConsultant: boolean;
};

const BankInfo = (props: Props) => {
  const { bankInfo, isConsultant } = props;

  const [showEditBankInfo, setShowEditBankInfo] = useState(false);
  const isBankInfoCompleted = useAppSelector(
    (state) =>
      state.clientsReducer.clientProfile.profileStatus?.isPayoutCompletion
  );
  const onEdit = (e: any) => { setShowEditBankInfo(true); }

  return (
    <Card
      title={
        <div className="flex justify-between items-center mb-2">
          <span className="font-bold">Bank Details</span>
          {isConsultant && !isBankInfoCompleted && (
            <Tooltip title="Add Bank Details">
              <div
                className="flex items-center gap-1 text-[#FBB360]"
                role="button"
              >
                <Icon name="warning" fontSize={16} className="font-semibold" />
                <span className="text-xs">Action needed</span>
              </div>
            </Tooltip>
          )}
          {isConsultant && (
            <span className="bg-[#1677ff] text-white p-1 flex justify-center items-center rounded-[50%] hover:bg-[#1890ff]">
              <Icon role="button" name="edit" fontSize={18} onClick={onEdit} />
            </span>
          )}
        </div>
      }
    >
      {bankInfo && (
        <div>
          <div className="grid grid-cols-8">
            <div className="col-span-3 text-grey capitalize">Bank</div>
            <div className="col-span-4">{bankInfo.bankName}</div>
            <CopyIcon text={bankInfo.bankName} />
          </div>
          <Divider className="my-2" />
          <div className="grid grid-cols-8">
            <div className="col-span-3 text-grey">Account #</div>
            <div className="col-span-4">
              <MaskedText text={bankInfo.accountNumber} />
            </div>
            <CopyIcon text={bankInfo.accountNumber} />
          </div>
          <Divider className="my-2" />
          <div className="grid grid-cols-8">
            <div className="col-span-3 text-grey">Account Type</div>
            <div className="col-span-4 capitalize">{bankInfo.accountType}</div>
            <CopyIcon text={bankInfo.accountType} />
          </div>
          <Divider className="my-2" />
          <div className="grid grid-cols-8">
            <div className="col-span-3 text-grey">Institute #</div>
            <div className="col-span-4 capitalize">
              {bankInfo.institutionNumber}
            </div>
            <CopyIcon text={bankInfo.institutionNumber} />
          </div>
          <Divider className="my-2" />
          <div className="grid grid-cols-8">
            <div className="col-span-3 text-grey">Transit #</div>
            <div className="col-span-4 capitalize">
              {bankInfo.transitNumber}
            </div>
            <CopyIcon text={bankInfo.transitNumber} />
          </div>
        </div>
      )}
      {showEditBankInfo && isConsultant && (
        <BankDetailsForm
          bankInfo={bankInfo}
          showForm={showEditBankInfo}
          hideForm={() => setShowEditBankInfo(false)}
        />
      )}
    </Card>
  );
};

export default BankInfo;
