import React from "react";
import Button from "./Button";

type Props = {
  onApprove: () => void;
  onReject: () => void;
  isApprovalInprogress?: boolean;
  isRejectionInprogress?: boolean;
};

const ApproveReject = (props: Props) => {
  const { onApprove, isApprovalInprogress } = props;
  return (
    <div className="flex gap-2">
      <Button
        id="approve_button"
        title="Approve"
        onClick={() => !isApprovalInprogress && onApprove()}
        icon="priority"
        size="sm"
        variant="filled"
        isLoading={isApprovalInprogress}
      />
      {/* <Button
        id="reject_button"
        title="Reject"
        onClick={() => !isRejectionInprogress && onReject()}
        icon="cancel"
        variant="outline"
        size="sm"
        isLoading={isRejectionInprogress}
      /> */}
    </div>
  );
};

export default ApproveReject;
