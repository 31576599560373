import { useState, useMemo, useCallback, useEffect } from "react";
import ClientsTable from "./ProfileTable";
import SideDrawer from "../common/SideDrawer";
import { TableFilterTypes, CreateClientTypes } from "../../models/types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getClientsByFacilityThunk,
  getAllClientsThunk,
  createClientProfileThunk,
} from "../../redux/thunks/clients";

import useUserRole from "../hooks/useUserRole";
import { cloneDeep } from "lodash";
import { createClientProfileValidationConfig } from "./validationConfig";
import { validateForm } from "../../helpers/validationHelpers";
import { useTranslation } from "react-i18next";
import { CREATE_CLIENT } from "../../constants/defaultData";
import { ERRORS_TEMPLATE } from "./constants";
import CreateClientProfile from "./CreateClientProfile";

function Clients() {
  const [openCreateClientDrawer, setOpenCreateClientDrawer] = useState(false);
  const [dataCopy, setDataCopy] = useState({});
  const [clientProfileData, setClientProfileData] = useState<CreateClientTypes>(
    cloneDeep(CREATE_CLIENT)
  );
  const isLoading = useAppSelector((state) => state.clientsReducer.loading);
  const [errors, setErrors] = useState({ ...ERRORS_TEMPLATE });
  const { t } = useTranslation();
  const clientsListFromState = useAppSelector(
    (state) => state.clientsReducer.clients
  );
  const dispatch = useAppDispatch();
  const { isConsultant } = useUserRole();
  const jobsCategoryFromState = useAppSelector(
    (state) => state.jobCategoryReducer.jobCategories
  );
  const totalNumberOfClients = useAppSelector(
    (state) => state.clientsReducer.totalRecords
  );
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id
  );

  const jobCategoryFilterData: TableFilterTypes[] = useMemo(() => {
    return jobsCategoryFromState.map((item) => ({
      id: item.jobTemplateId,
      label: item.title,
    }));
  }, [jobsCategoryFromState]);

  useEffect(() => {
    if (isConsultant || facilityId) {
      isConsultant
        ? dispatch(getAllClientsThunk())
        : dispatch(getClientsByFacilityThunk(Number(facilityId)));
    }
  }, [dispatch, facilityId, isConsultant]);

  const handleCreateClientDrawerClose = useCallback(() => {
    setOpenCreateClientDrawer(false);
    setClientProfileData({ ...CREATE_CLIENT });
  }, []);

  const handleAddClientProfile = useCallback(() => {
    // When Create job type is selected, setting empty template in the state
    const template = {
      ...CREATE_CLIENT,
      // id: (jobsCategoryFromState?.length + 1).toString(),
    };
    setClientProfileData(template);
    setDataCopy(template);
    setOpenCreateClientDrawer(true);
  }, []);

  const handleConfirmation = useCallback(
    (id?: string, resetClicked: boolean = false) => {
      // Showing confirmation only when the user clicks a row other than the currently selected on
      if (id === "new") {
        handleAddClientProfile();
      }
      if (id === "close") {
        handleCreateClientDrawerClose();
      }
    },
    [
      dataCopy,
      handleAddClientProfile,
      clientProfileData,
      openCreateClientDrawer,
      handleCreateClientDrawerClose,
    ]
  );
  const handleClose = useCallback(() => {
    setOpenCreateClientDrawer(false);
    setClientProfileData(CREATE_CLIENT);
    setErrors(cloneDeep(ERRORS_TEMPLATE));
  }, []);

  const handleSave = useCallback(() => {
    const dataCopy = {
      ...clientProfileData,
      phoneNumber: clientProfileData.phoneNumber.startsWith("+1")
        ? clientProfileData.phoneNumber
        : `+1${clientProfileData.phoneNumber}`,
    };
    dispatch(
      createClientProfileThunk({
        formData: dataCopy,
        callback: handleClose,
      })
    );
  }, [clientProfileData, dispatch]);

  const validateFields = useCallback(() => {
    let { valid, formData, errorObject } = validateForm(
      clientProfileData,
      createClientProfileValidationConfig,
      errors
    );

    if (!formData?.email.trim()) {
      valid = false;
      errorObject.email = t("errors.emailRequired") || "";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      valid = false;
      errorObject.email = t("errors.invalidEmail") || "";
    }

    if (!formData?.phoneNumber.trim()) {
      valid = false;
      errorObject.phoneNumber = t("errors.phoneNumberRequired") || "";
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      valid = false;
      errorObject.phoneNumber = t("errors.invalidPhoneNumber") || "";
    }

    setClientProfileData(formData);
    if (valid) {
      handleSave();
    } else {
      setErrors(errorObject);
    }
  }, [errors, clientProfileData]);

  const handleChange = useCallback(
    (id: string, value: string | number | null) => {
      // Setting the value in state
      setClientProfileData({ ...clientProfileData, [id]: value });

      // Resetting the errors
      setErrors({ ...errors, [id]: "" });
    },
    [clientProfileData, errors]
  );

  return (
    <div className="h-full bg-white flex">
      <div className="mr-3 w-full h-full">
        <ClientsTable
          data={clientsListFromState}
          totalRecords={totalNumberOfClients}
          jobCategoryFilterData={jobCategoryFilterData}
          handleConfirmation={handleConfirmation}
        />
      </div>
      {openCreateClientDrawer && (
        <SideDrawer
          open={openCreateClientDrawer}
          onClose={() => handleConfirmation("close")}
          title={t("createClientProfile.createNewClient") || ""}
          onSubmit={validateFields}
          primaryButton={t("common.save") || ""}
          disablePrimaryBtn={isLoading}
        >
          <CreateClientProfile
            data={clientProfileData}
            onChange={handleChange}
            errors={errors}
          />
        </SideDrawer>
      )}
    </div>
  );
}

export default Clients;
