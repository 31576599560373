import { useState, useMemo, useCallback, useEffect } from "react";
import CalendarHeader from "./calendar-header/CalendarHeader";
import "./Calendar.css";
import Month from "./month/Month";
import SideDrawer from "../common/SideDrawer";
import { CalenderViewType } from "../../models/day.model";
import {
  ContactTypes,
  DropDownOptionTypes,
  PostJobErrorsTypes,
  PostJobTypes,
  WithdrawJobRequest,
} from "../../models/types";
import { NEW_JOB_DATA_TEMPLATE, NEW_JOB_ERRORS_TEMPLATE } from "./constants";
import ConfirmationModal from "../common/ConfirmationModal";
import { isEqual } from "lodash";
import dayjs from "dayjs";
import RightPane from "./RightPane";
import { useTranslation } from "react-i18next";
import Icon from "../common/Icon";
import { Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  createJobsThunk,
  deleteJobThunk,
  getJobSummaryThunk,
  unAssignJobThunk,
  updateJobThunk,
} from "../../redux/thunks/shiftSchedule";
import { setJobsForSelectedDay } from "../../redux/reducers/shiftScheduleReducer";
import { resetClientProfile } from "../../redux/reducers/clientsReducer";
import { DATE_FORMAT_PRIMARY } from "../../constants/defaults";
import SectionName from "../common/SectionName";
import { validateForm } from "../../helpers/validationHelpers";
import { postJobFormValidationConfig } from "./validationConfig";
import {
  getDateFromUtcDate,
  getShiftStartEndDateTimes,
  getTimeStringInTimezone,
  getTodayInTimeZone,
} from "../../helpers/dateTime.helper";
import useFacilityTimezone from "../hooks/useFacilityTimezone";
import { disableShift } from "../../helpers/helpers";
import { setSidePanel } from "../../redux/reducers/appReducer";
import { SIDE_PANEL_NAMES } from "../../constants/sidePanel";
import MarkJobCompletedForm from "../job/mark-job-completed/MarkJobCompletedForm";
import { JOB_RESPONSE_DATA_TEMPLATE } from "../job/mark-job-completed/constant";
import { JobTypes } from "../../models/job.model";

const Calendar = () => {
  const { t } = useTranslation();
  // Calendar Related operations
  const { timeZoneId } = useFacilityTimezone();
  const dispatch = useAppDispatch();
  const [currentDate, setCurrentDate] = useState(
    getTodayInTimeZone(timeZoneId)
  );
  const isLoading = useAppSelector(
    (state) => state.shiftScheduleReducer.loading
  );
  const [showMarkJobComplete, setShowMarkJobComplete] = useState<boolean>(false);
  const [showUnAssignUser, setShowUnAssignUser] = useState<boolean>(false);
  const [calendarView, setCalendarView] =
    useState<CalenderViewType>("bi-weekly-view");
  const [selectedDay, setSelectedDay] = useState<string>("");
  const month = useAppSelector((state) => state.shiftScheduleReducer.monthData);
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id?.toString() || ""
  );
  useEffect(() => {
    setCurrentDate(getTodayInTimeZone(timeZoneId));
  }, [timeZoneId]);

  useEffect(() => {
    if (facilityId) {
      dispatch(
        getJobSummaryThunk(currentDate, calendarView, facilityId, timeZoneId)
      );
    }
    return () => {
      dispatch(
        setSidePanel({
          name: "",
          status: false,
          type: "",
        })
      );
    };
  }, [currentDate, calendarView, dispatch, facilityId, timeZoneId]);

  const openDrawer = useAppSelector((state) => state.appReducer.sidePanel);

  useEffect(() => {
    if (!openDrawer?.status) {
      setPostJobData({ ...NEW_JOB_DATA_TEMPLATE });
      setPostJobErrors({ ...NEW_JOB_ERRORS_TEMPLATE });
      setResetConfirmation({ status: false, triggeredFrom: "" });
      setSelectedClientId(null);
      setHourlyRateNotPresent(false);
      setNewPostJobDate({
        jobCategory: "",
        date: "",
      });
    }
  }, [openDrawer]);
  const setOpenDrawer = useCallback(
    (state: { status: boolean; type: string }) => {
      dispatch(
        setSidePanel({
          name: SIDE_PANEL_NAMES.calendar,
          status: state.status,
          type: state.type,
        })
      );
    },
    [dispatch]
  );
  const onToday = useCallback(() => {
    const today = getTodayInTimeZone(timeZoneId);
    setCurrentDate(today);
  }, [timeZoneId]);
  const onPrevious = useCallback(() => {
    let prevDates;
    if (calendarView === "month-view") {
      prevDates = dayjs(currentDate).subtract(1, "months");
    } else {
      prevDates = dayjs(currentDate).subtract(2, "weeks");
    }
    setCurrentDate(prevDates);
  }, [calendarView, currentDate]);
  const onNext = useCallback(() => {
    let nextDates;

    if (calendarView === "month-view") {
      nextDates = dayjs(currentDate).add(1, "months");
    } else {
      nextDates = dayjs(currentDate).add(2, "weeks");
    }
    setCurrentDate(nextDates);
  }, [calendarView, currentDate]);

  const handleCalenderViewChange = useCallback(
    (view: CalenderViewType) => {
      setCalendarView(view);
      onToday();
    },
    [onToday]
  );

  // Post Job Related operations
  const [postJobData, setPostJobData] = useState<PostJobTypes>({
    ...NEW_JOB_DATA_TEMPLATE,
  });
  const [postJobErrors, setPostJobErrors] = useState<PostJobErrorsTypes>({
    ...NEW_JOB_ERRORS_TEMPLATE,
  });
  const [resetConfirmation, setResetConfirmation] = useState({
    status: false,
    triggeredFrom: "",
  });
  const [selectedClientId, setSelectedClientId] = useState<number | null>(null);
  const [hourlyRateNotPresent, setHourlyRateNotPresent] = useState(false);
  const [selectedJob, setSelectedJob] = useState<JobTypes>(JOB_RESPONSE_DATA_TEMPLATE);
  const [newPostJobState, setNewPostJobDate] = useState<{
    jobCategory: string;
    date: string;
  }>({ jobCategory: "", date: "" });
  const shiftsData = useAppSelector((state) => state.settingsReducer.shifts);
  const jobCategoryData = useAppSelector(
    (state) => state.jobCategoryReducer.jobCategories
  );

  const jobCategoryOptions: DropDownOptionTypes[] = useMemo(() => {
    return (jobCategoryData || []).map((element) => ({
      value: element.id,
      label: element.title,
    }));
  }, [jobCategoryData]);

  const shiftOptions: DropDownOptionTypes[] = useMemo(() => {
    return (shiftsData || [])
      .map((element) => {
        return {
          startTime: element.startTime,
          value: element.id.toString(),
          label: `${element.title}: ${element.startTime} - ${element.endTime}`,
          disabled: disableShift(
            postJobData.shiftDate,
            element.startTime,
            timeZoneId
          ),
        };
      })
      .sort((a, b) => {
        if (a.disabled && !b.disabled) {
          return -1;
        } else if (!a.disabled && b.disabled) {
          return 1;
        } else {
          return 0;
        }
      });
  }, [timeZoneId, postJobData.shiftDate, shiftsData]);

  const selectedJobCategoryData = useCallback(
    (id: string | number) => {
      return jobCategoryData?.filter(
        (element) => Number(element.id) === Number(id)
      )?.[0];
    },
    [jobCategoryData]
  );

  const handlePhoneChange = useCallback(
    (value: string) => {
      let updatedVal = value;
      // Inserting space in the phone value as per Canadian Phone format
      // Checking if the value has only numbers and space
      const phonePattern = /^[0-9 ]*$/;
      if (!phonePattern.test(value) || value.length > 12) {
        return;
      }
      if (
        value &&
        (value.length === 3 || value.length === 7) &&
        postJobData?.contactMobile &&
        value.length > postJobData.contactMobile.length
      ) {
        updatedVal = updatedVal + " ";
      }
      setPostJobData({ ...postJobData, contactMobile: updatedVal });
      setPostJobErrors({ ...postJobErrors, contactMobile: "" });
    },
    [postJobData, postJobErrors]
  );
  const handleName = useCallback(
    (id: "contactName" | "postedBy", value: string) => {
      const namePattern = /^[a-z ,.'-]+$/i;
      if (!namePattern.test(value)) {
        return;
      }
      setPostJobData({ ...postJobData, [id]: value });
    },
    [postJobData]
  );
  const handleJobCategoryChange = useCallback(
    (value: string) => {
      const selectedJobCopy = selectedJobCategoryData(value);
      const { contactName, contactEmail, contactMobile } = selectedJobCopy;
      const phone = contactMobile?.startsWith("+1 ")
        ? contactMobile?.slice(3)
        : contactMobile;
      setPostJobData({
        ...postJobData,
        jobCategoryId: value,
        contactName: contactName || "",
        contactEmail: contactEmail || "",
        contactMobile: phone || "",
      });
      setPostJobErrors({ ...postJobErrors, jobCategoryId: "" });
    },
    [postJobErrors, postJobData, selectedJobCategoryData]
  );

  const setHourlyRateOnJobCategoryChange = useCallback(
    (value: string) => {
      const selectedJobCopy = selectedJobCategoryData(value);
      const { hourlyRate } = selectedJobCopy;
      setHourlyRateNotPresent(Number(hourlyRate.replace("$", "")) <= 0);
    },
    [selectedJobCategoryData]
  );

  const handleConfirmation = useCallback(
    (triggeredFrom: "close-button" | "date" | "add-button") => {
      const isDataChanged = !isEqual(postJobData, NEW_JOB_DATA_TEMPLATE);
      if (
        openDrawer &&
        openDrawer.status &&
        openDrawer.type === "post-job" &&
        isDataChanged
      ) {
        setResetConfirmation({ status: true, triggeredFrom });
        // Save the date from which click is received
      } else {
        triggeredFrom === "close-button"
          ? setOpenDrawer({ status: false, type: "" })
          : setOpenDrawer({ status: true, type: "summary" });
        setPostJobErrors(NEW_JOB_ERRORS_TEMPLATE);
        dispatch(setJobsForSelectedDay({ selectedDate: "", data: [] }));
      }
    },
    [postJobData, openDrawer, setOpenDrawer, dispatch]
  );
  const handleClickOnDate = useCallback(
    (selectedDay: string, showPanel: boolean) => {
      showPanel && handleConfirmation("date");
      setSelectedDay(selectedDay);
    },
    [handleConfirmation]
  );
  const handleClickOnAddButton = useCallback(
    (jobCategory: string, date?: string, resetDone?: boolean) => {
      const isSameDate = date === postJobData.shiftDate;
      if (!isSameDate || !date) {
        const isDataChanged = !isEqual(postJobData, NEW_JOB_DATA_TEMPLATE);
        if (
          !resetDone &&
          openDrawer &&
          openDrawer.status &&
          openDrawer.type === "post-job" &&
          isDataChanged
        ) {
          if (date) {
            setNewPostJobDate({ jobCategory, date });
          }
          handleConfirmation("add-button");
          // Save the date from which click is received
        } else {
          let jobDate: string | undefined =
            getTodayInTimeZone(timeZoneId).format(DATE_FORMAT_PRIMARY);
          if (date) {
            jobDate = date;
          } else {
            if (selectedDay) {
              const facilityTime = getTimeStringInTimezone(jobDate, timeZoneId);
              const selectedTime = getTimeStringInTimezone(
                selectedDay,
                timeZoneId
              );
              let showSelectedDate =
                facilityTime.isSame(selectedTime) ||
                facilityTime.isBefore(selectedTime);
              jobDate =
                selectedDay && showSelectedDate ? selectedDay : undefined;
            }
          }
          const selectedJobCopy = selectedJobCategoryData(
            jobCategory.toString()
          );
          const { contactName, contactEmail, contactMobile, hourlyRate } =
            selectedJobCopy;
          setHourlyRateNotPresent(Number(hourlyRate.replace("$", "")) <= 0);
          const phone = contactMobile?.startsWith("+1 ")
            ? contactMobile?.slice(3)
            : contactMobile;
          setPostJobData({
            ...postJobData,
            jobCategoryId: selectedJobCopy.id,
            contactName: contactName || "",
            contactEmail: contactEmail || "",
            contactMobile: phone || "",
            shiftDate: jobDate,
          });
          setOpenDrawer({ status: true, type: "post-job" });
        }
      }
    },
    [
      handleConfirmation,
      openDrawer,
      postJobData,
      selectedDay,
      selectedJobCategoryData,
      setOpenDrawer,
      timeZoneId,
    ]
  );
  const handleYes = useCallback(() => {
    if (resetConfirmation.triggeredFrom === "date") {
      setOpenDrawer({ status: true, type: "summary" });
      setPostJobData(NEW_JOB_DATA_TEMPLATE);
    } else if (resetConfirmation.triggeredFrom === "add-button") {
      handleClickOnAddButton(
        newPostJobState.jobCategory,
        newPostJobState.date,
        true
      );
    } else {
      setOpenDrawer({ status: false, type: "" });
      setPostJobData(NEW_JOB_DATA_TEMPLATE);
    }
    setResetConfirmation({ status: false, triggeredFrom: "" });
    setPostJobErrors(NEW_JOB_ERRORS_TEMPLATE);
  }, [
    handleClickOnAddButton,
    newPostJobState.date,
    newPostJobState.jobCategory,
    resetConfirmation.triggeredFrom,
    setOpenDrawer,
  ]);

  const handleNo = useCallback(() => {
    setResetConfirmation({ status: false, triggeredFrom: "" });
  }, []);

  const handleUnAssignJobConfirmation = () => {
    if (selectedJob && selectedJob.id) {
      const payload: WithdrawJobRequest = {
        userId: selectedJob?.clientId,
        jobId: selectedJob?.id,
        shiftStartDateTime: selectedJob?.shiftStartDateTime,
        shiftEndDateTime: selectedJob?.shiftEndDateTime,
      };
      dispatch(
        unAssignJobThunk(
          payload,
          calendarView,
          facilityId,
          timeZoneId,
          currentDate,
          handleUnAssignedJobClose
        )
      );
    }
  };

  const handleUnAssignedJobClose = () => {
    setShowUnAssignUser(false);
    setOpenDrawer({ status: false, type: "" })
  };

  const handlePostJobSave = useCallback(() => {
    const formattedData = { ...postJobData };
    formattedData.contactMobile = formattedData.contactMobile?.startsWith("+1 ")
      ? formattedData.contactMobile
      : "+1 " + formattedData.contactMobile;
    const shiftDetails = shiftsData.filter(
      (shift) => Number(shift.id) === Number(formattedData.shiftId)
    )?.[0];
    const { startTime, endTime } = shiftDetails;
    const { startDateTime, endDateTime } = getShiftStartEndDateTimes(
      formattedData.shiftDate ||
      getTodayInTimeZone(timeZoneId).format(DATE_FORMAT_PRIMARY),
      startTime,
      endTime,
      true,
      timeZoneId
    );
    formattedData.shiftStartDateTime = startDateTime;
    formattedData.shiftEndDateTime = endDateTime;
    delete formattedData.shiftDate;
    if (formattedData.id) {
      dispatch(
        updateJobThunk(
          formattedData,
          calendarView,
          facilityId,
          timeZoneId,
          currentDate,
          handleClose
        )
      );
    } else {
      dispatch(
        createJobsThunk(
          formattedData,
          calendarView,
          facilityId,
          timeZoneId,
          currentDate,
          handleClose
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarView, dispatch, postJobData, facilityId]);
  const handleDeleteJob = (id: string, reason: string, userId: string) => {
    dispatch(
      deleteJobThunk(
        id,
        reason,
        calendarView,
        facilityId,
        timeZoneId,
        currentDate,
        handleClose,
        userId
      )
    );
  };

  const handleMarkJobAsCompletedClick = (job: JobTypes) => {
    setSelectedJob(job);
    setShowMarkJobComplete(true);
  };

  const handleUnAssignUserClick = (job: JobTypes) => {
    setSelectedJob(job);
    setShowUnAssignUser(true);
  };

  const handleClose = () => {
    setOpenDrawer({ type: "", status: false });
    setPostJobData(NEW_JOB_DATA_TEMPLATE);
    setSelectedClientId(null);
    dispatch(resetClientProfile());
  };
  const validatePostJobData = useCallback(() => {
    const { valid, formData, errorObject } = validateForm(
      postJobData,
      postJobFormValidationConfig,
      postJobErrors
    );
    setPostJobData(formData);
    if (valid) {
      !isLoading && handlePostJobSave();
    } else {
      setPostJobErrors(errorObject);
    }
  }, [postJobErrors, postJobData, isLoading, handlePostJobSave]);

  const handleEditJob = useCallback(
    (job: any) => {
      if (job.contactMobile && job.contactMobile.startsWith("+1 ")) {
        job.contactMobile = job.contactMobile?.slice(3);
      }
      job.shiftDate = getDateFromUtcDate(job.shiftStartDateTime, timeZoneId);
      setPostJobData({ ...job, positions: 1 });
      setOpenDrawer({ status: true, type: "edit-job" });
    },
    [setOpenDrawer, timeZoneId]
  );
  const handleNameClick = useCallback(
    (clientId: number) => {
      setOpenDrawer({ ...openDrawer, type: "user-profile" });
      setSelectedClientId(clientId);
    },
    [openDrawer, setOpenDrawer]
  );
  const handleChange = useCallback(
    (id: string, value: string | number | null) => {
      if (id === "jobCategory" && value) {
        handleJobCategoryChange(value.toString());
      } else if (id === "contactPhone" && value) {
        handlePhoneChange(value.toString());
      } else if ((id === "contactName" || id === "postedBy") && value) {
        handleName(id, value?.toString());
      } else {
        if (id === "jobCategoryId" && value) {
          setHourlyRateOnJobCategoryChange(value.toString());
        }
        setPostJobData({ ...postJobData, [id]: value });
        setPostJobErrors({ ...postJobErrors, [id]: "" });
      }
    },
    [
      postJobData,
      postJobErrors,
      handleJobCategoryChange,
      handleName,
      handlePhoneChange,
      setHourlyRateOnJobCategoryChange,
    ]
  );
  const SummaryTitle = () => (
    <div>
      {t("jobSchedule.jobPositions")}{" "}
      <span className="text-grey text-[16px] ml-2">{selectedDay}</span>
    </div>
  );
  const DrawerTitle = () => {
    if (openDrawer) {
      if (openDrawer.type === "post-job") {
        return <>{t("jobSchedule.postJob")}</>;
      } else if (openDrawer.type === "summary") {
        return <SummaryTitle />;
      } else if (
        openDrawer.type === "user-profile" ||
        openDrawer.type === "edit-job"
      ) {
        return (
          <div
            role="button"
            className="w-fit"
            onClick={() => setOpenDrawer({ ...openDrawer, type: "summary" })}
          >
            <Tooltip title="Back" placement="right">
              <Icon name="keyboard_backspace" />
            </Tooltip>
          </div>
        );
      }
    }
    return <></>;
  };
  const setSelectedContact = (contact: ContactTypes) => {
    const { email, name, officePhone, mobile } = contact;
    let phone = mobile ? mobile : officePhone;
    if (phone.includes("+1 ")) {
      phone = phone.replace("+1 ", "");
    } else if (phone.includes("+1")) {
      phone = phone.replace("+1", "");
    }
    setPostJobData((prev) => ({
      ...prev,
      contactMobile: phone,
      contactName: name,
      contactEmail: email,
    }));
  };
  return (
    <>
      <SectionName className="mb-2" />
      <div className="h-full bg-white flex">
        <div className="main-container  overflow-auto mr-3 w-full">
          <CalendarHeader
            selectedDate={currentDate}
            onToday={onToday}
            onPrevious={onPrevious}
            onNext={onNext}
            showPanel={handleClickOnAddButton}
            calendarView={calendarView}
            setCalendarView={handleCalenderViewChange}
          />
          <div className="calendar-container">
            <Month
              weeks={month.weeks}
              showSummary={handleClickOnDate}
              selectedDay={selectedDay}
              showPanel={handleClickOnAddButton}
            />
          </div>
        </div>
        {openDrawer &&
          openDrawer.status &&
          openDrawer.name === SIDE_PANEL_NAMES.calendar && (
            <SideDrawer
              open={
                openDrawer.status &&
                openDrawer.name === SIDE_PANEL_NAMES.calendar
              }
              onClose={() => handleConfirmation("close-button")}
              title={<DrawerTitle />}
              showFooter={
                openDrawer.type === "post-job" || openDrawer.type === "edit-job"
              }
              disablePrimaryBtn={hourlyRateNotPresent || isLoading}
              primaryButton={
                openDrawer.type === "edit-job"
                  ? t("common.save") || ""
                  : t("common.post") || ""
              }
              onSubmit={validatePostJobData}
            >
              <RightPane
                type={openDrawer.type}
                postJobData={postJobData}
                postJobErrors={postJobErrors}
                handleChange={handleChange}
                jobCategoryOptions={jobCategoryOptions}
                shiftOptions={shiftOptions}
                selectedDay={selectedDay}
                handleEdit={handleEditJob}
                handleNameClick={handleNameClick}
                selectedClientId={selectedClientId}
                handleDeleteJob={handleDeleteJob}
                handleMarkJobAsCompleted={handleMarkJobAsCompletedClick}
                handleUnAssignUser={handleUnAssignUserClick}
                selectedContact={setSelectedContact}
                hourlyRateNotPresent={hourlyRateNotPresent}
              />
            </SideDrawer>
          )}
      </div>
      {resetConfirmation && (
        <ConfirmationModal
          text={t("alertMessages.discardConfirmation")}
          open={resetConfirmation.status}
          handleYes={handleYes}
          handleNo={handleNo}
        />
      )}
      {showUnAssignUser && (
        <ConfirmationModal
          text={t("alertMessages.unAssignUserConfirmation")}
          open={showUnAssignUser}
          handleYes={handleUnAssignJobConfirmation}
          handleNo={() => {
            setShowUnAssignUser(false);
          }}
        />
      )}
      {
        <MarkJobCompletedForm
          show={showMarkJobComplete}
          job={selectedJob}
          calendarView={calendarView}
          currentDate={currentDate}
          onHide={() => {
            setShowMarkJobComplete(false);
          }}
          handleClose={handleClose}
        />
      }
    </>
  );
};

export default Calendar;
