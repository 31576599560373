import { EMPLOYEE_PROFILE } from "../../constants/defaultData";
import { PAGINATION } from "../../constants/pagination";
import { ClientProfileTypes } from "./../../models/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ClientsReducerState {
  clients: ClientProfileTypes[];
  loading: boolean;
  clientProfile: ClientProfileTypes;
  clientProfileCompletionPercentage: number;
  totalRecords: number;
  hasNextPage: boolean;
  isUpdateInProgress: boolean;
  isDocUploadInProgress: boolean;
}
const initialState: ClientsReducerState = {
  clients: [],
  loading: false,
  clientProfile: EMPLOYEE_PROFILE,
  clientProfileCompletionPercentage: 0,
  totalRecords: 0,
  hasNextPage: false,
  isUpdateInProgress: false,
  isDocUploadInProgress: false,
};

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<ClientProfileTypes[]>) => {
      state.clients = action.payload || [];
    },
    setNewClient: (state, action: PayloadAction<ClientProfileTypes>) => {
      state.clients = [action.payload, ...state.clients]
    },
    setClientProfile: (state, action: PayloadAction<ClientProfileTypes>) => {
      state.clientProfile = action.payload;
    },
    setClientProfileCompletionPercentage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.clientProfileCompletionPercentage = action.payload;
    },
    setClientsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setClientUpdateInprogress: (state, action: PayloadAction<boolean>) => {
      state.isUpdateInProgress = action.payload;
    },
    setDocUploadInprogress: (state, action: PayloadAction<boolean>) => {
      state.isDocUploadInProgress = action.payload;
    },
    setClientRecordsCount: (state, action: PayloadAction<number>) => {
      state.totalRecords = action.payload || 0;
    },
    setClientHasNextPage: (state, action: PayloadAction<boolean>) => {
      state.hasNextPage = action.payload || false;
    },
    resetClientState: (state) => {
      state.clientProfile = EMPLOYEE_PROFILE;
      state.clients = [];
      state.totalRecords = 0;
    },
    resetClientProfile: (state) => {
      state.clientProfile = EMPLOYEE_PROFILE;
    },
    removeFirstClients: (state) => {
      state.clients = state.clients.slice(PAGINATION.defaultPageSize);
    },
    removeLastClients: (state) => {
      state.clients = state.clients.slice(0, PAGINATION.defaultPageSize * -1);
    },
    setCurrentEmployers: (
      state,
      action: PayloadAction<{fullTime: number[]; casual: number[]}>,
    ) => {
      state.clientProfile = {...state.clientProfile, currentEmployer: action.payload};
    },
  },
});

export const {
  setClients,
  setClientProfile,
  setClientProfileCompletionPercentage,
  setClientRecordsCount,
  resetClientProfile,
  resetClientState,
  setClientsLoading,
  removeFirstClients,
  setClientHasNextPage,
  removeLastClients,
  setCurrentEmployers,
  setNewClient,
  setClientUpdateInprogress,
  setDocUploadInprogress,
} = clientsSlice.actions;

export default clientsSlice.reducer;
