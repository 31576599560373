import {
  isValidEmail,
  isValidHourlyRate,
  isValidPhone,
} from "../../helpers/validationHelpers";

export const jobCategoryFormValidationConfig = {
  title: {
    required: "Job category title is required.",
  },
  hourlyRate: {
    required: "Hourly rate is required.",
    regex: {
      callback: isValidHourlyRate,
      message: "Please provide valid hourly rate.",
    },
  },
  description: {
    required: "Job description is required.",
  },
  contactEmail: {
    regex: {
      callback: isValidEmail,
      message: "Please provide a valid email.",
    },
  },
  contactMobile: {
    regex: {
      callback: isValidPhone,
      message: "Please provide a valid phone number.",
    },
  },
};
