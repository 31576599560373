import { ShiftErrorTypes, ShiftTypes } from "../../models/types";
import InputField from "../common/InputField";
import { useTranslation } from "react-i18next";
import ShiftInterval from "../common/ShiftInterval";

type ShiftFormTypes = {
  shiftData: ShiftTypes;
  errors: ShiftErrorTypes;
  onChange: (id: string, value: string | number | null) => void;
  validateShiftName: () => void;
};

const ShiftForm = (props: ShiftFormTypes) => {
  const { shiftData, errors, onChange, validateShiftName } = props;
  const { t } = useTranslation();

  return (
    <div>
      <InputField
        type="text"
        id="title"
        label={t("settings.shiftName") || ""}
        value={shiftData.title}
        onChange={onChange}
        onBlur={(id: string, value: string | number | null) => {
          validateShiftName();
        }}
        required={true}
        message={errors.title}
        className="mb-1"
      />
      <ShiftInterval
        startTime={shiftData.startTime}
        finishTime={shiftData.endTime}
        onChange={onChange}
        startTimeError={errors.startTime}
        finishTimeError={errors.endTime}
        finishTimeId="endTime"
      />
    </div>
  );
};

export default ShiftForm;
