import { Progress } from "antd"
import { useAppSelector } from "../../redux/hooks"
import ProfileProgressDetails from "./ProfileProgressDetails"

const ProfileProgress = () => {
      const profileStatus = useAppSelector(
        (state) => state.clientsReducer.clientProfile.profileStatus
      );
    return (
      <div className="w-3/4 flex items-center justify-between">
        <Progress
          percent={profileStatus?.completionPercentage}
          showInfo={false}
          status={
            profileStatus?.completionPercentage < 100 ? "exception" : undefined
          }
        />
        <ProfileProgressDetails />
      </div>
    );
}

export default ProfileProgress;