import { Card, Tooltip } from "antd";
import Icon from "../common/Icon";
import ResetPassword from "../facilities/UserManagement";
import { useTranslation } from "react-i18next";
import { FacilitiesTypes } from "../../models/types";
import { useState } from "react";
import AddUserForm from "../facilities/AddUserForm";
import useUserRole from "../hooks/useUserRole";

type Props = {
  facilityDetails: FacilitiesTypes;
};

const UserManagementTable = (props: Props) => {
  const { t } = useTranslation();
  const { facilityDetails } = props;
  const { isConsultant, isFacilityAdmin } = useUserRole();
  const [showAddUser, setShowAddUser] = useState(false);
  return (
    <Card
      className="shadow-md my-4 -pt-2"
      title={
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Icon name="person_add" fontSize={18} className="mr-1" />
            {t("facilities.userManagement")}
          </div>
          {(isConsultant || isFacilityAdmin) && (
            <Tooltip title={t("settings.createUser")}>
              <span className="bg-[#1677ff] text-white p-1 flex justify-center items-center rounded-[50%] hover:bg-[#1890ff]">
                <Icon
                  role="button"
                  name="add"
                  fontSize={18}
                  className="font-semibold"
                  onClick={() => {
                    setShowAddUser(true);
                  }}
                />
              </span>
            </Tooltip>
          )}
        </div>
      }
    >
      <div className="p-4">
        <ResetPassword
          facilityId={facilityDetails.id}
          showRoleOnTooltip={false}
        />
      </div>
      <AddUserForm
        showForm={showAddUser}
        hideForm={() => setShowAddUser(false)}
        facilityId={facilityDetails.id}
        facilityName={facilityDetails.title}
      />
    </Card>
  );
};

export default UserManagementTable;
