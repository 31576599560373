import UnreadDot from "./UnreadDot";
import { Notification } from "../../../models/types";
import { useMemo } from "react";
import { getFromNow } from "../../../helpers/helpers";

type Props = {
  notification: Notification;
  message?: string;
  children?: React.ReactNode;
};

const NotificationText = (props: Props) => {
  const customMessage = props.message;
  const { message, notificationStatus, createdOn } = props.notification;

  const timeStamp = useMemo(() => {
    return getFromNow(createdOn);
  }, [createdOn]);

  if (props.children) {
    return (
      <div>
        <div className="flex">
          <div className="text-wrap"> {props.children}</div>
          {notificationStatus !== "read" && (
            <UnreadDot className="mt-[8px] ml-1" />
          )}
        </div>
        <div className="small-text light-text mt-1">{timeStamp}</div>
      </div>
    );
  }
  return (
    <div>
      <div className="flex">
        <div className="text-wrap">{customMessage ?? message}</div>
        {notificationStatus !== "read" && (
          <UnreadDot className="mt-[8px] ml-1" />
        )}
      </div>
      <div className="small-text light-text mt-1">{timeStamp}</div>
    </div>
  );
};

export default NotificationText;
