import { Divider } from "antd";
import ScheduleJob from "./ScheduleJob";
import ApproveUserProfile from "./ApproveUserProfile";
import DisableClient from "./DisableClient";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { disableClientForFacilityThunk, enableClientForFacilityThunk } from "../../redux/thunks/clients";
import Icon from "../common/Icon";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

type Props = {
  isProfileApproved: boolean;
  hasPendingDocs: boolean;
  clientId: number | null;
  isClientDisabled: boolean;
};

export const ActionButtons = (props: Props) => {
  const {
    isProfileApproved,
    clientId,
    hasPendingDocs,
    isClientDisabled,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id
  );
  const clientProfile = useAppSelector(
    (state) => state.clientsReducer.clientProfile
  );
  const [disableDisableClientButton, setDisableDisableClientButton] = useState(false);

  useEffect(() => {
    const isFullTimeInCurrentFacility = clientProfile.fullTime?.find((item) => item.facilityId === facilityId);
    const isCasualInCurrentFacility = clientProfile.casual?.find((item) => item.facilityId === facilityId);
    if (isFullTimeInCurrentFacility || isCasualInCurrentFacility) {
      setDisableDisableClientButton(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientProfile]);

  const handleDisableClient = () => {
    if (clientId && facilityId) {
      dispatch(disableClientForFacilityThunk(clientId, Number(facilityId)));
    }
  };

  const handleEnableClient = () => {
    if (clientId && facilityId) {
      dispatch(enableClientForFacilityThunk(clientId, Number(facilityId)));
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div
          role="button"
          className="icon-wrapper-lg"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Icon name="west" fontSize={28} className="text-semibold" />
        </div>
        <div className="p-2 flex justify-end gap-3">
          {
            <DisableClient
              isDisabled={disableDisableClientButton}
              isClientDisabled={isClientDisabled}
              onDisableClientClick={handleDisableClient}
              onEnableClientClick={handleEnableClient}
            />
          }
          {!isProfileApproved && (
            <ApproveUserProfile
              clientId={clientId}
              hasPendingDocs={hasPendingDocs}
            />
          )}
          {isProfileApproved && (
            <ScheduleJob
              disabled={isClientDisabled}
            />
          )}
        </div>
      </div>

      <Divider className="mb-2 mt-1" />
    </>
  );
};
