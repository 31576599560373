import { useState } from "react";
import { Checkbox } from "antd";
import InputField from "../../common/InputField";
import ConfirmationModal from "../../common/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import dayjs from "dayjs";
import { DATE_FORMAT_PRIMARY } from "../../../constants/defaults";
import { EducationTypes } from "../../../models/types";
import Datepicker from "../../common/Datepicker";
import { updateEducationInfoThunk } from "../../../redux/thunks/clients";

type EducationHistoryFormProps = {
  educationHistory: EducationTypes;
  showForm: boolean;
  hideForm: () => void;
};

const EducationHistoryForm = (props: EducationHistoryFormProps) => {
  const { showForm, hideForm, educationHistory } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clientProfile = useAppSelector(
    (state) => state.clientsReducer.clientProfile
  );
  const isUpdateInProgress = useAppSelector(
    (state) => state.clientsReducer.isUpdateInProgress
  );
  const [formState, setFormState] = useState<EducationTypes>(educationHistory);

  const [errors, setErrors] = useState<any>({
    school: "",
    degree: "",
    title: "",
    fieldOfStudy: "",
    startDate: "",
    endDate: "",
  });

  const handleChange = (id: string, value: any) => {
    if (id === "isPresent" && value === true) {
      setFormState({ ...formState, endDate: null, isPresent: value });
    } else {
      setFormState({ ...formState, [id]: value });
    }

    if (errors[id]) {
      setErrors({ ...errors, [id]: "" });
    }
  };

  const handleValidation = () => {
    let valid = true;
    const errorsCopy: any = { ...errors };

    if (!formState?.school.trim()) {
      valid = false;
      errorsCopy.school = t("errors.schoolRequired");
    }

    if (!formState?.degree.trim()) {
      valid = false;
      errorsCopy.degree = t("errors.degreeRequired");
    }

    if (!formState?.fieldOfStudy.trim()) {
      valid = false;
      errorsCopy.fieldOfStudy = t("errors.fieldOfStudyRequired");
    }

    if (!formState?.startDate) {
      valid = false;
      errorsCopy.startDate = t("errors.startDateRequired");
    }

    if (!formState?.isPresent && !formState?.endDate) {
      valid = false;
      errorsCopy.endDate = t("errors.endDateRequired");
    }

    setErrors(errorsCopy);

    if (valid) {
      handleSave();
    }
  };

  const handleSave = () => {
    let data;
    if (formState?.isPresent) {
      data = {
        ...formState,
        userId: clientProfile?.id,
        isEducationCompletion: true,
        endDate: null,
      };
    } else {
      data = {
        ...formState,
        userId: clientProfile?.id,
        isEducationCompletion: true,
      };
    }

    dispatch(
      updateEducationInfoThunk({ payload: { ...data }, callback: handleClose })
    );
  };

  const handleClose = () => {
    // Reset form and errors after saving
    setFormState({
      id: "",
      school: "",
      degree: "",
      fieldOfStudy: "",
      startDate: "",
      endDate: "",
      grade: "",
      isPresent: false,
      isEducationCompletion: false,
    });

    setErrors({
      school: "",
      degree: "",
      title: "",
      fieldOfStudy: "",
      startDate: "",
      endDate: "",
    });

    hideForm(); // Close the form after saving
  };

  return (
    <ConfirmationModal
      open={showForm}
      title={`${t("clientProfile.updateEducationalHistory")}`}
      handleYes={handleValidation}
      handleNo={hideForm}
      primaryButtonText={t("common.save") || ""}
      secondaryButtonText={t("common.close") || ""}
      isPrimaryButtonDisabled={isUpdateInProgress}
      text={
        <div className="pt-4">
          <InputField
            id="school"
            type="text"
            label="School *"
            value={formState?.school}
            onChange={handleChange}
            message={errors?.school}
            status={errors?.school ? "error" : ""}
          />
          <InputField
            id="degree"
            type="text"
            label="Degree *"
            value={formState?.degree}
            onChange={handleChange}
            message={errors?.degree}
            status={errors?.degree ? "error" : ""}
          />
          <InputField
            id="fieldOfStudy"
            type="text"
            label="Field of Study *"
            value={formState?.fieldOfStudy}
            onChange={handleChange}
            message={errors?.fieldOfStudy}
            status={errors?.fieldOfStudy ? "error" : ""}
          />
          <Datepicker
            id="startDate"
            value={
              formState?.startDate ? dayjs(formState?.startDate) : undefined
            }
            onChange={handleChange}
            label={t("clientProfile.startDate") || ""}
            required={true}
            message={errors?.startDate}
            placeholder={DATE_FORMAT_PRIMARY}
            onlyFutureDate={false}
            status={errors?.startDate ? "error" : ""}
          />
          <div className={`flex flex-col mx-2 h[80px] w-full`}>
            <Checkbox
              id="isPresent"
              checked={formState?.isPresent}
              className="mb-3"
              onChange={(e: any) => handleChange("isPresent", e.target.checked)}
            >
              I am currently pursuing this course
            </Checkbox>
          </div>
          <Datepicker
            id="endDate"
            value={formState?.endDate ? dayjs(formState?.endDate) : undefined}
            onChange={handleChange}
            label={t("clientProfile.endDate") || ""}
            required={true}
            message={errors?.endDate}
            placeholder={DATE_FORMAT_PRIMARY}
            onlyFutureDate={false}
            disabled={formState?.isPresent}
            status={errors?.endDate ? "error" : ""}
          />
        </div>
      }
    />
  );
};

export default EducationHistoryForm;
