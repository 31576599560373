import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import Icon from "../common/Icon";

type Props = {
  onSuggest: () => void;
  isLoading: boolean;
  onApprove: () => void;
  disableSuggest: boolean;
  disableApprove: boolean;
};

const ActionButtons = (props: Props) => {
  const { onSuggest, isLoading, onApprove, disableApprove, disableSuggest } =
    props;
  const { t } = useTranslation();

  return (
    <div className="flex action-buttons mr-4">
      <Button
        type="primary"
        id="suggest-orientation-btn"
        icon={<Icon name="insert_invitation" fontSize={16} className="mr-1" />}
        className="mr-3 flex justify-center items-center"
        onClick={onSuggest}
        size="small"
        loading={isLoading}
        disabled={disableSuggest}
      >
        {t("orientations.suggest")}
      </Button>
      {!false && (
        <Button
          type="primary"
          id="approve-orientation-btn"
          icon={<Icon name="done" fontSize={16} className="mr-1" />}
          className="flex justify-center items-center"
          size="small"
          loading={isLoading}
          onClick={onApprove}
          disabled={disableApprove}
        >
          {t("common.approve")}
        </Button>
      )}
    </div>
  );
};

export default ActionButtons;
