import React, { useEffect, useState } from 'react';
import { Button } from "antd";
import Icon from "../common/Icon";
import ConfirmationModal from '../common/ConfirmationModal';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { disableFacilityThunk, enableFacilityThunk } from '../../redux/thunks/facility';

interface FacilityToggleButtonProps {
    facilityId: string;
}

const FacilityStatusToggleButton: React.FC<FacilityToggleButtonProps> = ({ facilityId }) => {
    const dispatch = useAppDispatch();
    const facilityStatus = useAppSelector((state) => state.facilitiesReducer.currentFacility?.enabled);
    const currentFacility = useAppSelector((state) => state.facilitiesReducer.currentFacility);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const onToggleClick = () => {
        setShowConfirmation(true);
    };

    const handleConfirmation = (proceed: boolean) => {
        setShowConfirmation(false);
        if (proceed) {
            if (facilityStatus) {
                const facility = { ...currentFacility, enabled: !facilityStatus };
                dispatch(disableFacilityThunk(facilityId, facility));
            }
            else {
                const facility = { ...currentFacility, enabled: !facilityStatus };
                dispatch(enableFacilityThunk(facilityId, facility));
            }
        }
    };

    const closeConfirmation = () => {
        setShowConfirmation(false);
    };

    return (
        <div>
            <span className="p-1 flex justify-center items-center">
                <Button
                    id="suggest-orientation-btn"
                    type="default"
                    icon={<Icon name={facilityStatus ? "unpublished" : "check_circle"} fontSize={24} />}
                    className="mr-3 flex justify-center items-center"
                    onClick={onToggleClick}
                    size="middle"
                >
                    {facilityStatus ? "Disable" : "Enable"}
                </Button>
            </span>

            {showConfirmation && (
                <ConfirmationModal
                    text={`Are you sure you want to ${facilityStatus ? "disable" : "enable"} facility?`}
                    open={showConfirmation}
                    handleYes={() => handleConfirmation(true)}
                    handleNo={closeConfirmation}
                />
            )}
        </div>
    );
};

export default FacilityStatusToggleButton;
