import { Notification } from "../../../../models/types";
import NotificationText from "../NotificationText";
import NotificationAvatar from "../../../common/UserAvatar";
import dayjs from "dayjs";
import { DATE_MMM_YEAR } from "../../../../constants/defaults";
import { getTimeFromUtcDate } from "../../../../helpers/dateTime.helper";
import { getJobCategory } from "../../../../helpers/helpers";

type Props = {
  notification: Notification;
  handleNotificationOpen: (id: number) => void;
};
const OrientationAccepted = (props: Props) => {
  const { notification, handleNotificationOpen } = props;
  const {
    clientFirstName,
    clientLastName,
    jobCategory,
    approvedDate,
    facility,
    timezoneId,
  } = notification;
  const Message = () => {
    const date = dayjs(approvedDate).format(DATE_MMM_YEAR);
    const time =
      (approvedDate && getTimeFromUtcDate(approvedDate, timezoneId)) || "";

    return (
      <div>
        <b>{`${clientFirstName} ${clientLastName} `}</b> has{" "}
        <b>accepted orientation</b> for <b>{getJobCategory(jobCategory)}</b> job at{" "}
        <b>{facility}</b> on <b>{date}</b> at <b>{time}</b>
      </div>
    );
  };
  return (
    <div onClick={() => handleNotificationOpen(notification.id)}>
      <div className="p-4 hover:bg-[#d9d9d9] flex items-start">
        <div className="mr-4">
          <NotificationAvatar
            src={notification.clientAvatarUrl || notification.facilityLogo}
            firstName={notification.clientFirstName}
            lastName={notification.clientLastName}
          />
        </div>
        <NotificationText notification={notification}>
          <Message />
        </NotificationText>
      </div>
    </div>
  );
};

export default OrientationAccepted;
