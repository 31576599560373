import React, { useMemo } from "react";
import Icon from "./Icon";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
  id: string;
  variant?: "outline" | "filled";
  title: string;
  onClick: () => void;
  icon?: string;
  size?: "sm" | "default";
  isLoading?: boolean;
  disabled?: boolean;
};
const BTN_CLASSES = {
  display: "flex",
  alignItems: "center",
  border: "1px solid black",
  borderRadius: "8px",
};

const OUTLINE_BTN_CLASSES = {
  color: "black",
  backgroundColor: "white",
};
const FILLED_BTN_CLASSES = {
  color: "white",
  backgroundColor: "black",
};
const DISABLED_BTN_CLASSES = {
  color: "black",
  backgroundColor: "rgb(177, 177, 177)",
  border: "1px solid #DDDDDD",
};
const Button = (props: Props) => {
  const {
    id,
    variant = "filled",
    title,
    onClick,
    icon,
    size = "default",
    isLoading,
    disabled,
  } = props;
  const buttonClasses = useMemo(() => {
    let buttonStyles = {};
    if (variant === "filled") {
      buttonStyles = {
        ...BTN_CLASSES,
        ...FILLED_BTN_CLASSES,
        padding: size === "default" ? "4px 12px" : "2px 8px",
        gap: size === "default" ? "8px" : "4px",
      };
    } else {
      buttonStyles = {
        ...BTN_CLASSES,
        ...OUTLINE_BTN_CLASSES,
        padding: size === "default" ? "4px 8px" : "2px 4px",
        gap: size === "default" ? "8px" : "4px",
      };
    }
    if (disabled) {
      buttonStyles = { ...buttonStyles, ...DISABLED_BTN_CLASSES };
    }
    return buttonStyles;
  }, [variant, disabled, size]);
  return (
    <div
      id={id}
      style={buttonClasses}
      role={disabled ? "div" : "button"}
      onClick={() => !isLoading && !disabled && onClick()}
      aria-disabled={disabled}
    >
      {isLoading ? (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: size === "sm" ? "14px" : "16px",
                color: variant === "filled" ? "white" : "black",
              }}
            />
          }
        />
      ) : (
        icon && <Icon fontSize={size === "sm" ? 16 : 18} name={icon} />
      )}
      <span className={size === "sm" ? "text-xs" : "text-sm"}>{title}</span>
    </div>
  );
};

export default Button;
