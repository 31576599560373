import axios from "axios";
import { baseUrl, getInstance } from ".";
import { SignInTypes } from "../models/types";
import dayjs from "dayjs";

const instance = getInstance(baseUrl);

export const login = async (payload: SignInTypes) => {
  return instance.post("/authentication/web/login", payload);
};
export const fetchMasterData = async () => {
  return instance.get("/master/data");
};
export const fetchShifts = async () => {
  const URL = `${process.env.REACT_APP_IRIS_API_BASE_URL}/shifts`;
  return axios.get(URL);
};
export const fetchJobTemplates = async () => {
  const URL = `${process.env.REACT_APP_IRIS_API_BASE_URL}/job-categories`;
  return axios.get(URL);
};
export const fetchFacilityTypes = async () => {
  const URL = `${process.env.REACT_APP_IRIS_API_BASE_URL}/facility-types`;
  return axios.get(URL);
};
export const fetchProvinces = async () => {
  const URL = `${process.env.REACT_APP_IRIS_API_BASE_URL}/provinces`;
  return axios.get(URL);
};
export const fetchFacilityDetails = async (id: string) => {
  const URL = `${process.env.REACT_APP_IRIS_API_BASE_URL}/facilities/${id}`;
  return axios.get(URL);
};
export const fetchEmployeeProfile = async (id: number | null) => {
  const URL = `${process.env.REACT_APP_IRIS_API_BASE_URL}/employee-profile`;
  return axios.get(URL);
};

export const logout = async (userId: number) => {
  return instance.post(`/authentication/logout/${userId}/null`);
};

export const getPreSignedUrl = async (fileId: number) => {
  return instance.get(`/file/${fileId}`);
};
export const getPreSignedUrls = async (fileIds: number[]) => {
  const requests = fileIds.map((f) => instance.get(`/file/${f}`));
  return Promise.allSettled(requests);
};
export const getInvoicePreSignedUrl = async (invoiceId: number) => {
  return instance.get(`/file/download/invoice/${invoiceId}`);
};

export const getUserTimeZoneFromGoogle = async (
  lat: string | number,
  long: string | number
) => {
  const now = dayjs();
  const timeStamp = now.unix();
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${long}&timestamp=${timeStamp}&key=${apiKey}`;
  const {
    data: { timeZoneId, timeZoneName },
  } = await axios.get(url);
  return { timeZoneId, timeZoneName };
};
