import { DatePicker } from "antd";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { DATE_FORMAT_PRIMARY } from "../../constants/defaults";
import {
  getTimeStringInTimezone,
  getTodayInTimeZone,
} from "../../helpers/dateTime.helper";
import useFacilityTimezone from "../hooks/useFacilityTimezone";
import { useMemo } from "react";

type DATE_PICKER_PROP_TYPES = {
  id: string;
  className?: string;
  value: Dayjs | undefined;
  onChange: (id: string, value: any) => void;
  disabled?: boolean;
  size?: "large" | "small";
  label?: string;
  required?: boolean;
  onlyFutureDate?: boolean;
  message?: string;
  status?: "error" | "warning" | "";
  placeholder?: string;
};
const Datepicker = (props: DATE_PICKER_PROP_TYPES) => {
  const {
    id,
    value,
    onChange,
    disabled = false,
    size,
    label,
    required = false,
    message,
    status = "",
    placeholder = "Select a date",
    className,
    onlyFutureDate = true,
  } = props;
  const { timeZoneId } = useFacilityTimezone();

  const currentFacilityDate = useMemo(() => {
    return getTimeStringInTimezone(
      getTodayInTimeZone(timeZoneId).format(DATE_FORMAT_PRIMARY),
      timeZoneId
    );
  }, [timeZoneId]);

  return (
    <div className={`flex flex-col mx-2 h[80px] ${className || ""}`}>
      <label className="mb-1 text-xs text-[#777676]">
        {label} {required && <span className="text-[#ff4d4f]">*</span>}
      </label>
      {onlyFutureDate ? <DatePicker
        id={id}
        onChange={(newValue) => {
          const val = newValue
            ? dayjs(newValue).format(DATE_FORMAT_PRIMARY)
            : "";
          onChange(id, val);
        }}
        disabled={disabled}
        status={status}
        size={size}
        placeholder={placeholder}
        value={value}
        showToday={false}
        disabledDate={(calendarDate) =>
          calendarDate.isBefore(currentFacilityDate)
        }
      /> : <DatePicker
        id={id}
        onChange={(newValue) => {
          const val = newValue
            ? dayjs(newValue).format(DATE_FORMAT_PRIMARY)
            : "";
          onChange(id, val);
        }}
        disabled={disabled}
        status={status}
        size={size}
        placeholder={placeholder}
        value={value}
        showToday={false}
      />}
      <span className="error-text">{message}</span>
    </div>
  );
};

export default Datepicker;
