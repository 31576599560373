import { MarkJobAsCompletedRequestType, PostJobTypes, WithdrawJobRequest } from "../models/types";
import { baseUrl, getInstance } from ".";

const instance = getInstance(baseUrl);

export type GetJobsByDate = {
  date: string;
  facilityId: string;
};

export type GetJobsSummaryByDates = {
  facilityId: string;
  dates: string[];
};

export const createJobs = async (payload: PostJobTypes) => {
  return instance.post("/job", payload);
};

export const getJobsByDate = async (payload: GetJobsByDate) => {
  return instance.post("/job/by-date", payload);
};

export const getJobSummaryByDates = async (payload: GetJobsSummaryByDates) => {
  return instance.post("/job/summary/by-dates", payload);
};

export const updateJob = async (payload: PostJobTypes) => {
  return instance.put("/job", payload);
};
export const deleteJob = async (jobId: string, reason: string, userId?: string) => {
  return instance.delete(`/job/${jobId}`, { data: { reason, userId } });
};

export const getUpcomingJobs = async () => {
  return instance.get(`/job/all/upcoming`);
};

export const markJobAsComplete = async (payload: MarkJobAsCompletedRequestType) => {
  return instance.post(`/job/mark-job-as-completed`, payload);
};

export const unAssignJob = async (payload: WithdrawJobRequest) => {
  return instance.post(`/job/withdraw-job`, payload);
};

