import { ShiftErrorTypes, ShiftTypes } from "../../models/types";

export const SHIFT_TEMPLATE: ShiftTypes = {
  id: "",
  startTime: "",
  endTime: "",
  title: "",
  hours: "",
  facilityId: "",
};

export const SHIFT_ERROR_TEMPLATE: ShiftErrorTypes = {
  startTime: "",
  endTime: "",
  title: "",
};

export const location = {
  address: "1600 Amphitheatre Parkway, Mountain View, california.",
  lat: 37.42216,
  lng: -122.08427,
};
