import { Result } from "antd";
import { Component, ErrorInfo, ReactNode } from "react";
import { logComponentErrorToSentry } from "./helpers/helpers";
interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error?: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: "",
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): State {
    console.error("Uncaught error:", error, errorInfo);
    logComponentErrorToSentry(error, errorInfo);

    return { hasError: true, error: JSON.stringify(error) };
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="h-screen w-full flex flex-col justify-center items-center pb-32">
          <Result
            status="500"
            subTitle="Sorry, something went wrong. We are working on it."
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
