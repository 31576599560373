import { t } from "i18next";
import { ServiceCharge } from "../../models/types"
import { Table } from "antd";
import SectionName from "../common/SectionName";

type Props = {
    data: ServiceCharge[],
    onTitleClick: (id: number) => void;
}


function ServiceChargeTable(props: Props) {
    const { data, onTitleClick } = props;

    const columns = [
        {
            title: t("serviceCharge.title"),
            width: "25%",
            dataIndex: "title",
            key: "title",
            render: (text: any, record: any) => {
                return (
                    <div className="link-text" onClick={() => onTitleClick(record.id)}>
                        {record.title}
                    </div>
                );
            }
        },
        {
            title: t("serviceCharge.rate"),
            width: "25%",
            dataIndex: "rateCut",
            key: "rate",
            render: (text: any, record: any) => (
                <div>
                    {record.isFlatRateCut ? "$" : ""}{record.rateCut}{record.isFlatRateCut ? "" : "%"}
                </div>
            )
        }
    ];



    return (
        <div>
            <SectionName className="mb-2" />
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                sticky
                rowClassName={() => 'editable-row'}
                size="small"
            />
        </div>
    )
}

export default ServiceChargeTable;