import * as Sentry from "@sentry/react";
import React from "react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";

if (process.env.NODE_ENV !== 'development') {
    const BLOCKED_URLS = ["localhost"];
    const dsn = process.env.REACT_APP_SENTRY_DSN;
    Sentry.init({
        dsn: dsn,
        integrations: [
            Sentry.browserTracingIntegration(),
            new Sentry.BrowserProfilingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],
        denyUrls: BLOCKED_URLS,
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 50% of the transactions
        tracePropagationTargets: [/^https:\/\/api\.blue\.carelynk\.ca/],
        // Session Replay
        replaysSessionSampleRate: 0.0, // This sets the sample rate at 0%.
        replaysOnErrorSampleRate: 1.0, // Monitoring 100% sessions where error occur.
        // Set profilesSampleRate to 1.0 to profile every transaction.
        // Since profilesSampleRate is relative to tracesSampleRate,
        // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
        // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
        // results in 25% of transactions being profiled (0.5*0.5=0.25)
        profilesSampleRate: 1.0,
    });
}