import "./WeekHeader.css";

const WeekHeader = () => {
  return (
    <div className="row-header fw-semibold">
      <div className="column-header day-header" id="day-header-sun">SUN</div>
      <div className="column-header day-header" id="day-header-mon">MON</div>
      <div className="column-header day-header" id="day-header-tue">TUE</div>
      <div className="column-header day-header" id="day-header-wed">WED</div>
      <div className="column-header day-header" id="day-header-thu">THU</div>
      <div className="column-header day-header" id="day-header-fri">FRI</div>
      <div className="column-header day-header" id="day-header-sat">SAT</div>
    </div>
  );
};

export default WeekHeader;
