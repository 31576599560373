import { AxiosError } from "axios";
import { FacilitiesTypes, FacilityUserPayloadTypes } from "../../models/types";
import {
  DeleteUserPayload,
  ResetUserPasswordPayload,
  UpdateUserPayload,
  createFacility,
  createFacilityUser,
  deleteUser,
  disableFacility,
  enableFacility,
  fetchFacilities,
  fetchFacilityById,
  fetchFacilityStatus,
  resetUserPassword,
  updateFacility,
  updateUser,
} from "../../services/facilities.services";
import {
  setCurrentFacility,
  setFacilities,
  setFacilitiesLoading,
  setFacilitiesUpdateProgress,
  updateFacilityInList,
} from "../reducers/facilitiesReducer";
import { AppDispatch } from "../store";
import { showToast } from "../reducers/feedbackReducer";
import { handleApiErrors, logToSentry } from "../../helpers/helpers";
import { setFacilityStatus, setFacilityTimeZone } from "../reducers/settingsReducer";
import { get } from "lodash";

export const getFacilitiesThunk =
  (
    orderBy?: string,
    orderDirection?: string,
    facilityTypeFilter?: string[],
    provinceFilter?: string[],
    search?: string,
    pageSize?: number,
    pageNumber?: number,
    callback?: () => void
  ) =>
    async (dispatch: AppDispatch) => {
      dispatch(setFacilitiesLoading(true));
      try {
        const response = await fetchFacilities(
          pageSize,
          pageNumber,
          orderBy,
          orderDirection,
          facilityTypeFilter,
          provinceFilter,
          search
        );
        dispatch(setFacilities(response.data.data ?? []));
        callback && callback();
      } catch (err: any) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Facilities", "getFacilitiesThunk");
      } finally {
        dispatch(setFacilitiesLoading(false));
      }
    };
export const getFacilityByIdThunk =
  (
    id: number,
    isCurrent?: boolean,
    callback?: () => void,
    hideLoading?: boolean
  ) =>
    async (dispatch: AppDispatch) => {
      !hideLoading && dispatch(setFacilitiesLoading(true));
      try {
        const response = await fetchFacilityById(id, false, false);
        if (isCurrent) {
          dispatch(setCurrentFacility(get(response.data.data, "facility", {})));
          dispatch(
            setFacilityTimeZone(
              get(response.data.data, ["facility", "timeZoneId"], "")
            )
          );
        }
        dispatch(updateFacilityInList(response.data.data?.facility));
        if (callback) {
          callback();
        }
      } catch (err: any) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Facilities", "getFacilityByIdThunk");
      } finally {
        !hideLoading && dispatch(setFacilitiesLoading(false));
      }
    };

export const getFacilityStatusThunk =
  (facilityId: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await fetchFacilityStatus(facilityId);
      dispatch(setFacilityStatus(response.data));
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Facilities", "getFacilityStatusThunk");
    } finally {
    }
  };

export const enableFacilityThunk =
  (facilityId: string, currentFacility: FacilitiesTypes) => async (dispatch: AppDispatch) => {
    try {
      await enableFacility(facilityId);
      dispatch(setCurrentFacility(currentFacility));
      dispatch(
        showToast({
          type: "success",
          message: "Facility enabled successfully!",
          show: true,
        })
      );
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Facilities", "enableFacilityThunk");
    } finally {
    }
  };

export const disableFacilityThunk =
  (facilityId: string, currentFacility: FacilitiesTypes) => async (dispatch: AppDispatch) => {
    try {
      await disableFacility(facilityId);
      dispatch(setCurrentFacility(currentFacility));
      dispatch(
        showToast({
          type: "success",
          message: "Facility disabled successfully!",
          show: true,
        })
      );
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Facilities", "disableFacilityThunk");
    } finally {
    }
  };

export const createFacilityThunk =
  (data: {
    facilityList: FacilitiesTypes[];
    formData: FacilitiesTypes;
    callback: () => void;
  }) =>
    async (dispatch: AppDispatch) => {
      dispatch(setFacilitiesLoading(true));
      try {
        await createFacility(data.formData);
        data.callback();
        dispatch(getFacilitiesThunk());
        dispatch(
          showToast({
            type: "success",
            message: "Facility is created successfully!",
            show: true,
          })
        );
      } catch (err: any) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Facilities", "createFacilityThunk");
      } finally {
        dispatch(setFacilitiesLoading(false));
      }
    };
export const updateFacilityThunk =
  (data: {
    facilityList?: FacilitiesTypes[];
    formData: FacilitiesTypes;
    updateCurrent: boolean;
    callback: () => void;
  }) =>
    async (dispatch: AppDispatch) => {
      dispatch(setFacilitiesLoading(true));
      try {
        const response = await updateFacility(data.formData);
        const feedbackCallback = () => {
          data.callback();
          dispatch(
            showToast({
              type: "success",
              message: "Updated facility details successfully!",
              show: true,
            })
          );
          dispatch(setFacilitiesLoading(false));
        };
        if (response.data.data.id) {
          dispatch(
            getFacilityByIdThunk(
              response.data.data.id,
              data.updateCurrent,
              feedbackCallback
            )
          );
          dispatch(getFacilityStatusThunk(response.data.data.id));
        }
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
        dispatch(setFacilitiesLoading(false));
        logToSentry(err, "Facilities", "updateFacilityThunk");
      }
    };

export const createFacilityUserThunk =
  (data: { payload: FacilityUserPayloadTypes; callback?: () => void }) =>
    async (dispatch: AppDispatch) => {
      dispatch(setFacilitiesUpdateProgress(true));
      try {
        const response = await createFacilityUser(data.payload);
        if (response.data.data) {
          dispatch(
            getFacilityByIdThunk(
              Number(data.payload.facilityId),
              true,
              () => { },
              true
            )
          );
          dispatch(getFacilityStatusThunk(data.payload.facilityId));
        }
        dispatch(
          showToast({
            type: "success",
            message: "Created the user successfully!",
            show: true,
          })
        );
        data.callback && data.callback();
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Facilities", "createFacilityUserThunk");
      } finally {
        dispatch(setFacilitiesUpdateProgress(false));
      }
    };

export const resetUserPasswordThunk =
  (payload: ResetUserPasswordPayload, callback: () => void) =>
    async (dispatch: AppDispatch) => {
      dispatch(setFacilitiesUpdateProgress(true));
      try {
        await resetUserPassword(payload);
        callback();
        dispatch(
          showToast({
            type: "success",
            message: "Password is changed successfully!",
            show: true,
          })
        );
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Facilities", "resetUserPasswordThunk");
      } finally {
        dispatch(setFacilitiesUpdateProgress(false));
      }
    };

export const updateFacilityUserThunk =
  (data: {
    payload: UpdateUserPayload;
    facilityId: number;
    callback?: () => void;
  }) =>
    async (dispatch: AppDispatch) => {
      dispatch(setFacilitiesUpdateProgress(true));
      try {
        const response = await updateUser(data.payload);
        if (response.data.data) {
          dispatch(
            getFacilityByIdThunk(Number(data.facilityId), true, () => { }, true)
          );
        }
        dispatch(
          showToast({
            type: "success",
            message: "Updated user details successfully!",
            show: true,
          })
        );
        data.callback && data.callback();
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Facilities", "updateFacilityUserThunk");
      } finally {
        dispatch(setFacilitiesUpdateProgress(false));
      }
    };
export const deleteUserThunk =
  (payload: DeleteUserPayload, facilityId: number, callback: () => void) =>
    async (dispatch: AppDispatch) => {
      dispatch(setFacilitiesUpdateProgress(true));
      try {
        await deleteUser(payload);
        dispatch(getFacilityByIdThunk(Number(facilityId), true, () => { }, true));
        dispatch(getFacilityStatusThunk(facilityId?.toString()));
        dispatch(
          showToast({
            type: "success",
            message: "User is deleted successfully!",
            show: true,
          })
        );
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Facilities", "deleteUserThunk");
      } finally {
        callback();
        dispatch(setFacilitiesUpdateProgress(false));
      }
    };
