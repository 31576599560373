import { Tabs } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ClientProfileTypes } from "../../../models/types";
import Education from "./Education";
import WorkHistory from "./WorkHistory";

type ClientProfileTabsTypes = {
  clientProfile: ClientProfileTypes;
};
const ClientProfileTabs = (props: ClientProfileTabsTypes) => {
  const {
    clientProfile: { workHistory, educationHistory, documents, id },
  } = props;
  const { t } = useTranslation();
  const tabsData = useMemo(
    () => [
      {
        label: t("clientProfile.workHistory"),
        key: "1",
        children: <WorkHistory workHistory={workHistory} />,
      },
      {
        label: t("clientProfile.education"),
        key: "2",
        children: <Education education={educationHistory} />,
      }
    ],
    [t, workHistory, educationHistory, documents, id]
  );
  return <Tabs items={tabsData} />;
};

export default ClientProfileTabs;
