import AddButton from "../common/AddButton/AddButton";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import ConfirmationModal from "../common/ConfirmationModal";
import SideDrawer from "../common/SideDrawer";
import FacilitiesForm from "./FacilitiesForm";
import FacilitiesTable from "./FacilitiesTable";
import SearchBar from "../common/SearchBar";
import {
  FacilitiesErrorsTypes,
  FacilitiesTypes,
  TableFilterTypes,
} from "../../models/types";
import {
  CONTACT_DETAILS_TEMPLATE,
  FACILITIES_ERRORS_TEMPLATE,
  FACILITIES_TEMPLATE,
} from "./constants";
import { cloneDeep, isEqual } from "lodash";
import { getFacilityType, getProvinceName } from "../../helpers/helpers";
import {
  createFacilityThunk,
  getFacilitiesThunk,
  updateFacilityThunk,
} from "../../redux/thunks/facility";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import SectionName from "../common/SectionName";
import { fetchServiceChargesByFacilityThunk } from "../../redux/thunks/serviceCharge";
import useUserRole from "../hooks/useUserRole";
import { validateForm } from "../../helpers/validationHelpers";
import {
  contactFormValidationConfig,
  facilityFormValidationConfig,
} from "../settings/validationConfig";
import { setSidePanel } from "../../redux/reducers/appReducer";
import { SIDE_PANEL_NAMES } from "../../constants/sidePanel";

const Facilities = () => {
  const { t } = useTranslation();
  const { isConsultant } = useUserRole();
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  // Redux state
  const facilitiesData = useAppSelector(
    (state) => state.facilitiesReducer.facilities
  );
  const isLoading = useAppSelector((state) => state.facilitiesReducer.loading);
  const selectedFacilityIdInApp = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id
  );
  const facilityTypeData = useAppSelector(
    (state) => state.appReducer.facilityTypes
  );
  const provinceData = useAppSelector((state) => state.appReducer.provinces);

  // Form state
  const [currentFacility, setCurrentFacility] = useState<FacilitiesTypes>(
    cloneDeep(FACILITIES_TEMPLATE)
  );
  const [rightPanelData, setRightPanelData] = useState<FacilitiesTypes>(
    cloneDeep(FACILITIES_TEMPLATE)
  );
  // Form Error state
  const [errors, setErrors] = useState<FacilitiesErrorsTypes>(
    cloneDeep(FACILITIES_ERRORS_TEMPLATE)
  );
  const [logo, setLogo] = useState<any>("");
  // Filters and Sort state
  const [typeFilter, setTypeFilter] = useState<string[] | []>([]);
  const [provinceFilter, setProvinceFilter] = useState<string[] | []>([]);
  const [sort, setSort] = useState<{ key: string; order: string }>({
    key: "",
    order: "",
  });
  const openDrawer = useAppSelector((state) => state.appReducer.sidePanel);
  useEffect(() => {
    if (!openDrawer?.status) {
      // Form state
      setCurrentFacility({ ...FACILITIES_TEMPLATE });
      setRightPanelData({ ...FACILITIES_TEMPLATE });
      setErrors({ ...FACILITIES_ERRORS_TEMPLATE });
      setLogo("");
      setTypeFilter([]);
      setProvinceFilter([]);
      setSort({
        key: "",
        order: "",
      });
    }
  }, [openDrawer, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(
        setSidePanel({
          name: "",
          status: false,
          type: "",
        })
      );
    };
  }, [dispatch]);
  const setOpenDrawer = useCallback(
    (state: { status: boolean; type: string }) => {
      dispatch(
        setSidePanel({
          name: SIDE_PANEL_NAMES.facilities,
          status: state.status,
          type: state.type,
        })
      );
    },
    [dispatch]
  );
  // Confirmation modal state
  const [showConfirmation, setShowConfirmation] = useState({
    status: false,
    id: "",
  });
  // Preparing table data
  const tableData = useMemo(() => {
    return facilitiesData.map((item) => ({
      ...item,
      type: getFacilityType(facilityTypeData, item.facilityTypeId),
      province: getProvinceName(provinceData, item.provinceId),
    }));
  }, [facilitiesData, facilityTypeData, provinceData]);

  const filteredFacilities = useMemo(() => {
    if (searchQuery) {
      return tableData.filter((row) => {
        const dataToCheck = (row.title ?? "").toLowerCase();
        return dataToCheck.includes(searchQuery.trim().toLowerCase());
      });
    }
    return tableData;
  }, [tableData, searchQuery]);

  // Required for Province column filter
  const provinceFilterData: TableFilterTypes[] = useMemo(() => {
    return provinceData.map((item) => ({ id: item.id, label: item.title }));
  }, [provinceData]);

  // Required for Facility Type column filter
  const facilityTypeFilterData: TableFilterTypes[] = useMemo(() => {
    return facilityTypeData.map((item) => ({ id: item.id, label: item.title }));
  }, [facilityTypeData]);

  // When a drawer is closed
  const handleClose = useCallback(() => {
    setOpenDrawer({ status: false, type: "" });
    setCurrentFacility(cloneDeep(FACILITIES_TEMPLATE));
    setErrors(cloneDeep(FACILITIES_ERRORS_TEMPLATE));
    setShowConfirmation({ status: false, id: "" });
  }, [setOpenDrawer]);

  const updateFacilities = useCallback(
    (id: string) => {
      const selectedFacility = cloneDeep(
        facilitiesData.filter((item) => item.id === id)
      );
      selectedFacility[0].contacts = selectedFacility[0].contacts.map(
        (item) => {
          return {
            ...item,
            mobile: item.mobile.startsWith("+1 ")
              ? item.mobile.slice(3)
              : item.mobile,
            officePhone: item.officePhone.startsWith("+1 ")
              ? item.officePhone.slice(3)
              : item.officePhone,
          };
        }
      );

      setOpenDrawer({ status: true, type: "" });
      setCurrentFacility(cloneDeep(selectedFacility[0]));
      setRightPanelData(cloneDeep(selectedFacility[0]));
      isConsultant &&
        selectedFacility &&
        dispatch(
          fetchServiceChargesByFacilityThunk(parseInt(selectedFacility[0].id))
        );
      // If there are already multiple contacts, adding the error objects for the same
      if (selectedFacility[0].contacts.length > 1) {
        const errorsObj = cloneDeep(FACILITIES_ERRORS_TEMPLATE);
        selectedFacility[0].contacts.forEach((contact, index) => {
          if (!contact.isPrimary) {
            errorsObj.contact.push({ ...CONTACT_DETAILS_TEMPLATE });
          }
        });
        setErrors(errorsObj);
      }
    },
    [dispatch, facilitiesData, isConsultant, setOpenDrawer]
  );

  const addNewFacility = useCallback(() => {
    setOpenDrawer({ status: true, type: "" });
    setCurrentFacility(cloneDeep(FACILITIES_TEMPLATE));
    setRightPanelData(cloneDeep(FACILITIES_TEMPLATE));
  }, [setOpenDrawer]);

  const dispatchGetData = useCallback(
    (
      sortBy?: string,
      sortDirection?: string,
      typeFilter?: string[],
      provinceFilter?: string[],
      search?: string
    ) => {
      // Dispatch get info action
      dispatch(
        getFacilitiesThunk(
          sortBy,
          sortDirection,
          typeFilter,
          provinceFilter,
          search
        )
      );
      handleClose();
    },
    [dispatch, handleClose]
  );

  const handleConfirmation = useCallback(
    (
      id: "new" | "close" | "filters" | string,
      resetClicked: boolean = false
    ) => {
      // Showing confirmation only when the user clicks a row other than the currently selected on
      if (id !== currentFacility.id) {
        const dataChanged = !isEqual(currentFacility, rightPanelData);
        if (
          openDrawer &&
          openDrawer.status &&
          !resetClicked &&
          (dataChanged || logo)
        ) {
          // If drawer is open, show the modal and save id of recently clicked row
          setShowConfirmation({
            status: true,
            id: id || "",
          });
        } else {
          // If drawer is not open, set the drawer. If Add button is clicked id would be "new"
          setErrors(cloneDeep(FACILITIES_ERRORS_TEMPLATE));
          if (id === "new") {
            addNewFacility();
          } else if (id === "close") {
            handleClose();
          } else if (id === "filters") {
            dispatchGetData();
          } else {
            id && updateFacilities(id);
          }
          setShowConfirmation({ id: "", status: false });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentFacility,
      handleClose,
      addNewFacility,
      openDrawer,
      updateFacilities,
      rightPanelData,
      logo,
    ]
  );

  // Handle filter change
  const onFilter = useCallback(
    (type: "facilityType" | "province", data: string[]) => {
      if (type === "facilityType") {
        setTypeFilter(data);
        dispatchGetData(sort.key, sort.order, data, provinceFilter);
      } else {
        setProvinceFilter(data);
        dispatchGetData(sort.key, sort.order, typeFilter, data);
      }
    },
    [dispatchGetData, provinceFilter, sort.key, sort.order, typeFilter]
  );
  // Handle Sort on table column
  const handleSort = useCallback(
    ({ key, order }: { key: string; order: string }) => {
      setSort({ key, order });
      dispatchGetData(key, order, typeFilter, provinceFilter);
    },
    [dispatchGetData, provinceFilter, typeFilter]
  );

  // Handle user input in the form
  const handleChange = useCallback(
    (id: string, value: string | number | null | boolean) => {
      setCurrentFacility({ ...currentFacility, [id]: value });
      setErrors({ ...errors, [id]: "" });
    },
    [currentFacility, errors]
  );

  const handleMultipleChange = useCallback(
    (changes: Partial<FacilitiesTypes>) => {
      setCurrentFacility((currentData) => ({
        ...currentData,
        ...changes,
      }));

      setErrors((currentData) => {
        const currentError = { ...currentData, searchFacility: "" };
        Object.keys(changes).forEach((key) => {
          currentError[key as keyof FacilitiesErrorsTypes] = "";
        });
        return currentError;
      });
    },
    []
  );

  // Adding multiple contacts
  const addContacts = useCallback(() => {
    // To add secondary contacts in the form
    const dataCopy = cloneDeep(currentFacility);
    const errorsCopy = cloneDeep(errors);
    dataCopy.contacts.push({ ...CONTACT_DETAILS_TEMPLATE });
    errorsCopy.contact.push({ ...CONTACT_DETAILS_TEMPLATE });
    setCurrentFacility(dataCopy);
    setErrors(errorsCopy);
  }, [currentFacility, errors]);

  // Deleting secondary contact
  const deleteContact = useCallback(
    (index: number) => {
      const dataCopy = cloneDeep(currentFacility);
      const errorsCopy = cloneDeep(errors);
      dataCopy.contacts.splice(index, 1);
      errorsCopy.contact.splice(index, 1);
      setCurrentFacility(dataCopy);
      setErrors(errorsCopy);
    },
    [currentFacility, errors]
  );
  // Handle user input in the contact form
  const handleContactChange = useCallback(
    (index: number, id: string, value: string | number | null) => {
      if (
        id === "email" ||
        id === "name" ||
        id === "mobile" ||
        id === "officePhone" ||
        id === "extension" ||
        id === "designation"
      ) {
        const val = value?.toString();
        const facilityDataCopy = cloneDeep(currentFacility);
        facilityDataCopy.contacts[index][id] = val || "";
        const errorsCopy = cloneDeep(errors);
        errorsCopy.contact[index][id] = "";
        setCurrentFacility(facilityDataCopy);
        setErrors(errorsCopy);
      }
    },
    [currentFacility, errors]
  );

  const handleSave = useCallback(() => {
    currentFacility.contacts.forEach((user) => {
      user.mobile = user.mobile.startsWith("+1 ")
        ? user.mobile
        : "+1 " + user.mobile;
      user.officePhone = user.officePhone.startsWith("+1 ")
        ? user.officePhone
        : "+1 " + user.officePhone;
    });
    if (currentFacility.id) {
      // Update action
      dispatch(
        updateFacilityThunk({
          formData: currentFacility,
          facilityList: facilitiesData,
          updateCurrent:
            Number(selectedFacilityIdInApp) === Number(currentFacility.id),
          callback: handleClose,
        })
      );
    } else {
      // Create action
      dispatch(
        createFacilityThunk({
          formData: currentFacility,
          facilityList: facilitiesData,
          callback: handleClose,
        })
      );
    }
  }, [
    currentFacility,
    dispatch,
    facilitiesData,
    selectedFacilityIdInApp,
    handleClose,
  ]);

  const validateFields = () => {
    let validData = true;
    const { valid, formData, errorObject } = validateForm(
      currentFacility,
      facilityFormValidationConfig,
      errors
    );
    if (!currentFacility.latitude || !currentFacility.longitude) {
      errorObject.searchFacility = "Please search and select the facility";
      validData = false;
    } else {
      errorObject.searchFacility = "";
    }
    currentFacility.contacts.forEach((contact, index) => {
      const {
        valid: isContactValid,
        formData: contactForm,
        errorObject: contactErrors,
      } = validateForm(
        contact,
        contactFormValidationConfig,
        errorObject.contact[index]
      );
      formData.contacts[index] = contactForm;
      errorObject.contact[index] = contactErrors;
      validData = validData && valid && isContactValid;
    });
    setCurrentFacility(formData);
    if (validData) {
      handleSave();
    } else {
      setErrors(errorObject);
    }
  };
  const closeConfirmation = () => {
    if (showConfirmation.id === "filters") {
      setSort({ key: "", order: "" });
      setTypeFilter([]);
      setProvinceFilter([]);
    }
    setShowConfirmation({ id: "", status: false });
  };
  return (
    <>
      <div className="h-full bg-white flex">
        <div className="mr-3 w-full">
          <div className="flex items-center justify-between">
            <SectionName className="mr-3" />
            <div className="my-2 flex flex-1 justify-end items-center gap-3">
              <SearchBar
                onSearch={setSearchQuery}
                placeHolder="Search Facilities by Name"
              />
              {isConsultant && (
                <AddButton
                  onClick={() => handleConfirmation("new")}
                  tooltipText={t("facilities.addFacility")}
                />
              )}
            </div>
          </div>
          <FacilitiesTable
            data={filteredFacilities}
            onClick={handleConfirmation}
            provinceFilterData={provinceFilterData}
            facilityTypeFilterData={facilityTypeFilterData}
            onFilter={onFilter}
            handleSort={handleSort}
            sortState={sort}
          />
        </div>
        {openDrawer && openDrawer.name === SIDE_PANEL_NAMES.facilities && (
          <SideDrawer
            open={openDrawer.status}
            onClose={() => handleConfirmation("close")}
            title={currentFacility.id ? "Update Facility" : "Create Facility"}
            primaryButton={t("common.save") || ""}
            onSubmit={validateFields}
            disablePrimaryBtn={isLoading}
          >
            <FacilitiesForm
              data={currentFacility}
              logo={currentFacility.logo}
              onChange={handleChange}
              onMultipleChange={handleMultipleChange}
              handleContactChange={handleContactChange}
              addContacts={addContacts}
              deleteContact={deleteContact}
              errors={errors}
              setLogo={setLogo}
            />
          </SideDrawer>
        )}
      </div>
      {showConfirmation && (
        <ConfirmationModal
          text={t("alertMessages.discardConfirmation")}
          open={showConfirmation.status}
          handleYes={() => handleConfirmation(showConfirmation.id, true)}
          handleNo={closeConfirmation}
        />
      )}
    </>
  );
};

export default Facilities;
