import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ServiceChargeTable from "./ServiceChargeTable";
import { fetchServiceChargesThunk, updateServiceChargeThunk } from "../../redux/thunks/serviceCharge";
import SideDrawer from "../common/SideDrawer";
import ServiceChargeTypeTemplate from "./ServiceChargeTypeTemplate";
import { ServiceCharge } from "../../models/types";
import { SERVICE_CHARGE_TYPE_TEMPLATE, Errors_Template } from "./constants";
import { cloneDeep, isEqual } from "lodash";
import { t } from "i18next";
import ConfirmationModal from "../common/ConfirmationModal";
import { setServiceChargeLoading } from "../../redux/reducers/serviceChargeReducer"

function ServiceChargePage() {
    const dispatch = useAppDispatch();

    let ServiceChargeData = useAppSelector(
        (state) => state.ServiceChargeReducer.ServiceCharge
    );

    const isLoading = useAppSelector(
      (state) => state.ServiceChargeReducer.loading
    );

    // State to manage right hand panel
    const [openDrawer, setOpenDrawer] = useState(false);

    const [ServiceChargeTemplateData, setServiceChargeTemplateData] = useState<ServiceCharge>(
        cloneDeep(SERVICE_CHARGE_TYPE_TEMPLATE)
    );
    const [dataCopy, setDataCopy] = useState({});

    const [showConfirmation, setShowConfirmation] = useState({
        status: false,
        id: 0,
    });

    const [errors, setErrors] = useState({ ...Errors_Template });

    useEffect(() => {
        // Dispatching action to load the requests when page renders for the first time
        dispatch(fetchServiceChargesThunk());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showServiceChargeDetails = useCallback(
        (id: number | undefined) => {
            // Setting currently selected object's data in the state
            let selectedData = cloneDeep(
                ServiceChargeData.filter((data) => data.id === id)?.[0]
            );

            setServiceChargeTemplateData(selectedData);
            setDataCopy(selectedData);
            setOpenDrawer(true);
        },
        [ServiceChargeData]
    );

    const handleDrawerClose = useCallback(() => {
        setOpenDrawer(false);
        setServiceChargeTemplateData({ ...SERVICE_CHARGE_TYPE_TEMPLATE });
        setErrors({ ...Errors_Template });
    }, []);

    const handleConfirmation = useCallback(
        (id?: number, resetClicked: boolean = false) => {
            // Showing confirmation only when the user clicks a row other than the currently selected on
            if (id !== ServiceChargeTemplateData.id) {
                const dataChanged = !isEqual(dataCopy, ServiceChargeTemplateData);
                if (openDrawer && !resetClicked && dataChanged) {
                    // If drawer is open, show the modal and save id of recently clicked row
                    setShowConfirmation({
                        status: true,
                        id: id || 0,
                    });
                } else {
                    // If drawer is not open, set the drawer. If Add button is clicked id would be "new"
                    setErrors({ ...Errors_Template });
                    id === 0
                        ? handleDrawerClose()
                        : showServiceChargeDetails(id);
                    setShowConfirmation({ id: 0, status: false });
                }
            }
        },
        [
            dataCopy,
            ServiceChargeTemplateData,
            openDrawer,
            showServiceChargeDetails,
            handleDrawerClose,
        ]
    );

    const handleBlur = useCallback(
        (id: string, value: string | number | null) => {
            //const trimmedValue = value?.toString().trim();
            setServiceChargeTemplateData({ ...ServiceChargeTemplateData, [id]: value });
        },
        [ServiceChargeTemplateData]
    );

    const handleChange = useCallback(
        (id: string, value: string | number | null) => {
            // Setting the value in state
            setServiceChargeTemplateData({ ...ServiceChargeTemplateData, [id]: value });

            // Resetting the errors
            setErrors({ ...errors, [id]: "" });
        },
        [ServiceChargeTemplateData, errors]
    );

    const handleClose = () => {
        setServiceChargeTemplateData({ ...SERVICE_CHARGE_TYPE_TEMPLATE });
        setOpenDrawer(false);
         dispatch(setServiceChargeLoading(false));
    };

    const handleSave = useCallback(() => {
        dispatch(updateServiceChargeThunk(ServiceChargeTemplateData, handleClose));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ServiceChargeTemplateData, dispatch]);

    const validateFields = useCallback(() => {
        let validData = true;
        const errorObjCopy = cloneDeep(errors);
        // Validating Title
        if (!ServiceChargeTemplateData.rateCut) {
            errorObjCopy.rateCut = t("errors.rateRequired");
            validData = false;
        } else {
            errorObjCopy.rateCut = "";
        }

        if (validData) {
            handleSave();
        } else {
            setErrors(errorObjCopy);
        }
    }, [errors, ServiceChargeTemplateData, handleSave]);



    return (
        <>
            <div className="h-full bg-white flex">
                <div className="mr-3 w-full">
                    <ServiceChargeTable
                        data={ServiceChargeData}
                        onTitleClick={(id: number) => {
                            handleConfirmation(id);
                        }}
                    />
                </div>
                {openDrawer && (
                    <SideDrawer
                        open={openDrawer}
                        title="Service Charges"
                        onSubmit={validateFields}
                        onClose={() => handleConfirmation(0)}
                        primaryButton={t("common.save") || ""}
                        disablePrimaryBtn ={isLoading}
                    >
                        <ServiceChargeTypeTemplate
                            data={ServiceChargeTemplateData}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={errors}
                        />
                    </SideDrawer>
                )}
            </div>
            {
                showConfirmation && (
                    <ConfirmationModal
                        text={t("alertMessages.discardConfirmation")}
                        open={showConfirmation.status}
                        handleYes={() => handleConfirmation(showConfirmation.id, true)}
                        handleNo={() => setShowConfirmation({ id: 0, status: false })}
                    />
                )
            }
        </>
    );
}

export default ServiceChargePage;