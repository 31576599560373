import { Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../common/Button";

type DeleteJobConfirmationTypes = {
  open: boolean;
  text: string | React.ReactNode;
  handleYes: (jobId: string) => void;
  handleNo: () => void;
};

const DeleteJobConfirmation = (props: DeleteJobConfirmationTypes) => {
  const { t } = useTranslation();
  const { open, handleNo, handleYes, text } = props;
  const [reason, setReason] = useState<string>("");

  const onReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
  };

  // Handle "Yes" button click
  const onYes = () => {
    handleYes(reason);
  };

  return (
    <Modal
      open={open}
      title="Delete Job"
      onOk={onYes}
      onCancel={handleNo}
      maskClosable={false}
      footer={
        <div className="flex gap-3 justify-center mt-6">
          <Button
            key="yes"
            id="yes-btn"
            variant="filled"
            onClick={onYes}
            title="Yes"
          />
          <Button
            key="no"
            variant="outline"
            onClick={handleNo}
            id="no-btn"
            title="No"
          />
        </div>
      }
    >
      {text}
      <TextArea
        rows={4}
        value={reason}
        onChange={onReasonChange}
      />
    </Modal>
  );
};

export default DeleteJobConfirmation;
