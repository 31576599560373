import {
  ContactTypes,
  FacilitiesErrorsTypes,
  FacilityUserTypes,
} from "../../models/types";
import { Tabs } from "antd";
import ContactForm from "./ContactForm";
import Icon from "../common/Icon";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import UserManagement from "./UserManagement";
import useUserRole from "../hooks/useUserRole";
import AddUserForm from "./AddUserForm";
import ServiceChargeTable from "./ServiceChargeTable";

type FacilityTabTypes = {
  contactData: ContactTypes[];
  addContacts: () => void;
  deleteContact: (index: number) => void;
  users: FacilityUserTypes[];
  facilityId: string;
  facilityName: string;
  handleContactChange: (
    index: number,
    id: string,
    value: string | number | null
  ) => void;
  errors: FacilitiesErrorsTypes;
  hideResetPassword?: boolean;
};

const FacilityTabs = (props: FacilityTabTypes) => {
  const { t } = useTranslation();
  const { isConsultant, isFacilityAdmin } = useUserRole();
  const [showUserCreationForm, setShowUserCreationForm] = useState(false);
  const {
    contactData,
    addContacts,
    deleteContact,
    handleContactChange,
    errors,
    facilityId,
    facilityName,
  } = props;
  const tabItems = useMemo(() => {
    const withoutResetTab = [
      {
        label: (
          <div className="flex justify-between items-center">
            <span>{t("facilities.contactInformation")}</span>
            {contactData.length < 4 && (
              <Icon
                name="add"
                fontSize={14}
                role="button"
                onClick={addContacts}
                className="bg-[#1677ff] text-white rounded-[50%] flex justify-center items-center p-[1px] font-semibold ml-4"
              />
            )}
          </div>
        ),
        key: "contactInformation",
        children: (
          <ContactForm
            contactData={contactData}
            deleteContact={deleteContact}
            handleContactChange={handleContactChange}
            errors={errors.contact}
          />
        ),
      },
    ];
    const withResetTab = [
      {
        label: (
          <div className="flex justify-between items-center">
            <span>{t("facilities.contactInformation")}</span>
            {contactData.length < 4 && (
              <Icon
                name="add"
                fontSize={14}
                role="button"
                onClick={addContacts}
                className="bg-[#1677ff] text-white rounded-[50%] flex justify-center items-center p-[1px] font-semibold ml-4"
              />
            )}
          </div>
        ),
        key: "contactInformation",
        children: (
          <ContactForm
            contactData={contactData}
            deleteContact={deleteContact}
            handleContactChange={handleContactChange}
            errors={errors.contact}
          />
        ),
      },
      {
        label: (
          <div className="flex justify-between items-center">
            <span>{t("facilities.userManagement")}</span>
            {(isConsultant || isFacilityAdmin) && (
              <>
                <Icon
                  name="add"
                  fontSize={14}
                  role="button"
                  onClick={() => setShowUserCreationForm(true)}
                  className="bg-[#1677ff] text-white rounded-[50%] flex justify-center items-center p-[1px] font-semibold ml-4"
                />
                <AddUserForm
                  showForm={showUserCreationForm}
                  hideForm={() => setShowUserCreationForm(false)}
                  facilityId={facilityId}
                  facilityName={facilityName}
                />
              </>
            )}
          </div>
        ),
        key: "resetPassword",
        children: (
          <>
            <div className="border my-5 relative rounded-md">
              <span className="blue-text absolute -top-[11px] left-4 px-1 bg-white">
                {t("facilities.users")}
              </span>{" "}
              <div className="p-4">
                <UserManagement
                  showRoleOnTooltip={true}
                  facilityId={facilityId}
                />
              </div>
            </div>
          </>
        ),
      },
      {
        label: isConsultant ? (
          <div className="flex justify-between items-center">
            <span>{t("facilities.serviceCharge")}</span>
          </div>
        ) : (
          ""
        ),
        key: "serviceCharge",
        children: (
          <>
            <div className="border my-5 relative rounded-md">
              <span className="blue-text absolute -top-[11px] left-4 px-1 bg-white">
                {t("facilities.serviceCharge")}
              </span>{" "}
              <div className="p-4">
                <ServiceChargeTable facilityId={facilityId} />
              </div>
            </div>
          </>
        ),
      },
    ];
    return isConsultant && facilityId ? withResetTab : withoutResetTab;
  }, [
    t,
    contactData,
    addContacts,
    deleteContact,
    handleContactChange,
    errors.contact,
    isConsultant,
    isFacilityAdmin,
    showUserCreationForm,
    facilityId,
    facilityName,
  ]);

  return <Tabs items={tabItems} />;
};

export default FacilityTabs;
