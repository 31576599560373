import { AppDispatch } from "../store";
import {
  setClientHasNextPage,
  setClientProfile,
  setClientProfileCompletionPercentage,
  setClientRecordsCount,
  setClients,
  setClientsLoading,
  setClientUpdateInprogress,
  setCurrentEmployers,
  setDocUploadInprogress,
  setNewClient,
} from "../reducers/clientsReducer";
import { handleApiErrors, logToSentry } from "../../helpers/helpers";
import { AxiosError } from "axios";
import { showToast } from "../reducers/feedbackReducer";
import {
  approveClientDocument,
  approveClientProfile,
  createClient,
  disableClientForFacility,
  enableClientForFacility,
  fetchAllClients,
  fetchClientById,
  fetchClientProfileById,
  fetchClientsByFacility,
  getCurrentEmployer,
  putClientProfileOnHold,
  setDocumentCompletion,
  updateAddress,
  updateBankInfo,
  updateCurrentEmployment,
  updateEducationInfo,
  updateEmail,
  updatePhoneNumber,
  updateWorkEligibility,
  updateWorkHistory,
  uploadLogo,
} from "../../services/clients.services";
import {
  CreateClientTypes,
  CurrentEmployerTypes,
  EducationTypes,
  UpdateEmailTypes,
  UpdatePhoneTypes,
  UserBankTypes,
  WorkEligibility,
  WorkHistoryTypes,
} from "../../models/types";
import { Address } from "../../helpers/googlePlacesHelpers";

export const getAllClientsThunk = () => async (dispatch: AppDispatch) => {
  dispatch(setClientsLoading(true));
  try {
    const response = await fetchAllClients();
    dispatch(setClients(response.data.data?.clientRecord ?? []));
    dispatch(setClientHasNextPage(response.data.data?.hasNextPage ?? false));
    dispatch(
      setClientRecordsCount(
        response.data.data?.paginationData?.totalNumberOfRecords ?? 0
      )
    );
  } catch (err) {
    handleApiErrors(err as AxiosError, dispatch);
    logToSentry(err, "Clients", "getAllClientsThunk");
  } finally {
    dispatch(setClientsLoading(false));
  }
};

export const getClientsByFacilityThunk =
  (facilityId: number) => async (dispatch: AppDispatch) => {
    dispatch(setClientsLoading(true));
    try {
      const response = await fetchClientsByFacility(facilityId);
      dispatch(setClients(response.data.data?.clientRecord ?? []));
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "getClientsByFacilityThunk");
    } finally {
      dispatch(setClientsLoading(false));
    }
  };

export const getClientByIdThunk =
  (clientId: number) => async (dispatch: AppDispatch) => {
    dispatch(setClientsLoading(true));
    try {
      const response = await fetchClientById(clientId);
      const clientProfileResponse = await fetchClientProfileById(clientId);
      dispatch(
        setClientProfile({
          ...response.data.data,
          profileStatus: { ...clientProfileResponse.data.data },
        })
      );
      dispatch(
        setClientProfileCompletionPercentage(
          clientProfileResponse?.data?.data?.completionPercentage ?? 0
        )
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "getClientByIdThunk");
    } finally {
      dispatch(setClientsLoading(false));
    }
  };

export const approveClientDocumentThunk =
  (
    clientId: number,
    documentId: number,
    fileTitle: string,
    callback?: () => void
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      await approveClientDocument(documentId);
      if (callback) {
        await callback();
      }
      dispatch(
        showToast({
          type: "success",
          message: `${fileTitle} approved successfully`,
          show: true,
          direction: "right",
        })
      );
      const response = await fetchClientById(clientId);
      const clientProfileResponse = await fetchClientProfileById(clientId);
      dispatch(
        setClientProfile({
          ...response.data.data,
          profileStatus: { ...clientProfileResponse.data.data },
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "approveClientDocumentThunk");
    }
  };

export const approveClientProfileThunk =
  (clientId: number) => async (dispatch: AppDispatch) => {
    try {
      await approveClientProfile(clientId);
      dispatch(
        showToast({
          type: "success",
          message: `Profile approved successfully`,
          show: true,
          direction: "right",
        })
      );
      const response = await fetchClientById(clientId);
      const clientProfileResponse = await fetchClientProfileById(clientId);
      dispatch(
        setClientProfile({
          ...response.data.data,
          profileStatus: { ...clientProfileResponse.data.data },
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "approveClientProfileThunk");
    } finally {
    }
  };

export const disableClientForFacilityThunk =
  (clientId: number, facilityId: number) => async (dispatch: AppDispatch) => {
    try {
      await disableClientForFacility(clientId, facilityId);
      dispatch(
        showToast({
          type: "success",
          message: `Client disabled successfully`,
          show: true,
          direction: "right",
        })
      );
      const response = await fetchClientById(clientId);
      const clientProfileResponse = await fetchClientProfileById(clientId);
      dispatch(
        setClientProfile({
          ...response.data.data,
          profileStatus: { ...clientProfileResponse.data.data },
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "disableClientForFacilityThunk");
    } finally {
    }
  };

export const enableClientForFacilityThunk =
  (clientId: number, facilityId: number) => async (dispatch: AppDispatch) => {
    try {
      await enableClientForFacility(clientId, facilityId);
      dispatch(
        showToast({
          type: "success",
          message: `Client enabled successfully`,
          show: true,
          direction: "right",
        })
      );
      const response = await fetchClientById(clientId);
      const clientProfileResponse = await fetchClientProfileById(clientId);
      dispatch(
        setClientProfile({
          ...response.data.data,
          profileStatus: { ...clientProfileResponse.data.data },
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "enableClientForFacilityThunk");
    } finally {
    }
  };

export const createClientProfileThunk =
  (data: { formData: CreateClientTypes; callback: () => void }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setClientsLoading(true));
      const response = await createClient(data.formData);
      const newUserId = response?.data?.data || null;
      if (newUserId) {
        const userDetails = await fetchClientById(newUserId);
        dispatch(setNewClient(userDetails.data.data));
      }
      if (response.data.data) {
        data.callback();
        dispatch(
          showToast({
            type: "success",
            message: "Client is created successfully!",
            show: true,
          })
        );
      }
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "createClientProfileThunk");
    } finally {
      dispatch(setClientsLoading(false));
    }
  };

export const updateWorkEligibilityThunk =
  (data: {
    payload: WorkEligibility;
    callback: () => void;
    setProfileOnHold: boolean;
    markDocsPending: boolean;
  }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setClientUpdateInprogress(true));
      const response = await updateWorkEligibility(data.payload);
      // Put client profile on hold
      if (data.setProfileOnHold && data?.payload?.userId) {
        await putClientProfileOnHold(data.payload.userId);
      }
      // Mark document section as pending
      if (data.markDocsPending && data?.payload?.userId) {
        await setDocumentCompletion({
          userId: data.payload.userId,
          isDocumentCompletion: false,
        });
      }
      if (response.data.data) {
        data.callback();
        const userId = Number(data?.payload?.userId);
        if (userId) {
          const response = await fetchClientById(userId);
          const clientProfileResponse = await fetchClientProfileById(userId);
          dispatch(
            setClientProfile({
              ...response.data.data,
              profileStatus: { ...clientProfileResponse.data.data },
            })
          );
        }
        dispatch(
          showToast({
            type: "success",
            message: "Work eligibility updated successfully!",
            show: true,
          })
        );
      }
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "updateWorkEligibilityThunk");
    } finally {
      dispatch(setClientUpdateInprogress(false));
    }
  };

export const updateBankInfoThunk =
  (data: { payload: UserBankTypes; callback: () => void }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setClientUpdateInprogress(true));
      const response = await updateBankInfo(data.payload);
      if (response.data.data) {
        data.callback();
        const userId = Number(data?.payload?.userId);
        if (userId) {
          const response = await fetchClientById(userId);
          const clientProfileResponse = await fetchClientProfileById(userId);
          dispatch(
            setClientProfile({
              ...response.data.data,
              profileStatus: { ...clientProfileResponse.data.data },
            })
          );
        }
        dispatch(
          showToast({
            type: "success",
            message: "Bank information updated successfully!",
            show: true,
          })
        );
      }
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "updateBankInfoThunk");
    } finally {
      dispatch(setClientUpdateInprogress(false));
    }
  };

export const updateWorkHistoryThunk =
  (data: { payload: WorkHistoryTypes; callback: () => void }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setClientUpdateInprogress(true));
      const response = await updateWorkHistory(data.payload);
      if (response.data.data) {
        data.callback();
        const userId = Number(data?.payload?.userId);
        if (userId) {
          const response = await fetchClientById(userId);
          const clientProfileResponse = await fetchClientProfileById(userId);
          dispatch(
            setClientProfile({
              ...response.data.data,
              profileStatus: { ...clientProfileResponse.data.data },
            })
          );
        }
        dispatch(
          showToast({
            type: "success",
            message: "Work history updated successfully!",
            show: true,
          })
        );
      }
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "updateWorkHistoryThunk");
    } finally {
      dispatch(setClientUpdateInprogress(false));
    }
  };

export const updateEducationInfoThunk =
  (data: { payload: EducationTypes; callback: () => void }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setClientUpdateInprogress(true));
      const response = await updateEducationInfo(data.payload);
      if (response.data.data) {
        data.callback();
        const userId = Number(data?.payload?.userId);
        if (userId) {
          const response = await fetchClientById(userId);
          const clientProfileResponse = await fetchClientProfileById(userId);
          dispatch(
            setClientProfile({
              ...response.data.data,
              profileStatus: { ...clientProfileResponse.data.data },
            })
          );
        }
        dispatch(
          showToast({
            type: "success",
            message: "Education history updated successfully!",
            show: true,
          })
        );
      }
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "updateEducationInfoThunk");
    } finally {
      dispatch(setClientUpdateInprogress(false));
    }
  };

export const uploadFileThunk =
  (payload: any, userId: number | null, callback: () => void) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setDocUploadInprogress(true));
      await uploadLogo(payload);
      callback();
      if (userId) {
        const response = await fetchClientById(userId);
        const clientProfileResponse = await fetchClientProfileById(userId);
        dispatch(
          setClientProfile({
            ...response.data.data,
            profileStatus: { ...clientProfileResponse.data.data },
          })
        );
      }
      dispatch(
        showToast({
          type: "success",
          message: "File is uploaded",
          show: true,
          direction: "right",
        })
      );
    } catch (err: any) {
      dispatch(
        showToast({
          type: "error",
          message: "Error uploading the file, please try later",
          show: true,
        })
      );
      logToSentry(err, "Clients", "uploadFileThunk");
    } finally {
      dispatch(setDocUploadInprogress(false));
    }
  };

export const updateCurrentEmploymentThunk =
  (data: {
    fullTimePayload: CurrentEmployerTypes;
    casualPayload: CurrentEmployerTypes;
    callback: () => void;
  }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setClientUpdateInprogress(true));
      await updateCurrentEmployment(data.fullTimePayload);
      await updateCurrentEmployment(data.casualPayload);
      data.callback();
      const userId = Number(data?.fullTimePayload?.userId);
      if (userId) {
        const response = await fetchClientById(userId);
        const clientProfileResponse = await fetchClientProfileById(userId);
        dispatch(
          setClientProfile({
            ...response.data.data,
            profileStatus: { ...clientProfileResponse.data.data },
          })
        );
      }
      dispatch(
        showToast({
          type: "success",
          message: "Current Employment updated successfully!",
          show: true,
        })
      );
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "updateCurrentEmploymentThunk");
    } finally {
      dispatch(setClientUpdateInprogress(false));
    }
  };

export const updateAddressThunk =
  (data: { payload: Address; callback: () => void }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setClientUpdateInprogress(true));
      const response = await updateAddress(data.payload);
      if (response.data.data) {
        data.callback();
        const userId = Number(data?.payload?.userId);
        if (userId) {
          const response = await fetchClientById(userId);
          const clientProfileResponse = await fetchClientProfileById(userId);
          dispatch(
            setClientProfile({
              ...response.data.data,
              profileStatus: { ...clientProfileResponse.data.data },
            })
          );
        }
        dispatch(
          showToast({
            type: "success",
            message: "Address updated successfully!",
            show: true,
          })
        );
      }
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "updateAddressThunk");
    } finally {
      dispatch(setClientUpdateInprogress(false));
    }
  };

export const setDocumentCompletionThunk =
  (data: { payload: any }) => async (dispatch: AppDispatch) => {
    try {
      const response = await setDocumentCompletion(data.payload);
      if (response.data.data) {
        const userId = Number(data?.payload?.userId);
        if (userId) {
          const response = await fetchClientById(userId);
          const clientProfileResponse = await fetchClientProfileById(userId);
          dispatch(
            setClientProfile({
              ...response.data.data,
              profileStatus: { ...clientProfileResponse.data.data },
            })
          );
        }
        dispatch(
          showToast({
            type: "success",
            message: "All required documents are approved!",
            show: true,
          })
        );
      }
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "setDocumentCompletionThunk");
    } finally {
    }
  };

export const getCurrentEmployerThunk =
  (userId: number) => async (dispatch: AppDispatch) => {
    try {
      const response: any = await getCurrentEmployer(userId);
      dispatch(setCurrentEmployers(response.data.data || []));
      console.log("Get Current Employer Thunk", response.data.data);
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "getCurrentEmployerThunk");
    } finally {
    }
  };

export const updatePhoneNumberThunk =
  (data: { payload: UpdatePhoneTypes; callback: () => void }) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await updatePhoneNumber(data.payload);
      if (response.data.data) {
        const userId = Number(data?.payload?.userId);
        if (userId) {
          const response = await fetchClientById(userId);
          const clientProfileResponse = await fetchClientProfileById(userId);
          dispatch(
            setClientProfile({
              ...response.data.data,
              profileStatus: { ...clientProfileResponse.data.data },
            })
          );
        }
        dispatch(
          showToast({
            type: "success",
            message: "Phone number updated successfully!",
            show: true,
          })
        );
      }
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "updatePhoneNumberThunk");
    } finally {
    }
  };

export const updateEmailThunk =
  (data: { payload: UpdateEmailTypes; callback: () => void }) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await updateEmail(data.payload);
      if (response.data.data) {
        const userId = Number(data?.payload?.userId);
        if (userId) {
          const response = await fetchClientById(userId);
          const clientProfileResponse = await fetchClientProfileById(userId);
          dispatch(
            setClientProfile({
              ...response.data.data,
              profileStatus: { ...clientProfileResponse.data.data },
            })
          );
        }
        dispatch(
          showToast({
            type: "success",
            message: "Email updated successfully!",
            show: true,
          })
        );
      }
    } catch (err: any) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Clients", "updateEmailThunk");
    } finally {
    }
  };
