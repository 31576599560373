import { useCallback, useMemo, useState } from "react";
import { Avatar } from "antd";
import { useTranslation } from "react-i18next";
import Icon from "../../common/Icon";
import { JOB_STATUSES } from "../../../constants/statuses";
import { useAppSelector } from "../../../redux/hooks";
import { isEmpty } from "lodash";
import DeleteJobConfirmation from "./DeleteJobConfirmation";
import { JobTypes } from "../../../models/job.model";
import useUserRole from "../../hooks/useUserRole";

type JOB_CARD_TYPES = {
  shift: string;
  job: JobTypes;
  isPreviousDay: boolean;
  handleEdit: (job: JobTypes) => void;
  handleNameClick: (id: number) => void;
  handleDeleteJob: (id: string, reason: string, userId: string) => void;
  handleMarkJobAsCompleted: (job: JobTypes) => void;
  handleUnAssignUser: (job: JobTypes) => void;
};
const JobCard = (props: JOB_CARD_TYPES) => {
  const { t } = useTranslation();
  const { job, isPreviousDay, handleEdit, handleNameClick, handleDeleteJob, handleMarkJobAsCompleted, handleUnAssignUser } =
    props;
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const shiftData = useAppSelector((state) => state.settingsReducer.shifts);

  const isFacilityEnabled = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.enabled
  );

  const handleDelete = useCallback(
    (jobId: string, reason: string, userId: string) => {
      handleDeleteJob(jobId, reason, userId);
      setDeleteConfirmation(false);
    },
    [handleDeleteJob]
  );
  const { isConsultant } = useUserRole();

  const showUserProfile = useCallback(() => {
    if (job.jobStatus !== JOB_STATUSES.open && !job.clientDeletedDate) {
      handleNameClick(Number(job.clientId));
    }
  }, [job, handleNameClick]);

  const shiftStartEndTime = useMemo(() => {
    const selectedShift = shiftData.filter(
      (s) => Number(s.id) === Number(job.shiftId)
    );
    if (isEmpty(selectedShift)) {
      return job.shiftTitle;
    }
    const { startTime, endTime } = selectedShift[0];
    return `${startTime} - ${endTime}`;
  }, [job.shiftId, job.shiftTitle, shiftData]);

  const jobDetails = useMemo(() => {
    if (!job.jobCategoryTitle) {
      return "";
    }
    let role = "";
    const jobRole = job.jobCategoryTitle.toLocaleLowerCase();
    if (jobRole.startsWith("rn")) {
      role = "RN";
    } else if (jobRole.startsWith("lpn")) {
      role = "LPN";
    } else if (jobRole.startsWith("cca")) {
      role = "CCA";
    } else if (jobRole.startsWith("sitter")) {
      role = "Sitter";
    }
    return job.isOrientation ? `${role} - Orientation` : role;
  }, [job.isOrientation, job.jobCategoryTitle]);

  const jobCardTitle = useMemo(() => {
    if (job.jobStatus === JOB_STATUSES.open) {
      return isPreviousDay ? "Unfilled" : "Open";
    } else {
      return `${job.clientFirstName} ${job.clientLastName}`;
    }
  }, [isPreviousDay, job.clientFirstName, job.clientLastName, job.jobStatus]);
  const showEditIcon = useMemo(() => {
    return (
      (job.jobStatus === JOB_STATUSES.open ||
        job.jobStatus === JOB_STATUSES.scheduled) &&
      !isPreviousDay
    );
  }, [isPreviousDay, job]);

  const showMarkAsCompleteIcon = useMemo(() => {
    return (job.jobStatus === JOB_STATUSES.scheduled);
  }, [isPreviousDay, job]);
  const showUnAssignUser = useMemo(() => {
    return (job.jobStatus === JOB_STATUSES.scheduled);
  }, [job]);
  return (
    <div className="text-start p-2 flex border-b">
      <div>
        <Avatar
          size={50}
          icon={<Icon name="person_outline" fontSize={32} />}
          className="flex justify-center items-center"
          src={job.jobStatus !== JOB_STATUSES.open && job.avatarUrl}
        />
      </div>
      <div className="ml-4 w-full">
        <div className="flex justify-between w-full">
          <div className="flex justify-between w-full">
            <span
              className={`text-[16px] font-semibold ${job.jobStatus !== JOB_STATUSES.open && !job.clientDeletedDate
                ? "hover:underline hover:cursor-pointer"
                : ""
                }`}
              id={`job-card-title-${job.id}`}
              onClick={showUserProfile}
            >
              {jobCardTitle}
            </span>
          </div>
          <div className="flex items-center">
            {job.jobStatus !== JOB_STATUSES.open && (
              <span className="text-grey text-xs job-status-text mr-2">
                {job.jobStatus}
              </span>
            )}
            {showEditIcon && isFacilityEnabled && (
              <Icon
                role="button"
                name="mode_edit_outline"
                className="border-r pr-1"
                fontSize={20}
                onClick={() => handleEdit(job)}
              />
            )}
            {!isPreviousDay
              && isFacilityEnabled
              && (job.jobStatus === JOB_STATUSES.open || job.jobStatus === JOB_STATUSES.scheduled)
              && (
                <Icon
                  name="delete_outline"
                  className="ml-1"
                  fontSize={20}
                  role="button"
                  onClick={() => setDeleteConfirmation(true)}
                />
              )}
            {showMarkAsCompleteIcon && isConsultant && (
              <Icon
                role="button"
                name="update"
                className="border-r pr-1"
                fontSize={20}
                onClick={() => handleMarkJobAsCompleted(job)}
              />
            )}
            {showUnAssignUser && isConsultant && (
              <Icon
                role="button"
                name="person_remove"
                className="border-r pr-1"
                fontSize={20}
                onClick={() => handleUnAssignUser(job)}
              />
            )}
            {job.jobStatus === JOB_STATUSES.open && !isPreviousDay && (
              <Icon
                role="button"
                name="update"
                className="border-r pr-1"
                fontSize={20}
                onClick={() => handleMarkJobAsCompleted(job)}
              />
            )}
          </div>
        </div>
        <div className="flex justify-between w-full mt-2 text-grey text-xs">
          <span>{jobDetails}</span>
          <span>{shiftStartEndTime}</span>
        </div>
      </div>
      {deleteConfirmation && (
        <DeleteJobConfirmation
          text={t("alertMessages.deleteJob")}
          open={deleteConfirmation}
          handleYes={(reason: string) => handleDelete(job.id, reason, job.clientId)}
          handleNo={() => setDeleteConfirmation(false)}
        />
      )}
    </div>
  );
};

export default JobCard;
