import { Tooltip } from "antd";
import Button from "../common/Button";
import ConfirmationModal from "../common/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { approveClientProfileThunk } from "../../redux/thunks/clients";

type Props = {
  hasPendingDocs: boolean;
  clientId: number | null;
};
const ApproveUserProfile = (props: Props) => {
  const { clientId, hasPendingDocs } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const clientProfile = useAppSelector(state => state.clientsReducer.clientProfile)
  const [verificationConfirmation, setVerificationConfirmation] =
    useState(false);

  const handleYes = () => {
    clientId && dispatch(approveClientProfileThunk(clientId));
    setVerificationConfirmation(false);
  };

  const handleNo = () => {
    setVerificationConfirmation(false);
  };
  const isReadyForApproval = useMemo(() => {
    return (
      clientProfile?.profileStatus?.isAddressCompletion &&
      clientProfile?.profileStatus?.isCurrentEmploymentCompletion &&
      clientProfile?.profileStatus?.isDocumentCompletion &&
      clientProfile?.profileStatus?.isPayoutCompletion &&
      clientProfile?.profileStatus?.isWorkEligibilityCompletion &&
      clientProfile?.profileStatus?.isWorkHistoryCompletion &&
      clientProfile?.profileStatus?.isEducationCompletion &&
      !hasPendingDocs
    );
  }, [clientProfile?.profileStatus]);
  if (!isReadyForApproval) {
    return (
      <Tooltip title="Make sure user's profile is complete and all the required documents are approved.">
        <div>
          <Button
            id="approve_button"
            title="Approve Profile"
            onClick={() => {}}
            icon="priority"
            variant="filled"
            disabled={!isReadyForApproval}
          />
        </div>
      </Tooltip>
    );
  }
  return (
    <>
      <Button
        id="approve_button"
        title="Approve Profile"
        onClick={handleYes}
        icon="priority"
        variant="filled"
        disabled={hasPendingDocs}
      />
      {verificationConfirmation && (
        <ConfirmationModal
          text={t("alertMessages.approveProfile")}
          open={verificationConfirmation}
          handleYes={handleYes}
          handleNo={handleNo}
        />
      )}
    </>
  );
};

export default ApproveUserProfile;
