import JobListTable from "./JobListTable";
import SideDrawer from "../common/SideDrawer";
import { useCallback, useMemo, useState } from "react";
import JobTypeTemplate from "./JobTypeTemplate";
import { Errors_Template, JOB_TYPE_TEMPLATE } from "./constants";
import { cloneDeep, isEqual } from "lodash";
import ConfirmationModal from "../common/ConfirmationModal";
import { ContactTypes, JobCategoryTypes } from "../../models/types";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateJobCategoryThunk } from "../../redux/thunks/jobCategory";
import { validateForm } from "../../helpers/validationHelpers";
import { jobCategoryFormValidationConfig } from "./validationConfig";
import useUserRole from "../hooks/useUserRole";

const JobList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const jobsCategoryFromState = useAppSelector(
    (state) => state.jobCategoryReducer.jobCategories
  );
  const isLoading = useAppSelector((state) => state.jobCategoryReducer.loading);
  const { isFacilityAdmin, isStaffAdmin } = useUserRole();
  // To show left side drawer with form
  const [openDrawer, setOpenDrawer] = useState(false);
  // State for Job List Drawer
  const [jobTemplateData, setJobTemplateData] = useState<JobCategoryTypes>(
    cloneDeep(JOB_TYPE_TEMPLATE)
  );
  // Copy of the intial data to identify if user has changed the values or not
  const [dataCopy, setDataCopy] = useState({});
  /* To show confirmation modal before resetting data,
   if user clicks on another Job Type when the drawer is open.
   It also keeps track of id of recently clicked row to show that specific data
   */
  const [showConfirmation, setShowConfirmation] = useState({
    status: false,
    id: "",
  });

  const [errors, setErrors] = useState({ ...Errors_Template });
  const handleAddJobType = useCallback(() => {
    // When Create job type is selected, setting empty template in the state
    const template = {
      ...JOB_TYPE_TEMPLATE,
      // id: (jobsCategoryFromState?.length + 1).toString(),
    };
    setJobTemplateData(template);
    setDataCopy(template);
    setOpenDrawer(true);
  }, []);
  const showHourlyRate = useMemo(() => {
    return !isFacilityAdmin && !isStaffAdmin;
  }, [isFacilityAdmin, isStaffAdmin]);
  const showJobDetails = useCallback(
    (id: string | undefined) => {
      // Setting currently selected object's data in the state
      let selectedData = cloneDeep(
        jobsCategoryFromState.filter((data) => data.id === id)?.[0]
      );
      const phone = selectedData.contactMobile?.slice(3);
      if (phone) {
        selectedData.contactMobile = phone;
      }
      selectedData.hourlyRate =
        selectedData.hourlyRate?.replace("$", "") ?? "".trim();
      setJobTemplateData(selectedData);
      setDataCopy(selectedData);
      setOpenDrawer(true);
    },
    [jobsCategoryFromState]
  );
  const handleDrawerClose = useCallback(() => {
    setOpenDrawer(false);
    setJobTemplateData({ ...JOB_TYPE_TEMPLATE });
    setErrors({ ...Errors_Template });
  }, []);
  const handleConfirmation = useCallback(
    (id?: string, resetClicked: boolean = false) => {
      // Showing confirmation only when the user clicks a row other than the currently selected on
      if (id !== jobTemplateData.id) {
        const dataChanged = !isEqual(dataCopy, jobTemplateData);
        if (openDrawer && !resetClicked && dataChanged) {
          // If drawer is open, show the modal and save id of recently clicked row
          setShowConfirmation({
            status: true,
            id: id || "",
          });
        } else {
          // If drawer is not open, set the drawer. If Add button is clicked id would be "new"
          setErrors({ ...Errors_Template });
          id === "new"
            ? handleAddJobType()
            : id === "close"
            ? handleDrawerClose()
            : showJobDetails(id);
          setShowConfirmation({ id: "", status: false });
        }
      }
    },
    [
      dataCopy,
      handleAddJobType,
      jobTemplateData,
      openDrawer,
      showJobDetails,
      handleDrawerClose,
    ]
  );

  const handleSave = useCallback(() => {
    const dataCopy = {
      ...jobTemplateData,
      contactMobile: jobTemplateData.contactMobile
        ? jobTemplateData.contactMobile?.startsWith("+1 ")
          ? jobTemplateData.contactMobile || ""
          : "+1 " + jobTemplateData.contactMobile
        : "",
    };
    if (dataCopy.id) {
      dataCopy.contactIsPrimary = true;
      dispatch(updateJobCategoryThunk(dataCopy, handleClose));
    } else {
      // dispatch(
      //   createJobCategoryThunk({
      //     formData: dataCopy,
      //     jobCategoryList: jobsCategoryFromState,
      //   })
      // );
    }
  }, [jobTemplateData, dispatch]);

  const handleClose = () => {
    setJobTemplateData({ ...JOB_TYPE_TEMPLATE });
    setOpenDrawer(false);
  };
  const validateFields = useCallback(() => {
    let { valid, formData, errorObject } = validateForm(
      jobTemplateData,
      jobCategoryFormValidationConfig,
      errors
    );
    if (
      !valid &&
      errorObject.hourlyRate === "Please provide valid hourly rate." &&
      !showHourlyRate
    ) {
      errorObject.hourlyRate = "";
      valid = true;
    }
    setJobTemplateData(formData);
    if (valid) {
      handleSave();
    } else {
      setErrors(errorObject);
    }
  }, [errors, handleSave, jobTemplateData, showHourlyRate]);
  const handlePhoneChange = useCallback(
    (value: string) => {
      // Inserting space in the phone value as per Canadian Phone format
      if (
        value &&
        (value.length === 3 || value.length === 7) &&
        jobTemplateData?.contactMobile &&
        value.length > jobTemplateData.contactMobile.length
      ) {
        return value + " ";
      }
      return value;
    },
    [jobTemplateData]
  );
  const handleChange = useCallback(
    (id: string, value: string | number | null) => {
      const stringValue = value?.toString();
      if (id === "contactPhone" && stringValue) {
        // Validating if phone number includes any other chars other than digits and space, and it is not more than 12
        const phonePattern = /^[0-9 ]*$/;
        if (!phonePattern.test(stringValue) || stringValue.length > 12) {
          return;
        }
        value = handlePhoneChange(stringValue);
      }
      if (id === "contactName" && stringValue) {
        const namePattern = /^[a-z ,.'-]+$/i;
        if (!namePattern.test(stringValue)) {
          return;
        }
      }
      // Setting the value in state
      setJobTemplateData({ ...jobTemplateData, [id]: value });

      // Resetting the errors
      setErrors({ ...errors, [id]: "" });
    },
    [handlePhoneChange, jobTemplateData, errors]
  );

  const handleNameSelect = (contact: ContactTypes) => {
    const { email, name, officePhone, mobile } = contact;
    let phone = mobile ? mobile : officePhone;
    if (phone.includes("+1 ")) {
      phone = phone.replace("+1 ", "");
    } else if (phone.includes("+1")) {
      phone = phone.replace("+1", "");
    }
    setJobTemplateData((prev) => ({
      ...prev,
      contactMobile: phone,
      contactName: name,
      contactEmail: email,
    }));
  };
  return (
    <>
      <div className="h-full bg-white flex">
        <div className="mr-3 w-full">
          {/* Disabling add button */}
          {/* <div className="my-2 flex justify-end">
            <AddButton
              onClick={() => handleConfirmation("new")}
              tooltipText={t("jobList.addNewJobType")}
            />
          </div> */}
          <JobListTable
            jobList={jobsCategoryFromState}
            onClick={handleConfirmation}
          />
        </div>
        {openDrawer && (
          <SideDrawer
            open={openDrawer}
            onClose={() => handleConfirmation("close")}
            title={t("common.job") || ""}
            onSubmit={validateFields}
            primaryButton={t("common.save") || ""}
            disablePrimaryBtn={isLoading}
          >
            <JobTypeTemplate
              data={jobTemplateData}
              onChange={handleChange}
              errors={errors}
              handleNameSelect={handleNameSelect}
            />
          </SideDrawer>
        )}
      </div>
      {showConfirmation && (
        <ConfirmationModal
          text={t("alertMessages.discardConfirmation")}
          open={showConfirmation.status}
          handleYes={() => handleConfirmation(showConfirmation.id, true)}
          handleNo={() => setShowConfirmation({ id: "", status: false })}
        />
      )}
    </>
  );
};

export default JobList;
