import { Card, Table, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import Icon from "../common/Icon";
import { ShiftTypes } from "../../models/types";

type ORIENTATION_TABLE_TYPES = {
  onNameClick: (empId: string) => void;
  handleCreate: () => void;
};
const ShiftsTable = (props: ORIENTATION_TABLE_TYPES) => {
  const { t } = useTranslation();
  const { onNameClick, handleCreate } = props;
  const shiftsData = useAppSelector((state) => state.settingsReducer.shifts);
  const columns = [
    {
      title: t("settings.shiftName"),
      dataIndex: "title",
      key: "title",
      width: "40%",
      render: (_: any, { title, id }: { title: string; id: string }) => {
        return (
          <div className="link-text" onClick={() => onNameClick(id)}>
            {title}
          </div>
        );
      },
    },
    {
      title: t("settings.startTime"),
      dataIndex: "startTime",
      key: "startTime",
      width: "30%",
    },
    {
      title: t("settings.finishTime"),
      dataIndex: "endTime",
      key: "endTime",
      width: "30%",
    },
  ];

  return (
    <Card
      className="shadow-md my-4 -pt-2"
      title={
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Icon name="schedule" fontSize={18} className="mr-1" />
            {t("settings.shift")}
          </div>
          <Tooltip placement="left" title={t("settings.createShift")}>
            <span className="bg-[#1677ff] text-white p-1 flex justify-center items-center rounded-[50%] hover:bg-[#1890ff]">
              <Icon
                role="button"
                name="add"
                fontSize={18}
                className="font-semibold"
                onClick={handleCreate}
              />
            </span>
          </Tooltip>
        </div>
      }
    >
      <Table
        columns={columns}
        dataSource={shiftsData}
        pagination={false}
        sticky
        size="small"
        rowKey={(shifts: ShiftTypes) => {
          return shifts.id;
        }}
      />
    </Card>
  );
};

export default ShiftsTable;
