import { AxiosError } from "axios";
import { handleApiErrors } from "../../helpers/helpers";
import { AppDispatch } from "../store";
import { getUpcomingJobs } from "../../services/shiftSchedule.services";
import { setUpcomingJobs, setUpcomingJobsLoading } from "../reducers/upcomingJobsReducer";


export const getAllUpcomingJobsThunk =
    () => async (dispatch: AppDispatch) => {
        dispatch(setUpcomingJobsLoading(true));
        try {
            const response = await getUpcomingJobs();
            dispatch(
                setUpcomingJobs(response.data.data)
            );
        } catch (err) {
            handleApiErrors(err as AxiosError, dispatch);
        } finally {
            dispatch(setUpcomingJobsLoading(false));
        }
    };