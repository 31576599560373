import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Notification } from "../../../../models/types";
import NotificationText from "../NotificationText";
import Icon from "../../../common/Icon";
import NotificationAvatar from "../../../common/UserAvatar";
import { ROUTE } from "../../../../constants/routes";
import dayjs from "dayjs";
import { DATE_MMM_YEAR } from "../../../../constants/defaults";
import { getTimeFromUtcDate } from "../../../../helpers/dateTime.helper";
import { getJobCategory } from "../../../../helpers/helpers";

type Props = {
  notification: Notification;
  handleNotificationOpen: (id: number) => void;
};
const JobCompleted = (props: Props) => {
  const { notification, handleNotificationOpen } = props;
  const {
    id,
    jobId,
    clientId,
    clientFirstName,
    clientLastName,
    jobAllocationId,
    shiftStartTime,
    shiftEndTime,
    jobCategory,
    jobDate,
    facility,
    facilityId,
    timezoneId,
  } = notification;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNotification = (action: "edit" | "approve") => {
    navigate(ROUTE.shiftApproval, {
      state: {
        action,
        id: jobId,
        jobAllocationId,
        clientId,
        clientFirstName,
        clientLastName,
        shiftStartTime,
        shiftEndTime,
        facilityId,
      },
    });
    handleNotificationOpen(id);
  };
  const Message = () => {
    const date = dayjs(jobDate).format(DATE_MMM_YEAR);
    const time = (jobDate && getTimeFromUtcDate(jobDate, timezoneId)) || "";
    return (
      <div>
        <b>
          {`${clientFirstName} ${clientLastName} `} completed{" "}
          {getJobCategory(jobCategory)}
        </b>{" "}
        job at <b>{facility}</b> scheduled on <b>{date}</b> at <b>{time}</b>
      </div>
    );
  };

  return (
    <>
      <div className="hover:bg-[#d9d9d9] p-4">
        <div className="flex items-start">
          <div className="mr-4">
            <NotificationAvatar
              src={notification.clientAvatarUrl || notification.facilityLogo}
              firstName={notification.clientFirstName}
              lastName={notification.clientLastName}
            />
          </div>
          <NotificationText notification={notification}>
            <Message />
          </NotificationText>
        </div>
        <div className="flex justify-end">
          <Button
            id="edit-shift-btn"
            type="primary"
            icon={<Icon name="edit" fontSize={16} />}
            className="mx-3 flex justify-center items-center"
            onClick={() => handleNotification("edit")}
            size="small"
          >
            {t("shiftApproval.editShift")}
          </Button>
          <Button
            id="approve-shift-btn"
            icon={<Icon name="done" fontSize={16} />}
            className="flex justify-center items-center secondary-btn"
            size="small"
            onClick={() => handleNotification("approve")}
          >
            {t("common.approve")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default JobCompleted;
