import { useMemo, useState } from "react";
import { Card } from "../common/Card";
import { UserWorkEligibilityTypes } from "../../models/types";
import { Divider, Tooltip } from "antd";
import dayjs from "dayjs";
import { YYYY_MMM_DATE } from "../../constants/defaults";
import MaskedText from "../common/MaskedText";
import { isEmpty } from "lodash";
import Icon from "../common/Icon";
import EditWorkEligibility from "./edit/WorkCredentials";
import { useAppSelector } from "../../redux/hooks"

type Props = {
  workEligibilityInfo: UserWorkEligibilityTypes;
  license?: string;
  jobCategory: string;
  isConsultant: boolean;
};

const JOB_CATEGORIES = {
  rn: "RN",
  cca: "CCA",
  lpn: "LPN",
  sitter: "Sitter Service",
};

const WorkCredentials = (props: Props) => {
  const { workEligibilityInfo, license, jobCategory, isConsultant } = props;
  const isWorkCredentialsCompleted = useAppSelector(
    (state) =>
      state.clientsReducer.clientProfile.profileStatus
        ?.isWorkEligibilityCompletion
  );
  const workPermitExpiry = useMemo(() => {
    if (!workEligibilityInfo?.workPermitExpiryDate) {
      return "NA";
    }
    const workPermitExpiryDate = workEligibilityInfo?.workPermitExpiryDate?.split('T')[0];
    return dayjs(workPermitExpiryDate).format(
      YYYY_MMM_DATE
    );
  }, [workEligibilityInfo?.workPermitExpiryDate]);
  const category = useMemo(() => {
    const jc = jobCategory?.toLowerCase();
    if (jc?.startsWith("rn")) {
      return JOB_CATEGORIES.rn;
    } else if (jc?.startsWith("lpn")) {
      return JOB_CATEGORIES.lpn;
    } else if (jc?.startsWith("cca")) {
      return JOB_CATEGORIES.cca;
    } else if (jc?.startsWith("sitter")) {
      return JOB_CATEGORIES.sitter;
    } else {
      return "";
    }
  }, [jobCategory]);

  const showLicense = useMemo(() => {
    if (category === JOB_CATEGORIES.rn || category === JOB_CATEGORIES.lpn) {
      return license;
    }
    return "";
  }, [category, license]);
  const [showEditWorkEligibility, setShowEditWorkEligibility] = useState(false);

  const onEdit = (e: any) => { setShowEditWorkEligibility(true); }

  return (
    <Card title={
      <div className="flex justify-between items-center mb-2">
        <span className="font-bold">Work Credentials</span>
        {isConsultant && !isWorkCredentialsCompleted && <Tooltip title="Add Work Credentials">
          <div className="flex items-center gap-1 text-[#FBB360]" role="button">
            <Icon name="warning" fontSize={16} className="font-semibold" />
            <span className="text-xs">Action needed</span>
          </div>
        </Tooltip>}
        {isConsultant && <span className="bg-[#1677ff] text-white p-1 flex justify-center items-center rounded-[50%] hover:bg-[#1890ff]">
          <Icon role="button" name="edit" fontSize={18} onClick={onEdit} />
        </span>}
      </div>
    }>
      {
        !isEmpty(workEligibilityInfo) && (<div>
          <div className="grid grid-cols-12">
            <div className="col-span-8 text-grey capitalize">
              Work Eligibility
            </div>
            <div className="col-span-4">{workEligibilityInfo?.legalStatus}</div>
          </div>
          <Divider className="my-2" />
          <div className="grid grid-cols-12">
            <div className="col-span-8 text-grey capitalize">
              Permit Expires on
            </div>
            <div className="col-span-4">{workPermitExpiry}</div>
          </div>
          <Divider className="my-2" />
          {isConsultant && (
            <div className="grid grid-cols-12">
              <div className="col-span-8 text-grey capitalize">SIN #</div>
              <div className="col-span-4">
                <MaskedText text={workEligibilityInfo?.sin} />
              </div>
            </div>
          )}
          <Divider className="my-2" />
          {category && (
            <>
              <div className="grid grid-cols-12">
                <div className="col-span-8 text-grey capitalize">
                  Job Category
                </div>
                <div className="col-span-4">{category}</div>
              </div>
              <Divider className="my-2" />
            </>
          )}
          {workEligibilityInfo?.nnasRegistered && (
            <>
              <div className="grid grid-cols-12">
                <div className="col-span-8 text-grey capitalize">
                  NNAS Registered
                </div>
                <div className="col-span-4">Yes</div>
              </div>
              <Divider className="my-2" />
            </>
          )}
          {showLicense && (
            <>
              <div className="grid grid-cols-12">
                <div className="col-span-8 text-grey capitalize">License</div>
                <div className="col-span-4">{showLicense}</div>
              </div>
              <Divider className="my-2" />
            </>
          )}
          {workEligibilityInfo?.hasAdvisoryReport && (
            <>
              <div className="grid grid-cols-12">
                <div className="col-span-8 text-grey capitalize">
                  Has AdvisoryReport
                </div>
                <div className="col-span-4">Yes</div>
              </div>
              <Divider className="my-2" />
            </>
          )}
          <>
            <div className="grid grid-cols-12">
              <div className="col-span-8 text-grey capitalize">
                Canadian Work Experience
              </div>
              <div className="col-span-4">
                {workEligibilityInfo?.canadianExperience} Years
              </div>
            </div>
            <Divider className="my-2" />
          </>
          <>
            <div className="grid grid-cols-12">
              <div className="col-span-8 text-grey capitalize">
                Int. Work Experience
              </div>
              <div className="col-span-4">
                {workEligibilityInfo?.internationalExperience} Years
              </div>
            </div>
          </>
        </div>)
      }
      {showEditWorkEligibility && (<EditWorkEligibility
        workEligibilityInfo={workEligibilityInfo}
        isConsultant={isConsultant}
        showForm={showEditWorkEligibility}
        hideForm={() => setShowEditWorkEligibility(false)} />)}
    </Card>
  );
};

export default WorkCredentials;
