import { Modal } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPresignedUrlThunk } from "../../redux/thunks/app";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  approveClientDocumentThunk,
  setDocumentCompletionThunk,
} from "../../redux/thunks/clients";
import Button from "./Button";
import { getRequiredDocumentList } from "../../helpers/documents.helper";
import { getClientDocuments } from "../../services/clients.services";
import { logToSentry } from "../../helpers/helpers";

type ConfirmationModalTypes = {
  open: boolean;
  clientId: number;
  documentId: number;
  fileName: string;
  handleCancel: () => void;
};
const VerifyDocumentModal = (props: ConfirmationModalTypes) => {
  const { t } = useTranslation();
  const { open, clientId, documentId, fileName, handleCancel } = props;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const clientProfile = useAppSelector(
    (state) => state.clientsReducer.clientProfile
  );

  useEffect(() => {
    handleLoadDocument();
  }, []);

  const handleLoadDocument = () => {
    if (documentId > 0) {
      dispatch(getPresignedUrlThunk(documentId))
        .then((result) => {
          console.log(result);
        })
        .catch((e) => {
          console.log(e);
          logToSentry(e, "Documents", "VerifyDocumentModal");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleDocumentCompletion = async () => {
    const requiredDocs = getRequiredDocumentList(
      clientProfile?.jobTemplateId,
      clientProfile?.workEligibility?.legalStatus
    );
    try {
      const response = await getClientDocuments(clientId);
      const clientDocs = response.data.data;
      const areAllDocsApproved = Object.keys(requiredDocs).every((docKey) => {
        return (
          clientDocs.filter(
            (clientDocs: any) =>
              clientDocs.title === docKey &&
              clientDocs.documentStatus === "Approved"
          )?.length > 0
        );
      });
      if (areAllDocsApproved) {
        dispatch(
          setDocumentCompletionThunk({
            payload: { isDocumentCompletion: true, userId: clientId },
          })
        );
      }
    } catch (error) {
      console.log("Unable to set the document completion status");
    }
  };
  const handleYes = () => {
    dispatch(
      approveClientDocumentThunk(
        clientId,
        documentId,
        fileName,
        handleDocumentCompletion
      )
    );
    handleCancel();
  };
  const handleNo = () => {
    handleCancel();
  };

  return (
    <Modal
      open={open}
      title={fileName}
      onOk={handleYes}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <div className="flex justify-end gap-3">
          <Button
            key="yes"
            variant="filled"
            onClick={handleYes}
            id="yes-btn"
            title={t("userDocument.approve")}
          />
          <Button
            key="no"
            variant="outline"
            id="no-btn"
            onClick={handleNo}
            title={t("common.cancel")}
          />
        </div>,
      ]}
    >
      <div>
        {isLoading && <div>Loading..</div>}
        {!isLoading && <div>{t("alertMessages.approveDocument")}</div>}
      </div>
    </Modal>
  );
};

export default VerifyDocumentModal;
