import { DATE_MONTH_YEAR } from "../../../constants/defaults";
import { getDateByFormat } from "../../../helpers/helpers";
import { WorkHistoryTypes } from "../../../models/types";
import Icon from "../Icon";

const WorkHistory = (props: { workHistory: WorkHistoryTypes[] }) => {
  const { workHistory } = props;
  if (!workHistory || !workHistory.length) {
    return null;
  }
  return (
    <div className="flex">
      <Icon name="work_history" fontSize={18} className="mt-[2px]" />
      <div className="ml-2">
        {workHistory.map((item, index) => {
          const startDate = getDateByFormat(item.startDate, DATE_MONTH_YEAR);
          const endDate = item.endDate
            ? getDateByFormat(item.endDate, DATE_MONTH_YEAR)
            : "Present";
          return (
            <div key={index} className="mb-3">
              <div className="font-semibold">{item.employer}</div>
              <div className="text-sm text-black">{item.title}</div>
              <div className="text-grey text-sm">{`${startDate} to ${endDate}`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkHistory;
