import { useTranslation } from "react-i18next";
import Icon from "./Icon";
import Button from "./Button";

type SideDrawerTypes = {
  open: boolean;
  onClose?: () => void;
  title: string | React.ReactElement | undefined;
  showFooter?: boolean;
  placement?: string;
  children: React.ReactNode;
  width?: string;
  viewMode?: boolean;
  primaryButton?: string;
  secondaryButton?: string;
  disablePrimaryBtn?: boolean;
  showBtnInHeader?: boolean;
  headerBtnTitle?: string;
  onHeaderBtnClick?: () => void;
  onSubmit?: () => void;
};
const SideDrawer = (props: SideDrawerTypes) => {
  const { t } = useTranslation();
  const {
    open,
    title,
    onClose,
    children,
    primaryButton = t("common.post"),
    secondaryButton = t("common.close"),
    onSubmit,
    showFooter = true,
    disablePrimaryBtn,
  } = props;
  const Title = () => (
    <div className="flex justify-between text-lg p-3 bg-white">
      <div className="font-semibold ml-4">{title}</div>
      <div className="flex gap-3">
        <div role="button" onClick={onClose}>
          <Icon name="close" />
        </div>
      </div>
    </div>
  );
  const handleSubmit = () => {
    onSubmit && onSubmit();
  };
  const handleClose = () => {
    onClose && onClose();
  };
  const Footer = () => {
    return (
      <div className="footer p-3 border-t gap-3 flex justify-end">
        <Button
          title={secondaryButton || ""}
          onClick={handleClose}
          id="cancel-btn"
          variant="outline"
        />
        <Button
          variant="filled"
          onClick={handleSubmit}
          disabled={disablePrimaryBtn}
          id="submit-btn"
          title={primaryButton || ""}
        />
      </div>
    );
  };
  if (!open) {
    return <></>;
  }
  return (
    <div className="container sidebar" style={{ maxWidth: "450px" }}>
      <Title />
      <div className="overflow-auto px-7 flex-1">{children}</div>
      {showFooter && (
        <div className="bg-white z-20">
          <Footer />
        </div>
      )}
    </div>
  );
};

export default SideDrawer;
