import { ShiftApprovalRequestType } from "./../../models/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ShiftApprovalReducerState {
  shiftRequests: ShiftApprovalRequestType[];
  loading: boolean;
}
const initialState: ShiftApprovalReducerState = {
  shiftRequests: [],
  loading: false,
};

export const clientsSlice = createSlice({
  name: "shiftApprovalRequests",
  initialState,
  reducers: {
    setShiftRequests: (
      state,
      action: PayloadAction<ShiftApprovalRequestType[]>
    ) => {
      state.shiftRequests = (action.payload || []).map((item, key) => ({
        key,
        ...item,
      }));
    },
    setShiftApprovalRequestLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetShiftApprovalState: (state) => {
      state.shiftRequests = [];
    },
  },
});

export const {
  setShiftRequests,
  setShiftApprovalRequestLoading,
  resetShiftApprovalState,
} = clientsSlice.actions;

export default clientsSlice.reducer;
