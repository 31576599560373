/* eslint-disable no-useless-escape */
import { cloneDeep } from "lodash";

export const validNumericInput = (value: string) => {
  const numericRegex = /^[0-9]+$/;
  return numericRegex.test(value);
};
export const isValidEmail = (value: string) => {
  const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return emailPattern.test(value);
};
export const isValidPhone = (value: string) => {
  const phoneNumberRegex =
    /^\+?(\d{1,3})?[-. ]?(\d{3})[-. ]?(\d{3})[-. ]?(\d{4})$/;
  return phoneNumberRegex.test(value);
};
export const isValidHourlyRate = (value: string) => {
  return Number(value) >= 1;
};
export const hasDataChanged = (formData: any, stateData: any) => {
  let changed = false;
  for (let key in formData) {
    if (formData[key] !== stateData[key]) {
      changed = true;
      break;
    }
  }
  return changed;
};

export function validateForm(
  formData: any,
  validationConfig: any,
  errorObject: any
) {
  formData = cloneDeep(formData);
  errorObject = cloneDeep(errorObject);
  let valid = true;
  
  Object.keys(formData).forEach((key) => {
    const value =
      typeof formData[key] === "string"
        ? formData[key]?.toString()?.trim()
        : formData[key];
    formData[key] = value;
    const config = validationConfig[key];
    let errorMessage = "";
    // Checking if the value exist or not
    if (
      config &&
      config.required &&
      (value === null || value === undefined || value === "")
    ) {
      errorMessage = config.required;
      valid = false;
    } else if (
      config &&
      config.minLength &&
      value &&
      value.length < config.minLength.length
    ) {
      // Checking for min length
      errorMessage = config.minLength.message;
      valid = false;
    } else if (
      config &&
      config.maxLength &&
      value &&
      value.length > config.maxLength.length
    ) {
      // Checking for max length
      errorMessage = config.maxLength.message;
      valid = false;
    } else if (
      config &&
      config.match &&
      formData[config.match.key] &&
      value !== formData[config.match.key]
    ) {
      // Checking for matching with other field like confirm password, confirm account number, etc.
      errorMessage = config.match.message;
      valid = false;
    } else if (
      value &&
      config &&
      config.regex &&
      !config.regex.callback(value)
    ) {
      // Regex validation
      errorMessage = config.regex.message;
      valid = false;
    }
    errorObject[key] = errorMessage;
  });
  return { valid, formData, errorObject };
}
