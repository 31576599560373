import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { JobCategoryTypes } from "../../models/types";
import { cloneDeep } from "lodash";

export interface JobCategoryReducerState {
  jobCategories: JobCategoryTypes[];
  loading: boolean;
}

const initialState: JobCategoryReducerState = {
  loading: false,
  jobCategories: [],
};

export const jobsSlice = createSlice({
  name: "jobsCategory",
  initialState,
  reducers: {
    setJobCategories: (state, action: PayloadAction<JobCategoryTypes[]>) => {
      state.jobCategories = cloneDeep(action.payload) || [];
    },
    setJobCategoryLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetJobCategorySate: (state) => {
      state.jobCategories = [];
    },
  },
});

export const { setJobCategoryLoading, setJobCategories, resetJobCategorySate } =
  jobsSlice.actions;

export default jobsSlice.reducer;
