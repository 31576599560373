import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useEffect, useMemo, useState } from "react";
import {
  DropDownOptionTypes,
  UserWorkEligibilityTypes,
  WorkEligibility,
} from "../../../models/types";
import ConfirmationModal from "../../common/ConfirmationModal";
import InputField from "../../common/InputField";
import Dropdown from "../../common/Dropdown";
import Datepicker from "../../common/Datepicker";
import { DATE_FORMAT_PRIMARY } from "../../../constants/defaults";
import dayjs from "dayjs";
import RadioButton from "../../common/RadioButton";
import { updateWorkEligibilityThunk } from "../../../redux/thunks/clients";
import { Checkbox } from "antd";
import {
  getActiveJobsByClientId,
  getUpcomingJobsByClientId,
} from "../../../services/clients.services";

type Props = {
  workEligibilityInfo: UserWorkEligibilityTypes;
  license?: string;
  isConsultant: boolean;
  showForm: boolean;
  hideForm: () => void;
};

const LEGAL_STATUSES = [
  { id: "Citizen", value: "Citizen" },
  { id: "Permanent Resident", value: "Permanent Resident" },
  { id: "Work Permit", value: "Work Permit" },
  { id: "Student Permit", value: "Student Permit" },
];

const CERTIFICATION_TYPE_OPTIONS: DropDownOptionTypes[] = [
  { value: "ccaCertification", label: "CCA certification" },
  {
    value: "internationalEducationNurse",
    label: "International education nurse",
  },
];

const YES_NO_OPTIONS: DropDownOptionTypes[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

const EditWorkEligibility = ({
  showForm,
  hideForm,
  workEligibilityInfo,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const clientProfile = useAppSelector(
    (state) => state.clientsReducer.clientProfile
  );
  const jobCategoryData = useAppSelector(
    (state) => state.appReducer.jobTemplates
  );

  const jobCategoryOptions = useMemo(
    () =>
      (jobCategoryData || []).map((element) => ({
        value: element.id,
        label: element.title,
      })),
    [jobCategoryData]
  );

  const legalStatusOptions = useMemo(
    () =>
      (LEGAL_STATUSES || []).map((element) => ({
        value: element.id,
        label: element.value,
      })),
    []
  );

  const [formState, setFormState] =
    useState<UserWorkEligibilityTypes>(workEligibilityInfo);
  const [certificationType, setCertificationType] = useState<string>("");
  const [nnasRegistered, setNnasRegistered] = useState<string>("false");
  const [hasAdvisoryReport, setHasAdvisoryReport] = useState<string>("false");
  const [jobCategory, setJobCategory] = useState<string>("");

  const [errors, setErrors] = useState<any>({
    legalStatus: "",
    sin: "",
    jobTemplateId: "",
    workPermitExpiryDate: "",
    nnasRegistered: "",
  });

  const [showWorkPermitExpiry, setShowWorkPermitExpiry] =
    useState<boolean>(false);
  const [showCertificationType, setShowCertificationType] =
    useState<boolean>(false);
  const [showNNASRegistration, setShowNNASRegistration] =
    useState<boolean>(false);
  const [showHasAdvisoryReport, setShowHasAdvisoryReport] =
    useState<boolean>(false);
  const [showLicenseNumber, setShowLicenseNumber] = useState<boolean>(false);
  const [showLPNEligible, setShowLPNEligible] = useState<boolean>(false);
  const [showSitterEligible, setShowSitterEligible] = useState<boolean>(false);
  const isUpdateInProgress = useAppSelector(
    (state) => state.clientsReducer.isUpdateInProgress
  );
  useEffect(() => {
    if (!workEligibilityInfo) {
      setFormState((prevState) => ({
        ...prevState,
        nnasRegistered: "no",
        hasAdvisoryReport: "no",
      }));
    } else {
      if (
        ["Work Permit", "Student Permit"].includes(
          workEligibilityInfo.legalStatus
        )
      ) {
        setShowWorkPermitExpiry(true);
      }
      if (workEligibilityInfo.workPermitExpiryDate) {
        setFormState((prevState) => ({
          ...prevState,
          workPermitExpiryDate:
            workEligibilityInfo?.workPermitExpiryDate?.split("T")[0],
        }));
      }
      if (workEligibilityInfo.jobTemplateId) {
        const jCategory = getJobCategory(workEligibilityInfo.jobTemplateId);
        setJobCategory(jCategory?.title || "");
        if (jCategory?.title?.toLowerCase().startsWith("cca")) {
          setShowLPNEligible(false);
          setShowSitterEligible(true);
          setFormState((prevState) => ({
            ...prevState,
            lpnEligible: false,
          }));

          setShowCertificationType(true);
          if (workEligibilityInfo.internationalEducatedNurse) {
            setCertificationType("internationalEducationNurse");
            setFormState((prevState) => ({
              ...prevState,
              certificationType: "internationalEducationNurse",
            }));
            setShowNNASRegistration(true);
            if (workEligibilityInfo.nnasRegistered) {
              setShowHasAdvisoryReport(true);
              setNnasRegistered("true");
              setHasAdvisoryReport(
                workEligibilityInfo.hasAdvisoryReport ? "true" : "false"
              );
            } else {
              setNnasRegistered("false");
            }
          } else {
            setCertificationType("ccaCertification");
            setFormState((prevState) => ({
              ...prevState,
              certificationType: "ccaCertification",
            }));
          }
        } else {
          setShowCertificationType(false);
        }

        if (jCategory?.title?.toLowerCase().startsWith("rn")) {
          setShowLPNEligible(true);
          setShowSitterEligible(false);
          setFormState((prevState) => ({
            ...prevState,
            sitterEligible: false,
          }));
        }

        if (
          ["rn", "lpn"].some((prefix) =>
            jCategory?.title?.toLowerCase().startsWith(prefix)
          )
        ) {
          setShowLicenseNumber(true);
        } else {
          setShowLicenseNumber(false);
        }
      }
    }
  }, [workEligibilityInfo]);

  const handleBlur = (id: string, value: string | number | null) => {};

  const handleClose = () => {
    setFormState({
      legalStatus: "",
      sin: "",
      jobTemplateId: "",
      workPermitExpiryDate: "",
      nnasRegistered: "no",
      canadianExperience: 0,
      hasAdvisoryReport: "no",
      internationalExperience: 0,
    });
    setErrors({
      legalStatus: "",
      sin: "",
      jobTemplateId: "",
      workPermitExpiryDate: "",
    });
    hideForm();
  };

  const getJobCategory = (jobCategoryId: string | number | null | undefined) =>
    jobCategoryData.find((element) => element.id === Number(jobCategoryId));

  const getCertificationType = (
    certificationId: string | number | null | undefined
  ) =>
    CERTIFICATION_TYPE_OPTIONS.find(
      (element) => element.value === certificationId
    );

  const getAnswer = (id: string | number | null) => {
    const value = YES_NO_OPTIONS.find((element) => element.value === id);
    return value ? value.label === "Yes" : null;
  };

  const handleSave = (markDocsPending: boolean, setProfileOnHold: boolean) => {
    const {
      canadianExperience = 0,
      internationalExperience = 0,
      legalStatus,
      licenseNumber,
      jobTemplateId,
      sin,
      workPermitExpiryDate,
    } = formState;
    const certType = getCertificationType(certificationType);
    const selectedCertificationType = certType ? certType.label : "";
    const ccaCertification = selectedCertificationType
      ?.toLowerCase()
      ?.startsWith("cca");
    const internationalEducatedNurse = selectedCertificationType
      ?.toLowerCase()
      ?.startsWith("international");

    const isNNASRegistered = getAnswer(nnasRegistered);
    const isAdvisoryReport = !isNNASRegistered
      ? false
      : getAnswer(hasAdvisoryReport);
    let saveWorkPermitExpiryDate = true;
    if (
      legalStatus?.toLocaleLowerCase() !== "work permit" &&
      legalStatus?.toLocaleLowerCase() !== "student permit"
    ) {
      saveWorkPermitExpiryDate = false;
    }
    const payload: WorkEligibility = {
      id: workEligibilityInfo?.id,
      userId: clientProfile?.id,
      legalStatus,
      jobTemplateId: jobTemplateId || "",
      workPermitExpiry: saveWorkPermitExpiryDate
        ? workPermitExpiryDate
        : undefined,
      sin,
      isNnasRegistered: isNNASRegistered || false,
      canadianExperience,
      internationalExperience,
      hasAdvisoryReport: isAdvisoryReport || false,
      isWorkEligibilityCompletion: true,
      ccaCertification,
      internationalEducatedNurse,
      licenseNumber,
      sitterEligible: formState.sitterEligible
        ? formState.sitterEligible
        : null,
      lpnEligible: formState.lpnEligible ? formState.lpnEligible : null,
    };

    dispatch(
      updateWorkEligibilityThunk({
        payload,
        callback: handleClose,
        markDocsPending,
        setProfileOnHold,
      })
    );
  };

  const handleValidation = async () => {
    const errorsCopy = { ...errors };
    let valid = true;

    if (!formState?.legalStatus) {
      valid = false;
      errorsCopy.legalStatus = t("errors.legalStatusRequired");
    }
    if (
      ["Work Permit", "Student Permit"].includes(formState?.legalStatus) &&
      !formState?.workPermitExpiryDate
    ) {
      valid = false;
      errorsCopy.workPermitExpiryDate = t("errors.workPermitExpiryRequired");
    }
    if (!formState?.sin) {
      valid = false;
      errorsCopy.sin = t("errors.sinRequired");
    }
    if (!formState?.jobTemplateId) {
      valid = false;
      errorsCopy.jobTemplateId = t("errors.jobCategoryRequired");
    }
    if (formState?.jobTemplateId) {
      const jCategory = getJobCategory(formState?.jobTemplateId);
      if (
        jCategory?.title.toLowerCase().startsWith("cca") &&
        !formState?.certificationType
      ) {
        valid = false;
        errorsCopy.certificationType = t("errors.certificationTypeRequired");
      }
      if (
        ["rn", "lpn"].some((prefix) =>
          jCategory?.title.toLowerCase().startsWith(prefix)
        ) &&
        !formState?.licenseNumber
      ) {
        valid = false;
        errorsCopy.licenseNumber = t("errors.licenseNumberRequired");
      }
    }
    // Changing jobTemplateId should not be allowed if there exists any schedule or active jobs -> throw an error
    if (
      Number(formState.jobTemplateId) !== Number(clientProfile.jobTemplateId)
    ) {
      const activeJobsResponse = await getActiveJobsByClientId(
        Number(clientProfile.id)
      );
      const upcomingJobsResponse = await getUpcomingJobsByClientId(
        Number(clientProfile.id)
      );
      if (
        (activeJobsResponse?.data?.data &&
          activeJobsResponse?.data?.data?.length > 0) ||
        (upcomingJobsResponse?.data?.data &&
          upcomingJobsResponse?.data?.data?.length > 0)
      ) {
        valid = false;
        errorsCopy.jobTemplateId =
          "It is not possible to change the work category with active or upcoming jobs scheduled for the user.";
      }
    }

    if (valid) {
      let markDocsPending = false;
      let putProfileOnHold = false;
      if (
        Number(formState.jobTemplateId) !== Number(clientProfile.jobTemplateId)
      ) {
        putProfileOnHold = true;
        const isNewRnOrLpn =
          (Number(clientProfile.jobTemplateId) === 3 ||
            Number(clientProfile.jobTemplateId) === 4) &&
          (Number(formState.jobTemplateId) === 1 ||
            Number(formState.jobTemplateId) === 2);
        const isNewCCA = Number(formState.jobTemplateId) === 3;
        if (isNewRnOrLpn || isNewCCA) {
          markDocsPending = true;
        }
      }
      handleSave(markDocsPending, putProfileOnHold);
    } else {
      setErrors(errorsCopy);
    }
  };

  const handleChange = (id: string, value: any) => {
    if (id === "legalStatus") {
      setShowWorkPermitExpiry(
        ["Work Permit", "Student Permit"].includes(value)
      );
      if (formState?.legalStatus !== value) {
        setFormState((prevState) => ({
          ...prevState,
          workPermitExpiryDate: "",
        }));
      }
    }
    if (id === "jobTemplateId") {
      const jCategory = getJobCategory(value);
      if (formState?.jobTemplateId?.toString() !== value) {
        setFormState((prevState) => ({ ...prevState, licenseNumber: "" }));
      }
      setJobCategory(jCategory?.title || "");
      if (jCategory?.title.toLowerCase().startsWith("cca")) {
        setShowCertificationType(true);

        setShowLPNEligible(false);
        setShowSitterEligible(true);
        setFormState((prevState) => ({
          ...prevState,
          lpnEligible: false,
        }));
      } else {
        setCertificationType("");
        setNnasRegistered("false");
        setHasAdvisoryReport("false");
        setShowCertificationType(false);
        setShowNNASRegistration(false);
        setShowHasAdvisoryReport(false);
      }
      if (jCategory?.title.toLowerCase().startsWith("rn")) {
        setShowLPNEligible(true);
        setShowSitterEligible(false);
        setFormState((prevState) => ({
          ...prevState,
          sitterEligible: false,
        }));
      }

      setShowLicenseNumber(
        ["rn", "lpn"].some((prefix) =>
          jCategory?.title.toLowerCase().startsWith(prefix)
        )
      );
      if (
        ["lpn", "sitter"].some((prefix) =>
          jCategory?.title?.toLowerCase().startsWith(prefix)
        )
      ) {
        setShowLPNEligible(false);
        setShowSitterEligible(false);
      }
      if (
        ["sitter", "cca"].some((prefix) =>
          jCategory?.title.toLowerCase().startsWith(prefix)
        )
      ) {
        setFormState((prevState) => ({ ...prevState, licenseNumber: "" }));
      }
    }
    if (id === "certificationType") {
      setCertificationType(value);
      if (value === "internationalEducationNurse") {
        setShowNNASRegistration(true);
      } else {
        setNnasRegistered("false");
        setHasAdvisoryReport("false");
        setShowNNASRegistration(false);
        setShowHasAdvisoryReport(false);
      }
    }
    if (id === "nnasRegistered") {
      setNnasRegistered(value);
      setShowHasAdvisoryReport(value?.toString() === "true");
    }
    if (id === "hasAdvisoryReport") {
      setHasAdvisoryReport(value);
    }

    setFormState((prevState) => ({ ...prevState, [id]: value }));
    if (errors[id]) {
      setErrors((prevErrors: any) => ({ ...prevErrors, [id]: "" }));
    }
  };

  return (
    <ConfirmationModal
      open={showForm}
      title={t("clientProfile.editWorkEligibility") || ""}
      handleYes={handleValidation}
      handleNo={handleClose}
      primaryButtonText={t("common.save") || ""}
      secondaryButtonText={t("common.close") || ""}
      isPrimaryButtonDisabled={isUpdateInProgress}
      text={
        <div className="pt-4">
          <Dropdown
            id="legalStatus"
            options={legalStatusOptions}
            value={formState?.legalStatus}
            onChange={handleChange}
            label={t("clientProfile.legalStatus") || ""}
            placeholder={t("clientProfile.legalStatus") || ""}
            message={errors.legalStatus}
            status={errors.legalStatus ? "error" : ""}
          />
          {showWorkPermitExpiry && (
            <Datepicker
              id="workPermitExpiryDate"
              value={
                formState?.workPermitExpiryDate
                  ? dayjs(formState?.workPermitExpiryDate)
                  : undefined
              }
              onChange={handleChange}
              label={t("clientProfile.workPermitExpiry") || ""}
              required={true}
              message={errors.workPermitExpiryDate}
              placeholder={DATE_FORMAT_PRIMARY}
              status={errors.workPermitExpiryDate ? "error" : ""}
            />
          )}
          <InputField
            id="sin"
            type="text"
            label={t("clientProfile.sin") || ""}
            value={formState?.sin}
            placeholder={t("clientProfile.sin") || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            message={errors.sin}
            status={errors.sin ? "error" : ""}
          />
          <Dropdown
            id="jobTemplateId"
            options={jobCategoryOptions}
            value={jobCategory}
            onChange={handleChange}
            label={t("clientProfile.workCategory") || ""}
            placeholder={t("clientProfile.workCategory") || ""}
            message={errors.jobTemplateId}
            status={errors.jobTemplateId ? "error" : ""}
          />
          {showLPNEligible && (
            <div className={`flex flex-col mx-2 h[80px] w-full`}>
              <Checkbox
                id="lpnEligible"
                checked={formState?.lpnEligible}
                className="mb-3"
                onChange={(e: any) =>
                  handleChange("lpnEligible", e.target.checked)
                }
              >
                Are you interested to work as LPN?
              </Checkbox>
            </div>
          )}
          {showSitterEligible && (
            <div className={`flex flex-col mx-2 h[80px] w-full`}>
              <Checkbox
                id="sitterEligible"
                checked={formState?.sitterEligible}
                className="mb-3"
                onChange={(e: any) =>
                  handleChange("sitterEligible", e.target.checked)
                }
              >
                Are you interested to work as sitter?
              </Checkbox>
            </div>
          )}
          {showLicenseNumber && (
            <InputField
              id="licenseNumber"
              type="text"
              label={t("clientProfile.licenseNumber") || ""}
              value={formState?.licenseNumber}
              placeholder={t("clientProfile.licenseNumber") || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              message={errors.licenseNumber}
              status={errors.licenseNumber ? "error" : ""}
            />
          )}

          {showCertificationType && (
            <>
              <div className="mx-2 mb-3">
                <label className="mb-1 text-xs text-[#686565]">
                  {t("clientProfile.certificationType") || ""}{" "}
                  <span className="text-[#ff4d4f]">*</span>
                </label>
                <RadioButton
                  id="certificationType"
                  value={certificationType}
                  options={CERTIFICATION_TYPE_OPTIONS}
                  onChange={handleChange}
                />
                {errors.certificationType && (
                  <p className="text-xs text-red-500 ml-2">
                    {errors.certificationType}
                  </p>
                )}
              </div>
              {showNNASRegistration && (
                <div className="mx-2 mb-3">
                  <label className="mb-1 text-xs text-[#686565]">
                    {t("clientProfile.nnasRegistered") || ""}
                  </label>
                  <RadioButton
                    id="nnasRegistered"
                    value={nnasRegistered}
                    options={YES_NO_OPTIONS}
                    onChange={handleChange}
                  />
                </div>
              )}
              {showHasAdvisoryReport && (
                <div className="mx-2 mb-3">
                  <label className="mb-1 text-xs text-[#686565]">
                    {t("clientProfile.hasAdvisoryReport") || ""}
                  </label>
                  <RadioButton
                    id="hasAdvisoryReport"
                    value={hasAdvisoryReport}
                    options={YES_NO_OPTIONS}
                    onChange={handleChange}
                  />
                </div>
              )}
            </>
          )}
          <InputField
            id="canadianExperience"
            type="text"
            label={t("clientProfile.canadianExperience") || ""}
            value={formState?.canadianExperience}
            placeholder={t("clientProfile.canadianExperience") || ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputField
            id="internationalExperience"
            type="text"
            className="mt-1"
            label={t("clientProfile.internationalExperience") || ""}
            value={formState?.internationalExperience}
            placeholder={t("clientProfile.internationalExperience") || ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      }
    />
  );
};

export default EditWorkEligibility;
