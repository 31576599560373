import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import UpcomingJobsTable from "./UpcomingJobsTable";
import { getAllUpcomingJobsThunk } from "../../redux/thunks/upcomingJobs";

function UpcomingJobsPage() {
    const dispatch = useAppDispatch();

    let UpcomingJobsData = useAppSelector(
        (state) => state.UpcomingJobsReducer.UpcomingJobs
    );

    useEffect(() => {
        dispatch(getAllUpcomingJobsThunk());
    }, []);

    return (
        <>
            <div className="h-full bg-white flex">
                <div className="mr-3 w-full">
                    <UpcomingJobsTable
                        data={UpcomingJobsData}
                    />
                </div>
            </div>
        </>
    );
}

export default UpcomingJobsPage;