import { Skeleton } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  deleteUserThunk,
  resetUserPasswordThunk,
} from "../../redux/thunks/facility";
import ConfirmationModal from "../common/ConfirmationModal";
import Icon from "../common/Icon";
import ResetPasswordForm from "./ResetPasswordForm";
import { cloneDeep, get } from "lodash";
import useUserRole from "../hooks/useUserRole";
import Tooltip from "../common/ToolTip";
import UpdateUserForm from "./UpdateUserForm";
import { logoutThunk } from "../../redux/thunks/app";
import { ROUTE } from "../../constants/routes";
import { useNavigate } from "react-router";
import useResetState from "../hooks/useResetState";

const VALID_PWD_LENGTH = Number(process.env.REACT_APP_VALID_PASSWORD_LENGTH);

export type FormState = {
  password: string;
  confirmPassword: string;
};
export type ErrorState = {
  password: string;
  confirmPassword: string;
};
const UserManagement = ({
  facilityId,
  showRoleOnTooltip,
}: {
  facilityId: string;
  showRoleOnTooltip?: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isConsultant, isFacilityAdmin, userId } = useUserRole();
  const [confirmationState, setConfirmationState] = useState<{
    userName: string;
    userId: string;
    status: boolean;
  }>({ userId: "", userName: "", status: false });
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    userName: string;
    userId: string;
    status: boolean;
  }>({ userId: "", userName: "", status: false });
  const [updateUserState, setUpdateUserState] = useState<{
    userName: string;
    userId: string;
    roleName: string;
    status: boolean;
  }>({ userId: "", userName: "", roleName: "", status: false });
  const [resetFormState, setResetFormState] = useState<FormState>({
    password: "",
    confirmPassword: "",
  });
  const [resetErrorState, setResetErrorState] = useState<ErrorState>({
    password: "",
    confirmPassword: "",
  });
  const isLoading = useAppSelector((state) => state.facilitiesReducer.loading);
  const updateInprogress = useAppSelector(
    (state) => state.facilitiesReducer.updateInprogress
  );
  const facilitiesData = useAppSelector(
    (state) => state.facilitiesReducer.facilities
  );
  const currentFacility = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility
  );
  const navigate = useNavigate();
  const { resetState } = useResetState();

  const userList = useMemo(() => {
    if (isConsultant) {
      return cloneDeep(
        facilitiesData.filter((item) => item.id === facilityId)?.[0]?.users ??
          []
      );
    } else if (isFacilityAdmin) {
      return cloneDeep(get(currentFacility, "users", []));
    }
  }, [
    currentFacility,
    facilitiesData,
    facilityId,
    isConsultant,
    isFacilityAdmin,
  ]);

  const handleChange = (id: string, value: string | number | null) => {
    if (id === "password" || id === "confirmPassword") {
      value = value?.toString() || "";
      setResetFormState({ ...resetFormState, [id]: value });
      if (resetErrorState[id]) {
        setResetErrorState({ ...resetErrorState, [id]: "" });
      }
    }
  };
  const handleBlur = (id: string, value: string | number | null) => {
    if (id === "username" || id === "password" || id === "confirmPassword") {
      value = value?.toString().trim() || "";
      setResetFormState({ ...resetFormState, [id]: value });
    }
  };
  const handleResetPasswordValidation = () => {
    let valid = true;
    const errorsCopy = { ...resetErrorState };
    if (!resetFormState.password) {
      valid = false;
      errorsCopy.password = t("errors.passwordRequired");
    } else if (resetFormState.password.length < VALID_PWD_LENGTH) {
      valid = false;
      errorsCopy.password = t("errors.invalidPasswordLength");
    } else if (!resetFormState.confirmPassword) {
      valid = false;
      errorsCopy.confirmPassword = t("errors.passwordRequired");
    } else if (resetFormState.password !== resetFormState.confirmPassword) {
      valid = false;
      errorsCopy.confirmPassword = t("errors.passwordsDontMatch");
    }
    if (valid) {
      handleReset();
    } else {
      setResetErrorState(errorsCopy);
    }
  };

  const handleReset = () => {
    dispatch(
      resetUserPasswordThunk(
        {
          userId: confirmationState.userId,
          password: resetFormState.password,
        },
        handleSelfReset
      )
    );
  };

  const handleSelfReset = () => {
    if (Number(confirmationState.userId) === Number(userId)) {
      handleCloseResetPassword();
      dispatch(
        logoutThunk(userId, () => {
          resetState();
          navigate(ROUTE.signIn);
        })
      );
    } else {
      handleCloseResetPassword();
    }
  };
  const handleCloseResetPassword = () => {
    setConfirmationState({ userId: "", userName: "", status: false });
    setResetFormState({
      password: "",
      confirmPassword: "",
    });
    setResetErrorState({
      password: "",
      confirmPassword: "",
    });
  };

  const handleDeleteUser = () => {
    dispatch(
      deleteUserThunk(
        {
          userId: deleteConfirmation.userId,
          requestedBy: userId,
        },
        Number(facilityId),
        () => {
          setDeleteConfirmation({
            userId: "",
            userName: "",
            status: false,
          });
        }
      )
    );
  };
  return (
    <div>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div>
          {userList?.length ? (
            userList?.map((user) => (
              <div
                key={user?.username}
                className="flex justify-between mb-2 items-center"
              >
                <span
                  className={`flex items-center flex-grow-2 ${
                    showRoleOnTooltip ? "" : "w-[40%]"
                  }`}
                >
                  {showRoleOnTooltip ? (
                    <Tooltip title={user.roleName}>
                      <Icon
                        name={"person"}
                        className="mr-1 mt-[6px]"
                        fontSize={20}
                      />
                    </Tooltip>
                  ) : (
                    <Icon name={"person"} className="mr-1" fontSize={20} />
                  )}
                  <span>{user?.username}</span>
                </span>
                {!showRoleOnTooltip && <div>{user.roleName}</div>}
                <div className="flex items-center gap-2">
                  <Tooltip title={"Update User"}>
                    <Icon
                      name="edit"
                      role="button"
                      fontSize={20}
                      className="hover:bg-[#f1f3f4] rounded-[50%] p-2"
                      onClick={() => {
                        setUpdateUserState({
                          userId: user.userId,
                          userName: user?.username || "",
                          status: true,
                          roleName: user?.roleName || "",
                        });
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={"Reset Password"}>
                    <Icon
                      name="reset_wrench"
                      role="button"
                      fontSize={20}
                      className="hover:bg-[#f1f3f4] rounded-[50%] p-2"
                      onClick={() =>
                        setConfirmationState({
                          userId: user.userId,
                          userName: user?.username || "",
                          status: true,
                        })
                      }
                    />
                  </Tooltip>
                  <Tooltip title={"Delete User"}>
                    <Icon
                      name="auto_delete"
                      role="button"
                      fontSize={20}
                      disabled={Number(user.userId) === Number(userId)}
                      className={
                        Number(user.userId) === Number(userId)
                          ? "p-2"
                          : " hover:bg-[#f1f3f4] rounded-[50%] p-2"
                      }
                      onClick={() => {
                        setDeleteConfirmation({
                          userId: user.userId,
                          userName: user?.username || "",
                          status: true,
                        });
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            ))
          ) : (
            <>
              There are no users under this facility, please contact the admin
            </>
          )}
        </div>
      )}

      <ConfirmationModal
        open={confirmationState.status}
        title={`${t("facilities.resetPassword")} for ${
          confirmationState.userName
        }`}
        isLoading={updateInprogress}
        handleYes={handleResetPasswordValidation}
        handleNo={handleCloseResetPassword}
        primaryButtonText={t("common.change") || ""}
        secondaryButtonText={t("common.close") || ""}
        text={
          <ResetPasswordForm
            formState={resetFormState}
            errorState={resetErrorState}
            handleChange={handleChange}
            handleBlur={handleBlur}
            resetForSelf={Number(confirmationState.userId) === Number(userId)}
          />
        }
      />
      <ConfirmationModal
        open={deleteConfirmation.status}
        title={"Delete User"}
        handleYes={handleDeleteUser}
        handleNo={() => {
          setDeleteConfirmation({
            userId: "",
            userName: "",
            status: false,
          });
        }}
        isLoading={updateInprogress}
        primaryButtonText={t("common.delete") || ""}
        secondaryButtonText={t("common.close") || ""}
        text={
          <div className="my-3">
            Are you sure you want to delete the user{" "}
            <b>{deleteConfirmation.userName}</b>
          </div>
        }
      />
      <UpdateUserForm
        showForm={updateUserState.status}
        hideForm={() => {
          setUpdateUserState({
            userId: "",
            userName: "",
            roleName: "",
            status: false,
          });
        }}
        facilityId={facilityId}
        userId={updateUserState.userId}
        userName={updateUserState.userName}
        roleName={updateUserState.roleName}
        requestedBy={userId}
      />
    </div>
  );
};

export default UserManagement;
