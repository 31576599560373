import { Popover } from "antd";
import { useAppSelector } from "../../redux/hooks";
import Icon from "../common/Icon";
import { FACILITY_COMPLETION } from "./constants";
import { isEmpty } from "lodash"

const FacilityProgress = () => {
  const facilityStatus = useAppSelector(
    (state) => state.settingsReducer.facilityStatus
  ) as any;

  const content = (
    <div className="px-4">
      <ul>
        {FACILITY_COMPLETION.map((section) => {
          const isSectionVerified = facilityStatus?.[section.key];
          if(isEmpty(facilityStatus)){
            return <></>
          }
          return (
            <li key={section.key}>
              <p className="flex items-center gap-1">
                <span>{section.title}</span>
                <Icon
                  name={isSectionVerified ? "check_circle" : "cancel"}
                  fontSize={16}
                  className={
                    isSectionVerified ? "text-[#52c41a]" : "text-[#ff4d4f]"
                  }
                />
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <div>
      <Popover content={content} trigger="hover">
        <div role="button" className=" flex items-center">
          <Icon
            name="info"
            fontSize={30}
            className={
              facilityStatus?.isHourlyRateDefined &&
              facilityStatus?.isServiceChargeDefined &&
              facilityStatus?.isShiftsCreated &&
              facilityStatus?.isUsersAdded &&
              facilityStatus?.isContactsAdded &&
              facilityStatus?.isAddressProvided
                ? "text-[#52c41a]"
                : "text-[#ff4d4f]"
            }
          />
        </div>
      </Popover>
    </div>
  );
};

export default FacilityProgress;
