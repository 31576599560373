import { AxiosError } from "axios";
import { handleApiErrors, logToSentry } from "../../helpers/helpers";
import { CreateOrientation, OrientationRequestTypes } from "../../models/types";
import {
  fetchOrientationRequests,
  suggestNewDate,
  approveOrientation,
  SuggestDatePayload,
  ApproveDatePayload,
  fetchOverlappingJobsForOrientationSuggestion,
  createOrientation,
} from "../../services/orientation.services";
import {
  setOrientationLoading,
  setOrientationRequest,
  setOverlappingJobsForOrientation,
} from "../reducers/orientationReducer";
import { AppDispatch } from "../store";
import { showToast } from "../reducers/feedbackReducer";
import { getDateFromUtcDate } from "../../helpers/dateTime.helper";

export const getOrientationRequestsThunk =
  (facilityId: string, tz: string, callback?: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setOrientationLoading(true));
    try {
      const response = await fetchOrientationRequests(facilityId);
      let data = response.data.data || [];
      data = data?.map((o: OrientationRequestTypes) => {
        return {
          ...o,
          employeeName: `${o.firstName} ${o.lastName}`,
          requestedDates: o.requestedDates,
          suggestedDates: o.suggestedDates?.map((item: any) => {
            item = getDateFromUtcDate(item, tz);
            return item;
          }),
        };
      });
      dispatch(setOrientationRequest(data as OrientationRequestTypes[]));
      callback && callback();
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Orientation", "getOrientationRequestsThunk");
    } finally {
      dispatch(setOrientationLoading(false));
    }
  };
export const approveOrientationRequestThunk =
  (
    payload: ApproveDatePayload,
    facilityId: string,
    tz: string,
    callback?: () => void
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(setOrientationLoading(true));
    try {
      await approveOrientation(payload);
      dispatch(getOrientationRequestsThunk(facilityId, tz, callback));
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Orientation", "approveOrientationRequestThunk");
    } finally {
      dispatch(setOrientationLoading(false));
    }
  };
export const createOrientationThunk =
  (payload: CreateOrientation, callback?: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setOrientationLoading(true));
    try {
      await createOrientation(payload);
      callback && callback();
      dispatch(
        showToast({
          type: "success",
          message: "Successfully created the job",
          show: true,
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Orientation", "createOrientationThunk");
    } finally {
      dispatch(setOrientationLoading(false));
    }
  };
export const suggestOrientationDateThunk =
  (
    payload: SuggestDatePayload,
    facilityId: string,
    tz: string,
    callback: () => void
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(setOrientationLoading(true));
    try {
      await suggestNewDate(payload);
      dispatch(
        showToast({
          type: "success",
          message: "Suggested the date(s) for orientation",
          show: true,
        })
      );
      dispatch(getOrientationRequestsThunk(facilityId, tz, callback));
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Orientation", "suggestOrientationDateThunk");
    } finally {
      dispatch(setOrientationLoading(false));
    }
  };

export const getOverlappingJobForOrientationSuggestionThunk =
  (id: number, shiftId: string, callback?: () => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setOrientationLoading(true));
    try {
      const response = await fetchOverlappingJobsForOrientationSuggestion(
        id,
        shiftId
      );
      let data =
        response.data.map((result: any) => {
          return result.shiftDate;
        }) || [];
      dispatch(setOverlappingJobsForOrientation(data));
      callback && callback();
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Orientation", "getOverlappingJobForOrientationSuggestionThunk");
    } finally {
      dispatch(setOrientationLoading(false));
    }
  };
