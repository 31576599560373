import { Alert } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useCallback, useEffect, useMemo } from "react";
import { hideToast } from "../../redux/reducers/feedbackReducer";

const Toast = () => {
  const {
    toast: { type, show, message, direction },
  } = useAppSelector((state) => state.feedbackReducer);

  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(hideToast());
  }, [dispatch]);

  useEffect(() => {
    const autoHideToast = setTimeout(() => onClose(), 2500);
    return () => clearTimeout(autoHideToast);
  }, [show, onClose]);

  const directionClass = useMemo(() => {
    return direction === "left" ? "left-5" : "right-5";
  }, [direction]);

  if (!show) {
    return null;
  }

  return (
    <div className={`absolute top-5 ${directionClass} z-50`}>
      <Alert message={message} type={type} closable onClose={onClose} />
    </div>
  );
};

export default Toast;
