import { Image } from "antd";
import Icon from "../../common/Icon";
import Notifications from "../notifications";
import UserProfile from "./UserProfile";
import "./header.css";
import { useNavigate } from "react-router-dom";
import AnnouncementBanner from "./AnnouncementBanner";
import FacilitySelection from "./FacilitySelection";

const NewHeader = (props: any) => {
  const { onCollapse } = props;
  const navigate = useNavigate();

  return (
    <>
      <AnnouncementBanner />
      <div className="header-container">
        <div className="flex items-center gap-2">
          <Icon name="menu" role="button" onClick={onCollapse} />
          <Image
            width={150}
            src="/assets/carelynk_brand_logo.png"
            preview={false}
            className="p-2 mt-1"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "contain",
            }}
            role="button"
            onClick={() => navigate("/")}
          />
          <FacilitySelection />
        </div>
        <div className="right-container">
          <div className="flex gap-1">
            <Notifications />
            <UserProfile />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewHeader;
