import axios from "axios";
import { logToSentry } from "../helpers/helpers";

export const getInstance = (baseURL: string) => {
  const accessToken = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${accessToken}` };
  const axiosInstance = axios.create({
    baseURL,
    headers,
    withCredentials: true,
  });
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        window.location.href = "/signin";
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

export const baseUrl = process.env.REACT_APP_IRIS_API_BASE_URL || "";

export const downloadFile = async (
  fileUrl: string,
  fileTitle: string,
  fileExtension?: string
) => {
  try {
    const response = await axios({
      url: fileUrl,
      method: "GET",
      responseType: "blob",
    });
    // Extract file extension
    const extension = fileExtension
      ? fileExtension
      : response.data.type.split("/")[1];
    let fileName = `${fileTitle || "file"}.${extension}`; // Default filename if not found

    // Create a download link
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Trigger the download by simulating a click
    document.body.appendChild(link);
    link.click();

    // Clean up the object URL after the download has started
    window.URL.revokeObjectURL(url);

    // Remove the download link from the DOM
    document.body.removeChild(link);
  } catch (error) {
    // Handle any errors that occurred during the file download
    console.error("Error downloading the file:", error);
    logToSentry(error, "Services", "downloadFile");
    return error;
  }
};

export const downloadInvoicePdf = async (
  fileUrl: string,
  fileTitle: string
) => {
  downloadFile(fileUrl, fileTitle, "pdf");
};
