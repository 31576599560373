import { baseUrl, getInstance } from ".";

const instance = getInstance(baseUrl);

export const fetchNotifications = async (userId: number) => {
  return instance.get(`/notification/user/${userId}/type/web-push`);
};
export const markNotification = async (id: number) => {
  return instance.get(`/notification/mark-as-read/${id}?status=read`);
};
