import { startCase } from "lodash";
import { useMemo } from "react";
import { useLocation } from "react-router";

type Props = {
  className?: string;
};
const SectionName = (props: Props) => {
  const { className = "" } = props;
  const location = useLocation();
  const sectionName = useMemo(() => {
    return startCase(location.pathname.split("/")[1].replace("-", " "));
  }, [location.pathname]);

  return <div className={`text-lg flex ${className}`}>{sectionName}</div>;
};

export default SectionName;
