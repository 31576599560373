import { useCallback, useState } from "react";
import { DocumentTypes } from "../../../models/types";
import { useAppDispatch } from "../../../redux/hooks";
import { downloadUserDocumentThunk } from "../../../redux/thunks/app";
import Icon from "../Icon";
import VerifyDocumentModal from "../VerifyDocumentModal";
export const DOCUMENT_TITLES: any = {
  CcaCertificate: "CCA Certificate",
  CoverLetter: "Cover Letter",
  Resume: "Resume",
  IdProof: "Id Proof",
  WorkPermit: "Work Permit",
  ProfileImage: "Profile Picture",
};
const Documents = (props: {
  documents: DocumentTypes[];
  clientId: number;
  selectedDocumentUrl?: string;
}) => {
  const { documents, clientId } = props;
  const [verificationConfirmation, setVerificationConfirmation] = useState({
    status: false,
    documentId: 0,
    fileName: "",
  });
  const dispatch = useAppDispatch();
  const handleDownload = (fileId: number, fileName: string) => {
    dispatch(downloadUserDocumentThunk(fileId, fileName));
  };
  const handleApproveDocument = (
    documentId: number,
    fileId: number,
    fileName: string
  ) => {
    setVerificationConfirmation({
      status: true,
      documentId: documentId,
      fileName: fileName,
    });
  };
  const handleNo = useCallback(() => {
    setVerificationConfirmation({
      status: false,
      documentId: 0,
      fileName: "",
    });
  }, []);

  if (!documents || !documents.length) {
    return null;
  }
  return (
    <>
      <div className="ml-2 w-full pr-4 text-sm">
        {documents.map((item, index) => (
          <div key={item.fileId} className="flex justify-between mb-2">
            <div className="flex items-center">
              {DOCUMENT_TITLES[item.title] || item.title}
            </div>
            <div>
              {item.documentStatus && item.documentStatus === "Approved" && (
                <Icon
                  name="verified"
                  role="button"
                  fontSize={16}
                  className="my-1 blue-text font-semibold"
                />
              )}
              {(item.documentStatus === null ||
                item.documentStatus === "Pending") && (
                <Icon
                  name="error"
                  role="button"
                  fontSize={20}
                  className="my-1 error-text font-semibold"
                  onClick={() =>
                    handleApproveDocument(item.id, item.fileId, item.title)
                  }
                />
              )}
              <Icon
                name="download"
                role="button"
                fontSize={20}
                className="my-2 blue-text font-semibold"
                onClick={() => handleDownload(item.id, item.title)}
              />
            </div>
          </div>
        ))}
      </div>
      {verificationConfirmation && (
        <VerifyDocumentModal
          open={verificationConfirmation.status}
          clientId={clientId}
          documentId={verificationConfirmation.documentId}
          fileName={verificationConfirmation.fileName}
          handleCancel={handleNo}
        />
      )}
    </>
  );
};

export default Documents;
