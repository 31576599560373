import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getAllClientsThunk,
  getClientByIdThunk,
  getClientsByFacilityThunk,
} from "../../redux/thunks/clients";
import UserProfile from "./UserProfile";
import { get, isEmpty } from "lodash";
import { resetClientProfile } from "../../redux/reducers/clientsReducer";
import { Divider, Skeleton } from "antd";
import BankInfo from "./BankInfo";
import EducationInfo from "./EducationInfo";
import WorkHistory from "./WorkHistory";
import WorkEligibility from "./WorkCredentials";
import Documents from "./Documents";
import CurrentEmployment from "./CurrentEmployment";
import useUserRole from "../hooks/useUserRole";
import { ActionButtons } from "./ActionButtons";
import SideDrawer from "../common/SideDrawer";
import { CreateOrientationForm } from "../clients/CreateOrientationForm";
import ConfirmationModal from "../common/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { ContactTypes } from "../../models/types";
import { getShiftsByFacilityId } from "../../services/settings.services";
import { validateForm } from "../../helpers/validationHelpers";
import { createOrientationFormValidationConfig } from "../clients/validationConfig";
import { createOrientationThunk } from "../../redux/thunks/orientationRequests";
import dayjs from "dayjs";
import {
  convertUtcTimeToLocalTime,
  getShiftStartEndDateTimes,
} from "../../helpers/dateTime.helper";
import useFacilityTimezone from "../hooks/useFacilityTimezone";
import { setSidePanel } from "../../redux/reducers/appReducer";
import { SIDE_PANEL_NAMES, SIDE_PANEL_TYPES } from "../../constants/sidePanel";
import { disableShift } from "../../helpers/helpers";
import { PAGINATION, SORT } from "../../constants/pagination";
import DeviceDetails from "./DeviceDetails";

const DEFAULT_JOB_FORM_DATA = {
  facilityId: "",
  orientationDate: "",
  shiftId: "",
  contactName: "",
  contactMobile: "",
  contactEmail: "",
  postToPoolWhenWithdrawn: false,
};
const ClientProfile = () => {
  const { id: clientId } = useParams();
  const { t } = useTranslation();
  const { timeZoneId } = useFacilityTimezone();

  const dispatch = useAppDispatch();
  const clientProfile = useAppSelector(
    (state) => state.clientsReducer.clientProfile
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [createOrientationFormData, setCreateOrientationFormData] = useState(
    DEFAULT_JOB_FORM_DATA
  );
  const [createOrientationFormErrors, setCreateOrientationFormErrors] =
    useState(DEFAULT_JOB_FORM_DATA);
  const [facilityShifts, setFacilityShifts] = useState<{
    [key: string]: any[];
  }>({});
  const openDrawer = useAppSelector((state) => state.appReducer.sidePanel);
  const isLoading = useAppSelector((state) => state.clientsReducer.loading);
  const isPostJobPending = useAppSelector(
    (state) => state.orientationReducer.loading
  );
  const shiftsFromSettings = useAppSelector(
    (state) => state.settingsReducer.shifts
  );
  const facilityList = useAppSelector(
    (state) => state.facilitiesReducer.facilities
  );
  const facilityId = useAppSelector(
    (state) => state.facilitiesReducer.currentFacility.id
  );
  const { isConsultant, isFacilityAdmin } = useUserRole();

  useEffect(() => {
    if (clientId && Number(clientProfile.id) !== Number(clientId)) {
      dispatch(getClientByIdThunk(Number(clientId)));
    }
  }, [dispatch, clientId, clientProfile?.id]);
  useEffect(() => {
    if (!isConsultant) {
      setCreateOrientationFormData({
        ...createOrientationFormData,
        facilityId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId, isConsultant]);
  useEffect(() => {
    return () => {
      dispatch(resetClientProfile());
      handleFormClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isConsultant || facilityId) {
      isConsultant
        ? dispatch(getAllClientsThunk())
        : dispatch(getClientsByFacilityThunk(Number(facilityId)));
    }
  }, [dispatch, facilityId, isConsultant]);

  const clientName = useMemo(() => {
    return `${clientProfile.firstName} ${clientProfile.lastName}`;
  }, [clientProfile.firstName, clientProfile.lastName]);

  const shiftsOptions = useMemo(() => {
    if (
      createOrientationFormData.facilityId &&
      createOrientationFormData.orientationDate
    ) {
      let shiftsData = [];
      if (isConsultant) {
        shiftsData = facilityShifts[createOrientationFormData.facilityId] || [];
      } else {
        shiftsData = shiftsFromSettings;
      }
      const facilityTimeZoneId = getFacilityTimezoneId(
        createOrientationFormData.facilityId
      );
      return shiftsData.map((s) => {
        const disabled = disableShift(
          createOrientationFormData.orientationDate,
          s.startTime,
          facilityTimeZoneId
        );
        return {
          value: s.id,
          label: s.title,
          disabled,
        };
      }).sort((a, b) => {
        if (a.disabled && !b.disabled) {
          return -1;
        } else if (!a.disabled && b.disabled) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return [];
  }, [
    createOrientationFormData.facilityId,
    createOrientationFormData.orientationDate,
    facilityShifts,
    isConsultant,
    shiftsFromSettings,
  ]);

  const isClientDisabledForCurrentFacility = () => {
    return clientProfile?.disabledFacilities?.includes(Number(facilityId));
  };

  const handleFormClose = () => {
    // 1. Reset side panel state
    dispatch(
      setSidePanel({
        name: "",
        status: false,
        type: "",
      })
    );
    // 2. Reset confirmation modal state
    setShowConfirmation(false);
    // 3. Reset form data
    setCreateOrientationFormData({
      ...DEFAULT_JOB_FORM_DATA,
      facilityId: isConsultant ? "" : facilityId,
    });
    // 4. Reset errors
    setCreateOrientationFormErrors(DEFAULT_JOB_FORM_DATA);
  };
  const getFacilityShifts = async (id: string) => {
    const facilityId = Number(id);
    const response = await getShiftsByFacilityId(facilityId);
    const shifts = get(response, ["data", "data"], []) || [];
    const facilityTimeZoneId = getFacilityTimezoneId(facilityId);
    const shiftsData: { [key: string]: any } = { ...facilityShifts };
    shiftsData[id] = shifts.map((s: any) => ({
      ...s,
      startTime: convertUtcTimeToLocalTime(s.startTime, facilityTimeZoneId),
      endTime: convertUtcTimeToLocalTime(s.endTime, facilityTimeZoneId),
    }));
    setFacilityShifts(shiftsData);
  };

  const onCreateOrientationFormChange = async (
    id: string,
    value: string | number | null | boolean
  ) => {
    if (id === "facilityId" && value) {
      value = String(value);
      setCreateOrientationFormData({
        ...createOrientationFormData,
        facilityId: String(value),
        shiftId: "",
        contactEmail: "",
        contactMobile: "",
        contactName: "",
      });
      // Resetting errors
      setCreateOrientationFormErrors({
        ...createOrientationFormErrors,
        facilityId: "",
        shiftId: "",
        contactEmail: "",
        contactMobile: "",
        contactName: "",
      });
      const shiftDataExists = !isEmpty(get(facilityShifts, value));
      if (!shiftDataExists) {
        getFacilityShifts(value);
      }
    } else if (
      id === "shiftId" ||
      id === "orientationDate" ||
      id === "postToPoolWhenWithdrawn" ||
      !value
    ) {
      const updatedState = {
        ...createOrientationFormData,
        [id]: value,
      };
      if (!value && (id === "orientationDate" || id === "facilityId")) {
        updatedState.shiftId = ""
      }
      setCreateOrientationFormData(updatedState);
      // Resetting errors
      setCreateOrientationFormErrors({
        ...createOrientationFormErrors,
        [id]: "",
      });
    }
  };
  const setSelectedContact = (contact: ContactTypes) => {
    const { email, name, officePhone, mobile } = contact;
    let phone = mobile ? mobile : officePhone;
    if (phone.includes("+1 ")) {
      phone = phone.replace("+1 ", "");
    } else if (phone.includes("+1")) {
      phone = phone.replace("+1", "");
    }
    setCreateOrientationFormData((prev) => ({
      ...prev,
      contactMobile: phone,
      contactName: name,
      contactEmail: email,
    }));
  };
  function getFacilityTimezoneId(facilityId: number | string) {
    return isConsultant
      ? get(
        facilityList.filter((f) => Number(f.id) === Number(facilityId)),
        ["0", "timeZoneId"],
        ""
      )
      : timeZoneId;
  }
  const handleSave = () => {
    const {
      facilityId,
      shiftId,
      orientationDate,
      contactEmail,
      contactMobile,
      contactName,
      postToPoolWhenWithdrawn,
    } = createOrientationFormData;
    // Get client's job template id
    // Facility's shifts
    const shiftsData = isConsultant
      ? facilityShifts[facilityId]
      : shiftsFromSettings;
    // Selected shift for this orientation
    const selectedShift = shiftsData?.filter(
      (s) => Number(s.id) === Number(shiftId)
    )?.[0];
    let { startTime, endTime } = selectedShift;
    const facilityTimeZoneId = getFacilityTimezoneId(facilityId);
    let { startDateTime, endDateTime } = getShiftStartEndDateTimes(
      orientationDate,
      startTime,
      endTime,
      true,
      facilityTimeZoneId
    );
    if (clientProfile.id && clientProfile?.jobTemplateId) {
      const payload = {
        requestedBy: String(clientProfile.id),
        facilityId,
        jobTemplateId: clientProfile.jobTemplateId,
        requestedDates: [dayjs(startDateTime).format("YYYY-MM-DD")],
        shiftId,
        shiftStartDateTime: startDateTime,
        shiftEndDateTime: endDateTime,
        contactName,
        contactMobile,
        contactEmail,
        postToPoolWhenWithdrawn,
      };
      dispatch(createOrientationThunk(payload, handleFormClose));
    }
  };

  const onValidate = () => {
    const { valid, errorObject } = validateForm(
      createOrientationFormData,
      createOrientationFormValidationConfig,
      createOrientationFormErrors
    );
    if (valid) {
      handleSave();
    } else {
      setCreateOrientationFormErrors(errorObject);
    }
  };
  if (isLoading || !clientProfile.id) {
    return (
      <div className="p-3">
        <Skeleton active avatar />
        <Divider />
        <div className="pl-14">
          <Skeleton active />
        </div>
      </div>
    );
  }
  const discardForm = () => {
    const { facilityId, orientationDate, shiftId } = createOrientationFormData;
    if (facilityId || orientationDate || shiftId) {
      setShowConfirmation(true);
    } else {
      handleFormClose();
    }
  };
  return (
    <>
      <ActionButtons
        clientId={clientProfile.id}
        isProfileApproved={clientProfile.isProfileVerified}
        hasPendingDocs={clientProfile.hasPendingDocumentsToApprove}
        isClientDisabled={isClientDisabledForCurrentFacility()}
      />
      <div className="flex gap-3 pr-3">
        <div className="flex flex-col w-[25%] gap-2">
          <UserProfile
            firstName={clientProfile.firstName}
            lastName={clientProfile.lastName}
            avatarUrl={clientProfile.avatarUrl}
            isVerified={clientProfile.isProfileVerified}
            jobCategory={clientProfile.jobCategory}
            email={clientProfile.email}
            phone={clientProfile.phoneNumber}
            legalStatus={get(clientProfile, ["workEligibility", "legalStatus"])}
            address={get(clientProfile, ["address", 0])}
          />
          <CurrentEmployment currentEmployer={clientProfile.currentEmployer} />
        </div>
        {(isConsultant) && (
          <div className="flex flex-col w-[30%] gap-2">
            <Documents
              clientId={clientProfile.id}
              documents={clientProfile.documents}
              hasPendingDocs={clientProfile.hasPendingDocumentsToApprove}
              isConsultant={isConsultant}
            />
            {!isEmpty(clientProfile.deviceDetails) && isConsultant && (
              <DeviceDetails deviceDetails={clientProfile.deviceDetails} />
            )}
          </div>
        )}
        <div className="flex flex-col w-[25%] gap-2">
          <WorkEligibility
            workEligibilityInfo={clientProfile.workEligibility}
            license={clientProfile.licenseNumber}
            jobCategory={clientProfile.jobCategory}
            isConsultant={isConsultant}
          />
          {isConsultant && (
            <BankInfo
              bankInfo={clientProfile.payout}
              isConsultant={isConsultant}
            />
          )}
        </div>
        <div className="flex flex-col w-[20%] gap-2">
          <WorkHistory workHistory={clientProfile.workHistory} />
          <EducationInfo eductionInfo={clientProfile.educationHistory} />
        </div>
      </div>
      {openDrawer && (
        <SideDrawer
          open={
            openDrawer?.status &&
            openDrawer?.name === SIDE_PANEL_NAMES.clients &&
            openDrawer?.type === SIDE_PANEL_TYPES.createJobForClient
          }
          title={`Create Job for ${clientName}`}
          onSubmit={onValidate}
          showFooter={true}
          onClose={discardForm}
          headerBtnTitle={"Create Job"}
          primaryButton="Save"
          disablePrimaryBtn={isPostJobPending}
        >
          <CreateOrientationForm
            data={createOrientationFormData}
            onChange={onCreateOrientationFormChange}
            errors={createOrientationFormErrors}
            shiftsOptions={shiftsOptions}
            isConsultant={isConsultant}
            selectedContact={setSelectedContact}
            clientName={clientName}
          />
          {showConfirmation && (
            <ConfirmationModal
              text={t("alertMessages.discardConfirmation")}
              open={showConfirmation}
              handleYes={handleFormClose}
              handleNo={() => setShowConfirmation(false)}
            />
          )}
        </SideDrawer>
      )}
    </>
  );
};

export default ClientProfile;
