import dayjs from "dayjs";
import { Calendar, DateObject } from "react-multi-date-picker";
import "./index.css";
import type { Value } from "react-multi-date-picker";
import Icon from "../Icon";
import { useEffect, useState } from "react";

type DATE_SELECTOR_PROP_TYPES = {
  getSelectedDate: (
    selectedDates: string[] | undefined,
    calendarValues: Value
  ) => void;
  values: Value | null;
  datesToDisable?: string[];
};

const MultiDateSelector = (props: DATE_SELECTOR_PROP_TYPES) => {
  const { getSelectedDate, values, datesToDisable } = props;
  const [calendarValues, setCalendarValues] = useState(values);

  useEffect(() => {
    setCalendarValues(values);
    return () => setCalendarValues([]);
  }, [values]);

  const handleChange = (value: DateObject | DateObject[] | null) => {
    let selectedDates;
    if (value && Array.isArray(value)) {
      selectedDates = value.map((item) => item.format());
    } else if (value) {
      selectedDates = [value.format()];
    }
    getSelectedDate(selectedDates, value);
  };

  const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  const RightButton = () => (
    <div className="mr-2 circle-button">
      <Icon name="chevron_right" />
    </div>
  );
  const LeftButton = () => (
    <div className="ml-2 circle-button">
      <Icon name="chevron_left" />
    </div>
  );

  return (
    <Calendar
      value={calendarValues}
      onChange={handleChange}
      weekDays={weekDays}
      multiple
      renderButton={(direction: any, handleClick: any) => (
        <button onClick={handleClick}>
          {direction === "right" ? <RightButton /> : <LeftButton />}
        </button>
      )}
      mapDays={({ date }) => {
        const props = { style: { boxShadow: "none" } };
        const today = new DateObject();
        const disable = dayjs(today.format()).diff(dayjs(date.format())) > 0;
        const disableOtherDates = datesToDisable?.find(s => {
          return dayjs(s).format('DD-MM-YYYY').toString() === date.format('DD-MM-YYYY').toString()
        });
        if (disable || disableOtherDates) {
          return { disabled: true, style: props.style };
        }

        return props;
      }}
    />
  );
};

export default MultiDateSelector;
