import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  DropDownOptionTypes,
  FacilitiesErrorsTypes,
  FacilitiesTypes,
} from "../../models/types";
import { useAppSelector } from "../../redux/hooks";
import Dropdown from "../common/Dropdown";
import FacilityTabs from "./FacilityTabs";
import Logo from "./Logo";
import { Address } from "../../helpers/googlePlacesHelpers";
import AddressAutoComplete from "../common/AddressAutoComplete";
import InputField from "../common/InputField";
import { isEmpty } from "lodash";
import { getUserTimeZoneFromGoogle } from "../../services/app.services";
import { Checkbox } from "antd";

type FACILITIES_FORM_TYPES = {
  data: FacilitiesTypes;
  onChange: (id: string, value: string | number | null | boolean) => void;
  onMultipleChange: (changes: Record<string, string | number | null>) => void;
  handleContactChange: (
    index: number,
    id: string,
    value: string | number | null
  ) => void;
  addContacts: () => void;
  deleteContact: (index: number) => void;
  errors: FacilitiesErrorsTypes;
  setLogo: (file: any) => void;
  logo: string;
};
const FacilitiesForm = (props: FACILITIES_FORM_TYPES) => {
  const {
    data,
    onChange,
    onMultipleChange,
    addContacts,
    deleteContact,
    handleContactChange,
    setLogo,
    logo,
    errors,
  } = props;

  const { t } = useTranslation();
  const facilityTypes = useAppSelector(
    (state) => state.appReducer.facilityTypes
  );
  const provinceData = useAppSelector((state) => state.appReducer.provinces);
  const facilityTypeOptions: DropDownOptionTypes[] = useMemo(() => {
    return facilityTypes.map((item) => ({
      value: item.id,
      label: item.title,
    }));
  }, [facilityTypes]);
  const provinceOptions: DropDownOptionTypes[] = useMemo(() => {
    return provinceData.map((item) => ({
      value: item.id,
      label: item.title,
    }));
  }, [provinceData]);

  const facilitiesData = useAppSelector(
    (state) => state.facilitiesReducer.facilities
  );
  const selectedFacility = useMemo(() => {
    return facilitiesData.filter(
      (item) => Number(item.id) === Number(data.id)
    )?.[0];
  }, [facilitiesData, data.id]);

  const disableFields = useMemo(() => {
    if (!isEmpty(selectedFacility) && !isEmpty(data)) {
      return (
        data.latitude === selectedFacility.latitude &&
        data.longitude === selectedFacility.longitude
      );
    } else {
      return false;
    }
  }, [data, selectedFacility]);

  const updateAddressDetails = useCallback(
    async (address: Partial<Address>, name: string) => {
      const updatedData = { ...address, title: name };
      if (updatedData.latitude && updatedData.longitude) {
        const { timeZoneId, timeZoneName } = await getUserTimeZoneFromGoogle(
          updatedData.latitude,
          updatedData.longitude
        );
        updatedData.timeZoneId = timeZoneId;
        updatedData.timeZoneName = timeZoneName;
      }
      onMultipleChange(updatedData);
    },
    [onMultipleChange]
  );

  return (
    <div>
      <div className="flex w-full justify-center mb-2">
        <div className="text-center relative">
          <Logo
            facilityId={data.id}
            logo={logo}
            setLogo={setLogo}
            disableUpload={!data.id}
          />
        </div>
      </div>
      <div>
        <div className="pr-2">
          <Dropdown
            id="facilityTypeId"
            label={t("facilities.type") || ""}
            value={data.facilityTypeId || ""}
            onChange={onChange}
            required={true}
            options={facilityTypeOptions}
            className="w-full"
            message={errors.facilityTypeId}
            status={errors.facilityTypeId ? "error" : ""}
          />
        </div>

        <AddressAutoComplete
          setSelectedLocation={updateAddressDetails}
          label="Search Facility"
          message={errors.searchFacility}
          error={errors.searchFacility}
        />
        {!!data.latitude && !!data.longitude && (
          <div>
            <InputField
              type="text"
              id="title"
              label={t("facilities.facilityName") || ""}
              value={data.title}
              onChange={onChange}
              required={true}
              message={errors.title}
              status={errors.title ? "error" : ""}
            />
            <div className="flex justify-between">
              <InputField
                type="text"
                id="streetNumber"
                label={t("facilities.streetNumber") || ""}
                value={data.streetNumber}
                onChange={onChange}
                required={true}
                className="w-full"
                message={errors.streetNumber}
                status={errors.streetNumber ? "error" : ""}
                disabled={disableFields}
              />
              <InputField
                type="text"
                id="streetName"
                label={t("facilities.streetName") || ""}
                value={data.streetName}
                onChange={onChange}
                required={true}
                className="w-full"
                message={errors.streetName}
                status={errors.streetName ? "error" : ""}
                disabled={disableFields}
              />
            </div>
            <div className="flex justify-between">
              <InputField
                type="text"
                id="city"
                label={t("facilities.city") || ""}
                value={data.city}
                onChange={onChange}
                required={true}
                className="w-full"
                message={errors.city}
                status={errors.city ? "error" : ""}
                disabled={disableFields}
              />
              <InputField
                type="text"
                id="postalCode"
                label={t("facilities.postalCode") || ""}
                value={data.postalCode}
                onChange={onChange}
                className="w-full"
                required={true}
                message={errors.postalCode}
                status={errors.postalCode ? "error" : ""}
                disabled={disableFields}
              />
            </div>
            <div className="flex">
              <Dropdown
                id="provinceId"
                label={t("facilities.province") || ""}
                value={data.provinceId || ""}
                onChange={onChange}
                required={true}
                options={provinceOptions}
                className="w-full"
                message={errors.provinceId}
                status={errors.provinceId ? "error" : ""}
                disabled={disableFields}
              />
            </div>
          </div>
        )}
      </div>
      <div className="pl-2">
        <Checkbox
          id="noOrientationForEmployees"
          checked={data.noOrientationForEmployees}
          className="mb-3"
          onChange={(e) =>
            onChange("noOrientationForEmployees", e.target.checked)
          }
        >
          Orientation is not required for employees
        </Checkbox>
        <Checkbox
          checked={data.showJobsOnlyToEmployees}
          className="mb-3"
          onChange={(e) =>
            onChange("showJobsOnlyToEmployees", e.target.checked)
          }
        >
          Show jobs only to employees
        </Checkbox>
        <Checkbox
          checked={data.hideHourlyRateForEmployees}
          className="mb-3"
          onChange={(e) =>
            onChange("hideHourlyRateForEmployees", e.target.checked)
          }
        >
          Hide Hourly Rate for Employees
        </Checkbox>
      </div>
      <FacilityTabs
        contactData={data.contacts}
        users={data.users}
        addContacts={addContacts}
        deleteContact={deleteContact}
        handleContactChange={handleContactChange}
        errors={errors}
        facilityId={data.id}
        facilityName={data.title}
        hideResetPassword={data.id ? false : true}
      />
    </div>
  );
};

export default FacilitiesForm;
