import dayjs from "dayjs";
import { DayPropTypes, WeekPropTypes } from "../../../models/day.model";
import Day from "../day/Day";
import "./Week.css";

const Week = (props: WeekPropTypes) => {
  const { days, showSummary, selectedDay, showPanel } = props;
  return (
    <>
      {days.map((day: DayPropTypes, index: number) => (
        <div
          className={`calendar-column day ${
            dayjs(selectedDay).isSame(dayjs(day.day)) ? "active-day" : ""
          }`}
          key={index}
        >
          <Day
            key={day.id}
            {...day}
            showSummary={showSummary}
            showPanel={showPanel}
          />
        </div>
      ))}
    </>
  );
};

export default Week;
