import { baseUrl, getInstance } from ".";
import { CreateOrientation } from "../models/types";

const instance = getInstance(baseUrl);
export type OrientationRequestFilterTypes = {
  jobCategory: string[] | [];
  status: "scheduled" | "unscheduled" | "all";
};

export type SuggestDatePayload = {
  id: string;
  shiftId: string;
  suggestedDates: string[];
};

export type ApproveDatePayload = {
  id: string;
  approvedDate: string;
  shiftId: string;
  shiftStartDateTime: string;
  shiftEndDateTime: string;
};

export const fetchOrientationRequests = async (
  facilityId: string,
  filters?: OrientationRequestFilterTypes
) => {
  // let filter = "/";
  // if (filters?.status === "scheduled") {
  //   filter = "/?status=scheduled";
  // } else if (filters?.status === "unscheduled") {
  //   filter = "/?status=unscheduled";
  // } else {
  //   filter = "/?_sort=suggestedDate";
  // }
  // if (!isEmpty(filters?.jobCategory)) {
  //   filters?.jobCategory.forEach(
  //     (id, index) => (filter = `${filter}&jobCategory=${id}`)
  //   );
  // }
  return instance.get(`/orientation/facility/${facilityId}`);
};
export const suggestNewDate = async (payload: SuggestDatePayload) => {
  return instance.post("/orientation/suggest", payload);
};

export const approveOrientation = async (payload: ApproveDatePayload) => {
  return instance.post("/orientation/approve", payload);
};

export const fetchOverlappingJobsForOrientationSuggestion = async (
  id: number,
  shiftId: string
) => {
  return instance.get(`/orientation/${id}/overlapping-job-date/${shiftId}`);
};

export const createOrientation = async (payload: CreateOrientation) => {
  return instance.post("/job/create-job-for-user", payload);
};
