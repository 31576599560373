import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ClientInvoiceTable from "./ClientInvoiceTable";
import {
  fetchInvoiceCyclesThunk,
  getClientInvoicesOnScrollThunk,
  getClientInvoicesThunk,
} from "../../redux/thunks/invoices";
import { PAGINATION } from "../../constants/pagination";
import {
  removeFirstClientInvoices,
  removeLastClientInvoices,
} from "../../redux/reducers/invoices";
import PaginationScroll from "../common/PaginationScroll";
import { DropDownOptionTypes, InvoiceCycle } from "../../models/types";
import { set } from "lodash";

const ClientInvoices = () => {
  const dispatch = useAppDispatch();
  const invoices = useAppSelector(
    (state) => state.invoiceReducer.clientInvoices
  );
  const hasNextPage = useAppSelector(
    (state) => state.invoiceReducer.hasNextPage
  );

  const [selectedPaymentStatus, setSelectedPaymentStatus] =
    useState<string>("due");
  const [pageNumber, setPageNumber] = useState(PAGINATION.defaultPageNumber);
  const [adjustScroll, setAdjustScroll] = useState(false);

  useEffect(() => {
    // Dispatching action to load the requests when page renders for the first time
    dispatch(
      getClientInvoicesThunk(
        PAGINATION.defaultPageSize,
        PAGINATION.defaultPageNumber,
        selectedPaymentStatus
      )
    );
    dispatch(fetchInvoiceCyclesThunk());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilters = (key: "segmented", value: string) => {
    if (
      key === "segmented" &&
      (value === "due" || value === "paid" || value === "all")
    ) {
      dispatch(
        getClientInvoicesThunk(
          PAGINATION.defaultPageSize,
          PAGINATION.defaultPageNumber,
          value
        )
      );
      setSelectedPaymentStatus(value);
    }
  };

  const prev = async () => {
    if (pageNumber > 4) {
      setAdjustScroll(false);

      await dispatch(
        getClientInvoicesOnScrollThunk(
          PAGINATION.defaultPageSize,
          pageNumber - 4,
          selectedPaymentStatus,
          true
        )
      );
      setPageNumber(pageNumber - 1);

      dispatch(removeLastClientInvoices());
      setAdjustScroll(true);
    }
  };

  const next = async () => {
    setAdjustScroll(false);

    await dispatch(
      getClientInvoicesOnScrollThunk(
        PAGINATION.defaultPageSize,
        pageNumber + 1,
        selectedPaymentStatus
      )
    );

    setPageNumber(pageNumber + 1);

    if (pageNumber > 3) {
      dispatch(removeFirstClientInvoices());
      setAdjustScroll(true);
    }
  };

  return (
    <div className="h-full bg-white flex">
      <div className="mr-3 w-full">
        <PaginationScroll
          next={next}
          prev={prev}
          hasNext={hasNextPage}
          adjustScroll={adjustScroll}
        >
          <ClientInvoiceTable data={invoices} handleFilters={handleFilters} />
        </PaginationScroll>
      </div>
    </div>
  );
};

export default ClientInvoices;
