import { AppDispatch } from "../store";
import {
  markAsPaid,
  fetchFacilityInvoices,
  fetchClientInvoices,
  generateClientInvoices,
  generateFacilityInvoices,
  checkClientinvoiceReadyToGenerate,
  checkFacilityinvoiceReadyToGenerate,
  getFailedJobs,
  retryClientInvoiceGeneration,
  retryFacilityInvoiceGeneration,
  GenerateInvoicePayload,
  fetchInvoiceCycles,
  downloadInvoiceSummaryCSV,
} from "../../services/invoices.services";
import {
  setClientInvoiceCycles,
  setClientInvoiceGenerationEligibility,
  setClientInvoiceHasNextPage,
  setClientInvoices,
  setClientInvoicesClone,
  setClientInvoicesClonePrepend,
  setFacilityInvoiceGenerationEligibility,
  setFacilityInvoices,
  setFailedJobs,
  setInvoiceLoading,
} from "../reducers/invoices";
import {
  ClientInvoiceSummaryRequest,
  ClientInvoiceTypes,
  FacilityInvoiceTypes,
  InvoiceCycle,
  ScheduledJob,
} from "../../models/types";
import { handleApiErrors, logToSentry } from "../../helpers/helpers";
import { AxiosError } from "axios";
import { showToast } from "../reducers/feedbackReducer";
import { downloadUserDocumentThunk } from "./app";
import { PAGINATION } from "../../constants/pagination";

export const getFacilitiesInvoicesThunk =
  (
    facilityId: number,
    pageSize?: number,
    pageNumber?: number,
    paymentStatus?: string
  ) =>
    async (dispatch: AppDispatch) => {
      dispatch(setInvoiceLoading(true));
      try {
        const response = await fetchFacilityInvoices(
          facilityId,
          pageSize,
          pageNumber,
          paymentStatus === "all" ? "" : paymentStatus
        );
        const data = response.data.data;
        dispatch(
          setFacilityInvoices(
            (data?.finalResponse?.data || []) as FacilityInvoiceTypes[]
          )
        );
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Invoices", "getFacilitiesInvoicesThunk");
      } finally {
        dispatch(setInvoiceLoading(false));
      }
    };

export const getFacilitiesInvoicesOnScrollThunk =
  (
    facilityId: number,
    pageSize?: number,
    pageNumber?: number,
    paymentStatus?: string,
    prependData: boolean = false
  ) =>
    async (dispatch: AppDispatch) => {
      dispatch(setInvoiceLoading(true));
      try {
        const response = await fetchFacilityInvoices(
          facilityId,
          pageSize,
          pageNumber,
          paymentStatus === "all" ? "" : paymentStatus
        );
        const data = response.data.data;
        if (!prependData) {
          dispatch(
            setClientInvoicesClone(
              (data?.finalResponse?.data || []) as ClientInvoiceTypes[]
            )
          );
        } else {
          dispatch(
            setClientInvoicesClonePrepend(
              (data?.finalResponse?.data || []) as ClientInvoiceTypes[]
            )
          );
        }
        dispatch(setClientInvoiceHasNextPage(data?.hasNextPage ?? false));
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Invoices", "getFacilitiesInvoicesOnScrollThunk");
      } finally {
        dispatch(setInvoiceLoading(false));
      }
    };

export const getClientInvoicesThunk =
  (pageSize?: number, pageNumber?: number, paymentStatus?: string) =>
    async (dispatch: AppDispatch) => {
      dispatch(setInvoiceLoading(true));
      try {
        const response = await fetchClientInvoices(
          pageSize,
          pageNumber,
          paymentStatus === "all" ? "" : paymentStatus
        );
        const data = response.data.data;
        dispatch(
          setClientInvoices(
            (data?.finalResponse?.data || []) as ClientInvoiceTypes[]
          )
        );
        dispatch(setClientInvoiceHasNextPage(data?.hasNextPage ?? false));
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Invoices", "getClientInvoicesThunk");
      } finally {
        dispatch(setInvoiceLoading(false));
      }
    };

export const getClientInvoicesOnScrollThunk =
  (
    pageSize?: number,
    pageNumber?: number,
    paymentStatus?: string,
    prependData: boolean = false
  ) =>
    async (dispatch: AppDispatch) => {
      dispatch(setInvoiceLoading(true));
      try {
        const response = await fetchClientInvoices(
          pageSize,
          pageNumber,
          paymentStatus === "all" ? "" : paymentStatus
        );
        const data = response.data.data;
        if (!prependData) {
          dispatch(
            setClientInvoicesClone(
              (data?.finalResponse?.data || []) as ClientInvoiceTypes[]
            )
          );
        } else {
          dispatch(
            setClientInvoicesClonePrepend(
              (data?.finalResponse?.data || []) as ClientInvoiceTypes[]
            )
          );
        }
        dispatch(setClientInvoiceHasNextPage(data?.hasNextPage ?? false));
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Invoices", "getClientInvoicesOnScrollThunk");
      } finally {
        dispatch(setInvoiceLoading(false));
      }
    };

export const markAsPaidThunk =
  (invoices: number[], entity: "facility" | "client", facilityId: number) =>
    async (dispatch: AppDispatch) => {
      dispatch(setInvoiceLoading(true));
      try {
        const responses = await markAsPaid(invoices);
        let succeeded = [];
        let failed = [];
        responses.forEach((res, idx) => {
          if (res.status === "fulfilled") {
            succeeded.push(invoices[idx]);
          } else if (res.status === "rejected") {
            failed.push(invoices[idx]);
          }
        });
        if (succeeded.length) {
          entity === "facility"
            ? dispatch(
              getFacilitiesInvoicesThunk(
                facilityId,
                PAGINATION.defaultPageSize,
                PAGINATION.defaultPageNumber,
                "due"
              )
            )
            : dispatch(
              getClientInvoicesThunk(
                PAGINATION.defaultPageSize,
                PAGINATION.defaultPageNumber,
                "due"
              )
            );
        }
        if (failed.length) {
          dispatch(
            showToast({
              type: "error",
              message:
                "Could not mark some of the invoices as paid, please try again.",
              show: true,
            })
          );
        }
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Invoices", "markAsPaidThunk");
      } finally {
        dispatch(setInvoiceLoading(false));
      }
    };
export const downloadPdfThunk =
  ({ fileId, invoiceNumber }: { fileId: number; invoiceNumber: string }) =>
    async (dispatch: AppDispatch) => {
      dispatch(setInvoiceLoading(true));
      try {
        dispatch(downloadUserDocumentThunk(fileId, invoiceNumber));
      } catch (err) {
        handleApiErrors(err as AxiosError, dispatch);
        logToSentry(err, "Invoices", "downloadPdfThunk");
      } finally {
        dispatch(setInvoiceLoading(false));
      }
    };
export const generateClientInvoicesThunk =
  (payload: GenerateInvoicePayload) => async (dispatch: AppDispatch) => {
    try {
      await generateClientInvoices(payload);
      dispatch(
        showToast({
          type: "success",
          message: "Client Invoices generated successfully",
          show: true,
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Invoices", "generateClientInvoicesThunk");
    }
  };
export const generateFacilityInvoicesThunk =
  (payload: GenerateInvoicePayload) => async (dispatch: AppDispatch) => {
    try {
      await generateFacilityInvoices(payload);
      dispatch(
        showToast({
          type: "success",
          message: "Facility Invoices generated successfully",
          show: true,
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Invoices", "generateFacilityInvoicesThunk");
    }
  };

export const isClientInvoiceReadyToGenerateThunk =
  () => async (dispatch: AppDispatch) => {
    try {
      await checkClientinvoiceReadyToGenerate().then((response) => {
        dispatch(setClientInvoiceGenerationEligibility(response.data));
      });
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Invoices", "isClientInvoiceReadyToGenerateThunk");
    }
  };

export const isFacilityInvoiceReadyToGenerateThunk =
  () => async (dispatch: AppDispatch) => {
    try {
      await checkFacilityinvoiceReadyToGenerate().then((response) => {
        dispatch(setFacilityInvoiceGenerationEligibility(response.data));
      });
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Invoices", "isFacilityInvoiceReadyToGenerateThunk");
    }
  };
export const getFailedJobsThunk = () => async (dispatch: AppDispatch) => {
  try {
    const response = await getFailedJobs();
    dispatch(setFailedJobs((response?.data || []) as ScheduledJob[]));
  } catch (err) {
    handleApiErrors(err as AxiosError, dispatch);
    logToSentry(err, "Invoices", "getFailedJobsThunk");
  }
};
export const retryClientInvoiceGenerationThunk =
  (id: number) => async (dispatch: AppDispatch) => {
    try {
      await retryClientInvoiceGeneration(id);
      dispatch(
        showToast({
          type: "success",
          message: "Failed Client Invoices generated successfully",
          show: true,
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Invoices", "retryClientInvoiceGenerationThunk");
    }
  };
export const retryFacilityInvoiceGenerationThunk =
  (id: number) => async (dispatch: AppDispatch) => {
    try {
      await retryFacilityInvoiceGeneration(id);
      dispatch(
        showToast({
          type: "success",
          message: "Failed Facility Invoices generated successfully",
          show: true,
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
      logToSentry(err, "Invoices", "retryFacilityInvoiceGenerationThunk");
    }
  };
export const fetchInvoiceCyclesThunk =
  () => async (dispatch: AppDispatch) => {
    try {
      const response = await fetchInvoiceCycles('client');
      dispatch(setClientInvoiceCycles((response?.data.data || []) as InvoiceCycle[]));
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
    }
  };

export const downloadClientInvoiceSummaryThunk =
  (payload: ClientInvoiceSummaryRequest, cycle: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await downloadInvoiceSummaryCSV(payload);
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const fileTitle = `invoice_summary_${cycle}.csv`;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileTitle);
      document.body.appendChild(link);
      link.click();
      link.remove();
      dispatch(
        showToast({
          type: "success",
          message: "Successfully downloaded invoice summary",
          show: true,
        })
      );
    } catch (err) {
      handleApiErrors(err as AxiosError, dispatch);
    }
  };
