import { MonthPropTypes, WeekPropTypes } from "../../../models/day.model";
import WeekHeader from "../week-header/WeekHeader";
import Week from "../week/Week";
import "./Month.css";

const Month = (props: MonthPropTypes) => {
  const { weeks, showSummary, selectedDay, showPanel } = props;
  return (
    <div className="calendar-sheet-container">
      <WeekHeader />
      {weeks.map((week: WeekPropTypes, index: number) => (
        <div key={index} className="week-row">
          <Week
            days={week.days}
            showSummary={showSummary}
            selectedDay={selectedDay}
            showPanel={showPanel}
          />
        </div>
      ))}
    </div>
  );
};

export default Month;
