import { useState } from "react";
import InputField from "../../common/InputField";
import RadioButton from "../../common/RadioButton";
import ConfirmationModal from "../../common/ConfirmationModal";
import { UserBankTypes } from "../../../models/types";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateBankInfoThunk } from "../../../redux/thunks/clients";

type BankDetailsFormProps = {
  bankInfo: UserBankTypes;
  showForm: boolean;
  hideForm: () => void;
};

const BankDetailsForm = (props: BankDetailsFormProps) => {
  const { showForm, hideForm, bankInfo } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clientProfile = useAppSelector(
    (state) => state.clientsReducer.clientProfile
  );
  const isUpdateInProgress = useAppSelector(
    (state) => state.clientsReducer.isUpdateInProgress
  );
  const [formState, setFormState] = useState<any>(bankInfo);

  const [errors, setErrors] = useState<any>({
    accountType: "",
    bankName: "",
    accountHolderName: "",
    institutionNumber: "",
    transitNumber: "",
    accountNumber: "",
    confirmAccountNumber: "",
    userId: "",
  });

  const handleChange = (id: string, value: string | number | null) => {
    setFormState({ ...formState, [id]: value });
    if (errors[id]) {
      setErrors({ ...errors, [id]: "" });
    }
  };

  const validateInstitutionNumber = (value: string) => {
    if (!value?.trim()) {
      return "Institution number is required";
    }
    if (!/^\d{3}$/.test(value?.trim())) {
      return "Institution number must be exactly 3 digits";
    }
    return "";
  };

  const validateTransitNumber = (value: string) => {
    if (!value?.trim()) {
      return t("errors.transitNumber");
    }
    if (!/^\d{5}$/.test(value?.trim())) {
      return t("errors.transitNumberDigits");
    }
    return "";
  };

  const validateAccountNumber = (value: string) => {
    if (!value?.trim()) {
      return t("errors.accountNumber");
    }
    if (!/^\d{7}$/.test(value?.trim())) {
      return t("errors.accountNumberDigits");
    }
    return "";
  };

  const validateConfirmAccountNumber = (value: string) => {
    if (!value?.trim()) {
      return t("errors.confirmAccountNumber");
    }
    if (value?.trim() !== formState?.accountNumber?.trim()) {
      return t("errors.confirmAccountNumberMatching");
    }
    return "";
  };

  const handleValidation = () => {
    let valid = true;
    const errorsCopy: any = { ...errors };

    if (!formState?.accountType) {
      valid = false;
      errorsCopy.accountType = t("errors.accountType");
    }

    if (!formState?.bankName) {
      valid = false;
      errorsCopy.bankName = t("errors.bankName");
    }

    if (!formState?.accountHolderName) {
      valid = false;
      errorsCopy.accountHolderName = t("errors.accountHolderName");
    }

    errorsCopy.institutionNumber = validateInstitutionNumber(
      formState?.institutionNumber
    );
    errorsCopy.transitNumber = validateTransitNumber(formState?.transitNumber);
    errorsCopy.accountNumber = validateAccountNumber(formState?.accountNumber);
    errorsCopy.confirmAccountNumber = validateConfirmAccountNumber(
      formState?.confirmAccountNumber
    );

    if (!valid || Object.values(errorsCopy).some((error) => !!error)) {
      setErrors(errorsCopy);
    } else {
      // Proceed with form submission or further actions
      // Example: dispatch an action to save the form data
      handleSave();
    }
  };

  const handleSave = () => {
    const data = {
      ...formState,
      userId: clientProfile?.id,
      isPayoutCompletion: true,
    };
    dispatch(
      updateBankInfoThunk({ payload: { ...data }, callback: handleClose })
    );
  };

  const handleClose = () => {
    setFormState({
      accountType: "",
      bankName: "",
      accountHolderName: "",
      institutionNumber: "",
      transitNumber: "",
      accountNumber: "",
      confirmAccountNumber: "",
    });
    setErrors({
      accountType: "",
      bankName: "",
      accountHolderName: "",
      institutionNumber: "",
      transitNumber: "",
      accountNumber: "",
      confirmAccountNumber: "",
    });
    hideForm();
  };

  return (
    <ConfirmationModal
      open={showForm}
      title={`${t("clientProfile.updateBankDetails")}`}
      handleYes={handleValidation}
      handleNo={handleClose}
      primaryButtonText={t("common.save") || ""}
      secondaryButtonText={t("common.close") || ""}
      isPrimaryButtonDisabled={isUpdateInProgress}
      text={
        <div className="pt-4">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Account Type *
            </label>
            <div className="flex items-center">
              <RadioButton
                id="accountType"
                options={[
                  { value: "checking", label: "Checking" },
                  { value: "savings", label: "Savings" },
                ]}
                value={formState?.accountType}
                onChange={handleChange}
              />
              {errors?.accountType && (
                <p className="text-xs text-red-500 ml-2">
                  {errors?.accountType}
                </p>
              )}
            </div>
          </div>
          <InputField
            id="bankName"
            type="text"
            label="Bank Name *"
            value={formState?.bankName}
            onChange={handleChange}
            message={errors?.bankName}
            status={errors?.bankName ? "error" : ""}
          />
          <InputField
            id="accountHolderName"
            type="text"
            label="Account Holder Name *"
            value={formState?.accountHolderName}
            onChange={handleChange}
            message={errors?.accountHolderName}
            status={errors?.accountHolderName ? "error" : ""}
          />
          <InputField
            id="institutionNumber"
            type="text"
            label="Institution Number *"
            value={formState?.institutionNumber}
            onChange={handleChange}
            message={errors?.institutionNumber}
            status={errors?.institutionNumber ? "error" : ""}
          />
          <InputField
            id="transitNumber"
            type="text"
            label="Transit Number *"
            value={formState?.transitNumber}
            onChange={handleChange}
            message={errors?.transitNumber}
            status={errors?.transitNumber ? "error" : ""}
          />
          <InputField
            id="accountNumber"
            type="password"
            label="Account Number *"
            value={formState?.accountNumber}
            onChange={handleChange}
            message={errors?.accountNumber}
            status={errors?.accountNumber ? "error" : ""}
          />
          <InputField
            id="confirmAccountNumber"
            type="text"
            label="Confirm Account Number *"
            value={formState?.confirmAccountNumber}
            onChange={handleChange}
            message={errors?.confirmAccountNumber}
            status={errors?.confirmAccountNumber ? "error" : ""}
          />
        </div>
      }
    />
  );
};

export default BankDetailsForm;
