
export const SERVICE_CHARGE_TYPE_TEMPLATE = {
    id: 0,
    title: "",
    rateCut: 0,
    isFlatRateCut: true,
    overrideRateCut: false,
};

export const Errors_Template = {
    rateCut: "",
};