export const ROUTE = {
  scheduleJobs: "/schedule-jobs",
  jobCategory: "/job-category",
  orientations: "/orientations",
  clients: "/clients",
  facilityInvoices: "/facility-invoices",
  shiftApproval: "/shift-approval",
  facilities: "/facilities",
  settings: "/settings",
  clientInvoices: "/client-invoices",
  invoiceScheduler: "/invoice-scheduler",
  serviceCharge: "/service-charge",
  signIn: "/signin",
  upcomingJobs: "/upcoming-jobs",
};
